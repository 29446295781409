import React, { useEffect, useState } from 'react'
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'

import Box from '@mui/material/Box'
import { Steppers4 } from './StepperWeb';
import StepperPhone, { Step4 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import InquiryLayout from './InquiryLayout';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeSubjects } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';



const SubSubjects = () => {

    const dispatch = useDispatch();
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [subject, setSubject] = useState([]);

    console.log(checkboxStates , 'checkboxStates');


    // console.log(checkboxStates);

    const handleCheckboxChange = (value, isChecked , item) => {
        // console.log(value ,isChecked ,item, 'check' );
        const newCheckboxStates = [...checkboxStates];
        const index = newCheckboxStates.indexOf(value);
        if (isChecked) {
            newCheckboxStates.push(value);
        } else if (!isChecked && index !== -1) {
            newCheckboxStates.splice(index, 1);
        }
        setCheckboxStates(newCheckboxStates);
    };


    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)



  const user = JSON?.parse(localStorage?.getItem('user'))

    const isNavigate = () => {
        if (checkboxStates.length <= 0  ) {
            toast.error('Input field is required.');
          }else{
        dispatch(storeSubjects({ subjectIds: checkboxStates }));
        if(postCount>0){
            navigate('/dashboard/classes')
            }else{
          navigate('/classes')
          }
          }
    }
    const subjectData = useSelector((item) => item?.listing?.subjectData)
    const board = useSelector((item) => item?.listing?.boardSelect)
    // console.log(board?.boardName , 'boardSelect');
    const selectSubjectIds = useSelector((item) => item?.listing?.subjectSelectIds)
    console.log(selectSubjectIds, 'selectSubjectIds');


    useEffect(()=>{
        setCheckboxStates(selectSubjectIds ?? []);
    },[selectSubjectIds])


    const goBack = () => {
        navigate(-1);
    };

    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');

    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                <div className='d-flex justify-content-center align-items-center mx-auto' style={{ width: isXs ? '' : '80%' }}>Which of the following {board?.boardName} subjects do you need tuition for?</div>
            </Box>
            <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers4 />
            </div>
            <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step4 />
                </div>
            </div>
            {/* <div className='d-flex justify-content-center'>
                <div className='d-flex' style={{ width: '60%', borderRadius: '0px' }}>
                    <input className="form-control" type="search" placeholder="Search Subject" aria-label="Search" style={{ borderRadius: '0px' }} />
                </div>
            </div> */}

            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                <Box sx={{ width: '50%', height: '23vh', overflowY: 'scroll', border: '1px solid #BCBCBC' }}>
                    {/* <FormControl component="fieldset" sx={{ marginLeft: '5%' }}>
                        {subjectData?.map((item, index) => (
                            <FormGroup key={index} aria-label="position" >
                                <FormControlLabel
                                    control={<Checkbox onChange={(item) => handleCheckboxChange(item.target.value, item.target.checked , item)} />}
                                    checked={checkboxStates?.includes(item.id)}

                                    label={item?.subjectName}
                                    value={item?.id}
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        ))}
                    </FormControl> */}

<FormControl component="fieldset" sx={{ marginLeft: '5%' }}>
      {subjectData?.map((item) => (
        <FormGroup key={item.id} aria-label="position">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                checked={checkboxStates?.includes(item.id)}
              />
            }
            label={item?.subjectName}
            value={item?.id}
            labelPlacement="end"
          />
        </FormGroup>
      ))}
    </FormControl>
                </Box>
            </Box>

            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '2%' : '10%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </div>
            </div>
            <div className='d-flex justify-content-between px-4' >
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </div>
        </InquiryLayout>
    )
}

export default SubSubjects
