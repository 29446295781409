import React, { useEffect, useState } from 'react';
import img from '../../../assets/img.jpg';
import bg_new from '../../../assets/BG_NEW.jpg';
import Otp from '../../../assets/otp.svg';
import book from '../../../assets/book.svg';
import bulb from '../../../assets/bulb.svg';
import line from '../../../assets/line.svg';
import Otp1 from '../../../assets/Otp1.png';
import CurveLine from '../../../assets/CurveLine.png';
import wifi2 from '../../../assets/wifi2.png';
import LogoHeader from '../../../assets/LogoHeader.svg';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Backdrop, Box, Button, CircularProgress, TextField, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { studentLogin, updateStudentdata, verifyOtp } from '../../features/student/studentSlice';

const SignupOtp = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [storedFirstName, setStoredFirstName] = useState('');
    const [storedLastName, setStoredLastName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // State to manage the remaining time
    const [timeLeft, setTimeLeft] = useState(60); // 2 minutes in seconds

    const isRegister = useSelector((state) => state.student.studentData.isRegister)
    const userId = useSelector((state) => state.student.studentData.id)
    const mobile = useSelector((state) => state.student.studentMobile)

    // const otpVerify = useSelector((state) => state.student.studentData.otp)

    const allData = useSelector((state) => state.student.studentData)
    localStorage.setItem('user', JSON.stringify(allData))

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isRegister === 0) {
            if (!otp || !firstName.trim() || !lastName.trim()) {
                toast.error('All fields required')
                return
            }
            dispatch(verifyOtp({ firstName: firstName, lastName: lastName, userId: userId, otp: otp, roleId: 2 }))
                .then((apiResponse) => {
                    if (apiResponse?.payload?.data) {
                        dispatch(updateStudentdata(apiResponse?.payload?.data))
                        toast.success(apiResponse?.payload?.message);
                        if(apiResponse?.payload?.data?.postCount==0){
                            navigate('/subject')
                        }else{
                            navigate('/dashboard/home')
                        }
                        // navigate('/successfull')
                    } else {
                        const errotMessage = apiResponse?.payload?.message
                        toast.error(errotMessage)
                    }
                })
                .catch((error) => {
                    toast(error?.message || "An error occurred while logging in.");
                });
            // dispatch(updateStudentdata(firstName, lastName));
        }

        if (isRegister === 1) {
            if (!otp || isRegister ? '' : !firstName.trim() || !lastName.trim()) {
                toast.error('Complete all the fields');
                return
            }
            dispatch(verifyOtp({ firstName: firstName, lastName: lastName, userId: userId, otp: otp }))
                .then((apiResponse) => {
                    if (apiResponse?.payload?.data) {
                        dispatch(updateStudentdata(apiResponse?.payload?.data))
                        toast.success(apiResponse?.payload?.message);
                        // navigate('/successfull')
                        console.log(apiResponse?.payload.data , 'dsadsa');
                        if (apiResponse?.payload?.data?.postCount == 0) {
                            navigate('/subject')
                        }else {
                            navigate('/dashboard/home')
                        }
                        
                    } else{
                        const errotMessage = apiResponse?.payload?.message
                        toast.error(errotMessage)
                    }
                })
                .catch((error) => {
                    toast(error?.message || "An error occurred while logging in.");
                });
            // dispatch(updateStudentdata(firstName, lastName));
        }
    };

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const resendOtp = () => {
        dispatch(studentLogin({ mobile: mobile, fcmToken: '', roleId: 2 }))
        .then((apiResponse) => {
            if (apiResponse?.payload?.data) {
                dispatch(updateStudentdata(apiResponse?.payload?.data))
                // dispatch(storeMobile(phoneNumber));
                toast.success(apiResponse?.payload?.message);
                setIsButtonDisabled(true);
    setTimeLeft(120);
                // navigate('/signup-otp')
            } else {
                const errorMessage = apiResponse?.payload?.message
                toast.error(errorMessage);
            }
        })
        .catch((error) => {
            toast.error(error?.message || "An error occurred while resend otp in.");
        });
    }

  const loading = useSelector((state) => state?.student?.loading);


    const isXs = useMediaQuery('(min-width:600px)');
    
    const handleClose = () =>{
        // window.location.href = 'https://kanhahometutions.com';
        navigate('/student-signin')
    }

    useEffect(() => {
        if (timeLeft === 0) {
          setIsButtonDisabled(false);
          return;
        }
    
        const timerId = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1);
        }, 1000);
    
        return () => clearInterval(timerId); // Cleanup on component unmount
      }, [timeLeft]);

      // Effect to start the countdown when the component mounts
  useEffect(() => {
    setIsButtonDisabled(true);
    setTimeLeft(60); // Set initial 2-minute countdown
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };


    return (
        <div style={{ backgroundImage: `url(${bg_new})`, backgroundSize: 'cover', height: '100vh' }}>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '895px', height: '560px' }}>
                            <div style={{ marginTop: '10%', backgroundColor: '#ffffff' }}>
                                <div className='row'>
                                    <div className='col-md-6' style={{ display: isXs ? '' : 'none' }} >
                                        <div >
                                            <img src={Otp} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{ display: isXs ? 'none' : '' }}>
                                        <div style={{ backgroundColor: '#CB8B65', height: '20rem', position: 'relative', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                            <div className='d-flex justify-content-center'>
                                                <img src={LogoHeader} style={{ position: 'absolute', width: '40%', top: '1rem' }} />
                                                <div style={{ position: 'absolute', color: 'white', top: '5.5rem', border: '2px solid white', borderRadius: '20px', width: '40%', textAlign: 'center' }}>For Students</div>
                                                <img src={Otp1} style={{ position: 'absolute', bottom: 0, width: '50%' }} />
                                            </div>
                                            <img src={wifi2} style={{ position: 'absolute', bottom: 0, left: 5 }} />
                                            <img src={book} style={{ position: 'absolute', top: 0, right: 5 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', bottom: 0, right: 0, rotate: '180deg' }} />
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{position:'relative'}}>
                                    <Button style={{ position: 'absolute', right: '-2%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black' , marginTop:'-10px'}} onClick={() => handleClose()}>X</Button>
                                        <div className='d-flex flex-row justify-content-between p-2'>
                                            <div>
                                                <h3 className='pt-3' style={{ fontWeight: 'bold' }}>OTP Verification</h3>
                                                <img src={line} className='mb-4' />
                                            </div>
                                            <img src={book} style={{ height: '12%', width: '12%' }} />
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className='fw-bolder py-1 text-center' style={{ fontSize: '12px' }}>A One-Time Password has been sent to {mobile}</div>
                                            <div className="d-flex justify-content-center fw-bold py-2" style={{ marginTop: isXs ? '3%' : '2%', marginBottom: isXs ? '3%' : '' }}>
                                                <div className='d-flex justify-content-center col gap-2' style={{ width: '100%' }}>
                                                    <MuiOtpInput value={otp} onChange={handleChange} length={6} style={{ width: isXs ? '90%' : '100%' }} />
                                                </div>
                                            </div>

                                            {isRegister === 0 ? <div style={{ width: '100%', textAlign: 'center' }}>
                                                <div className="col-12 my-2 mb-4"  >
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': { width: '93%' },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField label="First Name" placeholder='Enter your First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} inputProps={{ style: { height: '10px', fontSize: '12px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                    </Box>

                                                </div>
                                                <div className="col-12 my-2 mb-4" >
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': { width: '93%' },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField label="Last Name" placeholder='Enter your Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} inputProps={{ style: { height: '10px', fontSize: '12px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                    </Box>

                                                </div>
                                            </div> : ""}

                                            <div className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                                <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>
                                            </div>

                                        </form>
                                        <div className="d-flex flex-row justify-content-between w-full my-2">
                                            <div className="flex-grow-1 text-center" style={{ fontSize: '12px', fontWeight: '900' }}>
                                                {/* <small className="block">
                                                        <span className="">Entered a wrong number?</span>
                                                        <a href="#" style={{ color: '#AF6031' }}>Change</a>
                                                    </small> <br /> */}
                                                <small className="block">
                                                    <span className="">Didn’t Receive OTP?</span>
                                                    <span onClick={()=>resendOtp()} 
                                                    style={{

                                                        color: '#AF6031',
          marginLeft: '10px',
          fontSize: '13px',
          cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
          opacity: isButtonDisabled ? 0.5 : 1,
                                                         
                                                    }}>Resend {isButtonDisabled ? `(${formatTime(timeLeft)})` : ''} </span>
                                                </small>
                                            </div>
                                            <div>
                                                <img src={bulb} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SignupOtp
