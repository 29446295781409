import React, { useEffect, useState } from 'react'
import SampleImage from '../../../../assets/SampleImage.svg';
import AdminImage from '../../../../assets/admin.svg';
import { Backdrop, Button, CircularProgress, Modal, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import ProfileTeacher from '../../DashboardTeacher/DashboardProfile/ProfileInformation/ProfileTeacher';
import { useDispatch, useSelector } from 'react-redux';
import { teacherDetails } from '../../../features/teacher/teacherListingSlice';

const Header = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [profileImg , setProfileImage] = useState('');
    const [colour , setColour] = useState('');

    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('user'));

    const profileImage = () =>{
        dispatch(teacherDetails({userId:user?.id})).then((response)=>{
             if(response?.payload?.status==200){
                // console.log(response?.payload?.data , 'data');
                setProfileImage(response?.payload?.data?.files[0]?.file_name)
             }
        })
    }
    
    useEffect(()=>{
        profileImage();
        randomColor();
    },[])

    const randomColor = () => {
        // Generate a random hex color
        // return '#' + Math.floor(Math.random()*16777215).toString(16);
        setColour('#' + Math.floor(Math.random()*16777215).toString(16));
      };

    const imageStyle = {
        width: isXs ? '100%' : '50%',
        height: 'auto', // Maintain aspect ratio
        objectFit: profileImg ? 'cover' : 'contain', // Adjust based on image availability
      };

    const loading = useSelector((state) => state.teacherListing.loading)


    return (
        <Box className="d-flex justify-content-center align-items-center"  >
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <Box className="d-flex text-start align-items-center gap-2" style={{ display: isXs ? '' : 'none' }}>
                <Box sx={{textTransform: 'capitalize'}} style={{ color: 'white',fontSize: '1.5rem', display: isXs ? '' : 'none' }}>{ user?.roleId == 1 ? 'Admin' : user?.firstName+' '+user?.lastName}</Box>
        



                { user?.roleId == 1 ?
                //  <Button ><img src={AdminImage} style={{ width: '90%', display : isXs ? '' : 'none' }} /></Button>

<Box
sx={{
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: profileImg ? 'transparent' : colour,
  overflow: 'hidden',
  marginLeft:"25px",
  cursor: "pointer"
  // Ensure the Box has a defined size and that children are properly aligned
}}
>

  <Typography
    variant="h5"
    sx={{ color: 'white', fontWeight: 'bold' }}
  >
    {`${user?.firstName?.charAt(0)?.toUpperCase()}`}
  </Typography>

</Box>
                :
                // <Button onClick={handleOpen} ><img src={ profileImg ?? SampleImage} style={{
                //     padding: 0,
                //     border: '2px solid white',
                //     borderRadius: "50%",
                //     width: "40px",
                //     height: "40px",
                //     objectFit: "cover",
                //     display: "block",
                //     margin: "0 auto",
                //  }} /></Button>

                 <Box
                 onClick={handleOpen}
                 sx={{
                   width: '40px',
                   height: '40px',
                   borderRadius: '50%',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   backgroundColor: profileImg ? 'transparent' : colour,
                   overflow: 'hidden',
                   marginLeft:"25px",
                   cursor: "pointer"
                   // Ensure the Box has a defined size and that children are properly aligned
                 }}
               >
                 {profileImg ? (
                   <img
                     src={profileImg}
                     alt="profile-img"
                     style={{
                       width: '100%',
                       height: '100%',
                       objectFit: 'cover',
                       // Ensure that the image covers the Box without stretching it
                     }}
                   />
                 ) : (
                   <Typography
                     variant="h5"
                     sx={{ color: 'white', fontWeight: 'bold' }}
                   >
                     {`${user?.firstName?.charAt(0)?.toUpperCase()}`}
                   </Typography>
                 )}
               </Box>
            }
            </Box>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ProfileTeacher profileImage={profileImage} setOpen={setOpen} colour={colour} setProfileImage={setProfileImage}/>
                </Modal>
            </div>
        </Box>
    )
}

export default Header
