import { Box, Typography } from '@mui/material'
import React from 'react'
import line from '../../../../../assets/line.svg'
import { Button } from 'bootstrap'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';



const PricingClass = () => {
    const navigate = useNavigate()

    const teacherData = useSelector((item) => item?.teacherListing?.availableCredits)
    console.log('aaaaa',teacherData);
    const navi = () =>{
        navigate('/dashboard/classcourse')
     }
    return (
        <Box>
            <Box className="mt-3" sx={{ marginLeft: '2%', display: 'flex' }}>
                <Box>
                    <Typography variant='h5'>Pricing & Class Packs</Typography>
                    <Box>Please specify pricing of all the classes you teach.</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '90%' }}>
                    <button onClick={() => navi()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '3rem', padding: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>Graphic Designing</div>
                            <div style={{ display: 'flex', gap:5 }}>
                                <div style={{color:'#FF3939', display: 'flex', alignItems:'center'}}>Not set</div>
                                <div style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </Box>
    )
}

export default PricingClass
