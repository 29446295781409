import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import line from '../../../../../assets/line.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from 'react-redux';
import { boardData, classData, createClassesTeacher, dropDownListings, subjectData, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


const backStyle = {
    backgroundColor: "#F3F3F3",
    border: "1px solid #DCDCDC",
    margin: "3%",
    borderLeft: "5px solid #CB8B65",
};
const titleStyle = {
    margin: "2%",
};
const contStyle = {
    marginLeft: "2%",
    marginBottom: "2%",
};

const AddClass = () => {

    const isXs = useMediaQuery("(min-width:600px)");
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [classIds, setClassIds] = useState([]);
    const [subjectIds, setSubjectIds] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [conductClassesIds, setConductClassesIds] = useState([]);
    const [classPreferenceId, setClassPreferenceId] = useState();

    


    const classList = useSelector((state) => state?.teacherListing?.listClass);

    console.log(classList , 'classList');
    const subjectList = useSelector((state) => state?.teacherListing?.listSubject);
    const cost = useSelector((item) => item?.teacherListing?.getClassTeacher[0]?.cost);
    const boardList = useSelector((item) => item?.teacherListing?.listBoard);
    const conductClass = useSelector((state) => state?.teacherListing?.dropDownData?.teacher_class_conduct_prefrence);
    const classPreference = useSelector((state) => state?.teacherListing?.dropDownData?.teacher_class_prefernce);

    const classHandleChange = (event, selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
        dispatch(subjectData({ classIds: selectedIds }));
        setClassIds(selectedIds)
    };

    const handleSubjectChange = (event, selectedOptions) => {
        const subjectIdsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
        setSubjectIds(subjectIdsGet);
        // console.log(subjectIdsGet, 'subjectIdsGet');
    };

    const handleBoardChange = (value, isChecked) => {
        const newCheckboxStates = [...checkboxStates];
        const index = newCheckboxStates.indexOf(value);
        if (isChecked) {
            newCheckboxStates.push(value);
        } else if (!isChecked && index !== -1) {
            newCheckboxStates.splice(index, 1);
        } else if (checkboxStates.length <= 0) {
            toast.error("Input field is required.");
        }
        setCheckboxStates(newCheckboxStates);
        // console.log(newCheckboxStates , 'checkboxStates');
    };

    const handleConductClassChange = (value, isChecked) => {
        // const conductClassIdsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
        // setConductClassesIds(conductClassIdsGet);
        // console.log(conductClassIdsGet , 'conductClassIdsGet');

        const newConductClassesIds = [...conductClassesIds];
        const index = newConductClassesIds.indexOf(value);
        if (isChecked) {
            newConductClassesIds.push(value);
        } else if (!isChecked && index !== -1) {
            newConductClassesIds.splice(index, 1);
        } else if (conductClassesIds.length <= 0) {
            toast.error("Input field is required.");
        }
        setConductClassesIds(newConductClassesIds);
        console.log(newConductClassesIds, 'newConductClassesIds');
    };

    const handleClassPrefeChange = (event) => {
        const value = event.target.value;
        setClassPreferenceId(value);
        // console.log(value, 'selected value classPeferance'); // 1 for Yes, 0 for No
    };

    const getSubjectIdListSelectedClassId = (classId, subNameList) => {
        const sIdList = [];
        for (let a = 0; a < subjectList.length; a++) {
            for (let q = 0; q < subNameList.length; q++) {
                if (subjectList[a].id == subNameList[q]) {
                    for (let b = 0; b < subjectList[a].duplicate_subject_name_ids.length; b++) {
                        if (subjectList[a].duplicate_subject_name_ids[b].class_id == classId) {
                            sIdList.push(subjectList[a].duplicate_subject_name_ids[b].subject_id);
                            break;
                        }
                    }
                }
            }
        }
        return sIdList;
    };

    const userId = JSON.parse(localStorage.getItem('user'));

    const pickCost = () => {
        dispatch(teacherDetails({userId:userId?.id}))
    }
    useEffect(() => {
        pickCost();
    }, [])

    const handleClick = () => {
        if (
            !classIds?.length || 
            !subjectIds?.length || 
            !checkboxStates?.length || 
            !conductClassesIds?.length 
          ) {
            toast.error("All fields are required.");
            return;
          }

        const payload = {
            userId: userId?.id,
            class: JSON.stringify(classIds?.map((classId) => ({
                classId,
                cost: cost,
                classPreferenceId: classPreferenceId, // Replace with the default class preference ID
                // subject: subjectIds?.map((subjectId) => ({
                //   subjectId,
                // })),
                subject: JSON.stringify(getSubjectIdListSelectedClassId(classId, subjectIds).map((subjectId) => ({
                    subjectId,
                }))),

                board: JSON.stringify(checkboxStates?.map((boardId) => ({
                    boardId,
                }))),
                classConductedPreference: JSON.stringify(conductClassesIds?.map((classConductedId) => ({
                    classConductedId,
                }))), // Replace with the default class conducted preference ID
            }))),
        };

        dispatch(createClassesTeacher(payload))
        .then((response) => {
            if(response?.payload?.status == 200){
                toast.success(response?.payload?.message);
                navigate('/dashboard/classconducted')
            }
        })

        // console.log(payload , 'payload');
    };

    useEffect(() => {
    }, [classIds, subjectIds]);

    const teacherProfile = () => {
        // dispatch(teacherDegree());
        dispatch(boardData());
        dispatch(classData());
        dispatch(dropDownListings());
      };
    
      useEffect(() => {
        teacherProfile();
      }, []);

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5'>Add Class</Typography>
                        <Box>Please specify all the details</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-2 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={handleClick} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' , display: isXs ? '' : 'none' , marginTop: '1rem' }}>Add Class</button>
                </Box>
            </Box>

            <Box sx={backStyle}>
                <Typography sx={titleStyle}>
                    Which Class and Subject do you want to Teach?
                </Typography>
                <Box sx={contStyle}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={classList ?? []}
                                disableCloseOnSelect
                                onChange={classHandleChange}
                                getOptionLabel={(option) => option?.className}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            //   onChange={(event) =>
                                            //     handleClassChange(
                                            //       event,
                                            //       event.target.checked,
                                            //       option
                                            //     )
                                            //   }
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            checked={selected}
                                        />
                                        {option?.className}
                                    </li>
                                )}
                                style={{ width: 280 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Classes"
                                        placeholder="Select Classes"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={subjectList ?? []}
                                disableCloseOnSelect
                                onChange={handleSubjectChange}
                                getOptionLabel={(option) => option?.subject_name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            // onChange={(event) =>
                                            //   handleSubjectChange(
                                            //     event,
                                            //     event?.target?.checked,
                                            //     option
                                            //   )
                                            // }
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            checked={selected}
                                        />
                                        {option?.subject_name}
                                    </li>
                                )}
                                style={{ width: 280 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Subjects"
                                        placeholder="Select Subjects"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={backStyle}>
                <Typography sx={titleStyle}>
                    Which school board do you teach for?
                </Typography>
                <Box sx={contStyle}>
                    {boardList?.map((item, index) => (
                        <FormGroup key={index} aria-label="position">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(item) =>
                                            handleBoardChange(
                                                item.target.value,
                                                item.target.checked
                                            )
                                        }
                                    />
                                }
                                label={item?.boardName}
                                value={item?.id}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    ))}
                </Box>
            </Box>

            <Box sx={backStyle}>
                <Typography sx={titleStyle}>
                    How would you like to conduct your classes?
                </Typography>
                <Box sx={contStyle}>
                    {/* <FormGroup>
                        <FormControlLabel
                          value="online"
                          control={<Checkbox />}
                          label="I can teach Live Online using Video Call"
                          labelPlacement="end"
                        />
                      </FormGroup> */}
                    {conductClass?.map((item, index) => (
                        <FormGroup key={index} aria-label="position">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(item) =>
                                            handleConductClassChange(
                                                item.target.value,
                                                item.target.checked
                                            )
                                        }
                                    />
                                }
                                label={item?.name}
                                value={item?.value}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    ))}
                    {/* <FormGroup>
                        <FormControlLabel
                          value="student"
                          control={<Checkbox />}
                          label="I can teach at the student's home"
                          labelPlacement="end"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          value="teacher"
                          control={<Checkbox />}
                          label="I can teach at my home"
                          labelPlacement="end"
                        />
                      </FormGroup> */}
                </Box>
            </Box>

            <Box sx={backStyle}>
                <Typography sx={titleStyle}>
                    Do you teach private or group classes? *
                </Typography>
                <Box sx={contStyle}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={classPreferenceId}
                            onChange={handleClassPrefeChange}
                        >

                            {classPreference?.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={value?.value}
                                    control={<Radio />}
                                    label={value?.name}
                                />
                            ))}

                        </RadioGroup>
                    </FormControl>

                </Box>
                
            </Box>
            <button onClick={handleClick} className="btn btn-primary border-0" style={{ fontSize: '14px', width: '17rem' , height: '3rem' , backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' , display: isXs ? 'none' : '', marginBottom: '2rem' , marginTop: '1.5rem' , marginLeft: '1.5rem' }}>Add Class</button>


        </>
    )
}

export default AddClass
