import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SampleImage from "../../../../assets/SampleImage.svg";
import online from "../../../../assets/online.png";
import location2 from "../../../../assets/location2.svg";
import line from "../../../../assets/line.svg";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postDetails, postSubscribeTeacher, teacherDetails } from "../../../features/teacher/teacherListingSlice";
import phone from "../../../../assets/Phone.svg";
import mail from "../../../../assets/Mail.svg";
// import { postDeatils } from "../../../features/teacher/teacherClassConductSlice";

import { toast } from "react-toastify";

const DashboardHomeEnquiryClick = () => {
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemData, setItemData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const closeModal = () => setOpen(false);

  const { itemId } = useParams();

  

  const teacherData = useSelector(
    (item) => item?.teacherListing?.availableCredits
  );
  const availableCredits = teacherData?.credits?.available_credits;

  

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.id;

  const teacherPostSubscribe = () => {
    dispatch(postSubscribeTeacher({ userId: userId, postId: postId }))
      .then((response) => {
        if (response?.payload?.success == true) {
          toast.success(response?.payload?.message);
          getPostDetails()
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("getting error", error);
      });
  };


  const studentEnquiry = useSelector(
    (item) => item?.teacherListing?.postDetail
  );

  const postId = studentEnquiry?.id;
  console.log(studentEnquiry , 'fdsfdsfds');

  const isNavigate = () => {
    navigate("/dashboard/subscriptionplan");
  };

  const goBack = () => {
    navigate(-1);
  };


  const getPostDetails = () =>{
      dispatch(postDetails({postId:itemId}))
      dispatch(teacherDetails({userId:userId}))
  }

  
  useEffect(() => {
    getPostDetails();
  }, []);

  const randomColor = () => {
    // Generate a random hex color
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  };

  const loading = useSelector(
    (item) => item?.teacherListing?.loading
  );
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    borderRadius: "10px",
    width: isXs ? "50%" : "90%",
    boxShadow: 24,
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", gap: 2, marginLeft: "2%", marginTop: "2%" }}>
        <button
          onClick={goBack}
          style={{
            border: "none",
            background: "transparent",
            display: "flex",
            alignItems: "start",
          }}
        >
          <ArrowBackRoundedIcon
            sx={{
              color: "white",
              backgroundColor: "#CB8B65",
              borderRadius: "10px",
              width: "2rem",
              height: "2rem",
              padding: "5px",
            }}
          />
        </button>
        <Box>
        <Typography variant="h5" style={{fontFamily: 'jost'}}>Enquiries</Typography>

          <Box>
            <img src={line} />
          </Box>
        </Box>
      </Box>
      <Box className="d-flex justify-content-center">
        <Box style={{ width: isXs ? "95%" : "95%" }}>
          <Box
            className="mt-2 px-0 py-3 shadow-sm"
            style={{
              border: "1px solid #D6D6D6",
              backgroundColor: "white",
              marginBottom: "3%",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ textAlign: isXs ? "" : "left" , display: "flex", gap: "1rem" }}>
              <Box>
              <Box
    sx={{
      width: isXs ?'100px' : '50px',
      height: isXs ?'100px' : '50px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: studentEnquiry?.user?.profileImage ? 'transparent' : randomColor(),
      overflow: 'hidden',
      marginLeft:"25px",
      marginTop:"10px"
      // Ensure the Box has a defined size and that children are properly aligned
    }}
  >
    {studentEnquiry?.user?.profileImage ? (
      <img
        src={studentEnquiry?.user?.profileImage}
        alt="profile-img"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          // Ensure that the image covers the Box without stretching it
        }}
      />
    ) : (
      <Typography
        variant="h5"
        sx={{ color: 'white', fontWeight: 'bold' }}
      >
        {`${studentEnquiry?.user?.firstName?.charAt(0)?.toUpperCase()}`}
      </Typography>
    )}
  </Box>
              </Box>

             

<Box>
                <Box>
                  <Box className="py-1 ">
                  <Typography variant="h5" style={{fontFamily: 'jost' , textTransform: 'capitalize'}}>

                      {studentEnquiry?.user?.firstName}{" "}
                      {studentEnquiry?.user?.lastName}
                    </Typography>
                    <Box sx={{flexDirection: isXs ? "" : "column"}} display={"flex"} gap={1}>
                      <Box fontSize={"14px"}>
                        {studentEnquiry?.classData?.class_name} ||{" "}
                      </Box>
                     
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, flexDirection: isXs ? "" : "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        {" "}
                        <img src={online} width={25} />{" "}
                      </Box >
                      {studentEnquiry?.classTypes?.map((item, index) => (
                        <Box key={index}>{item?.classTypeName?.name}</Box>
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", gap: .5  }}>
                      <Box>
                        {" "}
                        <img src={location2} width={18}  />{" "}
                      </Box>
                      <Box>
                        {studentEnquiry?.city}, {studentEnquiry?.state}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: isXs ? 2 : 0, flexDirection: isXs ? "" : "column" }}>
                    <Box sx={{ display: "flex", gap: .5 }}>
                      <Box>
                        {" "}
                        <img src={phone} width={15}  />{" "}
                      </Box>
                      {studentEnquiry?.user?.mobile}
                    </Box>
                    <Box sx={{ display: "flex", gap: .5 }}>
                      <Box>
                        {" "}
                        <img src={mail} width={15}  />{" "}
                      </Box>
                      <Box>
                        {studentEnquiry?.user?.email}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>




          <Box
            className="mt-2 px-3 py-3 shadow-sm"
            style={{
              border: "1px solid #D6D6D6",
              backgroundColor: "white",
              marginBottom: "3%",
              borderRadius: "10px",
            }}
          >
            <Box className="d-flex justify-content-center">
              {" "}
              <Typography variant="h5" style={{fontFamily: 'jost' , fontSize: "1.2rem"}}>Enquiry Details</Typography>{" "}

            </Box>
            <Box>
              <Typography
                variant="h6"
                style={{fontFamily: 'jost'}}
                color={"#CB8B65"}
                sx={{ marginTop: isXs ? "3%" : "5%" }}
              >
                {studentEnquiry?.classData?.class_name}
              </Typography>
              <Box
                sx={{ marginBottom: isXs ? "2%" : "5%" , fontSize: isXs ? "1rem" : ".8rem" , width: "60%" }}
                display={"flex"}
                gap={1}
              >
                {studentEnquiry?.subjects?.map((item, index) => (
                  <Box key={index}> {item?.subjectName?.subject_name}, </Box>
                ))}
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography
                color={"#CB8B65"}
                style={{fontFamily: 'jost'}}
                variant="h6"
                sx={{ marginTop: isXs ? "3%" : "5%" }}
              >
                Requirement at a glance
              </Typography>
            </Box>
            <Box
              sx={{
                display: isXs ? "" : "none",
                marginTop: isXs ? "1%" : "5%",
              }}
            >
              <Box display={"flex"}>
                <Container sx={{ width: "50%" }}>
                  <List>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Board</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Preferred Class Strength</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Max. Fee</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Availability</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Plan to Start</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Study Mode</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >Gender Preference</ListItemText>
                    </ListItem>
                  </List>
                </Container>
                <Container>
                  <List>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >
                        : {studentEnquiry?.board?.board_name}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >
                        : {studentEnquiry?.classPreference?.name}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >: ₹{studentEnquiry?.cost}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >
                        : {studentEnquiry?.classDayType?.name}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >
                        : {studentEnquiry?.classStartPlanType?.name}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      {" "}
                      <Typography  primaryTypographyProps={{fontFamily: 'jost'}} >:</Typography>
                      {studentEnquiry?.classTypes?.map((item, index) => (
                        <Typography key={index} style={{fontFamily: 'jost' , marginLeft: '.4rem'}}>
                          {item?.classTypeName?.name},
                        </Typography>
                      ))}
                    </ListItem>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{fontFamily: 'jost'}} >
                        : {studentEnquiry?.genderPreference?.name}
                      </ListItemText>
                    </ListItem>
                  </List>
                </Container>
              </Box>
            </Box>
            <Box
              sx={{
                display: isXs ? "none" : "block",
                marginBottom: isXs ? "2%" : "5%",
              }}
            >
              <Typography>
                Board : {studentEnquiry?.board?.board_name}
              </Typography>
              <Typography>
                Preferred Class Strength :{" "}
                {studentEnquiry?.classPreference?.name}
              </Typography>
              <Typography>Max. Fee : ₹{studentEnquiry?.cost}</Typography>
              <Typography>
                Availability : {studentEnquiry?.classDayType?.name}
              </Typography>
              <Typography>
                Plan to Start : {studentEnquiry?.classStartPlanType?.name}
              </Typography>
              <Typography>
                Study Mode :{" "}
                {studentEnquiry?.classTypes?.map((item, index) => (
                  <Typography key={index}>
                    {item?.classTypeName?.name},
                  </Typography>
                ))}
              </Typography>
              <Typography>
                Gender Preference : {studentEnquiry?.genderPreference?.name}
              </Typography>
            </Box>
            <Divider />

            <Box sx={{ marginTop: isXs ? "3%" : "5%" }}>
              <Typography style={{fontFamily: 'jost'}}>Address</Typography>
            </Box>
            <Box>
              <Box>
                {studentEnquiry?.addressOne}, {studentEnquiry?.addressTwo},{" "}
                {studentEnquiry?.city}, {studentEnquiry?.state},{" "}
                {studentEnquiry?.pincode}
              </Box>
            </Box>
          </Box>
          <Box className="d-flex justify-content-center mb-4">
            {!studentEnquiry?.purchase && (
              <Button onClick={handleOpen}>
                <Typography
                  sx={{
                    color: "white",
                    backgroundColor: "#CB8B65",
                    borderRadius: "10px",
                    padding: 2,
                    variant: isXs ? "h4" : "h6",
                    fontFamily: 'jost',

                  }}
                >
                  Use 1 Credit to See Student Personal Details
                </Typography>
              </Button>
            )}

            {availableCredits === 0 ? (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        backgroundColor: "#2B978E",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 2,
                        fontSize: "3vw",
                        color: "white",
                      }}
                    >
                      <Box sx={{ fontSize: isXs ? "3vw" : "6vw" }}>
                        Available Credits
                      </Box>
                      <Box sx={{ fontSize: isXs ? "3vw" : "6vw" }}>
                        {" "}
                        {availableCredits ? availableCredits : 0}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: isXs ? "5%" : "10%",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          width: isXs ? "60%" : "90%",
                          fontSize: isXs ? "24px" : "18px",
                          fontWeight: 500,
                        }}
                      >
                        You don't have Credits Please Subscribe to Get Credits
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: isXs ? "5%" : "10%",
                        marginBottom: isXs ? "5%" : "10%",
                      }}
                    >
                      <Box
                        className="mt-4 d-flex justify-content-evenly px-5"
                        style={{ width: "100%" }}
                      >
                        <Box style={{ width: "50%", marginRight: "8px" }}>
                          <button
                            onClick={closeModal}
                            className="btn btn-primary py-2 w-100 border-1"
                            style={{
                              fontSize: "14px",
                              backgroundColor: "white",
                              color: "#7E7E7E",
                              fontWeight: "600",
                              borderRadius: "0",
                              borderColor: "#CB8B65",
                            }}
                          >
                            Cancel
                          </button>
                        </Box>
                        <Box style={{ width: "50%", marginLeft: "8px" }}>
                          <button
                            onClick={() => isNavigate()}
                            className="btn btn-primary py-2 w-100 border-1"
                            style={{
                              fontSize: "14px",
                              backgroundColor: "#FFDB7E",
                              color: "#866D2C",
                              fontWeight: "600",
                              borderRadius: "0",
                              borderColor: "#CB8B65",
                            }}
                          >
                            Subscribe
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </div>
            ) : (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        backgroundColor: "#2B978E",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 2,
                        fontSize: "3vw",
                        color: "white",
                      }}
                    >
                      <Box sx={{ fontSize: isXs ? "2vw" : "6vw" }}>
                        Available Credits
                      </Box>
                      <Box sx={{ fontSize: isXs ? "3vw" : "6vw" }}>
                        {" "}
                        {availableCredits ? availableCredits : 0}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: isXs ? "5%" : "10%",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          width: isXs ? "60%" : "90%",
                          fontSize: isXs ? "24px" : "18px",
                          fontWeight: 500,
                        }}
                      >
                        Are you Sure Want to Use 1 Credit to See Student
                        Personal Details
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: isXs ? "5%" : "10%",
                        marginBottom: isXs ? "5%" : "10%",
                      }}
                    >
                      <Box
                        className="mt-4 d-flex justify-content-evenly px-5"
                        style={{ width: "100%" }}
                      >
                        <Box style={{ width: "50%", marginRight: "8px" }}>
                          <button
                            onClick={closeModal}
                            className="btn btn-primary py-2 w-100 border-1"
                            style={{
                              fontSize: "14px",
                              backgroundColor: "white",
                              color: "#7E7E7E",
                              fontWeight: "600",
                              borderRadius: "0",
                              borderColor: "#CB8B65",
                            }}
                          >
                            Cancel
                          </button>
                        </Box>
                        <Box style={{ width: "50%", marginLeft: "8px" }}>
                          <button
                            onClick={teacherPostSubscribe}
                            className="btn btn-primary py-2 w-100 border-1"
                            style={{
                              fontSize: "14px",
                              backgroundColor: "#FFDB7E",
                              color: "#866D2C",
                              fontWeight: "600",
                              borderRadius: "0",
                              borderColor: "#CB8B65",
                            }}
                          >
                            Yes
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardHomeEnquiryClick;
