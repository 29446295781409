import React, { useState ,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../assets/search.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; 
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axiosInstanceSM from "../../Utils/axiosSm";
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab } from "bootstrap";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
    '& .MuiSwitch-thumb': {
      color: theme.palette.action.disabled,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

const columns = [
  { id: "id", label: "Sr.No" },
  { id: "BoardName", label: "Board Name", minWidth: "160px" },
  { id: "Classes", label: "Class", minWidth: "160px" },
  { id: "Subject", label: "Subject" },
  { id: "Notes", label: "Notes", minWidth: "160px" },
  { id: "Chapter", label: "Chapters", minWidth: "160px" },
  // { id: "Status", label: "Status" },
];

function Notes() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([ ]);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModel, setOpenEditModal] = useState(false);
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [viewChapterModal, setViewChapterModal] = useState(false);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteDescription, setNoteDescription] = useState('');
  const [chapterTitle, setChapterTitle] = useState('');
  const [chapterfile, setChapterfile] = useState('');
  const [openChapterModal, setOpenChapterModal] = useState(false);
  const [editChapterModal, setEditChapterModal] = useState(false);
  const [notsData, setNotsData]= useState([])
  const [subjectId, setSubjectId] = useState('')
  const [subjectIdNew, setSubjectIdNew] = useState('');
  const [subjectIdEdit ,setSubjectIdEdit] = useState('')
  const [classId ,setClassId] = useState('')
  const [openDelete, setOpenDelete] = useState('');
  const [openDeleteNew, setOpenDeleteNew] = useState('');
  const [notesId, setNotesId] = useState('')
  const [fileSelected, setFileSelected] = useState(false);
  const [chapterFile, setChapterFile] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); 
  const [chaptersData , setChaptersData] =useState([])
  const [chapterId , setChapterId] = useState('')


const handleCloseDelete = () => {
  setOpenDelete(false);
};

const handleCloseDeleteNew = () => {
  setOpenDeleteNew(false);
};

  const handleAddNotesClick = (item) => {
    setOpenModal(true)
    setSubjectId(item.subjectId); 
  };

  const handlecloseEditModel =()=>{
    setOpenEditModal(false)
  }

  const handleAddChapterClick =  (item) => {
    setOpenChapterModal(true);
    setSubjectId(item); 
    fetchDatachapters(item)
   
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseChapterModal = () => {
    setOpenChapterModal(false);
    setChapterTitle('');
  };

  const handleCloseChapterEditModal = () => {
    setEditChapterModal(false)
  };


  const handleViewNote = (row) => {
    setViewNoteModal(row);
  };

  const handleViewChapter = (row) => {
    setViewChapterModal(true);
  };

  const handleCloseViewNoteModal = () => {
    setViewNoteModal(false);
  };

  const handleCloseViewChapterModal = () => {
    setViewChapterModal(false);
  };

  const handleEditNotes = (item) =>{
    setOpenEditModal(true);
    setNoteTitle(item.notes[0].notesTitle);
    setNoteDescription(item.notes[0].notesDescription)
    setSubjectIdNew(item.subjectId); 
    setClassId(item.notes[0].notesId); 
    setNotesId(item.notes[0].notesId)
  }

  const handleEditNotesNew = async (e) => {
    e.preventDefault();
    const formDataNew = new FormData();
    formDataNew.append("_method", "PUT");
    formDataNew.append("token", "abcd123");
    formDataNew.append("subjectId",subjectIdNew )
    formDataNew.append("notesTitle",noteTitle)
    formDataNew.append("notesDescription",noteDescription)
    try {
      const response = await axiosInstanceSM.post(`api/sm/v1/note/${classId}`, formDataNew);
      setOpenEditModal(false);
      if(response.data.success === true){
        toast.success(response.data.message)
      }
      } 
      catch (error) {
        if (error.response) {
          toast.error(error.response.data.message || 'Something went wrong!');
        }      }
    
  };

  const fetchDatasubjects = async () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`; 
    const payload = {
      token: token,
      notesRequired:'1',
       };
    try {
      const response = await axiosInstanceSM.post("api/sm/v1/subjects", payload);
      setNotsData(response.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchDatasubjects();
  }, []);

  const fetchDatachapters = async (subjectId) => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`; 
    const payload = {
      token: token,
      notesRequired:'1',
      paginate: '1',
      subjectId: subjectId 
    };

    try {
      const response = await axiosInstanceSM.post("api/sm/v1/chapters", payload);
      setChaptersData(response.data.data);
    } catch (error) {
    }
        };

  useEffect(() => {
    fetchDatachapters();
  }, []);




const handleSubmitNotes = async (e)=>{
  e.preventDefault();
  setOpenModal(false);
  const d = new Date();
  let text = d.toLocaleDateString();
  let token = `vkSoft1234${text}`;
  const formdata = new FormData();
  formdata.append("subjectId" , subjectId) 
  formdata.append("notesTitle",noteTitle )
  formdata.append("notesDescription",noteDescription)
  formdata.append("token", token)

try{
const response = await axiosInstanceSM.post("api/sm/v1/note", formdata)
setNotsData(response.data.data)
if(response.data.success === true){
  toast.success(response.data.message)
  fetchDatasubjects()
}
}
catch (error){
  if (error.response) {
    toast.error(error.response.data.message || 'Something went wrong!');
  }
}
}

const deleteNotes = async () => {
  setOpenDelete(false);
  const d = new Date();
  let text = d.toLocaleDateString();
  let token = `vkSoft1234${text}`;
  const payload = {
    token: token,
    _method: 'DELETE',
  };
   
  try {
    const response = await axiosInstanceSM.post(`api/sm/v1/note/${notesId}`, payload );
    if(response.data.success === true){
      toast.success(response.data.message)
      fetchDatasubjects()
    }else{
      toast.error(response.data.message)
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.message || 'Something went wrong!');
    }
  }


}

// Add Chapter 
const handlesubmitChapter = async (e) => {
  e.preventDefault();
  const d = new Date();
  let text = d.toLocaleDateString();
  let token = `vkSoft1234${text}`;
  const formdata = new FormData();
  formdata.append("subjectId", subjectId);
  formdata.append("chapterOrder", "1");
  formdata.append("chapterTitle", chapterTitle);
  formdata.append("chapterFile", chapterFile);
  formdata.append("token", token);

  if (!chapterTitle.trim()) {
    toast.error('Please provide a chapter Name');
    return; 
  }
  if (!chapterFile) {
    toast.error('Please provide a chapter Pdf');
    return; 
  }
  try {
    const response = await axiosInstanceSM.post("api/sm/v1/chapter", formdata);
    const newChapter = response.data.data;
      setChaptersData((prevChapters) => [...prevChapters, newChapter]);
      
    if(response.data.success === true){
      toast.success(response.data.message)
      setFormSubmitted(true)
      setChapterFile('')
      setChapterTitle('')
      setSelectedFileName('')
      setFileSelected('')
       }
      
  }
  catch (error) {
    if (error.response) {
      toast.error(error.response.data.message || 'Something went wrong!');
    }}
};
 
// Edit Chapter
const handleEditChapter = (item) =>{
  setEditChapterModal(true)
  setChapterId(item.chapterId)
}

const handleEditchapterNew = async (e) => {
  e.preventDefault();
  setEditChapterModal(false)
  const formDataNew = new FormData();
  formDataNew.append("_method", "PUT");
  formDataNew.append("token", "abcd123");
  formDataNew.append("subjectId", subjectIdEdit )
  formDataNew.append("chapterOrder", "1")
  formDataNew.append("chapterTitle", chapterTitle)
  try {
    const response = await axiosInstanceSM.post(`api/sm/v1/chapter/${subjectIdEdit}`, formDataNew);
    if(response.data.success == true){
      toast.success(response.data.message)
      fetchDatachapters(subjectId)
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.message || 'Something went wrong!');
    }  
  }
};

const deletechapter = async () => {
  setOpenDeleteNew(false);
  const d = new Date();
  let text = d.toLocaleDateString();
  let token = `vkSoft1234${text}`;
  const payload = {
    token: token,
    _method: 'DELETE',
  };
   
  try {
    const response = await axiosInstanceSM.post(`api/sm/v1/chapter/${chapterId}`, payload );
    if(response.data.success == true){
      toast.success(response.data.message)
      fetchDatachapters(subjectId)
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.message || 'Something went wrong!');
    }  
  }
}


  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4rem",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          marginTop: "0rem",
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          justifyContent: "space-between",
          boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
        }}
      >
        <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
          Notes
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup>
            <Form.Control
              placeholder="Search..."
              aria-label="Search"
              value={searchTerm}
            />
            <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
              <img src={search} alt="" />
            </Button>
          </InputGroup>
        </div>
      </div>
      
      {/* Table of Notes */}
      <Paper sx={{ width: "95%", overflow: "hidden", marginLeft: "2rem", marginTop: '30px' }}>
        <TableContainer sx={{ maxHeight: 360 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#CB8B65",
                      fontWeight: "500",
                      color: "#ffffff",
                      fontSize: "1.1rem",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {notsData.map((item)=>(
                  <TableRow hover role="checkbox"  key={item.id}>
                    <TableCell>{item.subjectId}</TableCell>
                    <TableCell>{item.board.boradName}</TableCell>
                    <TableCell>{item.class.className}</TableCell>
                    <TableCell>{item.subjectName}</TableCell>
                    <TableCell>
                      {item.notes?.[0]?.notesDescription ? (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div onClick={() => handleViewNote(item)} style={{ cursor: 'pointer' }}>
                            <RemoveRedEyeIcon color="warning" />
                          </div>
                          <div onClick={() => handleEditNotes(item) } style={{ cursor: 'pointer' }}>
                            <EditIcon color="warning" />
                          </div>
                          <div onClick={() => {setNotesId(item.notes[0].notesId); setOpenDelete(true)}} style={{ cursor: 'pointer' }}>
                            <DeleteIcon color="warning" />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            border: '1px solid #ED6C02',
                            textAlign: 'center',
                            borderRadius: '3px',
                            padding: '3px 0px',
                            color: '#ED6C02',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleAddNotesClick(item)}
                        >
                          Add Notes
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                  
                      {item.chapters?.[0]?.chapterTitle ? (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div onClick={() => {handleAddChapterClick(item.subjectId) }} style={{ cursor: 'pointer' }}>
                            <RemoveRedEyeIcon color="warning" />
                          </div>
                          </div>
                         
                      ) : (
                        <div
                          style={{
                            border: '1px solid #ED6C02',
                            textAlign: 'center',
                            borderRadius: '3px',
                            padding: '3px 0px',
                            color: '#ED6C02',
                            cursor: 'pointer',
                          }}
                          onClick={() => { handleAddChapterClick(item); setSubjectId(item.subjectId) }}
                        >
                          Add Chapter
                        </div>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <CustomSwitch
                        color="warning"
                      />
                    </TableCell> */}
                  </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          page={page}
        /> */}
      </Paper>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Notes</DialogTitle>
        <form onSubmit={handleSubmitNotes}>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            required
            onChange={(e) => setNoteTitle(e.target.value)}
            style={{ marginBottom: '20px', marginTop: '10px' }}
          />
          <TextField
            label="Description"
            multiline
            fullWidth
            required 
             onChange={(e) => setNoteDescription(e.target.value)}
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Cancel
          </Button>
          <Button type="submit" style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Submit
          </Button>
        </DialogActions>
        </form>
      </Dialog>
       
{/* EDIT POPUP NOTES */}
      <Dialog open={openEditModel} onClose={handlecloseEditModel}>
        <DialogTitle>Edit Notes</DialogTitle>
        <form onSubmit={handleSubmitNotes}>
        <DialogContent>
          <TextField
            label="Title"
            value={noteTitle}
            fullWidth
            onChange={(e) => setNoteTitle(e.target.value)}
            style={{ marginBottom: '20px'}}
          />
          <TextField
            label="Description"
            multiline
            fullWidth 
            value={noteDescription}
             onChange={(e) => setNoteDescription(e.target.value)}
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecloseEditModel} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Cancel
          </Button>
          <Button onClick={handleEditNotesNew} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Submit
          </Button>
        </DialogActions>
        </form>
      </Dialog>


{/* DELETE NOTES POPU{} */}
      <Dialog  open={openDelete} onClose={handleCloseDelete}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px'  }}>
          <CloseIcon style={{ color: 'red', fontSize: '60px', padding:'10px', marginBottom: '20px', borderRadius:'50%', border:'2px solid red'   }} />
          <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
            Are you sure ?
          </div>
          <div style={{textAlign: 'center',width:'320px' , marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button 
              onClick={handleCloseDelete} 
              style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width:'100px', border:'0px' }}
            >
              Cancel
            </button>
            <button 
              onClick={deleteNotes} 
              style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width:'100px', border:"0px"}}
            >
              Yes
            </button>
          </DialogActions>
        </div>
      </Dialog>

      {/* View Notes Modal */}
      <Dialog fullWidth maxWidth="md" open={viewNoteModal} onClose={handleCloseViewNoteModal}>
        <DialogTitle>View Notes</DialogTitle>
        <DialogContent>
  
  {viewNoteModal?.notes?.map((item,index) => (
    <div key={index}>
      <div style={{marginBottom:"20px"}}>
      <div style={{fontSize:'17px', fontWeight:'500', marginBottom:'7px' }}> <u>Notes Title</u></div>
      <div style={{fontSize:'17px', fontWeight:'500'}}>{item?.notesTitle}</div>
      </div>
      <div>
      <div style={{fontSize:'17px', fontWeight:'500', marginBottom:'7px'}}> <u>Notes Description</u></div>
      <p>{item?.notesDescription}</p>
      </div>
    </div>
  ))} 

</DialogContent>

        <DialogActions>
          <Button onClick={handleCloseViewNoteModal} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

{/* Add Chapter Popup */}
<Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "750px",
          },
        },
      }}
      open={openChapterModal}
      onClose={handleCloseChapterModal}
    >
      <DialogTitle>Add Chapter</DialogTitle>
      <div onClick={handleCloseChapterModal} style={{ color: "white", backgroundColor: "gray", position:'absolute', right:'0', borderRadius:'50%', padding:'3px 10px', fontSize:'20px', cursor:'pointer' }}>
X
          </div>
      <DialogContent>
     
        <form onSubmit={handlesubmitChapter}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div>
              <TextField
                label="Chapter Name"
                fullWidth
                value={chapterTitle}
                onChange={(e) => setChapterTitle(e.target.value)}
                style={{ marginBottom: "20px", marginTop: "10px" }}
                InputProps={{
                  style: {
                    width: "400px",
                    height: "45px",
                    padding: "0 14px",
                  },
                }}
              />
            </div>
            <div style={{}}>

              <Button
                component="label"
                role={undefined}
                style={{
                  backgroundColor: fileSelected ? "gray" : "white",
                  color: fileSelected ?  "white":"#cb8b65",
                  border:'solid #cb8b65 1px',
                  marginBottom: "10px",
                  width: "150px",
                }}
                tabIndex={-1}
                startIcon={<PictureAsPdfIcon />}
              >
   {selectedFileName=="" ? "Choose PDF" :selectedFileName.length > 10 ? `${selectedFileName.substring(0, 10)}...` : selectedFileName}
                <VisuallyHiddenInput
                  type="file"
                  accept=".pdf"
                  multiple
                  onChange={(e) => {
                    setChapterFile(e.target.files[0]);
                    setSelectedFileName(e.target.files[0].name);
                    setFileSelected(true);
                  }}
                />
              </Button>
            </div>
              <Button type="submit" style={{color: "white", backgroundColor: "#CB8B65", marginRight: "1rem", marginBottom: '10px' }}>
                Upload
              </Button>
          </div>
          </form>

          <Table>
      <TableHead>
        <TableRow>
          <TableCell>Chapter Name</TableCell>
          <TableCell>File Name</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {chaptersData.map((item) => (
          <TableRow hover role="checkbox" key={item.subjectId}>
            <TableCell>{item.chapterTitle}</TableCell>
            <TableCell>{item.chapterFile.length > 30 ? item.chapterFile.slice(0, 30) + '...' : item.chapterFile}</TableCell>
            <TableCell>
    <div onClick={() => {setChapterId(item.chapterId) ; setOpenDeleteNew(true)}} style={{ cursor: 'pointer', marginLeft:'10px'}}>
        <DeleteIcon color="warning" />
    </div>                   
           </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

        
          
      </DialogContent>
    </Dialog>

      {/* Edit Chapter Popup */}
      <Dialog maxWidth="md" fullWidth  open={editChapterModal} onClose={handleCloseChapterEditModal}>
        <DialogTitle>Edit Chapter</DialogTitle>
        <DialogContent >
        <form onSubmit={handlesubmitChapter}>
        <div style={{display:"flex", gap:'10px', alignItems:'center'}}>
        <div>
        <TextField
  label="Chapter Name"
  fullWidth
  value={chapterTitle}
  onChange={(e) => setChapterTitle(e.target.value)}
  style={{ marginBottom: '20px', marginTop: '10px' }}
  InputProps={{
    style: {
      width:'400px',
      height: '45px',
      padding: '0 14px',
    },
  }}
/>
          </div>
<div>
          <Button
      component="label"
      role={undefined}
      style={{backgroundColor:'#CB8B65', color:'white',marginBottom:'10px', width:'140px'}}
      tabIndex={-1}
      startIcon={<PictureAsPdfIcon />}
    >
      Upload PDF
      <VisuallyHiddenInput
        type="file"
        accept=".pdf"
        multiple
        onChange={(e)=>setChapterfile(e.target.files[0])}
      />
    </Button>
    </div>  
        </div>
        <Button onClick={handleCloseChapterModal} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Cancel
          </Button>
          
       <button onClick={handleEditchapterNew}>Yes</button>
        </form>  
        </DialogContent>
      </Dialog>

      {/* View Chapter Modal */}
      <Dialog fullWidth maxWidth="sm" open={viewChapterModal} onClose={handleCloseViewChapterModal}>
        <DialogTitle>View Chapter</DialogTitle>
        <DialogContent>
          <h3>{chapterTitle}</h3>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewChapterModal} style={{ color: 'white', backgroundColor: '#CB8B65' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

            {/* DELETE CHAPTERS POPUP */}
            <Dialog  open={openDeleteNew} onClose={handleCloseDeleteNew}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px'  }}>
          <CloseIcon style={{ color: 'red', fontSize: '60px', padding:'10px', marginBottom: '20px', borderRadius:'50%', border:'2px solid red'   }} />
          <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
            Are you sure ?
          </div>
          <div style={{textAlign: 'center',width:'320px' , marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button 
              onClick={handleCloseDeleteNew} 
              style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width:'100px', border:'0px' }}
            >
              Cancel
            </button>
            <button 
              onClick={deletechapter} 
              style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width:'100px', border:"0px"}}
            >
              Yes
            </button>
          </DialogActions>
        </div>
      </Dialog>

    </>
  );
}

export default Notes;
