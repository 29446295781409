import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import SampleImage from "../../../../../assets/SampleImage.svg";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import TabProfile from "./TabProfile";
import TabClasses from "./TabClasses";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TabReviews from "./TabReviews";
import { useDispatch } from "react-redux";
import { teacherDetails } from "../../../../features/teacher/teacherListingSlice";

const TutorProfile = () => {
  const isXs = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState("1");
  const dispatch = useDispatch();
  const [firstName, setFistName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [colour, setColour] = React.useState("");


  const [profileImg, setProfileImg] = React.useState("");

  const profileDetail = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;
    dispatch(teacherDetails({ userId: userId }))
      .then((response) => {
        if (response?.payload?.status == 200) {
          setFistName(response?.payload?.data?.first_name);
          setLastName(response?.payload?.data?.last_name);
          setMobile(response?.payload?.data?.mobile);
          setProfileImg(response?.payload?.data?.files[0]?.file_name);
          setEmail(response?.payload?.data?.email);
        }
      })
      .catch((error) => {
        console.log("getting error", error);
      });
  };

  useEffect(() => {
    profileDetail();
    randomColor();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const randomColor = () => {
    // Generate a random hex color
    setColour('#' + Math.floor(Math.random()*16777215).toString(16));
  };
  return (
    <Box>
      <Box sx={{ backgroundColor: "#CB8B65", padding: 2 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {/* <img
              src={profileImg ?? SampleImage}
              style={{ width: isXs ? "90%" : "40%" , border: '2px solid white', borderRadius: '200px' }}
            /> */}

<Box
    sx={{
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: profileImg ? 'transparent' : colour,
      overflow: 'hidden',
      marginRight:"25px"
      // Ensure the Box has a defined size and that children are properly aligned
    }}
  >
    {profileImg ? (
      <img
        src={profileImg}
        alt="profile-img"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          // Ensure that the image covers the Box without stretching it
        }}
      />
    ) : (
      <Typography
        variant="h5"
        sx={{ color: 'white', fontWeight: 'bold' , }}
      >
        {`${firstName?.charAt(0)?.toUpperCase()}`}
      </Typography>
    )}
  </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: isXs ? "" : "center",
              margin: isXs ? "" : "0 auto",
            }}
          >
            <Box sx={{ color: "white", display: "flex", alignItems: "center" }}>
              <Box>
                <Typography variant="h4" style={{fontFamily: 'jost',textTransform: 'capitalize'}}>
                  {firstName + " " + lastName}
                </Typography>
                {/* <Typography variant='p'>Hitesh Ailani</Typography>
                                <Typography variant='p'>Hitesh Ailani</Typography> */}
                <Box sx={{}}>
                  <Box> {email}</Box>
                  <Box> {mobile}</Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} textColor="#CB8B65" centered>
              <Tab
                label="Profile"
                value="1"
                style={{ fontFamily: "jost", fontWeight: "600" }}
              />
              <Tab
                label="Classes"
                value="2"
                style={{ fontFamily: "jost", fontWeight: "600" }}
              />
              <Tab
                label="Reviews"
                value="3"
                style={{ fontFamily: "jost", fontWeight: "600" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            {" "}
            <TabProfile />{" "}
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <TabClasses />{" "}
          </TabPanel>
          <TabPanel value="3">
            <TabReviews />{" "}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default TutorProfile;
