import React, { useEffect, useState } from 'react'
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'

import Box from '@mui/material/Box'
import { Steppers8 } from './StepperWeb';
import StepperPhone, { Step8 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import InquiryLayout from './InquiryLayout';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownListings, storeStartPlan } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';

const Start = () => {
    const [radioValue, setRadioValue] = useState(0);

    const dispatch = useDispatch()
    const startListings = async () => {
        dispatch(dropDownListings())
    }

    const startList = useSelector((state) => state.listing.dropDownData.class_start_plan_type)
    const startValue = startList?.map((item) => item?.value)

    const selectStartClassPlanType = useSelector((state) => state?.listing?.postPayload?.classStartPlanTypeId)
    console.log(selectStartClassPlanType , 'selectClassPreference');


    useEffect(()=>{
        setRadioValue(selectStartClassPlanType);
    },[selectStartClassPlanType])

    useEffect(() => {
        startListings()
    }, [])

    const handleClick = (value) => {
        // console.log(item);
        setRadioValue(value);
        // console.log('aaa',radioValue);

    };

    const user = JSON?.parse(localStorage?.getItem('user'))

    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)

    const isNavigate = (event, value) => {
        if (radioValue == '' || radioValue == null || radioValue == 0) {
            toast.error('Input field is required.');
        } else {
            dispatch(storeStartPlan({ classStartPlanTypeId: radioValue }));
            if(postCount>0){
                navigate('/dashboard/online')
                }else{
              navigate('/online')
              }
        }
        }
        const goBack = () => {
            navigate(-1);
        };


        const navigate = useNavigate()
        const isXs = useMediaQuery('(min-width:600px)');
        return (
            <InquiryLayout>
                <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                    <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                    <div className='d-flex justify-content-center align-items-center mx-auto'>When do you plan to start Tuition?</div>
                </Box>
                <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                    <Steppers8 />
                </div>
                <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                    <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                        <Step8 />
                    </div>
                </div>
                <div className='d-flex justify-content-center' >
                    <div className='container border border-secondary-subtle p-3' style={{ width: isXs ? '60%' : '80%', borderColor: '#FBFBFB' }}>
                        <form >
                            {startList && startList.map((item, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <label for={item.value}>
                                        {item.name}
                                    </label>
                                    <input type="radio" name="select" value={item.value} id={item.value} onChange={() => handleClick(item.value)}  checked={radioValue === item.value}/>
                                </div>))}
                        </form>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ marginTop: isXs ? '3%' : '5%', width: isXs ? '' : '70%' }} >
                        <NextButton onClick={() => isNavigate()} />
                    </div>
                </div>
                <div className='d-flex justify-content-between px-4'>
                    <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                    <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
                </div>
            </InquiryLayout>
        )
    }

    export default Start
