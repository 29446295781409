import React from 'react'
import { Box, Button, Container, Divider, Grid, List, ListItem, ListItemText, Modal, Typography, useMediaQuery } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import line from '../../../assets/line.svg';
import { useSelector } from 'react-redux';


const EnquiryDetails = () => {
    const navigate = useNavigate();

    const selectedPost = useSelector((item) => item?.listing?.teacherSubscribeData)
    // console.log(selectedPost);

    const isXs = useMediaQuery('(min-width:600px)');
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Box className="px-3 py-1 " style={{ border: '0px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%', borderRadius: '10px' }}>
            <Box className="mt-3" sx={{ marginLeft: '0%', display: 'flex', gap: 1 }}>
                <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h6'  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Enquiry Details</Typography>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            <Box sx={{backgroundColor:'#CB8B65', display:'flex', marginTop: '1rem' , justifyContent:'center', borderRadius:'5px'}}>
                <Typography variant='h5' style={{fontWeight: '500' , fontFamily: 'jost'}} color={'white'} sx={{ paddingY:'1rem' }}>{selectedPost?.classData?.class_name}</Typography>
            </Box>
            <Divider />
            <Box>
                <Typography color={'#CB8B65'} variant='h6' style={{fontFamily: 'jost'}} sx={{ marginTop: isXs ? '3%' : '5%' , }}>Requirement at a glance</Typography>
            </Box>
            <Box sx={{ display: isXs ? '' : 'none', marginTop: isXs ? '1%' : '5%' }}>
                <Box display={'flex'}>
                    <Container sx={{ width: '50%' }}>
                        <List>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Board</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Preferred Class Strength</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Max. Fee</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Availability</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Plan to Start</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Study Mode</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Gender Preference</ListItemText>
                            </ListItem>
                        </List>
                    </Container>
                    <Container>
                        <List>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  {selectedPost?.board?.board_name}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  {selectedPost?.classPreference?.name}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  ₹{selectedPost?.cost}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  {selectedPost?.classDayType?.name}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  {selectedPost?.classStartPlanType?.name}</ListItemText>
                            </ListItem>
                            <ListItem>
                            {selectedPost?.classTypes?.map((item, index)=>(<ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}} key={index}>: {item?.classTypeName?.name}</ListItemText>))}
                            </ListItem>
                            <ListItem>
                                <ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>:  {selectedPost?.genderPreference?.name}</ListItemText>
                            </ListItem>
                        </List>
                    </Container>
                </Box>
            </Box>
            <Box sx={{ display: isXs ? 'none' : 'block', marginBottom: isXs ? '2%' : '5%' }}>
                <Typography>Board : {selectedPost?.board?.board_name}</Typography>
                <Typography>Preferred Class Strength : {selectedPost?.classPreference?.name}</Typography>
                <Typography>Max. Fee :  ₹{selectedPost?.cost}</Typography>
                <Typography>Availability : {selectedPost?.classDayType?.name}</Typography>
                <Typography>Plan to Start : {selectedPost?.classStartPlanType?.name}</Typography>
                <Typography>Study Mode : {selectedPost?.classTypes?.map((item, index)=>(<ListItemText  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}} key={index}>: {item?.classTypeName?.name}</ListItemText>))}</Typography>
                <Typography>Gender Preference : {selectedPost?.genderPreference?.name}</Typography>
            </Box>
            <Divider style={{border: '.5px solid grey'}} />

            <Box sx={{ marginTop: isXs ? '3%' : '5%' }}>
                <Typography  primaryTypographyProps={{fontFamily: 'jost' , fontSize: '1.1rem'}}>Address</Typography>
            </Box>
            <Box>
                <Box>{selectedPost?.addressOne}, {selectedPost?.addressTwo}, {selectedPost?.city}, {selectedPost?.state}</Box>
            </Box>
        </Box>
    )
}

export default EnquiryDetails
