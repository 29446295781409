import { Backdrop, Box, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import line from '../../../../assets/line.svg'
import SampleImage from '../../../../assets/SampleImage.svg'
import { useDispatch, useSelector } from 'react-redux';
import { creditHistory } from '../../../features/teacher/teacherListingSlice';
// import SampleImage from '../../../assets/SampleImage.svg';



const items = [
    { name: 'Sherya Chaturvedi', class: 'Class 11th I Accountancy, Economincs', credit: '-1 Credit', date: '24 May, 2024', },
];

const CreditHistory = () => {

    const dispatch = useDispatch();
    const isXs = useMediaQuery('(min-width:600px)');

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const credit = useSelector((item) => item?.teacherListing?.historyCredit)
    const loading = useSelector((item) => item?.teacherListing?.loading)

    // const subjects = credit?.map(sub => sub.subject_name).join(', ');
    // console.log(subjects, 'subjects');


    const userCreditHistory = () => {
        dispatch(creditHistory({userId:userId}))
    }

    useEffect(() => {
        userCreditHistory()
    }, [])

    const randomColor = () => {
        // Generate a random hex color
        return '#' + Math.floor(Math.random()*16777215).toString(16);
      };
    
    return (
        <Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <Box>
                        <Typography variant='h5' style={{fontFamily: 'jost'}}>All Credit History</Typography>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
            </Box>
            {credit?.map((item, index) => (
//                 <Box key={index} className="d-flex justify-content-center">
//                     <Box style={{ width: isXs ? '95%' : '95%' }}>
//                         <Box className="mt-2 px-3 py-3 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%', borderRadius: '10px' }}>
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                                 <Box className='py-1 d-flex gap-2'>
//                                     <Box sx={{ display: isXs ? '' : 'none' }}> <img src={item?.user?.profileImage ?? SampleImage}                             style={{ 
//                                 width: '100%', 
//                                 height: '100%', 
//                                 objectFit: 'cover' 
//                             }} 
//  /> </Box>
//                                     <Box>
//                                         <Box sx={{ fontSize: '16px', fontWeight: 'bold' }}>{item?.user?.first_name} {item?.user?.last_name}</Box>
//                                         <Box fontSize={'12px'}>{item?.classData?.class_name}</Box>
//                                     </Box>
//                                 </Box>
//                                 <Box className='py-1' textAlign={'end'}>
//                                     <Typography variant='subtitle2' sx={{ color: '#EF1818' }}>{"-"+item.credit+" "+"Credit"}</Typography>
//                                     <Box sx={{ fontSize: isXs ? '14px' : '10px' }}>{item.createdAt}</Box>
//                                 </Box>
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Box>

<Box key={index} className="d-flex justify-content-center">
    <Box style={{ width: isXs ? '95%' : '95%' }}>
        <Box className="mt-2 px-3 py-3 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%', borderRadius: '10px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='py-1 d-flex gap-2'>
                    {/* <Box sx={{ display: isXs ? '' : 'none', height: '100px', width: '100px', overflow: 'hidden', borderRadius: '50%' }}>
                        <img 
                            src={item?.user?.profileImage ?? SampleImage} 
                            style={{ 
                                width: '100%', 
                                height: '100%', 
                                objectFit: 'cover' 
                            }} 
                        />
                    </Box> */}
                    <Box
    sx={{
      display: isXs ? '' : 'none',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: item?.user?.profileImage ? 'transparent' : randomColor(),
      overflow: 'hidden',
    //   marginLeft:"25px"
      // Ensure the Box has a defined size and that children are properly aligned
    }}
  >
    {item?.user?.profileImage ? (
      <img
        src={item?.user?.profileImage}
        alt="profile-img"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          // Ensure that the image covers the Box without stretching it
        }}
      />
    ) : (
      <Typography
        variant="h5"
        sx={{ color: 'white', fontWeight: 'bold' }}
      >
        {`${item?.user?.first_name?.charAt(0)?.toUpperCase()}`}
      </Typography>
    )}
  </Box>
                    <Box sx={{marginTop:'20px'}}>
                        <Box sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {item?.user?.first_name} {item?.user?.last_name}
                        </Box>
                        <Box fontSize={'12px'}>{item?.class_data} | {item?.subject?.map((sub,index)=>{
                            return sub?.subject_name?.subject_name+','
                        })}</Box>
                    </Box>
                </Box>
                <Box className='py-1' textAlign={'end'}>
                    <Typography variant='subtitle2' sx={{ color: '#EF1818' }}>
                        {"-" + item.credit + " " + "Credit"}
                    </Typography>
                    <Box sx={{ fontSize: isXs ? '14px' : '10px' }}>{item.createdAt}</Box>
                </Box>
            </Box>
        </Box>
    </Box>
</Box>

            ))}
        </Box>
    )
}

export default CreditHistory
