import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import line2 from "../../../../../assets/line2.svg";
import SampleImage from "../../../../../assets/SampleImage.svg";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import tick from "../../../../../assets/Vector 67.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  profileUpdateTeachers,
  teacherDetails,
} from "../../../../features/teacher/teacherListingSlice";
import { toast } from "react-toastify";
import { profileUpdate } from "../../../../features/student/listingSlice";

const sxCenter = {
  display: "flex",
  justifyContent: "center",
};

const ProfileTeacher = ({ profileImage, setOpen ,colour}) => {
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [profileImagShow, setProfileImagShow] = useState("");
  const [profileImag, setProfileImag] = useState("");
  const dispatch = useDispatch();

  const userId = JSON.parse(localStorage.getItem("user"));


  const saveImage = (e) => {
    setProfileImag(e);
    setProfileImagShow(URL.createObjectURL(e));
    const userId = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();
    formdata.append("profileImage", e);
    formdata.append("userId", userId?.id);

    if(userId?.roleId==3){
      dispatch(profileUpdateTeachers(formdata)).then((response) => {
        if (response?.payload?.success) {
          toast.success(response?.payload?.message);
          // profileImage();
          setOpen(false)
          window.location.reload();
        }
      });
    }else if(userId?.roleId==2){
      dispatch(profileUpdate(formdata)).then((response) => {
        if (response?.payload?.success) {
          // alert('dsahgdsad');
          toast.success(response?.payload?.message);
          profileImage();
          setOpen(false)
        }
      });
    }
  };


  const teacherImag = () => {
    const userId = JSON.parse(localStorage.getItem("user"));
    dispatch(teacherDetails({ userId: userId?.id })).then((response) => {
      if (response?.payload?.status == 200) {
        setProfileImagShow(response?.payload?.data?.files[0]?.file_name);
      }
    });
  };

  useEffect(() => {
    teacherImag();
  }, []);

  useDispatch(() => {}, []);

  const randomColor = () => {
    // Generate a random hex color
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  };

  const goBack = () => {
    navigate("/dashboard/home");
  };

  const loading = useSelector((state) => state.teacherListing.loading)

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        minHeight: "60vh",
        position: "relative",
        width: isXs ? "60vw" : "90vw",
        margin: "0 auto",
        marginTop: isXs ? "2%" : "5%",
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
      <Stack>
        <Box
          sx={{
            backgroundColor: "#CB8B65",
            position: "relative",
            paddingBottom: isXs ? "0" : "20%",
            paddingTop: "2%",
          }}
        >
          <Box sx={{ display: "flex", marginLeft: "2%" }}>
            {/* <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: '#CB8B65', backgroundColor: 'white', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button> */}
            <Box
              sx={{
                color: "white",
                marginLeft: "2%",
                width: isXs ? "30%" : "80%",
              }}
            >
              <Typography variant="h4" style={{ fontFamily: "jost" , fontWeight: '500' }}>
                Profile Photo
              </Typography>
              <img src={line2} style={{ padding: 0 }} />
              <Box>
                Please upload a good quality, professional and smiling picture
                of yours.
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
          >
            {/* <Box
              sx={{
                position: "absolute",
                width: isXs ? "12%" : "25%",
                bottom: isXs ? "-15%" : "",
              }}
            >
              <img
                src={profileImagShow ? profileImagShow : SampleImage}
                style={{
                  padding: 0,
                  border: '2px solid white',
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Box> */}


            <Box
    sx={{
      padding:0,
      border: '2px solid white',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      bottom: isXs ? "-15%" : "",
      position: "absolute",

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: profileImagShow ? 'transparent' : colour,
      overflow: 'hidden',
      objectFit: "cover",

      // position: "absolute",
      // width: isXs ? "12%" : "25%",
      // bottom: isXs ? "-15%" : "",
      // marginLeft:"25px"
      // Ensure the Box has a defined size and that children are properly aligned
    }}
  >
    {profileImagShow ? (
      <img
        src={profileImagShow}
        alt="profile-img"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    ) : (
      <Typography
        variant="h5"
        sx={{ color: 'white', fontWeight: 'bold' }}
      >
        {`${userId?.firstName?.charAt(0)?.toUpperCase()}`}
      </Typography>
    )}
  </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: isXs ? "5%" : "10%",
          }}
        >
          <Box
            className="py-4 d-flex justify-content-center px-5"
            style={{ width: isXs ? "30%" : "100%" }}
          >
            {/* <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Upload New Photo</button> */}

            <label
              htmlFor="file-vendor"
              className="btn btn-primary py-2 w-100 border-0"
              style={{
                fontSize: "14px",
                backgroundColor: "#FFDB7E",
                color: "#866D2C",
                fontWeight: "600",
                borderRadius: "0",
                borderColor: "#CB8B65",
                cursor: "pointer", // Add cursor pointer to indicate clickability
              }}
            >
              Upload
            </label>
            <input
              type="file"
              name="thumbnail_image"
              id="file-vendor"
              className="hidden"
              style={{ display: "none" }} // Use style to hide the input field
              placeholder="Select Thumbnail Image"
              onChange={(event) => saveImage(event.target.files[0])} // Uncomment this line to handle file change
            />
          </Box>
        </Box>
        <Box sx={{display: 'flex',  marginBottom: "1rem", flexDirection: 'column' , gap: '1rem' , padding: '0rem' , fontSize: isXs  ? '' : '.8rem'}}>
          <Box sx={{ width: "65%", textAlign: "center", margin: "0 auto"  }}>
            Photo size should be at least 100px wide and up to 10MB. Photo
            format supported are .jpg, .png and .bmp
          </Box>

          <Box sx={{display: 'flex' , flexDirection: 'column' , gap: '.5rem' , padding: '.5rem 2rem'}}>
            <Box sx={{color: '#CB8B65' , fontWeight:'bold' , fontSize: isXs  ? '1rem' : '1rem'}}>Profile Photo Acceptance Guidelines</Box>
            <Box>
             <img src={tick} alt="" /> Must be a good quality photo. Blurred photo is not accepted.
            </Box>
            <Box>
            <img src={tick} alt="" /> Make sure you don't have a contact number, or any text on your
              Photo.
            </Box>
            <Box>
            <img src={tick} alt="" /> Must be your front facing Photo. Side facing photo is not accepted.
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileTeacher;
