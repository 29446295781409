import React, { useEffect, useState } from 'react'
import Edit from '../../../assets/Edit.svg';
import line from '../../../assets/line.svg';
import { Backdrop, Box, CircularProgress, FormGroup, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdate, studentProfileDetails } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#CB8B65',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const AccountProfile = () => {
    const dispatch = useDispatch()

    const studentDetails = () => {
        dispatch(studentProfileDetails({ userId: userId })).then((response) => {
            if (response?.payload?.status == 200) {
                setFirstName(response?.payload.data.firstName)
                setLastName(response?.payload.data.lastName)
                setEmail(response?.payload.data.email)
                setMobile(response?.payload.data.mobile)
                setSmsStatus(response?.payload.data.smsStatus ? true : false)
            }
        })
            .catch((error) => {
                console.log('getting error', error);
            });
    }

    // const userId = useSelector((item) => item.listing.postPayload.userId)
    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const studentData = useSelector((state) => state?.listing?.profileInfoDetails)
    const loading = useSelector((state) => state?.listing?.loading)


    useEffect(() => {
        studentDetails()
    }, [userId])

    const isXs = useMediaQuery('(min-width:600px)');
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [smsStatus, setSmsStatus] = useState();

    const handleClick = () => {
        if (firstName === '' || firstName === null || firstName === 0) {
            toast.error('Input field is required.');
        } else if (lastName === '' || lastName === null || lastName === 0) {
            toast.error('Input field is required.');
        } else if (email === '' || email === null || email === 0) {
            toast.error('Input field is required.');
        } else if (mobile === '' || mobile === null || mobile === 0) {
            toast.error('Input field is required.');
        }
        else {
            dispatch(profileUpdate({ userId: userId, email: email, mobile: mobile, firstName: firstName, lastName: lastName, smsStatus: smsStatus })).then((response) => {
                if (response?.payload?.success) {
                    toast.success(response?.payload?.message);
                }
            }).catch((error) => {
                console.log('getting error', error);
            });
        }
    }

    const fnameChange = (e) => {
        setFirstName(e.target.value)
    }
    const lnameChange = (e) => {
        setLastName(e.target.value)
    }
    const emailChange = (e) => {
        setEmail(e.target.value)
    }
    const mobileChange = (e) => {
        const value = e.target.value;
        // Restrict input to 10 digits
        if (value.length <= 10) {
            setMobile(value.replace(/\D/, '')); // Remove non-digit characters
        }
        // setMobile(e.target.value)
    }
    const smsChange = (e) => {
        setSmsStatus(e.target.checked)
    }

    return (
        <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
      <Box style={{display:'flex' , justifyContent:'space-between'}}>
      <Box className="d-flex mt-4" style={{ width: '50%', borderRadius: '0px', marginLeft: '6%', fontSize: '30px' }}>
                Profile Info
            </Box>
           
                        <button onClick={handleClick} style={{ border: 'none', backgroundColor: "transparent" , marginTop: '1.8rem' , display: isXs ? 'none' : '' , marginRight: '1.2rem' }}><img src={Edit}/></button>
           
            <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' , display: isXs ? 'flex' : 'none' }}>
                <button onClick={handleClick} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
            </Box>

      </Box>
            
            <img src={line} className='mb-3' style={{ borderRadius: '0px', marginLeft: '6%', fontSize: '30px' }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '100%', textAlign: 'center' }}>
                    <Grid container marginTop={isXs ? '5%' : '10%'} sx={{ marginLeft: isXs ? '' : '8%' }}>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <TextField
                                label="First Name"
                                // placeholder={studentData?.firstName}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'grey' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                onChange={fnameChange}
                                value={firstName}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '5px',
                                        borderColor: 'grey',
                                        fontFamily: 'jost',
                                    },  textTransform: 'capitalize',
                                    minWidth: isXs ? '80%' : '100%'

                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6} marginTop={isXs ? '' : '10%'}>
                            <TextField
                                label="Last Name"
                                // placeholder={studentData?.lastName}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'grey' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                onChange={lnameChange}
                                value={lastName}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '5px',
                                        borderColor: 'grey',
                                        fontFamily: 'jost',
                                        textTransform: "capitalize",
                                        
                                    },
                                    minWidth: isXs ? '80%' : '100%'

                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container marginTop={isXs ? '5%' : '10%'} sx={{ marginLeft: isXs ? '' : '8%' }}>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <TextField
                                label="Email"
                                placeholder={studentData?.email}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'grey' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                onChange={emailChange}
                                value={email}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '5px',
                                        borderColor: 'grey',
                                        fontFamily: 'jost',
                                        textTransform: "capitalize",
                                    },
                                    minWidth: isXs ? '80%' : '100%'
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6} marginTop={isXs ? '' : '10%'}>
                            <TextField
                                label="Phone"
                                placeholder={studentData?.mobile}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'grey' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                onChange={mobileChange}
                                value={mobile}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '5px',
                                        borderColor: 'grey',
                                        fontFamily: 'jost',
                                        textTransform: "capitalize",
                                        
                                    },
                                    minWidth: isXs ? '80%' : '100%'

                                }}
                            />
                        </Grid>

                    </Grid>
                    <Box  sx={{display: isXs ? '' : 'none'}}>
                    <Box className='d-flex align-items-center mt-3 mx-5 gap-3'>
                        <hr style={{ color: 'black', width: '70%', display: isXs ? '' : 'none' }} />
                        Edit Personal Info
                        <button onClick={handleClick} style={{ border: 'none', backgroundColor: "transparent" }}><img src={Edit} wid/></button>
                    </Box>
                    </Box>
                    <Box className='d-flex' sx={{ justifyContent: isXs ? '' : 'left' , marginTop: isXs ? '' : '2rem' , marginLeft: isXs ? '3rem' : '1.5rem' }}>
                        <Typography style={{fontFamily: 'jost'}}>SMS Alert</Typography>
                        <FormGroup className='d-flex align-items-center justify-content-center mx-3'>
                            <AntSwitch checked={smsStatus} onChange={smsChange} inputProps={{ 'aria-label': 'ant design' }} />
                        </FormGroup>
                    </Box>
                    <Box className="py-4 text-end px-4" style={{ width: isXs ? '30%' : '100%' , display: isXs ? 'none' : 'flex' }}>
                <button onClick={handleClick} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
            </Box>
                </Stack>
            </Box>
        </>
    )
}

export default AccountProfile
