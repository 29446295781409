import React, { useState } from 'react';
import img from '../../../assets/img.jpg';
import Signup1 from '../../../assets/Signup1.png';
import CurveLine from '../../../assets/CurveLine.png';
import wifi2 from '../../../assets/wifi2.png';
import imgs from '../../../assets/imgs.svg';
import LogoHeader from '../../../assets/LogoHeader.svg';
import book from '../../../assets/book.svg';
import bulb from '../../../assets/bulb.svg';
import line from '../../../assets/line.svg';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Backdrop, Button, CircularProgress, Modal, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSignUp } from '../../store/studentAuthSlice';
import axios from 'axios';
import { storeMobile, studentLogin, loading, updateStudentdata } from '../../features/student/studentSlice';
import { toast } from 'react-toastify';
import { adminLogin, adminVerifyOtp } from '../../features/admin/adminAuthSlice';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const AdminLogin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isXs = useMediaQuery('(min-width:600px)');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [openVerifyOtp, setOpenVerifyOtp] = React.useState(false);
  const [resendCounter, setResendCounter] = useState(30);
  const [otp, setOtp] = useState("");

  const handleCloseVerifyOtp = () => setOpenVerifyOtp(false);

  const handleOtpSubmit = () => {
    if (otp == "") {
      toast.error("Otp field is required");
      return;
    }
    dispatch(
      adminVerifyOtp({
        mobile: phoneNumber,
        roleId: 1,
        otp: otp,
      })
    ).then((response) => {
      if (response?.payload?.status == 200) {
        toast.success(response?.payload?.message);
        setOpenVerifyOtp(false);
        localStorage.setItem('user', JSON.stringify(response?.payload?.data))
        setPhoneNumber('');
        setOtp('');
        navigate('/dashboard/admin')
      } else {
        toast.success(response?.payload?.message);
      }
    });
  }

  // const handleResendOtp = () => {
  //   dispatch(resendCloseVerifyOtp({mobile:mobile , roleId:2 , type:2})).then((response)=>{
  //       if(response.payload.status==200){
  //           toast.success(response?.payload?.message);
  //           setResendCounter(30); // Reset the counter
  //       }
  //   })
  // };

  const isValidIndianMobileNumber = (mobile) => {
    const indianMobileNumberPattern = /^[6-9]\d{9}$/;
    return indianMobileNumberPattern.test(mobile);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        document.getElementById('submitBtn3').click();
    }
};

  const submitHandler = async (e) => {
    // alert('dsahdbsa');
    e.preventDefault();
    if (phoneNumber.trim() === '') {
      toast.error('Mobile number is required.');
      return;
      // console.log('ababs')
    }
    if (isValidIndianMobileNumber(phoneNumber)) {
      // alert('fsfsf');
      dispatch(adminLogin({ mobile: phoneNumber, roleId: 1 }))
        .then((apiResponse) => {
          if (apiResponse?.payload?.data) {
            dispatch(updateStudentdata(apiResponse?.payload?.data))
            toast.success(apiResponse?.payload?.message);
            // navigate('/signup-otp')
            setOpenVerifyOtp(true);
          } else if (apiResponse?.payload?.status === 422) {
            const errorMessage = apiResponse?.payload?.message
            toast.error(errorMessage);
          }
        })
        .catch((error) => {
          toast.error(error?.message || "An error occurred while logging in.");
        });
    } else {
      toast.error('Please enter a valid  mobile number.');
    }
    dispatch(storeMobile(phoneNumber));

  };

  const handleResendOtp = () => {
    dispatch(adminLogin({ mobile: phoneNumber, roleId: 1 }))
      .then((apiResponse) => {
        if (apiResponse?.payload?.data) {
          // dispatch(updateStudentdata(apiResponse?.payload?.data))
          // dispatch(storeMobile(phoneNumber));
          toast.success(apiResponse?.payload?.message);
          // navigate('/signup-otp')
        } else {
          const errorMessage = apiResponse?.payload?.message
          toast.error(errorMessage);
        }
      })
      .catch((error) => {
        toast.error(error?.message || "An error occurred while resend otp in.");
      });
  }

  const loading = useSelector((state) => state?.admin?.loading);


  React.useEffect(() => {
    let timer;
    if (resendCounter > 0) {
      timer = setInterval(() => {
        setResendCounter((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [resendCounter]);

  return (
    <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className='d-flex flex-row justify-content-center align-items-center'>
        <div className='container border-0'>
          <div className='row justify-content-center'>
            <div style={{ width: '895px', height: '560px' }}>
              <div style={{ marginTop: '10%', backgroundColor: '#ffffff' }}>
                <div className='row'>
                  <div className='col-md-6' style={{ display: isXs ? '' : 'none' }} >
                    <div >
                      <img src={imgs} alt='vector' className='img-fluid' style={{ width: '100%', borderBottomLeftRadius: isXs ? '' : '50px', borderBottomRightRadius: isXs ? '' : '50px' }} />
                    </div>
                  </div>
                  <div className='col-md-6' style={{ display: isXs ? 'none' : '' }}>
                    <div style={{ backgroundColor: '#CB8B65', height: '20rem', position: 'relative', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                      <div className='d-flex justify-content-center'>
                        <img src={LogoHeader} style={{ position: 'absolute', width: '40%', top: '1rem' }} />
                        <div style={{ position: 'absolute', color: 'white', top: '5.5rem', border: '2px solid white', borderRadius: '20px', width: '40%', textAlign: 'center' }}>For Students</div>
                        <img src={Signup1} style={{ position: 'absolute', bottom: 0 }} />
                      </div>
                      <img src={wifi2} style={{ position: 'absolute', bottom: 0, left: 5 }} />
                      <img src={book} style={{ position: 'absolute', top: 0, right: 5 }} />
                      <img src={CurveLine} style={{ position: 'absolute', top: 0, left: 0 }} />
                      <img src={CurveLine} style={{ position: 'absolute', bottom: 0, right: 0, rotate: '180deg' }} />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='d-flex flex-row justify-content-between p-2'>
                      <div>
                        <h3 className='pt-3' style={{ fontWeight: 'bold' }}>Admin Login</h3>
                        <img src={line} className='mb-4' />
                      </div>
                      <img src={book} style={{ height: '12%', width: '12%', display: isXs ? '' : 'none' }} />
                    </div>
                    <div>
                      <form onSubmit={submitHandler}>
                        <div className="container">
                          <div className="row" style={{ fontSize: '14px', marginTop: isXs ? '5%' : '' }}>
                            {/* <div className="col-12 my-2 mb-4" style={{display: isXs ? '' : 'none'}} >
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': { width: '93%' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField label="Username" placeholder='Enter your Full Name' inputProps={{ style: { height: '10px', fontSize:'12px' } }} InputLabelProps={{sx:{color:'black'}}} color='grey' focused />
                                                            </Box> 

                                                        </div> */}
                            {/* <div className="col-12 my-2 mb-4" style={{display: isXs ? '' : 'none'}}>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': { width: '93%' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField label="Email" placeholder='Enter your Email' inputProps={{ style: { height: '10px', fontSize:'12px' } }} InputLabelProps={{sx:{color:'black'}}} color='grey' focused />
                                                            </Box>
                                                        </div> */}
                            <div className="col-12 my-2" >
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { width: '93%' },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  label="Phone number"
                                  sx={{
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                      WebkitAppearance: "none",
                                      margin: 0,
                                    },
                                    "input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                  }}
                                  type='number'
                                  placeholder='Enter your Phone number'
                                  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                  onKeyPress={handleKeyPress} // Handle Enter key press

                                  inputProps={{ style: { height: '10px', fontSize: '12px' } }}
                                  InputLabelProps={{ sx: { color: 'black' } }}
                                  color='grey'
                                  focused

                                />
                              </Box>
                            </div>
                            {/* <div className="col-12 my-2" style={{display: isXs ? 'none' : '', textAlign:'center'}}>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': { width: '93%' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField label="Email or Phone number" placeholder='Enter your Email or Phone number' inputProps={{ style: { height: '10px', fontSize:'12px' } }} InputLabelProps={{sx:{color:'black'}}} color='grey' focused />
                                                            </Box>
                                                        </div> */}
                            <div style={{ width: '100%', textAlign: 'center', marginTop: isXs ? '40%' : '5%' }}>
                              <button id="submitBtn3" className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0' }}>Login</button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="d-flex flex-row justify-content-between w-full my-4" style={{ position: 'relative' }}>
                        {/* <div className="flex-grow-1 text-center" style={{ fontSize: '12px', fontWeight: '900' }}>
                                                    <small className="block">
                                                        <span className="">Already have an account?</span>
                                                        <a href="#" style={{ color: '#AF6031' }}>Login Now!</a>
                                                    </small>
                                                </div> */}
                        <div>
                          <img src={bulb} style={{ position: 'absolute', right: '2%', top: '-1rem', display: isXs ? '' : 'none' }} />
                        </div>
                      </div>
                      <Modal
                        open={openVerifyOtp}
                        onClose={handleCloseVerifyOtp}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                              OTP Verification
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3%",
                            }}
                          >
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2, width: "90%", textAlign: "center" }}
                            >
                              Please enter the OTP sent to your registered mobile number.
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3%",
                            }}
                          >
                            <TextField
                              label="OTP"
                              variant="outlined"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              sx={{ width: "50%", textAlign: "center" }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3%",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={handleOtpSubmit}
                              sx={{ backgroundColor: "#CB8B65", color: "white" }}
                            >
                              Verify OTP
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3%",
                            }}
                          >
                            <Typography>
                              Didn't receive the OTP?{" "}
                              <Button
                                variant="text"
                                onClick={handleResendOtp}
                                disabled={resendCounter > 0}
                                sx={{ color: "#CB8B65", textTransform: "none" }}
                              >
                                Resend OTP {resendCounter > 0 && `(${resendCounter}s)`}
                              </Button>
                            </Typography>
                          </Box>
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AdminLogin;
