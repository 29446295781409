import { Box, Link, TextField, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Footer from '../pages/LandingPage/Footer';
import logo from "../../assets/LogoHeader.svg";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { toast } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavBar from "../Navigation/NavBar";

function ContactUs() {
  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [description, setDescription] = useState('');
  const [captcha, setCaptcha] = useState('');

  const handleContactSubmit = async (e) => {
    e.preventDefault();

    if (!captcha) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }
    let date = new Date();
    let randomnumber = Math.floor((Math.random() * 1000000));
    let CurrentDateTime = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${randomnumber}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
    
      const formDatanew = new FormData();
    formDatanew.append("fullName", name);
    formDatanew.append("email", email);
    formDatanew.append('phone', mobile);
    formDatanew.append('description', description);
    formDatanew.append('token', CurrentDateTime);

    try {
      const response = await axios.post("https://app.kanhahometutions.com/api/v1/contact", formDatanew);
      if (response.data.success === true) {
        toast.success(response.data.message);
        setName('');
        setEmail('');
        setMobile('');
        setDescription('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <>
    <NavBar/>
    <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Contact Us</div></div>
   
      <div style={{ display: 'flex', padding: isXs ? '50px' : '0px', gap: '20px', flexDirection: isXs ? 'row' : 'column', alignItems: isXs ? '' : 'center' }}>
        {/* Left Column */}
        <div style={{ flex: '1', padding: '30px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <h4 style={{ fontSize: '30px' }}>Get In Touch</h4>
          <div style={{ width: '100%' }}>
            Reach out to us for personalized tutoring solutions, expert guidance, and support tailored to your learning needs.
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: isXs? 'center':'' }}>
            <LocalPhoneIcon style={{ color: '#CB8B65', fontSize: '20px' }} />
            <div style={{ fontSize: '17px' }}>Mobile:</div>
            <div>+91-6376400386</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' , alignItems: isXs? 'center':'' }}>
            <EmailIcon style={{ color: '#CB8B65', fontSize: '18px' }} />
            <div style={{ fontSize: '17px' }}>Email:</div>
            <div>info@kanhahometutions.com</div>
          </div>
          <div style={{ display: 'flex', gap: '10px',  alignItems: isXs? 'center':''  }}>
            <LocationOnIcon style={{ color: '#CB8B65', fontSize: '20px' }} />
            <div style={{ fontSize: '17px' }}>Address:</div>
            <div> Plot No. 45, Vaishali Nagar, Jaipur</div>
          </div>
        </div>

        {/* Right Column */}
        <div style={{ flex: '1', padding: '30px', borderRadius: '10px', boxShadow: '1px 1px 25px rgba(10, 5, 19, 0.1)' }}>
          <div style={{ fontSize: '25px', fontWeight: '600', paddingBottom: '15px' }}>Contact Us</div>
          <form onSubmit={handleContactSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TextField
              label="Full Name"
              required
              placeholder='Enter Your Full Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                style: { height: '40px', fontSize: '12px', textAlign: 'center' }
              }}
              InputLabelProps={{
                sx: { color: 'black', textAlign: 'center' }
              }}
              fullWidth
              color="grey"
              focused
            />
            <TextField
              label="Email"
              required
              placeholder='Enter Your Email'
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: { height: '40px', fontSize: '12px', textAlign: 'center' }
              }}
              InputLabelProps={{
                sx: { color: 'black', textAlign: 'center' }
              }}
              variant="outlined"
              fullWidth
              focused
              color="grey"
            />
            <TextField
              label="Phone Number"
              required
              type="number"
              placeholder='Enter Your Phone Number'
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              InputProps={{
                style: { height: '40px', fontSize: '14px', textAlign: 'center' }
              }}
              InputLabelProps={{
                sx: { color: 'black', textAlign: 'center' }
              }}
              variant="outlined"
              fullWidth
              focused
              color="grey"
            />
            <TextField
              label="Description"
              required
              multiline
              placeholder='Description'
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputProps={{
                style: { height: '100px', fontSize: '14px', textAlign: 'center' }
              }}
              InputLabelProps={{
                sx: { color: 'black', textAlign: 'center' }
              }}
              variant="outlined"
              fullWidth
              focused
              color="grey"
            />
            <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', gap: '10px', alignItems: 'center' }}>
            <ReCAPTCHA 
                sitekey='6Ld44GsqAAAAAMN-dQSDaiZCjEdoZtD6jreQsOD6'
                onChange={(value) => setCaptcha(value)}
              /> 
               <button type="submit" style={{ backgroundColor: '#CB8B65', color: 'white', width: isXs ? '33%' : '100%', height: '2.5rem', border: 'none', borderRadius: '3px', margin: '0px auto' }}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
