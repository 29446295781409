import React, { useEffect, useState } from 'react'
import line from '../../../assets/line.svg';
import { Backdrop, Box, Card, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import AccountClose from '../../../assets/AccountClose.svg';
import { useDispatch, useSelector } from 'react-redux';
import { accountClose } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';
import { dropDownListings } from '../../features/teacher/teacherListingSlice';



const AccountSettings = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch()
    const [description, setdescription] = useState('')
    const [radioValue, setRadioValue] = useState(0);

    const userId = useSelector((item) => item?.listing?.postPayload?.userId)

    const closeReason = useSelector((item) => item?.listing?.dropDownData?.close_account_reason)
    const loading = useSelector((item) => item?.listing?.loading)

    const reasonId = closeReason?.map((item) => item?.value)
    // const closeName = closeReason?.map((item) => item?.name)

    const studentDetails = (event, value) => {
        if ( description === '' || description === null || description === 0) {
            toast.error('Input field is required.');
        }else if (radioValue === '' || radioValue === null || radioValue === 0){
            toast.error('Input field is required.');
        }
        else {
        const selectedReason = reasonId?.find((item) => item.value === value);
        const user = JSON.parse(localStorage.getItem('user'))
        dispatch(accountClose({ userId: user?.id, reasonId:selectedReason, description:description })).then((response)=>{
            if(response?.payload?.status==200){
                toast.success(response?.payload?.message);
            }
        })
        }
    }


    const handleClick = (value) => {
        // console.log(item);
        setRadioValue(value);
        // console.log('aaa',radioValue);

    };

    const handleChange = (event) => {
        setdescription(event.target.value);
      };

    const closeAccountListing = () =>{
           dispatch(dropDownListings())
    }


      useEffect(()=>{
        closeAccountListing();
      },[])

    return (
        <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <Box className="d-flex mt-4" style={{ width: '50%', borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }}>
                Settings
            </Box>
            <img src={line} className='mb-3' style={{ borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ marginTop: isXs ? '2%' : '5%', width: '95%' }}>
                            <Box sx={{ marginLeft: '3%' }}>
                                <Box sx={{ display: 'flex', gap: 1, marginTop: '2%' }}>
                                    <img src={AccountClose}/>
                                    <Typography sx={{fontFamily: "jost" , fontSize: "1.4rem"}}>Close your Account</Typography>
                                </Box>
                                <Box sx={{ fontSize: isXs ? '1rem' : '3vw', marginTop: '1%' }}>Let us know, why you are leaving us</Box>
                                <Box sx={{ backgroundColor: '#FBFBFB', width: '95%', marginTop: '2%', border: '0.5px solid grey' }}>
                                    <Box sx={{ padding: '3%' }}>
                                        <Box>Reason for closing your account</Box>
                                        <div className='d-flex'>
                                            <hr style={{ color: 'black', width: '95%' }} />
                                        </div>
                                        <FormControl>
                                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                                {closeReason?.map(option => (
                                                    <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.name} onChange={() => handleClick(option.value)}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: isXs ? '2%' : '8%' }}>
                                    Your feedback is important to us. Could you spare 2 minutes to give detailed feedback on Kanha Home Tuitons?
                                </Box>
                                <TextField
                                    id="outlined-multiline-static"
                                    variant='outlined'
                                    multiline
                                    sx={{ width: '95%', marginTop: isXs ? '2%' : '8%' }}
                                    rows={4}
                                    placeholder="Please provide a brief reason"
                                    onChange={handleChange}
                                />
                                <button onClick={studentDetails} style={{ borderColor: "transparent", marginTop: isXs ? '2%' : '8%', padding: '5px', width: isXs ? '20%' : '40%', borderRadius: '0px', backgroundColor: '#FFDB7E', color: '#866D2C' }}>Submit</button>
                                <div className="text-center py-3" style={{ fontSize: '11px', width: isXs ? '80%' : '95%', margin: '0 auto' }}>
                                    <small>
                                        <span >*Note: Closing your account will disable your profile and remove it from our servers. You will also stop getting email and SMS alerts from us.</span>
                                       
                                    </small>
                                </div>
                            </Box>
                        </Card>
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default AccountSettings
