import React from 'react'
import BackArrow from '../../assets/BackArrow.svg'

const BackButton = ({ onClick }) => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
      <button className='d-flex justify-content-center align-items-center' style={{ height: '35px', fontSize: '40px', width: '40px', borderRadius: '8px', backgroundColor: 'white', color: '#CB8B65', border: '0px' }} onClick={onClick}>
        <img src={BackArrow}alt='back' />
      </button>
    </div>
  )
}

export default BackButton
