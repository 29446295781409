import { Backdrop, Box, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import line from '../../../../assets/line.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { historySubscriptionTeacher } from '../../../features/teacher/teacherListingSlice';


const items = [
    { name: 'Basic Plan', credits: '2 Credit Added', price: '₹500', date: '24 June, 2024 | 2:00 PM', },
];


const SubscriptionHistory = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'))
    const idUser = user?.id

    const historySubscription = () => {
        dispatch(historySubscriptionTeacher({userId:idUser}))
    }

    useEffect(() => {
        historySubscription();
    },[])

    const subscriptionHistoryData = useSelector((item) => item?.teacherListing?.subscriptionHistory)
    const loading = useSelector((item) => item?.teacherListing?.loading)


     const goBack = () => {
        navigate(-1);
      };
    return (
        <Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5' style={{fontFamily: 'jost'}}>Subscription History</Typography>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
            </Box>
            {subscriptionHistoryData.map((item, index) => (
                <Box key={index} className="d-flex justify-content-center">
                    <Box style={{ width: isXs ? '95%' : '95%' }}>
                        <Box className="mt-2 px-3 py-3 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%', borderRadius: '10px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box className='py-1'>
                                    <Typography variant='h6' style={{fontFamily: 'jost'}}>{item.planName}</Typography>
                                    <Box fontSize={'12px'}>{item.credit} Credit Added</Box>
                                </Box>
                                <Box className='py-1' textAlign={'end'}>
                                    <Typography variant='h6' sx={{ color: '#EF1818' }}>{item.price}</Typography>
                                    <Box fontSize={'12px'}>{item.created_at}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default SubscriptionHistory
