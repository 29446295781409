import { Autocomplete, Box, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdateTeachers, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { toast } from 'react-toastify';



const backStyle = {
    backgroundColor: "#F3F3F3",
    border: "1px solid #DCDCDC",
    // margin: "3%",
    borderLeft: "5px solid #CB8B65",
  };
  const titleStyle = {
    margin: "2%",
  };
  const contStyle = {
    marginLeft: "2%",
    marginBottom: "2%",
  };

  
const ProfileEducation = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const [isDegree, setIsDegree] = useState('');
    const [degreeId, setIsDegreeId] = useState('')
    const [isUniversity, setIsUniversity] = useState('');
    const [isDegreeStatus, setIsDegreeStatus] = useState('');
    const [experiance, setExperiance] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [experinceDesc, setExperinceDesc] = useState('');
  const [taught, setTaught] = useState('');

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const degreeData = useSelector((item) => item?.teacherListing?.degreeTeacher);

    const teacherData = useSelector((item) => item?.teacherListing?.availableCredits)

    const teacherDetail = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setIsDegree(response?.payload?.data?.teacher_profile?.degree?.degree_name)
                    setIsDegreeId(response?.payload?.data?.teacher_profile?.degree?.id)
                    setIsUniversity(response?.payload?.data?.teacher_profile?.university_name)
                    setTaught(response?.payload?.data?.teacher_profile?.taught)
                    setSchoolName(response?.payload?.data?.teacher_profile?.school_name)
                    setExperinceDesc(response?.payload?.data?.teacher_profile?.experinceDesc)
                    setIsDegreeStatus(response?.payload?.data?.teacher_profile?.degree_status)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    useEffect(() => {
        teacherDetail()
    }, [userId])

    const handleDegreeChange = (e) => {
        setIsDegree(e.target.value)
    }

    const handleUniversityChange = (e) => {
        setIsUniversity(e.target.value)
    }

    const handleRadioClick = (e) => {
        setIsDegreeStatus(e.target.value)
    }

    const handleSaveChanges = () => {
         if (isUniversity === '' || isUniversity === null || isUniversity === 0) { 
            alert('22')
            toast.error('Input field is required.');
        } else {
            dispatch(profileUpdateTeachers({ userId:userId, degreeId:degreeId, universityName: isUniversity, degreeStatus : isDegreeStatus , taught:taught ,  schoolName:schoolName , experinceDesc:experinceDesc}))
            .then((response) => {
                if (response?.payload?.success == true) {
                    toast.success(response?.payload?.message);
                }
            }).catch((error) => {
                console.log('getting error', error);
            });
        }
    }

    const handleSelectTaught = (event) => {
        const value = event.target.value;
        setTaught(value);
        console.log(value, 'selected value taught'); // 1 for Yes, 0 for No
      };

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };
    return (
        <Box>
        <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
            <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h5' style={{fontFamily: 'jost'}}>Education</Typography>
                    <Box>Please specify all of your Education background</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                <button onClick={handleSaveChanges} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
            <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '95%', padding: 2 }}>
                {/* <Box>
                    <Typography>Type*</Typography>
                    <FormControl sx={{ width: isXs ? '40%' : '80%', marginTop: '2%' }}>
                        <InputLabel id="demo-simple-select-label">Search</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Type*"
                        >
                            <MenuItem >Degree</MenuItem>
                            <MenuItem >Certification</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} /> */}

                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography sx={{ marginBottom: isXs ? '2%' : '5%' , fontFamily: 'jost' }}>Degree Name*</Typography>
                    <Autocomplete
                        fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                               
                                fontFamily: 'jost',
                            },
                           
                        }}
                        id="free-solo-demo"
                        freeSolo
                        value={isDegree}
                        onChange={handleDegreeChange}
                        options={degreeData?.map((item) => item?.degreeName)}
                        renderInput={(params) => (
                            <TextField {...params} label="Select"  InputLabelProps={{style: {fontFamily: 'jost'}}} />
                        )}
                        disableClearable
                    />
                </Box>

                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />

                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography sx={{fontFamily: 'jost'}}>College or University*</Typography>
                    {/* <Autocomplete
                        sx={{ width: isXs ? '40%' : '80%', marginTop: '2%' }}
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search input"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    /> */}
                    <TextField
                        
                        onChange={handleUniversityChange}
                        sx={{ width: isXs ? '40%' : '80%', marginTop: '2%'}}
                        value={isUniversity}
                        />
                        
                        
                </Box>

                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />

                <Box sx={{ marginTop: isXs ? '3%' : '8%', marginBottom: '1px' }}>
                    <Typography sx={{fontFamily: 'jost'}}>Degree Status*</Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            value={isDegreeStatus}
                            onChange={handleRadioClick}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="1" control={<Radio />} label={<Typography fontFamily={'jost'}>Completed</Typography>} />
                            <FormControlLabel value="0" control={<Radio />} label={<Typography fontFamily={'jost'}>Persuing</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {/* <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />

                <Box>
                    <Typography>Upload Documents</Typography>
                    <Box>
                        <Box className="mt-3" style={{ width: isXs ? '30%' : '100%' }}>
                            <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Upload Documents</button>
                        </Box>
                    </Box>
                </Box> */}

{/* <Box sx={backStyle}>
              
              </Box> */}


<Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
<Typography sx={titleStyle}>
                  Have you ever taught in any School or College? *
                </Typography>
                <Box sx={contStyle}>
                  <FormControl sx={{ width: "10%" }}>
                    <Select value={taught} onChange={handleSelectTaught}>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                { taught ?  <Box sx={contStyle}>


                  
                <TextField
                  placeholder="School/College Name"
                  // fullWidth
                  rows={4}
                  value={schoolName}
                  onChange={(e)=>setSchoolName(e.target.value)}
                  sx={{ marginBottom:'20px' , width:'50%'}}
                />
                  <TextField
                    value={experinceDesc}
                    onChange={(e)=>setExperinceDesc(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Describe your experience in School or College*"
                  />
                </Box> : ' '
             }
                </Box>
            </Stack>
        </Box>
    </Box>
    )
}

export default ProfileEducation
