
import React, { useEffect, useState } from 'react'
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'
import img from '../../../assets/img.jpg';

import Box from '@mui/material/Box'
import { Steppers7 } from './StepperWeb';
import StepperPhone, { Step7 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import Backbutton from '../../Buttons/BackButton'
import InquiryLayout from './InquiryLayout';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownListings, storeDayType } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';

const Days = () => {

    const dispatch = useDispatch()
    const [radioValue, setRadioValue] = useState(null); 


    const dayListings = async () => {
        dispatch(dropDownListings())
    }

    const dayList = useSelector((state) => state.listing.dropDownData.class_day_type)
    const dayValue = dayList?.map((item) => item?.value)

    const selectClassDayType = useSelector((state) => state?.listing?.postPayload?.classDayTypeId)
    console.log(selectClassDayType , 'selectClassPreference');


    useEffect(()=>{
        setRadioValue(selectClassDayType);
    },[selectClassDayType])
    

    useEffect(() => {
        dayListings()
    }, [])

    const handleClick = (value) => {
        // console.log(item);
        setRadioValue(value); 
        // console.log('aaa',radioValue);
        
    };

    const user = JSON?.parse(localStorage?.getItem('user'))
    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)


    const isNavigate = (event, value) => {
        if (radioValue == '' || radioValue == null || radioValue == 0) {
            toast.error('Input field is required.');
          }else{
        dispatch(storeDayType({ classDayTypeId: radioValue }));
        if(postCount>0){
            navigate('/dashboard/start')
            }else{
          navigate('/start')
          }
          }
    }
    const goBack = () => {
        navigate(-1);
    };


    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <Backbutton style={{ marginRight: 'auto' }} onClick={goBack} />
                <div className='d-flex justify-content-center align-items-center mx-auto'>What days are you generally available to take demo classes?</div>
            </Box>
            <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers7 />
            </div>
            <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step7 />
                </div>
            </div>
            <div className='d-flex justify-content-center' >
                <div className='container border border-secondary-subtle p-3' style={{ width: '60%', borderColor: '#FBFBFB' }}>
                    <form >
                        {dayList && dayList.map((item, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                <label for={item.value}>
                                    {item.name}
                                </label>
                                <input type="radio" name="select" value={item.value} id={item.value} onChange={() => handleClick(item.value)} checked={radioValue === item.value}/>
                            </div>))}
                    </form>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '3%' : '5%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </div>
            </div>
            <div className='d-flex justify-content-between px-4'>
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </div>
        </InquiryLayout>
    )
}

export default Days
