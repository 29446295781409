import { Box, Typography, useMediaQuery, Stack, FormGroup, Button, Modal, Backdrop, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import line from '../../../../../assets/line.svg'
import DevicesIcon from '@mui/icons-material/Devices';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Delete1 from '../../../../../assets/delete1.svg'
import { makeStyles } from '@mui/styles';
import Delete from './Delete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeacherClasses, getTeacherClasses } from '../../../../features/teacher/teacherListingSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

// const AntSwitch = styled(Switch)(({ theme }) => ({
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: 'flex',
//     '&:active': {
//         '& .MuiSwitch-thumb': {
//             width: 15,
//         },
//         '& .MuiSwitch-switchBase.Mui-checked': {
//             transform: 'translateX(9px)',
//         },
//     },
//     '& .MuiSwitch-switchBase': {
//         padding: 2,
//         '&.Mui-checked': {
//             transform: 'translateX(12px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 opacity: 1,
//                 backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#CB8B65',
//             },
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//         width: 12,
//         height: 12,
//         borderRadius: 6,
//         transition: theme.transitions.create(['width'], {
//             duration: 200,
//         }),
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 16 / 2,
//         opacity: 1,
//         backgroundColor:
//             theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
//         boxSizing: 'border-box',
//     },
// }));

const ClassConducted = () => {
    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch()
    const [isClassId, setIsClassId] = useState()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        borderRadius: '10px',
        width: isXs ? '50%' : '90%',
        boxShadow: 24,
    };

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const classData = useSelector((item) => item?.teacherListing?.getClassTeacher);

    const loading = useSelector((item) => item?.teacherListing?.loading);

    const classConductedId = classData?.map((item) => item?.class_id)

    const listConductClasses = () => {
        dispatch(getTeacherClasses({ userId: userId }));

    }

    useEffect(() => {
        listConductClasses();
    }, [])

    const handleOpen = (id) => {
        setIsClassId(id)
        setOpen(true)
    };

    const handleDelete = () => {
        dispatch(deleteTeacherClasses({userId:userId, classConductedId:isClassId})).then((response)=>{
            if(response?.payload?.status ==200){
                toast.success(response?.payload?.message);
            }  
            else if (response?.payload?.success == false) {
                toast.warning(response?.payload?.message);
            }
        }) .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            listConductClasses();
            setOpen(false);
          });

      
    }

    const isNavigate = () => {
        navigate('/dashboard/add-class')
    }

    return (
        <Box>
            <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box className="mt-3" sx={{ marginLeft: '2%', display: 'flex'}}>
                <Box>
                    <Typography  variant='h5' style={{fontFamily: "jost"}}>Classes Conducted</Typography>
                    <Box>Please specify all classes that you teach.</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            {classData?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                    <Stack sx={{ display: 'flex', flexDirection: 'row' , justifyContent: 'space-between' , backgroundColor: '#f5f5f5', minHeight: '15vh', width: '100%', padding: 2 , gap: 5 }}>
                        <Box className='d-flex flex-column gap-2'>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between'  }}>
                                <Typography style={{fontFamily: "jost" , fontSize: "1.2rem"}}>{item?.class_data?.class_name}</Typography>
                    <button onClick={() => handleOpen(item?.id)} style={{ color: 'white', backgroundColor: 'red', borderRadius: '2rem' , width: '2rem' , height: '2rem' , border: 'none' , display: isXs ? 'none' : '' }}> <DeleteForeverOutlinedIcon style={{fontSize: '1.2rem'}} /></button>
                               
                            </Box>
                            <Box className='py-1 px-1' style={{ color: '#AE6539', display: 'flex', width: isXs ? '60%' : '100%', fontSize: '14px', border: '1px solid #dcdcdc', borderRadius: '5px' }}>
                                {item?.subject?.map((item, index) => (<Box key={index} >{item?.subject_name?.subject_name},</Box>))}

                            </Box>
                            <Box display={'flex'} gap={1}>
                                <Box sx={{ color: 'black' , fontSize: isXs ? '' : '.8rem'}}> Board Name :</Box>
                                <Box sx={{ display: 'flex' , fontSize: isXs ? '' : '.8rem'}}>

                                    {item?.board?.map((item, index) => (<Box sx={{ textAlign: 'left'}} key={index} >{item?.board_name?.board_name},</Box>))}
                                </Box>
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <Box sx={{ color: 'black' , fontSize: isXs ? '' : '.8rem'  }}> Class Type:</Box>
                                <Box sx={{  fontSize: isXs ? '' : '.8rem' , maxWidth: isXs ? '100%' : '70%'}}>
                                    {item?.class_type?.map((item, index) => (<Box key={index} >{item?.class_type_name?.name},</Box>))}
                                </Box>
                            </Box>
                            
                        </Box>

                        
                                    <button onClick={() => handleOpen(item?.id)} style={{ color: 'white', backgroundColor: 'red', borderRadius: '2rem' , width: '7rem' , height: '3rem' , border: 'none' , display: isXs ? '' : 'none' }}> <DeleteForeverOutlinedIcon /> Delete</button>
                                
                    </Stack>
                </Box>))}


            <Box style={{ textAlign: 'left', marginTop: isXs ? '3%' : '8%' , marginBottom: '5%' , marginLeft: "3%" }}>
                <button onClick={isNavigate} className="btn btn-primary py-2" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Add Another Class</button>
            </Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: isXs ? '3%' : '8%' }}>
                <Box display={'flex'} justifyContent={'space-between'} sx={{ width: '90%' }}>
                    <Box>Show on my Profile and send me enquiries for BSc Tuition</Box>
                    <FormGroup className='d-flex align-items-center justify-content-center mx-3'>
                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </FormGroup>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box display={'flex'} justifyContent={'space-between'} sx={{ width: '90%' }}>
                    <Box>Allow Students to book demo class for BSc Tuition</Box>
                    <FormGroup className='d-flex align-items-center justify-content-center mx-3'>
                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </FormGroup>
                </Box>
            </Box> */}

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ backgroundColor: '#CB8B65', padding: 2, display: 'flex', justifyContent: 'center', borderRadius: '10px' }}>
                            <img src={Delete1} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: isXs ? '5%' : '10%' }}>
                            <Box sx={{ textAlign: 'center', fontSize: isXs ? '20px' : '16px', fontWeight: 500 }}>
                                <div>Are you sure you want to delete Graphic Designing? </div>
                                <div>Any Associated Courses will also get deleted.</div>
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: isXs ? '5%' : '10%', marginBottom: isXs ? '5%' : '10%' }}>
                            <Box className="mt-4 d-flex justify-content-evenly px-5" style={{ width: '100%' }}>
                                <Box style={{ width: '50%', marginRight: '8px' }}><button onClick={handleClose} className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: 'white', color: '#7E7E7E', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Cancel</button></Box>
                                <Box style={{ width: '50%', marginLeft: '8px' }}><button onClick={handleDelete} className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Yes</button></Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </Box >
    )
}

export default ClassConducted
