import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


import DoneIcon2 from '../../../assets/Done2.png';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    right: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#F4C95B',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#F4C95B',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3, 
    width: 20,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: '4vw',
  height: '4vw', 
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#CB8B65',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#CB8B65'
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <img src={DoneIcon2} alt="Done" style={{ width: '80%', height: '80%' }} /> : null}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const steps = ['1', '2','3','4','5','6','7','8','9','10'];

export default function CustomizedSteppers() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={0}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step1() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={0}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step2() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={1}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step3() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={2}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step4() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={3}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step5() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={4}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step6() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={5}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step7() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={6}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step8() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={7}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step9() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={8}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Step10() {

  return (
    <Stack sx={{ width: '50%' }}>
      <div className={'mx-1' }>
        <Stepper alternativeLabel activeStep={9}>
          {steps.map((_, index) => (
            <Step key={index} >
              <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ margin: 0, padding: 0 }}/>
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

