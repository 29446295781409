import { Box, Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import SampleImage from '../../../../assets/SampleImage.svg';
import online from '../../../../assets/online.png'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';




const DashboardHomeStudent = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const buttonStyle = {
        color: 'black',
        borderRadius: '20px',
        width: '100%',
    }
    return (
        <Stack>
            <Box className="d-flex justify-content-center">
                <Box style={{ width: isXs ? '95%' : '95%' }}>
                    <Box className="mt-2 px-3 py-3 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%', borderRadius: '10px' }}>
                        <Grid container sx={{ textAlign: isXs ? '' : 'center' }}>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={SampleImage} style={{ width: isXs ? '70%' : '' }} /></Box>
                            </Grid>

                            <Grid items xs={12} sm={12} md={9} lg={9} xl={9}>
                                <Box>
                                    <Box className='py-1 '>
                                        <Box  sx={{ display: 'flex' }}>
                                            <Typography variant='h6'>Harsh Mehta</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box> <img src={online} /> </Box>
                                                <Box>Online Class</Box>
                                            </Box>
                                        </Box>
                                        <Box fontSize={'14px'}>Class 12th I Accountancy, Economics</Box>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box> <PlaceOutlinedIcon sx={{ color: '#CB8B65' }} /> </Box>
                                            <Box>Jaipur, Rajasthan</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Stack>
    )
}

export default DashboardHomeStudent
