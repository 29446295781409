import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import line from "../../../../assets/line.svg";
import Group22 from "../../../../assets/Group22.svg";
import Subscription1 from "../../../../assets/Subscription1.svg";
import Subscription2 from "../../../../assets/Subscription2.svg";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  teacherDetails,
  subscriptionPurchase,
  subscription,
  genrateOrderId,
  paymentVerify,
  refundPayment,
} from "../../../features/teacher/teacherListingSlice";
import { toast } from "react-toastify";

const SubscriptionPlan = () => {
  const isXs = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [orderId, setOrderId] = useState("");
  const [responseId, setResponseId] = useState("");

  // console.log('subscriptionPlan',subscriptionPlan);
  const user = JSON.parse(localStorage.getItem("user"));
  const idUser = user?.id;

  const subscriptionData = useSelector(
    (item) => item?.teacherListing?.planSubscribtion
  );
  const loading = useSelector((item) => item?.teacherListing?.loading);

  const teacherCredits = useSelector(
    (item) => item?.teacherListing?.availableCredits
  );
  // console.log('aaaa',teacherCredits);

  const planSubcription = () => {
    dispatch(subscription());
  };

  const creditsAvailable = () => {
    dispatch(teacherDetails({ userId: idUser }));
  };

  useEffect(() => {
    planSubcription();
    creditsAvailable();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

//   const buySubscription = async (index) => {
//     const razorpayKeyId = process.env.RAZORPAY_KEY_ID;

//     const selectedPlan = subscriptionData[index];
//     const planName = selectedPlan.planName;
//     const price = selectedPlan.price;
//     const credit = selectedPlan.credit;
//     const planId = selectedPlan.id;


//     const res = await loadScript(
//       "https://checkout.razorpay.com/v1/checkout.js"
//     );

//     if (!res) {
//       toast.error("Razorpay SDK failed to load. Are you online?");
//       return;
//     }

//     // const result = await axios.post("http://localhost:5000/payment/orders");

//     const formdata = new FormData();
//     formdata.append("amount", 1);
//     dispatch(genrateOrderId(formdata)).then((response) => {
//       if (response?.payload?.status == 200) {
//         setOrderId(response?.payload?.data?.order_id);
//         const options = {
//           key: "rzp_test_C7lkGE3FCf1x0V", // Enter the Key ID generated from the Dashboard
//         //   key: razorpayKeyId, // Enter the Key ID generated from the Dashboard
//           amount: 1 * 100, // Amount is in currency subunits. Default currency is INR. Hence, 100 refers to 100 paise
//           currency: "INR",
//           name: "Kanha Tutions",
//           description: "Payment to Kanha Home Tution",
//           image: "", // Add your logo url here
//           order_id: response?.payload?.data?.order_id,
//           handler: function (response) {
//             console.log(response, "response genrate");
//             console.log(response.razorpay_order_id, "response genrate");

//             setResponseId(response.razorpay_payment_id);
             
//             dispatch(
//               paymentVerify({
//                 razorpay_order_id: response?.razorpay_order_id,
//                 razorpay_payment_id: response?.razorpay_payment_id,
//                 razorpay_signature: response?.razorpay_signature,
//                 plan_id: planId,
//                 order_id: orderId,
//               })
//             ).then((payVerifyresponse) => {
//               if (payVerifyresponse?.payload?.status == 200) {
//                 dispatch(
//                   subscriptionPurchase({
//                     userId: idUser,
//                     planName: planName,
//                     price: 1,
//                     credit: credit,
//                   })
//                 ).then((response) => {
//                   if (response?.payload?.status == 200) {
//                     toast.success(response?.payload?.message);
//                     creditsAvailable();
//                   } else {
//                     toast.error(
//                       response?.payload?.message ||
//                         "Subscription purchase failed"
//                     );
//                   }
//                 });
//               } else {
//                 toast.error(
//                   response?.payload?.message || "Subscription purchase failed"
//                 );
//               }
//             });
//           },
//           prefill: {
//         name: "Kanha Home Tution",
//         email: "kanhahome04@gmail.com",
//         contact: "6376400386"
//           },
//           notes: {
//             address: "Kanha Corporate Office",
//           },
//           theme: {
//             color: "#F4C430",
//           },
//         };

//         const paymentObject = new window.Razorpay(options);
//         console.log(paymentObject, "paymentObject");
//         paymentObject.open();
//       } else {
//         toast.error("something went wrong");
//       }
//     });
//   };
const buySubscription = async (index) => {
  
const user = JSON.parse(localStorage.getItem('user'))
const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const selectedPlan = subscriptionData[index];
  const planName = selectedPlan.planName;
  const price = selectedPlan.price;
//   const price = 1;
  const credit = selectedPlan.credit;
  const planId = selectedPlan.id;

  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  if (!res) {
    toast.error('Razorpay SDK failed to load. Are you online?');
    return;
  }

  const formdata = new FormData();
  formdata.append('amount', price);
  formdata.append('palnId', planId);
  dispatch(genrateOrderId(formdata)).then((orderResponse) => {
    if (orderResponse?.payload?.status === 200) {
      setOrderId(orderResponse?.payload?.data?.order_id);
      const options = {
        key: razorpayKeyId,
          // key: "rzp_test_C7lkGE3FCf1x0V", // Enter the Key ID generated from the Dashboard
        amount: price * 100,
        currency: 'INR',
        name: 'Kanha Tutions',
        description: 'Payment to Kanha Home Tution',
        image: '',
        order_id: orderResponse?.payload?.data?.order_id,
        handler: async (response) => {
          console.log(response, 'response genrate');
          setResponseId(response.razorpay_payment_id);

          try {
            const payVerifyresponse = await dispatch(
              paymentVerify({
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                plan_id: planId,
                planName: planName,
                price: price,
                credit: credit,
                order_id: orderResponse?.payload?.data?.order_id,
              })
            );

            if (payVerifyresponse?.payload?.status === 200) {
                toast.success(payVerifyresponse?.payload?.message);
                creditsAvailable();
              } else {
              toast.error(
                payVerifyresponse?.payload?.message || 'Subscription purchase failed'
              );
              // Initiate refund if payment verification fails
              initiateRefund(response.razorpay_payment_id);  //NORMAL REFUND
            }
          } catch (error) {
            console.error('Error in payment process:', error);
            toast.error('An error occurred during the payment process.');
            // Initiate refund in case of any error
            initiateRefund(response.razorpay_payment_id); //NORMAL REFUND
          }
        },
        prefill: {
          name: user?.firstName+' '+user?.lastName,
          email: user?.email,
          contact: user?.mobile,
        },
        notes: {
          address: '',
        },
        theme: {
          color: '#F4C430',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      toast.error('Something went wrong');
    }
  });
};

const initiateRefund = async (paymentId) => {
    try {
        const refundPaymentResponse = dispatch(refundPayment({paymentId:paymentId}))
      if (refundPaymentResponse?.payload?.status === 200) {
        toast.success('Refund initiated successfully');
      } else {
        toast.error('Failed to initiate refund');
      }
    } catch (error) {
      console.error('Error initiating refund:', error);
      toast.error('An error occurred while initiating the refund');
    }
  };
  

  const navi = () => {
    navigate("/dashboard/subscriptionhistory");
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{ display: isXs ? "flex" : "", justifyContent: "space-between" }}
      >
        <Box className="mt-3" sx={{ marginLeft: "2%", display: "flex" }}>
          <Box>
            <Typography variant="h5" style={{ fontFamily: "jost" }}>
              Subscription Plans
            </Typography>
            <Box>
              <img src={line} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            marginTop: isXs ? "0%" : "8%",
            position: "relative",
            zIndex: 1,
            display: isXs ? "" : "none",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Group22}
              style={{
                width: "100%",
                height: "auto",
                position: "absolute",
                zIndex: -1,
              }}
            />
          </Box>
          <Box
            className="mx-5"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <Box sx={{ color: "white", marginTop: "8%", fontSize: "2vw" }}>
                Available Credits
              </Box>
              <Button
                onClick={() => navi()}
                sx={{
                  color: "white",
                  border: "1px solid white",
                  borderRadius: "20px",
                  marginTop: "3%",
                  fontSize: "1vw",
                }}
              >
                View Details
              </Button>
            </Box>
            <Box sx={{ color: "white", fontSize: "6vw" }}>
              {" "}
              {teacherCredits?.credits?.available_credits ? teacherCredits?.credits?.available_credits : 0}{" "}
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: "3%", display: isXs ? "none" : "" }}>
          <Box sx={{ backgroundColor: "#2A9B8B" }}>
            <Box
              className="mx-2"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Box sx={{ color: "white", marginTop: "8%", fontSize: "5vw" }}>
                  Available Credits
                </Box>
                <Button
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    borderRadius: "20px",
                    marginTop: "3%",
                    fontSize: "3vw",
                  }}
                >
                  View Details
                </Button>
              </Box>
              <Box sx={{ color: "white", fontSize: "18vw" }}> 2 </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: isXs ? "3%" : "8%" , textAlign: "center"}}>
          Maximize your reach and connect with more students by choosing the
          subscription plan that fits your teaching goals.
        </Box>
        <Box>
          <Grid container>
            {subscriptionData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                <Box
                  sx={{
                    marginTop: isXs ? "5%" : "8%",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={Subscription1}
                      style={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        zIndex: -1,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      marginLeft: isXs ? "4rem" : "3rem",
                      marginTop: isXs ? "" : "4%",
                      fontSize: isXs ? "3vw" : "6vw",
                    }}
                  >
                    <Box sx={{ fontFamily: "jost" }}>{item?.planName}</Box>
                    <Box>₹{item?.price}</Box>
                  </Box>
                  {item.description.split("\\r\\n\\r\\n").map((line, index) => (
                    <Box key={index} sx={{ marginTop: "4%" }}>
                      <Box
                        display={"flex"}
                        sx={{
                          marginLeft: isXs ? "4rem" : "3rem",
                          fontSize: isXs ? "1.1rem" : "3vw",
                          width: "75%"
                        }}
                      >
                        <Box>
                          <TaskAltIcon sx={{ color: "#3D81F7" }} />
                        </Box>
                        <Box key={index}>{line}</Box>
                      </Box>
                    </Box>
                  ))}
                  <Box
                    sx={{ marginTop: "2%", marginLeft: isXs ? "7rem" : "5rem" }}
                  >
                    <Button
                      onClick={() => buySubscription(index)}
                      sx={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "#3D7EF7",
                        fontFamily: "jost",
                        width: "12rem",
                        borderRadius: "20px",
                        "&:hover": { backgroundColor: "#3D7EF7" },
                      }}
                    >
                      <Box>Subscribe Now</Box>
                      <Box>
                        {" "}
                        <NavigateNextIcon />{" "}
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* <Box>
          <Grid container>
            {subscriptionData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                <Box
                  sx={{
                    marginTop: isXs ? "3%" : "8%",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={Subscription1}
                      style={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        zIndex: -1,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      marginLeft: isXs ? "4rem" : "3rem",
                      marginTop: isXs ? "" : "4%",
                      fontSize: isXs ? "3vw" : "6vw",
                    }}
                  >
                    <Box sx={{ fontFamily: "jost" }}>{item?.planName}</Box>
                    <Box>₹{item?.price}</Box>
                  </Box>
                  {item.description.split("\\r\\n\\r\\n").map((line, index) => (
                    <Box key={index} sx={{ marginTop: "4%" }}>
                      <Box
                        display={"flex"}
                        sx={{
                          marginLeft: isXs ? "4rem" : "3rem",
                          fontSize: isXs ? "1.5vw" : "3vw",
                        }}
                      >
                        <Box>
                          <TaskAltIcon sx={{ color: "#3D81F7" }} />
                        </Box>
                        <Box key={index}>{line}</Box>
                      </Box>
                    </Box>
                  ))}
                  <Box
                    sx={{ marginTop: "2%", marginLeft: isXs ? "7rem" : "5rem" }}
                  >
                    <Button
                      onClick={() => buySubscription(index)}
                      sx={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "#3D7EF7",
                        fontFamily: "jost",
                        width: "12rem",
                        borderRadius: "20px",
                        "&:hover": { backgroundColor: "#3D7EF7" },
                      }}
                    >
                      <Box>Subscribe Now</Box>
                      <Box>
                        {" "}
                        <NavigateNextIcon />{" "}
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box> */}
      </Box>
    </Box>
  );
};

export default SubscriptionPlan;
