import { Box, Typography, useMediaQuery, Stack, FormGroup, Button, Modal, Backdrop, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import line from '../../../../../assets/line.svg'
import DevicesIcon from '@mui/icons-material/Devices';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Delete1 from '../../../../../assets/delete1.svg'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeacherClasses, getTeacherClasses } from '../../../../features/teacher/teacherListingSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

// const AntSwitch = styled(Switch)(({ theme }) => ({
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: 'flex',
//     '&:active': {
//         '& .MuiSwitch-thumb': {
//             width: 15,
//         },
//         '& .MuiSwitch-switchBase.Mui-checked': {
//             transform: 'translateX(9px)',
//         },
//     },
//     '& .MuiSwitch-switchBase': {
//         padding: 2,
//         '&.Mui-checked': {
//             transform: 'translateX(12px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 opacity: 1,
//                 backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#CB8B65',
//             },
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//         width: 12,
//         height: 12,
//         borderRadius: 6,
//         transition: theme.transitions.create(['width'], {
//             duration: 200,
//         }),
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 16 / 2,
//         opacity: 1,
//         backgroundColor:
//             theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
//         boxSizing: 'border-box',
//     },
// }));

const TabClasses = () => {
    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch()
    const [isClassId, setIsClassId] = useState()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        borderRadius: '10px',
        width: isXs ? '50%' : '100%',
        boxShadow: 24,
    };

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const classData = useSelector((item) => item?.teacherListing?.getClassTeacher);
    const loading = useSelector((item) => item?.teacherListing?.loading);

    const classConductedId = classData?.map((item) => item?.class_id)

    const listConductClasses = () => {
        dispatch(getTeacherClasses({ userId: userId }));

    }

    useEffect(() => {
        listConductClasses();
    }, [])

    const handleOpen = (id) => {
        setIsClassId(id)
        setOpen(true)
    };

    const handleDelete = () => {
        dispatch(deleteTeacherClasses({userId:userId, classConductedId:isClassId})).then((response)=>{
            if(response?.payload?.status ==200){
                toast.success(response?.payload?.message);
            }
        })
        listConductClasses();
        setOpen(false);
    }

    

    return (
        <Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box className="mt-3" sx={{ marginLeft: '2%', display: 'flex' }}>
                <Box>
                    <Typography variant='h5'>Classes Conducted</Typography>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            {classData?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                    <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '15vh', width: '100%', padding: 3 }}>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>{item?.class_data?.class_name}</Typography>
                            </Box>
                            <Box className='py-1 mt-2 px-1' style={{ color: '#AE6539', display: 'flex', width: isXs ? '60%' : '100%', fontSize: '12px', border: '1px solid #7E7E7E', borderRadius: '10px' }}>
                                {item?.subject?.map((item, index) => (<Box key={index} >{item?.subject_name?.subject_name},</Box>))}

                            </Box>
                            <Box display={'flex'} gap={2}>
                                <Box sx={{ color: '#636172' }}> Board Name :</Box>
                                <Box display={'flex'}>
                                    {item?.board?.map((item, index) => (<Box key={index} >{item?.board_name?.board_name},</Box>))}
                                </Box>
                            </Box>
                            <Box display={'flex'} gap={2}>
                                <Box sx={{ color: '#636172' }}> Class Conducted Type:</Box>
                                <Box>
                                    {item?.class_type?.map((item, index) => (<Box key={index} >{item?.class_type_name?.name},</Box>))}
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </Box>))}

            
        </Box >
    )
}

export default TabClasses
