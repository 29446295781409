import React, { useEffect, useState } from 'react'
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg';

import Box from '@mui/material/Box'
import { Steppers5 } from './StepperWeb';
import StepperPhone, { Step5 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import InquiryLayout from './InquiryLayout';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownListings, storePreference } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';


const Classes = () => {
    const [radioValue, setRadioValue] = useState(null);    

    const dispatch = useDispatch()
    const preferenceListings = async () => {
        dispatch(dropDownListings())
    }

    const preferenceList = useSelector((state) => state?.listing?.dropDownData?.class_prference)
    const preferenceValue = preferenceList?.map((item) => item?.value)

    const selectClassPreference = useSelector((state) => state?.listing?.postPayload?.classPreferenceId)
    console.log(selectClassPreference , 'selectClassPreference');


    useEffect(()=>{
        setRadioValue(selectClassPreference);
    },[selectClassPreference])

    const user = JSON?.parse(localStorage?.getItem('user'))


    useEffect(() => {
        preferenceListings()
    }, [])

    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)


    const isNavigate = (event, value) => {
        if (radioValue == '' || radioValue == null || radioValue == 0) {
            toast.error('Input field is required.');
          }else{
        dispatch(storePreference({ classPreferenceId: radioValue }));
        if(postCount>0){
            navigate('/dashboard/fee')
            }else{
          navigate('/fee')
          }
          }
      };

      const handleClick = (item, value) => {
        // console.log(item);
        setRadioValue(value); 
        // console.log('aaa',radioValue);
        
    };

    const goBack = () => {
        navigate(-1);
    };

    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                <div className='d-flex justify-content-center align-items-center mx-auto' style={{ width: isXs ? '' : '80%' }}>Are you looking for Private or Group Classes?</div>
            </Box>
            <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers5 />
            </div>
            <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step5 />
                </div>
            </div>

            <div className='d-flex justify-content-center' >
                <div className='container border border-secondary-subtle p-3' style={{ width: isXs ? '60%' : '80%', borderColor: '#FBFBFB' }}>
                    <form  >
                        {preferenceList && preferenceList.map((item, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                <label for={item.value}>
                                    {item.name}
                                </label>
                                <input type="radio" name="select" onChange={() => handleClick(item, item.value)} value={item.value} id={item.value} checked={radioValue === item.value}
                                />
                            </div>))}
                    </form>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '2%' : '3%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </div>
            </div>
            <div className='d-flex justify-content-between px-4' style={{ marginTop: isXs ? '' : '5%' }}>
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </div>
        </InquiryLayout>
    )
}

export default Classes
