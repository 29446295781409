import React from 'react';
import Footer from '../pages/LandingPage/Footer';
import { Link } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import logo from "../../assets/LogoHeader.svg";
import teacher from '../../assets/TeacherNew.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavBar from "../Navigation/NavBar";

function ForTeacher() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');

    return (
        <>
        <NavBar/>
        <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>For Teacher</div></div>

            <div style={{ padding: isXs ? '60px 0px' : '30px 0px', paddingBottom: '150px' }}>
                <div style={{ fontSize: isXs ? '30px' : '18px', textAlign: 'center' }}>
                    Grow Your Career with Kanha Home Tutions: Start Your Tutoring Journey
                </div>
                <div style={{ padding: isXs ? '20px 180px' : '20px 20px', fontSize: '14px', textAlign: 'center' }}>
                    Become a tutor with Kanha Home Tuitions and make a difference in students' lives while growing your teaching career. By joining our platform, you can set your own schedule, teach subjects you're passionate about, and connect with students in your area. Creating a free tutor profile is easy—simply share your qualifications, experience, and availability. Our system provides you with verified tuition inquiries, ensuring you receive genuine student leads. Plus, with flexible and secure subscription options, you can access premium features that highlight your profile to attract more students. Start your journey today, join our network of dedicated tutors, and help shape the future of education!
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <button style={{ backgroundColor: '#CB8B65', width: isXs ? "150px" : "160px", color: 'white', border: 'none', padding: '8px 12px', borderRadius: '3px', fontSize: isXs ? '17px' : '14px' }}>
                        <a style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }} href="/signup-teacher"> Create Your Profile</a>
                    </button>
                </div>

                <div style={{ fontSize: isXs ? '25px' : '20px', padding: isXs ? "30px 30px 0px 0px":"40px 0px 0px 0px", textAlign: 'center', color: '#CB8B65', fontWeight: '500' }}>
                    How It Works: Your Path to Becoming a Kanha Tutor
                </div>

                <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', alignItems: 'center' }}>
                    <div style={{ width: isXs ? '60%' : '100%', padding: isXs ? '0 100px' : '0 20px' }}>
                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                1. Create Your Tutor Profile and Register
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                To get started, simply create your tutor profile. It’s easy, quick, and free! Click on the Create Your Free Tutor Profile link and fill out the required information, including your name, qualifications, subjects you teach, and your availability. Upload a professional photo and complete your registration by submitting any necessary documents to showcase your credibility.
                            </div>
                        </div>

                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                2. Get Verified Tuition Enquiries
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                Once your profile is verified, you’ll begin receiving genuine inquiries from students or parents in your area. You’ll be notified about new tutoring opportunities based on your subjects and location preferences. Simply browse the inquiries, connect with students or parents, and finalize the details like timing, fees, and lesson plans. Confirm the lessons and start teaching!
                            </div>
                        </div>

                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                3. Easy Payment Options for Subscription
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                Unlock premium features with our flexible subscription plans, designed to meet your tutoring needs. Choose the plan that suits you, and securely pay through options like UPI, credit/debit cards, or net banking. Enjoy uninterrupted access to premium inquiries and tools that help grow your tutoring career!
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'start', width: isXs ? '40%' : '100%', display: 'flex', justifyContent: 'center', marginTop: isXs ? '0' : '20px' }}>
                        <img style={{ height: isXs ? '520px' : '360px', width: isXs ? '520px' : '360px', paddingRight: isXs? '100px':'0px' }} src={teacher} alt="Teacher" />
                    </div>
                </div>

                <div style={{
                    margin: isXs ?'0% 7%':"0% 2%",
                    width: isXs ? "85%":'95%',
                    marginTop: '30px',
                    boxShadow: '0 4px 12px rgba(203, 139, 101, 0.4)',
                    borderRadius: '8px',
                    padding: isXs ? '60px':'30px',
                    backgroundColor:"white"
                    
                }}>
                    <div style={{ fontWeight: '500', fontSize: '18px', paddingTop:'10px' }}>
                        Subscription Plans for Teachers
                    </div>
                    <div style={{ fontSize: '16px', paddingTop: '10px' }}>We offer two flexible subscription plans to meet your tutoring needs:</div>
                    <div style={{ fontSize: '15px', paddingTop: '10px', paddingLeft: '15px' }}>1. Rs 500/- Plan: Receive 3 credits.</div>
                    <div style={{ fontSize: '15px', paddingTop: '10px', paddingLeft: '15px' }}>2. Rs 1000/- Plan: Receive 9 credits.</div>
                    <div style={{ fontWeight: '500', fontSize: '18px', paddingTop: '20px' }}>Key Benefits</div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px',paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Guaranteed Leads :</div>
                        <div style={{ fontSize: '14px' }}>If you subscribe and do not secure tuition from us, we will extend your subscription plan until you do.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Lead Information :</div>
                        <div style={{ fontSize: '14px' }}>We provide student inquiry leads with the main phone numbers and email.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Refundable Credits :</div>
                        <div style={{ fontSize: '14px' }}>Credits will be refunded if both the email and phone number provided are incorrect.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px' }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Non-Refundable Credits :</div>
                        <div style={{ fontSize: '14px' }}>If a demo is conducted but the outcome is unsuccessful, credits will also not be refunded.</div>
                    </div>

                    <div style={{ fontSize: '14px', padding: '15px', paddingBottom:'10px' }}>These terms ensure fairness and help you focus on growing your tutoring career!</div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForTeacher;
