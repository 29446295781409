import axios from "axios";

const EndPoint = 'https://app.kanhahometutions.com/api/v2';
// const EndPoint = 'http://127.0.0.1:8000/api/v2';

const axiosInstanceV2 = axios.create({
    timeout: 1000000,
    baseURL: EndPoint,
});


axiosInstanceV2.interceptors.request.use(function (config) {
  // Do something before request is sent
   const user = JSON.parse(localStorage.getItem('user'))
   if(user){
    const token = user.accessToken
    if(token){
        config.headers['Authorization'] = 'Bearer ' + token;
    }
   }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


export default axiosInstanceV2;
