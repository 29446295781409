import React from 'react'
import FrontArrow from '../../assets/FrontArrow.svg'

const NextButton = ({ onClick }) => {
    return (
        <div className='d-flex' style={{ alignItems: 'center' }}>
            <button className="btn btn-outline" type="submit" style={{ borderColor: "#DFDFDF", width: '400px', borderRadius: '0px', backgroundColor: '#FFDB7E', color: '#866D2C' }} onClick={onClick}>
                <div className='d-flex justify-content-center' style={{ width: '100%' }}>
                    <div style={{ margin: 'auto' }}>Next</div>
                    <div><img src={FrontArrow} alt='back' style={{ marginLeft: 'auto' }} /></div>
                </div>
            </button>
        </div>


    )
}

export default NextButton
