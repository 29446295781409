import React, { useCallback, useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import creditHistory from "../../../assets/creditHistory.png";
import subscriptionPlans from "../../../assets/subscriptionPlans.png";
import search from "../../../assets/search.png";
import Verified from "../../../assets/VerifedTick.svg";
import NonVerified from "../../../assets/UnVerfiedQuestion.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from '@mui/material/Tooltip';

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { userAccountCloseOpen, userListsV2 } from "../../features/admin/accountPostSlice";
import {
  Backdrop, CircularProgress, Switch, styled, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popover,
  Container,
  Box,
  Typography,
} from "@mui/material";
import CreditHistoryPopup from "../../ModalPopups/CreditHistoryPopup";


const columns = [
  { id: "userId", label: "ID" },
  { id: "name", label: "Name", minWidth: "180px" },
  { id: "mobile", label: "Mobile" },
  { id: "email", label: "Email", minWidth: "200px" },

  // { id: "city_name", label: "City" },
  // { id: "state_name", label: "State" },
  { id: "dob", label: "Date Of Birth", minWidth: "140px" },
  { id: "genderId", label: "Gender" },
  { id: "addressLineOne", label: "Add-1", minWidth: "200px" },
  { id: "addressLineTwo", label: "Add-2", minWidth: "200px" },
  { id: "state", label: "State" },
  { id: "city", label: "City" },
  { id: "pinCode", label: "Pincode" },
  { id: "languageName", label: "Language", minWidth: "200px" },
  { id: "degreeName", label: "Degree" },
  { id: "universityName", label: "University", minWidth: "200px" },
  { id: "className", label: "Class", minWidth: "250px" },
  { id: "subjectName", label: "Subject", minWidth: "250px" },
  { id: "boardName", label: "Board", minWidth: "250px" },
  { id: "classTypeNameName", label: "Conduct_Yours_Classes", minWidth: "200px" },
  { id: "schoolName", label: "School/College", minWidth: "200px" },
  { id: "classPreferenceName", label: "ClassType", minWidth: "200px" },
  { id: "experiance", label: "Experience", minWidth: "200px" },
  { id: "jobType", label: "JobType", minWidth: "200px" },
  { id: "cost", label: "Fee/Hr", minWidth: "200px" },
  { id: "availableCredits", label: "Credits" },
  { id: "acccountStatus", label: "Status" },
  { id: "action", label: "Action" },

];

export default function StudentList() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchTermQuery, setSearchTermQuery] = React.useState("");
  const [manageSearch, setManageSearch] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [dataColumn2, setDataColumn2] = useState([]);
  const [dataRow2, setDataRow2] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredReview, setHoveredReview] = useState('');
  const [resp, setResp] = useState();

  const handlePopoverOpen = (event, review) => {
    setAnchorEl(event.currentTarget);
    setHoveredReview(review);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredReview('');
  };

  const openHover = Boolean(anchorEl);


  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // Handle the confirmation action here
    const paylaod = {
      userId: currentId,
      reasonId: 0,
      description: "By Admin",
      accountStatus: !currentStatus
    }
    dispatch(userAccountCloseOpen(paylaod)).then((resp) => {

      setResp(resp)

    });
    // setSwtichToggle(!swtichToggle)

    setOpen(false);
  };
  // 
  const handleClickOpen2 = (datarow, titlepopup, dataColumns) => {
    setDataColumn2(dataColumns);
    setDataRow2(datarow);
    setTitle(titlepopup);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  // 

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(1);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchBtn();  // Trigger the search when Enter is pressed
    }
  };

  const handleSearchChange = (event) => {
    
      setManageSearch(1);
      setSearchTerm(event.target.value);

    // setRowsPerPage(+rowsPerPage);
    // setCurrentPage(0);
  };
  const handleSearchBtn = () => {

    setSearchTermQuery(searchTerm);

    setRowsPerPage(+rowsPerPage);
    setCurrentPage(0);

  }
  
  const studentList = () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`

    const paylaod = {
      roleId: 3,
      token: token,
      page: currentPage,
      pageCount: rowsPerPage
    }
    if (searchTermQuery.length > 0) {
      paylaod.search = searchTermQuery
      paylaod.page = 1
    }
    dispatch(userListsV2(paylaod));
  }





  useEffect(() => {
    studentList();
  }, [resp, currentPage, rowsPerPage, searchTermQuery])

  const { userListData, userAccountCloseOpenData, loading } = useSelector((state) => state?.accountPost);
  // setMeta(userListData.meta)

  const rows = userListData?.data?.map((student) => ({
    userId: '#TUTOR' + student?.userId,
    name: student?.firstName && student?.lastName ? `${student?.firstName} ${student?.lastName}` : "N/A",
    mobile: student.mobile ?? "-",
    isPhoneVerified: student.isPhoneVerified ?? 0 ,
    email: student?.email ?? "-",
    city: student?.teacherProfile?.city ?? "-",
    state: student?.teacherProfile?.state ?? "-",
    dob: student?.teacherProfile?.dob ? student?.teacherProfile?.dob :"-",
    genderId: student?.teacherProfile?.genderId ? "Female" : "Male",
    addressLineOne: student?.teacherProfile?.addressLineOne ?? "-",
    addressLineTwo: student?.teacherProfile?.addressLineTwo ?? "-",
    pinCode: student?.teacherProfile?.pinCode ?? "-",
    languageName: student?.languages?.map((item) => item?.languageName).join(", ") ?? "-",
    degreeName: student?.teacherProfile?.degreeName ?? "-",
    universityName: student?.teacherProfile?.universityName ?? "-",
    className: student?.conductedClasses?.map((item) => item?.className)?.join(", ") ?? "-",
    subjectName: [...new Set(student?.conductedClasses?.map((item) => item?.subject?.map((subItem) => subItem?.subjectName ?? "").join(", ")))].join(", ") ?? " -",
    boardName: [... new Set(student?.conductedClasses?.map((item) => item?.board?.map((subItem) => subItem?.boardName ?? "").join(", ")))].join(", ") ?? " -",
    classTypeNameName: [...new Set(student?.conductedClasses?.map((item) => item?.classType?.map((subItem) => subItem?.classTypeNameName ?? "")?.map((sub2Item) => sub2Item.replace(/I can teach/g, ""))?.join(", ")))].join(", ") ?? " -",
    schoolName: student?.teacherProfile?.schoolName ?? "-",
    classPreferenceName: [... new Set(student?.conductedClasses?.map((item) => item?.classPreference?.name))].join(", ") ?? " -",
    experiance: student?.teacherProfile?.experiance ?? "-",
    jobType: student?.teacherProfile?.jobType == 2 ? "Part-time" : student?.teacherProfile?.jobType == 1 ? "Full-time" : null ?? "-",
    cost: [... new Set(student?.conductedClasses?.map((item) => item?.cost))]?.join(", ") ?? "-",
    availableCredits: student?.availableCredits,
    acccountStatus: student?.acccountStatus,
    action: { creditHistory: student?.creditHistory, teacherSubscription: student?.teacherSubscription }



  }));

  const filteredRows = rows?.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.mobile.includes(searchTerm) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.state.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleCreditHSubsBtn = (row) => {
    let SubscriptionPlanCol = ["Plan Name", " Credit_Added", " Amount", "Date"];
    let CreditHistoryCol = ["Student Name", "Phone no.", "Class", "Subject", "Date"];
    const CreditHistoryRowData = row.action.creditHistory?.map((itemH) => {
      return {
        studentName: `${itemH?.user?.first_name} ${itemH?.user?.last_name}` ?? "-",
        mobile: itemH?.user?.mobile,
        class_data: itemH?.class_data,
        subject: itemH?.subject?.map((sujItem) => sujItem?.subject_name?.subject_name).join(", "),
        date: itemH?.createdAt
      }
    })
    return (
      <span style={{ display: "flex" }}>
        <Tooltip title="Credit History">
          <img
            src={creditHistory}
            width={25}
            height={25}
            style={{ cursor: "pointer", marginRight: 13 }}
            onClick={() => handleClickOpen2(CreditHistoryRowData, "Credit History", CreditHistoryCol)}
          />
          {/* <AddchartIcon sx={{ cursor: "pointer", color: '#ed6c02', marginRight: 3 }} />   */}
        </Tooltip>
        {"  "}     {"  "}
        <Tooltip title="Subscription Plans">
          < img
            src={subscriptionPlans}
            width={25}
            height={25}

            style={{ cursor: "pointer", marginLeft: 3 }}
            onClick={() => handleClickOpen2(row?.action?.teacherSubscription, "Subscription Plans", SubscriptionPlanCol)}

          />
          {/* <SubscriptionsIcon sx={{ cursor: "pointer", color: '#ed6c02' }} /> */}
        </Tooltip>


      </span>

    )
  }
  const handleSwitchChange = (id, currentStat) => {
    setCurrentId(id)
    setCurrentStatus(currentStat)
    // alert(id)
    setOpen(true)
  };
  const handleActionBtn = (row) => {
    let rowId = row?.userId.replace(/#TUTOR/g, "");
    return (
      <CustomSwitch
        checked={row?.acccountStatus ? true : false}
        //row.enabled}
        onChange={() => handleSwitchChange(rowId, row?.acccountStatus)}
        color="warning"
      />
    )
  }
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      '& .MuiSwitch-thumb': {
        color: theme.palette.action.disabled,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  }));

  
  const handleMobile=(row)=>{
    // console.log("row",row)
   return <span >{ row?.isPhoneVerified ?<span style={{display:"flex" ,alignItems: "center",
    alignContent: "space-around",
    justifyContent: "flex-end"}}> {row?.mobile} <p style={{visibility:"hidden"}}>d</p>
   <img src={Verified} width={"20%"} style={{cursor:"pointer"}} title="mobile number verified"/></span>
   :
   <span  style={{display:"flex" ,alignItems: "center",
    alignContent: "space-around",
    justifyContent: "flex-end"}}>{row?.mobile}<p style={{visibility:"hidden"}}>d</p> <img src={NonVerified} width={"20%"} style={{cursor:"pointer"}} title="mobile number not verified"/></span> }</span>
  }

  return (
    <div style={{ backgroundColor: "#FFFFFF", height: "100vh", borderRadius: "10px" }}>
      <CreditHistoryPopup
        open={open2}
        onClose={handleClose2}
        dataRow={dataRow2}
        title={title}
        DataColumn={dataColumn2}
      />

      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            position: 'absolute',
            top: 50, // Align the dialog to the top
            m: 0 // Remove the default margin
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to make this change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ background: "#f3b054" }}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} style={{ background: "#f3b054" }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}

      <main
        style={{
          marginLeft: "0rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "#ffffff",

            borderRadius: "10px",
            marginTop: "0rem",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "space-between",
            boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)"

          }}
        >
          <span
            style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}
          >
            All Teachers
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputGroup>
              <Form.Control
                placeholder="Search...."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={(event) => handleSearchChange(event)}
                onKeyDown={(event) => handleKeyDown(event)}  // Detect Enter key press

              />
              <Button
                onClick={() => handleSearchBtn()}
                style={{ backgroundColor: "#CB8B65", border: 0 }}>
                <img
                  src={search} alt="" />
              </Button>
            </InputGroup>
          </div>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <div>
            <p style={{ fontSize: "1.5rem" }}>All Teacher List</p>
          </div>
          <Paper
            sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth" }}
          >
            {loading ? <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop> : <TableContainer component={Paper} sx={{ maxHeight: '400px', overflowX: 'auto', maxWidth: 900 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{

                          backgroundColor: "#CB8B65",
                          color: "#ffffff",
                          minWidth: column?.minWidth ?? null
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = column.id == "acccountStatus" ? handleActionBtn(row) : column.id == "action" ? handleCreditHSubsBtn(row) : column.id == "mobile" ?handleMobile(row):row[column.id];
                          return (
                            ["className", "subjectName", "boardName", "classTypeNameName"].includes(column.id) ?
                              <TableCell
                                key={column.id}

                                onClick={(e) => handlePopoverOpen(e, value)}
                                onMouseLeave={handlePopoverClose}
                              >
                                <span style={{ cursor: "pointer" }}>{`${value.slice(0, 22)}...`}</span>
                              </TableCell>
                              : <TableCell
                                key={column.id}
                              >
                                {value}
                              </TableCell>


                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>}

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={userListData?.meta?.total}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>


          {/* Popover for displaying review on hover */}
          <Popover
            sx={{
              pointerEvents: 'none', border: "black"
            }}
            open={openHover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableRestoreFocus
          >
            <Container maxWidth="md">

              <Box component="section"
                maxWidth={500}
                sx={{ my: 2, border: '2px solid grey' }}>
                {hoveredReview.length > 0 ? <Typography sx={{ p: 1 }}>{hoveredReview}</Typography> :
                  <div style={{
                    margin: "0 0 0 0"
                  }}>"no data found"</div>}
              </Box>
            </Container>


          </Popover>
        </div>

      </main>
    </div>
  );
}
