import React, { useEffect, useState } from "react";
import Edit from "../../../assets/Edit.svg";
import line from "../../../assets/line.svg";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  dropDownListings,
  findSubject,
  profileUpdate,
  studentBoardListing,
  studentProfileDetails,
  getPinCodeStatus
} from "../../features/student/listingSlice";
import { toast } from "react-toastify";
import {
  cityListing,
  statesListing,
} from "../../features/teacher/teacherListingSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "../AccountAdmin/AdminCss/AdminDashboard.css";
import { accountPostCreate } from "../../features/admin/accountPostSlice";
// import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#CB8B65",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AdminCreatePost = () => {
  const dispatch = useDispatch();

  //   const studentDetails = () => {
  //     dispatch(studentProfileDetails({ userId: userId }))
  //       .then((response) => {
  //         if (response?.payload?.status == 200) {
  //           setFirstName(response?.payload.data.firstName);
  //           setLastName(response?.payload.data.lastName);
  //           setEmail(response?.payload.data.email);
  //           setMobile(response?.payload.data.mobile);
  //           setSmsStatus(response?.payload.data.smsStatus ? true : false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("getting error", error);
  //       });
  //   };

  // const userId = useSelector((item) => item.listing.postPayload.userId)
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.id;

  const studentData = useSelector(
    (state) => state?.listing?.profileInfoDetails
  );

  //   useEffect(() => {
  //     studentDetails();
  //   }, [userId]);

  const isXs = useMediaQuery("(min-width:600px)");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [fee, setFee] = useState("");
  const [classPreferenceId, setClassPreferenceId] = useState("");
  const [classDayTypeId, setClassDayTypeId] = useState("");
  const [classStartPlanTypeId, setClassStartPlanTypeId] = useState("");
  const [genderPreferenceId, setGenderPreferenceId] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [subjectId, setSubjectIds] = useState([]);
  const [classTypeId, setClassTypeId] = useState([]);

  //   const [genderPreferenceId, setGenderPreferenceId] = useState();

  const [smsStatus, setSmsStatus] = useState();
  const [state, setState] = useState();
  const [stateId, setStateId] = useState("");
  const [city, setCity] = useState();
  const [cityId, setCityId] = useState("");
  const [classSelect, setClassSelect] = useState();
  const [classId, setClassId] = useState("");
  const [board, setBoard] = useState();
  const [boardId, setBoardId] = useState("");
  const [sub, setSubjects] = useState([]);
  const [classValueSelected, setClassValueSelected] = useState(0);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [radioValue, setRadioValue] = useState(0);
  const [isPincodeChecked, setIsPincodeChecked] = useState(true)


  // const handleClick = () => {
  //     if (firstName === "" || firstName === null || firstName === 0) {
  //         toast.error("Input field is required.");
  //     } else if (lastName === "" || lastName === null || lastName === 0) {
  //         toast.error("Input field is required.");
  //     } else if (email === "" || email === null || email === 0) {
  //         toast.error("Input field is required.");
  //     } else if (mobile === "" || mobile === null || mobile === 0) {
  //         toast.error("Input field is required.");
  //     } else {
  //         dispatch(
  //             profileUpdate({
  //                 userId: userId,
  //                 email: email,
  //                 mobile: mobile,
  //                 firstName: firstName,
  //                 lastName: lastName,
  //                 smsStatus: smsStatus,
  //             })
  //         )
  //             .then((response) => {
  //                 if (response?.payload?.status == 200) {
  //                     toast.success(response?.payload?.message);
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.log("getting error", error);
  //             });
  //     }
  // };

  const handleClick = (selectedValue) => {
    console.log(selectedValue);
    setClassValueSelected(selectedValue);
    setRadioValue(selectedValue);
    // if (!selectedValues.includes(selectedValue)) {
    //     setSelectedValues(prevValues => [...prevValues, selectedValue]);
    // } else {
    //     setSelectedValues(prevValues => prevValues.filter(value => value !== selectedValue));
    // }

    // setClassValueSelected(selectedValue);
    if (selectedValue !== "2") {
      // setCheckboxStates(selectedValue);
      setCheckboxStates([]);
      setCheckboxStates((prevItems) => [...prevItems, selectedValue]);
    }
    if (selectedValue === "2") {
      setCheckboxStates([]);
    }

    console.log(checkboxStates, "checkboxStates");
  };

  const handleStateClick = async (event, value) => {
    // setIsState(event.target.value)
    const selectedState = stateData?.find(
      (state) => state?.stateName === value
    );
    dispatch(cityListing({ stateId: selectedState?.id }));
    // dispatch(storeLocation(selectedState))
    setState(selectedState);
    setStateId(selectedState?.id);
  };

  const handleCityClick = async (event, value) => {
    const selectedCity = cityData?.find((city) => city?.cityName === value);
    setCity(selectedCity);
    setCityId(selectedCity.id);
  };

  const handleSelectClass = async (event, value) => {
    const selectedClass = listData?.find(
      (subject) => subject?.className === value
    );
    setSubjects(selectedClass?.subjects);
    console.log(selectedClass?.subjects, "selectedClass");
    const classId = selectedClass.id;
    setClassId(classId);
    setClassSelect(selectedClass);
  };

  const handleSelectBoard = async (event, value) => {
    const selectedBoard = boardData.find((item) => item?.boardName === value);
    setBoardId(selectedBoard?.id);
    setBoard(selectedBoard);
  };

  const handleSubjectChange = (event, selectedOptions) => {
    const subjectIdsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
    setSubjectIds(subjectIdsGet);
    console.log(subjectIdsGet, "subjectIdsGet");
  };

  const handleClassPreference = (item, value) => {
    setClassPreferenceId(value);
  };

  const handleClassDayType = (item, value) => {
    setClassDayTypeId(value);
  };

  const handleClassStartPlanType = (item, value) => {
    setClassStartPlanTypeId(value);
  };

  const handleGenderPreference = (item, value) => {
    setGenderPreferenceId(value);
  };

  const findState = async (event, value) => {
    dispatch(statesListing({ countryId: 101 }));
  };

  const findclass = async () => {
    dispatch(findSubject());
  };

  const boardListings = async () => {
    dispatch(studentBoardListing());
    dispatch(dropDownListings());
  };

  const handleCheckboxChange = (value, isChecked) => {
    // console.log(value);
    const newCheckboxStates = [...checkboxStates];
    const index = newCheckboxStates.indexOf(value);
    if (isChecked) {
      newCheckboxStates.push(value);
    } else if (!isChecked && index !== -1) {
      newCheckboxStates.splice(index, 1);
    }
    setCheckboxStates(newCheckboxStates);
  };

  const handlePincode = async (e) => {
    const re = /^[0-9\b]+$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      setPincode(e?.target?.value);

    }

  }

  const handlePinCodeCheck = async (e) => {
    setIsPincodeChecked(false)
    try {
      const response = await dispatch(getPinCodeStatus(e.target.value))

      if (response?.payload[0]?.Status == "Success") {
        const res1 = response?.payload[0]?.PostOffice.some((item) => (state.stateName.toLowerCase().toString() == item.State.toLowerCase().toString()));
        const res2 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.District.toLowerCase().toString()));
        const res3 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Division.toLowerCase().toString()));
        const res4 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Region.toLowerCase().toString()));
        const res5 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Block.toLowerCase().toString()));
        const res6 = response?.payload[0]?.PostOffice.some((item) => (item.Name.toLowerCase().toString().includes(city.cityName.toLowerCase().toString())));
        // console.log({"res1":res1,"res2":res2,"res3":res3,"res4":res4,"res5":res5,"res6":res6})

        const res = res1 && res2 || res3 || res4 || res5 || res6

        if (res == false) {
          setIsPincodeChecked(false)
          toast.error("Please fill right PinCode !")

        } else if (res == true) {
          setIsPincodeChecked(true);
          toast.success("Pincode is right !")
        }

      } else {
        toast.error("No Pincode available !")
        setIsPincodeChecked(false)

      }

    } catch (error) {
      console.log("bk", error)
    }

  }

  // const handleCheckboxChange = (value, isChecked) => {
  //     // console.log(value);
  //     const newCheckboxStates = [...checkboxStates];
  //     const index = newCheckboxStates.indexOf(value);
  //     if (isChecked) {
  //         newCheckboxStates.push(value);
  //     } else if (!isChecked && index !== -1) {
  //         newCheckboxStates.splice(index, 1);
  //     }
  //     setCheckboxStates(newCheckboxStates);
  // };

  useEffect(() => {
    findState();
    findclass();
    boardListings();
  }, []);

  const submitForm = () => {
    console.log(checkboxStates.length , subjectId , firstName ,lastName ,mobile , email,fee , addressLineOne,addressLineTwo,stateId,cityId ,pincode , classId, boardId , classDayTypeId , classStartPlanTypeId , genderPreferenceId, classPreferenceId , 'dhsadhasdas' );
    if (
      !checkboxStates.length ||
      !subjectId?.length ||
      firstName == "" ||
      lastName == "" ||
      mobile == "" ||
      email == "" ||
      fee == "" ||
      addressLineOne == "" ||
      addressLineTwo == "" ||
      stateId == "" ||
      cityId == "" ||
      pincode == "" ||
      classId == "" ||
      boardId == "" ||
      classDayTypeId == "" ||
      classStartPlanTypeId == "" ||
      genderPreferenceId == "" ||
      classPreferenceId == ""
    ) {
      toast.error("All fields are required");
      return;
    }

    const payload = {
      firstName: firstName,
      lastName: lastName,
      mobile: mobile,
      email: email,
      addressLineOne: addressLineOne,
      addressLineTwo: addressLineTwo,
      stateId: stateId,
      cityId: cityId,
      pincode: pincode,
      classId: classId,
      subjectIds: subjectId,
      boardId: boardId,
      cost: fee,
      classPreferenceId: classPreferenceId,
      classStartPlanTypeId: classStartPlanTypeId,
      genderPreferenceId: genderPreferenceId,
      classDayTypeId: classDayTypeId,
      classTypeIds: checkboxStates,
      countryId: "101",
      roleId: 2,
      created_by:1
    };

    dispatch(accountPostCreate(payload)).then((response) => {
      if (response.payload?.status == 200) {
        toast.success(response.payload?.message);
        window.location.reload();
      }
    });
  };

  const loading = useSelector((state) => state?.accountPost?.loading);
  const stateData = useSelector((state) => state?.teacherListing?.stateList);
  const cityData = useSelector((state) => state?.teacherListing?.cityList);
  const listData = useSelector((state) => state?.listing?.classData);
  const boardData = useSelector((state) => state?.listing?.boardData);
  const preferenceList = useSelector(
    (state) => state?.listing?.dropDownData?.class_prference
  );
  const preferenceValue = preferenceList?.map((item) => item?.value);
  const dayList = useSelector(
    (state) => state?.listing?.dropDownData?.class_day_type
  );
  const dayValue = dayList?.map((item) => item?.value);
  const startList = useSelector(
    (state) => state?.listing?.dropDownData?.class_start_plan_type
  );
  const startValue = startList?.map((item) => item?.value);
  const classTypeList = useSelector(
    (state) => state?.listing?.dropDownData?.class_type
  );
  const genderList = useSelector(
    (state) => state?.listing?.dropDownData?.gender_preference
  );
  const genderValue = genderList?.map((item) => item?.value);

  // console.log(listData , 'cityData');
  useEffect(() => {
    // console.log(classPreferenceId , 'classPreferenceId');
    console.log(checkboxStates, "checkboxStates");
  }, [
    classPreferenceId,
    classDayTypeId,
    classStartPlanTypeId,
    genderPreferenceId,
    checkboxStates,
    stateId,
    cityId,
    boardId,
    classId,
    subjectId,
  ]);

  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        className="d-flex mt-4"
        style={{
          width: "50%",
          borderRadius: "0px",
          marginLeft: "2%",
          fontSize: "30px",
        }}
      >
        Create Post
      </Box>
      <img
        src={line}
        className="mb-3"
        style={{ borderRadius: "0px", marginLeft: "2%", fontSize: "30px" }}
      />
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
        <Stack
          sx={{
            backgroundColor: "",
            minHeight: "55vh",
            width: "90%",
            textAlign: "center",
          }}
        >
          <Box className="d-flex align-items-center mt-3 mx-5 gap-3">
            <hr
              style={{
                color: "black",
                width: "70%",
                display: isXs ? "" : "none",
              }}
            />
            Create Personal Info
            {/* <button onClick={handleClick} style={{ border: 'none', backgroundColor: "transparent" }}><img src={Edit} /></button> */}
          </Box>
          <Grid
            container
            marginTop={isXs ? "5%" : "15%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                label="First Name"
                // placeholder={studentData?.firstName}
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "15%"}
            >
              <TextField
                label="Last Name"
                // placeholder={studentData?.lastName}
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                // onChange={lnameChange}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={isXs ? "5%" : "15%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                label="Email"
                // placeholder='email'
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "15%"}
            >
              <TextField
                label="Phone"
                // placeholder={studentData?.mobile}
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={isXs ? "5%" : "15%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                label="Address Line 1"
                // placeholder={studentData?.firstName}
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                onChange={(e) => setAddressLineOne(e.target.value)}
                value={addressLineOne}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "15%"}
            >
              <TextField
                label="Address Line 2"
                // placeholder={studentData?.lastName}
                inputProps={{ style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                // onChange={lnameChange}
                // value={lastName}
                onChange={(e) => setAddressLineTwo(e.target.value)}
                value={addressLineTwo}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={isXs ? "5%" : "15%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <Autocomplete
                fullWidth
                id="free-solo-demo"
                freeSolo
                value={state}
                sx={{ width: "80%", border: ".1px solid #C1C1C1", borderRadius: "6px", marginLeft: "45px" }}
                onChange={handleStateClick}
                options={stateData?.map((option) => option?.stateName)}
                renderInput={(params) => (
                  <TextField style={{}} {...params} label="State" />
                )}
                disableClearable
              />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "15%"}
            >
              <Autocomplete
                fullWidth
                id="free-solo-demo"
                freeSolo
                value={city}
                sx={{ width: "80%", border:".1px Solid #c1c1c1", borderRadius: "6px", marginLeft: "45px" }}
                onChange={handleCityClick}
                options={cityData?.map((option) => option?.cityName)}
                renderInput={(params) => <TextField {...params} label="City" />}
                disableClearable
              />
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={isXs ? "5%" : "15%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "15%"}
            >
              
              <TextField
                label="Pincode"
                // placeholder={studentData?.lastName}
                inputProps={{ maxLength: 6, mixLength: 6, style: { height: "22px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                onChange={(e) => {      setPincode(e?.target?.value);

                  // e.target.value.length >= 6 && handlePinCodeCheck(e);
                  // handlePincode(e)
                }}

                value={pincode}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    borderColor: "grey",
                  },
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Box className="d-flex align-items-center mt-3 mx-5 gap-3">
            <hr
              style={{
                color: "black",
                width: "70%",
                display: isXs ? "" : "none",
              }}
            />
            Create Learning Info
            {/* <button onClick={handleClick} style={{ border: 'none', backgroundColor: "transparent" }}><img src={Edit} /></button> */}
          </Box>
          <Box
            className="d-flex mx-4"
            sx={{ justifyContent: isXs ? "" : "center" }}
          >
            {/* <Typography>SMS Alert</Typography>
                        <FormGroup className='d-flex align-items-center justify-content-center mx-3'>
                            <AntSwitch checked={smsStatus} onChange={smsChange} inputProps={{ 'aria-label': 'ant design' }} />
                        </FormGroup> */}
            <Grid
              container
              marginTop={isXs ? "5%" : "15%"}
              sx={{ marginLeft: isXs ? "" : "8%" }}
            >
              <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  fullWidth
                  id="free-solo-demo"
                  freeSolo
                  value={classSelect}
                  sx={{
                    width: "85%",
                    borderRadius: "6px",
                    marginLeft: "22px",
                    border: ".1px solid #c1c1c1"
                  }}
                  onChange={handleSelectClass}
                  options={listData?.map((option) => option?.className)}
                  renderInput={(params) => (
                    <TextField {...params} label="Class" />
                  )}
                  disableClearable
                />
              </Grid>

              <Grid
                item
                xs={10}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                marginTop={isXs ? "" : "15%"}
              >
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={sub ?? []}
                  disableCloseOnSelect
                  onChange={handleSubjectChange}
                  getOptionLabel={(option) => option?.subjectName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        // onChange={(event) =>
                        //   handleSubjectChange(
                        //     event,
                        //     event?.target?.checked,
                        //     option
                        //   )
                        // }
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                      />
                      {option?.subjectName}
                    </li>
                  )}
                  // style={{ width: 500 }}
                  sx={{
                    width: "80%",
                    borderRadius: "5px",
                    marginLeft: "45px",
                    border: "1px solid #c1c1c1"
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Subjects"
                      placeholder="Select Subjects"

                    />
                  )}
                />
              </Grid>
              <Grid
                container
                marginTop={isXs ? "5%" : "15%"}
                sx={{ marginLeft: isXs ? "" : "8%" }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    fullWidth
                    id="free-solo-demo"
                    freeSolo
                    value={board}
                    sx={{
                      width: "85%",
                      borderRadius: "5px",
                      marginLeft: "22px",
                       border: "1px solid #c1c1c1"
                    }}
                    onChange={handleSelectBoard}
                    options={boardData?.map((option) => option?.boardName)}
                    renderInput={(params) => (
                      <TextField {...params} label="Boards" />
                    )}
                    disableClearable
                  />
                </Grid>

                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  marginTop={isXs ? "" : "15%"}
                >
                  <TextField
                    label="Fee"
                    placeholder={'price per hour'}
                    inputProps={{ style: { height: "22px" } }}
                    InputLabelProps={{ sx: { color: "black" } }}
                    color="grey"
                    focused
                    variant="outlined"
                    onChange={(e) => setFee(e.target.value)}
                    value={fee}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        borderColor: "grey",
                        marginLeft: "5px"
                      },
                      minWidth: "80%",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={isXs ? "5%" : "15%"}
                sx={{ marginLeft: isXs ? "" : "0%" }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="container border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "85%" : "80%",
                        borderColor: "#c1c1c1",
                        border: "2px solid",
                        borderRadius: "6px",
                        marginLeft: "0px",
                        marginRight: "20px" 
                        
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" , textAlign: "left"  , marginBottom: "1.5rem"  }}>
                          Are you looking for Private or Group Classes?
                        </h6>

                        {preferenceList &&
                          preferenceList.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <label for={item.value}>{item.name}</label>
                              <input
                                type="radio"
                                name="select"
                                onChange={() =>
                                  handleClassPreference(item, item.value)
                                }
                                value={classPreferenceId}
                                id={item.value}
                              />
                            </div>
                          ))}
                      </form>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  marginTop={isXs ? "" : "15%"}
                >
                  <div className="d-flex justify-content-center">
                    <div
                      className="container border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "80%" : "80%",
                        
                        borderColor: "#c1c1c1",
                        border: "2px solid",
                        borderRadius: "6px",
                       
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" , textAlign: "left"  , marginBottom: "1.5rem"  }}>
                          What days are you generally available to take classes?
                        </h6>

                        {dayList &&
                          dayList.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                                
                              }}
                            >
                              <label for={item.value}>{item.name}</label>
                              <input
                                type="radio"
                                name="select"
                                onChange={() =>
                                  handleClassDayType(item, item.value)
                                }
                                value={classDayTypeId}
                                id={item.value}
                              />
                            </div>
                          ))}
                      </form>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={isXs ? "5%" : "15%"}
                sx={{ marginLeft: isXs ? "" : "8%" }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="container border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "85%" : "80%",
                        
                        borderColor: "#c1c1c1",
                        border: "2px solid",
                        borderRadius: "6px",
                        marginLeft: "0px",
                        marginRight: "20px" 
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" , textAlign: "left"  , marginBottom: "1.5rem"  }}>
                          When do you plan to start (Class name) Tuition?
                        </h6>

                        {startList &&
                          startList.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                                textAlign: "left",
                                
                                
                              }}
                            >
                              <label for={item.value}>{item.name.trim()}</label>
                              <input
                                type="radio"
                                name="select"
                                onChange={() =>
                                  handleClassStartPlanType(item, item.value)
                                }
                                value={classStartPlanTypeId}
                                id={item.value}
                              />
                            </div>
                          ))}
                      </form>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  marginTop={isXs ? "" : "15%"}
                >
                  <div className="d-flex justify-content-center">
                    <div
                      className="container border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "80%" : "80%",
                       
                        borderColor: "#c1c1c1",
                        border: "2px solid",
                        borderRadius: "6px",
                        height: "16rem"
                       
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" , textAlign: "left" , marginBottom: "1.5rem" }}>
                          How would you like to attend your (Class name) Tuition
                          Classes?
                        </h6>
                        {classTypeList &&
                          classTypeList
                            ?.filter((item) => item?.parentId === 0)
                            ?.map((filteredItem, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                  textAlign: "left"
                                }}
                              >
                                <label htmlFor={filteredItem?.value}>
                                  {filteredItem?.name}
                                </label>
                                <input
                                  type="radio"
                                  name="select"
                                  value={filteredItem?.value}
                                  id={filteredItem?.value}
                                  onChange={() =>
                                    handleClick(filteredItem?.value)
                                  }
                                />
                              </div>
                            ))}
                        <div
                          style={{
                            display:
                              classValueSelected === "2" ? "block" : "none",
                          }}
                        >
                          {classTypeList &&
                            classTypeList
                              ?.filter((item) => item?.parentId === 2)
                              ?.map((filteredItem, index) => (
                                <div key={index} style={{textAlign:'left'}}>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${filteredItem?.value}`}
                                    value={filteredItem?.value}
                                    name={`checkbox-${filteredItem?.value}`}
                                    onChange={(item) =>
                                      handleCheckboxChange(
                                        item?.target?.value,
                                        item?.target?.checked
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`checkbox-${filteredItem?.value}`}
                                    style={{ marginLeft: "2%"  }}
                                  >
                                    {filteredItem?.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </form>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={isXs ? "5%" : "15%"}
                sx={{ marginLeft: isXs ? "" : "0%" }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="container border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "85%" : "80%",
                       
                        borderColor: "#c1c1c1",
                        border: "2px solid",
                        borderRadius: "6px",
                        marginLeft: "0px",
                        marginRight: "20px"
                        
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" , textAlign: "left"  , marginBottom: "1.5rem"  }}>
                          Do you have any Tutor gender preference?
                        </h6>

                        {genderList &&
                          genderList.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                                
                              }}
                            >
                              <label for={item.value}>{item.name.trim()}</label>
                              <input
                                type="radio"
                                name="select"
                                onChange={() =>
                                  handleGenderPreference(item, item.value)
                                }
                                value={genderPreferenceId}
                                id={item.value}
                              />
                            </div>
                          ))}
                      </form>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  marginTop={isXs ? "" : "15%"}
                >
                  {/* <div className="d-flex justify-content-center">
                    <div
                      className="container border border-secondary-subtle p-3"
                      style={{
                        width: isXs ? "80%" : "80%",
                        borderColor: "#FBFBFB",
                      }}
                    >
                      <form>
                        <h6 style={{ lineHeight: "1.5rem" }}>
                          How would you like to attend your (Class name) Tuition
                          Classes?
                        </h6>
                        {classTypeList &&
                          classTypeList
                            ?.filter((item) => item?.parentId === 0)
                            ?.map((filteredItem, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label htmlFor={filteredItem?.value}>
                                  {filteredItem?.name}
                                </label>
                                <input
                                  type="radio"
                                  name="select"
                                  value={filteredItem?.value}
                                  id={filteredItem?.value}
                                  onChange={() =>
                                    handleClick(filteredItem?.value)
                                  }
                                />
                              </div>
                            ))}
                        <div
                          style={{
                            display:
                              classValueSelected === "2" ? "block" : "none",
                          }}
                        >
                          {classTypeList &&
                            classTypeList
                              ?.filter((item) => item?.parentId === 2)
                              ?.map((filteredItem, index) => (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${filteredItem?.value}`}
                                    value={filteredItem?.value}
                                    name={`checkbox-${filteredItem?.value}`}
                                    onChange={(item) =>
                                      handleCheckboxChange(
                                        item?.target?.value,
                                        item?.target?.checked
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`checkbox-${filteredItem?.value}`}
                                    style={{ marginLeft: "2%" }}
                                  >
                                    {filteredItem?.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </form>
                    </div>
                  </div> */}
                  {/* <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '3%' : '5%', width: isXs ? '40%' : '70%' }} >
                    <Button onClick={() => submitForm()} > Submit </Button>
                </div>
            </div> */}

                  {/* <div className="bar"> */}
                  
                  {/* </div> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
      <div className="search  mt-5">
                    <div className="right" style={{marginLeft: "6rem" , marginBottom: "2rem"}}>
                      <button onClick={submitForm} className="create-btn" style={{fontWeight: "600" , width: "10rem"}}>
                        Submit
                      </button>
                    </div>
                  </div>
    </>
  );
};

export default AdminCreatePost;
