import { Box, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import line from '../../../../assets/line.svg'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { accountClose, accountLogout } from '../../../features/student/listingSlice';
import { dropDownListings } from '../../../features/teacher/teacherListingSlice';
import { persistStore } from 'redux-persist';
import store from '../../../store/store';
// import store from '../../store/store';

const CloseAccount = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const [closeAccountList , setCloseAccountList] = useState([]);
    const [reasionId , setCloseReasionId] = useState();
    const [description , setDescription] = useState();
    const dispatch = useDispatch();

    const closeAccoutnList = useSelector((item) => item?.teacherListing?.dropDownData?.teacher_close_account_reason);
    // console.log(closeAccoutnList , 'closeAccoutnList');

    const handleCloseAccount = (event, value) => {
        console.log(value , 'vale');
        const user = JSON.parse(localStorage.getItem('user'))
  const persistor = persistStore(store);

        const userId = user?.id
        if ( description === '' || description === null || description === 0) {
            toast.error('description  is required.');
        }else if (reasionId === '' || reasionId === null || reasionId === 0){
            toast.error('Input field is required.');
        }
        else {
        // const selectedReason = closeAccoutnList?.find((item) => item.value === value);
        dispatch(accountClose({ userId: userId, reasonId:reasionId, description:description })).then((response) => {
            if (response?.payload?.success) {
                toast.success(response?.payload?.message)
                setDescription('')
                setCloseReasionId ('')
                setTimeout(() => {
                    persistor.purge().then(() => {
                        localStorage.clear();
                        localStorage.removeItem('persist:root');
                        window.location.href = 'https://kanhahometutions.com';
                    });
                }, 2000);
             
                // dispatch()
                // dispatch(accountLogout()).then((response)=>{
                //     console.log(response , 'ressfgjdhfsdk');
                // if(response?.payload?.status==200){
                //     toast.success(response?.payload?.message)
                   
                // }
                
                // })
                
            }
        }).catch((error) => {
            console.log('getting error', error);
        })

        }
    }

    const resionListing = () =>{
         dispatch(dropDownListings());
    }


    const handleClick = (value) => {
        console.log(value);
        setCloseReasionId(value);
    };

    const handleChange = (event) => {
        setDescription(event.target.value);
      };

    useEffect(()=>{
    //    console.log(closeReasionId , 'closeReasionId');
    },[reasionId])

    useEffect(()=>{
        resionListing();
    },[])
    
    return (
        <Box>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '2%', display: 'flex', gap: 1 }}>
                    <Box>
                        <Typography variant='h5' style={{fontFamily: 'jost'}}>Close your Account</Typography>
                        <Box>We will miss you! Let us know, why you are leaving us</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={handleCloseAccount} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '35vh', width: '100%', textAlign: 'left', padding: 2 }}>
                    <Box sx={{ marginTop: isXs ? '3%' : '8%' , display: 'flex' , flexDirection: 'column' , gap: '2rem' }}>
                        <Typography style={{fontFamily: 'jost' , textAlign: 'left' , fontWeight: 'bold'}}>Reason for closing your account.*</Typography>
                        <Box sx={{ display: 'flex', marginLeft: '2%' }}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    {/* <FormControlLabel value="a" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I have discontinued teaching/ training student</Typography>}/>
                                    <FormControlLabel value="b" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I have another Tutor Find account"</Typography>}/>
                                    <FormControlLabel value="c" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I am receiving too many emails</Typography>}/>
                                    <FormControlLabel value="d" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I am not getting enough value for my membership</Typography>}/>
                                    <FormControlLabel value="e" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I have a privacy concern</Typography>}/>
                                    <FormControlLabel value="f" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I am receiving unwanted contacts</Typography>}/>
                                    <FormControlLabel value="g" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I was just exploring</Typography>}/>
                                    <FormControlLabel value="h" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>Cannot continue due to personal reasons</Typography>}/>
                                    <FormControlLabel value="i" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>I don’t find Tutor Find useful</Typography>}/>
                                    <FormControlLabel value="other" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>Other</Typography>}/> */}
                                       {closeAccoutnList?.map(option => (
                                                    <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.name} onChange={() => handleClick(option.value)}/>
                                                ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                    <Typography sx={{ marginTop: isXs ? '3%' : '8%' }}>Your feedback is important to us. Could you spare 2 minutes to give a detailed feedback on Find Tutor?</Typography>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={5}
                        sx={{ width: '100%', marginTop: isXs ? '3%' : '8%' }}
                        placeholder='Please Provide a Brief Reason'
                        value={description}
                        onChange={handleChange}
                    />
                    <Box sx={{ marginTop: isXs ? '3%' : '8%', fontSize: '14px' }}>
                    <span>Closing your account will disable your profile and remove it from Google and other search sites in 7-30 days. You will also stop
                    </span>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default CloseAccount
