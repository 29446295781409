import React, { useEffect, useState } from 'react'
import line from '../../../../../assets/line.svg'
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { teacherDetails } from '../../../../features/teacher/teacherListingSlice';


const ProfileTagline = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isTagline, setIsTagline] = useState('')
    
    const teacherTagline = useSelector((item) => item?.teacherListing?.availableCredits?.teacher_profile?.tagline)

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const teacherDetail = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setIsTagline(response?.payload?.data?.teacher_profile?.tagline)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    useEffect(() => {
        teacherDetail()
    }, [userId])
    
     const goBack = () => {
        navigate(-1);
      };
    return (
        <Box >
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap:1 }}>
                <button onClick={goBack} style={{border:'none', background:'transparent', display:'flex', alignItems:'start'}}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5'>Profile Tagline</Typography>
                        <Box>Give your profile a unique identity by adding a tagline.</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '90%', padding:2 }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant='h6'>Enter a Tagline*</Typography>
                        <Box><TextField value={isTagline} id="outlined-basic" label="Outlined" variant="outlined" sx={{ width: '100%' }} /></Box>
                    </Box>
                    <Box sx={{marginTop:isXs?'2%':'5%'}}>
                        <Typography> Your Tagline must be unique that would catch student's attention (Max 100 characters)</Typography>
                        <Box>
                            <List>
                                <ListItem>
                                    <ListItemIcon> <DoneIcon sx={{ color: '#A6653F' }} /> </ListItemIcon>
                                    <Box>Must be your front facing Photo. Side facing photo is not accepted.</Box>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon> <DoneIcon sx={{ color: '#A6653F' }} /> </ListItemIcon>
                                    <Box>Must be a good quality photo. Blurred photo is not accepted.</Box>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon> <DoneIcon sx={{ color: '#A6653F' }} /> </ListItemIcon>
                                    <Box>Make sure you don't have a contact number, or any text on your Photo.</Box>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>E.g.: "IT Professional Trainer with 15 years of experience in IT Industry" or "Experienced & certified Dance instructor from Kings united India Mumbai"</Box>
                    </Box>
                </Stack>
            </Box>

        </Box>
    )
}

export default ProfileTagline
