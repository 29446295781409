import { Box, Container, Stack, Toolbar, styled } from '@mui/material'
import React from 'react'
import LeftLayout from './LeftLayout'
import RightLayout from './RightLayout'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <Box sx={{backgroundColor:'#EEEEEE', minHeight:'100vh'}}>
        <Navbar/>
        <Box sx={{width:'90%', marginTop:'2%', marginLeft:'5%'}}>
            <Stack direction="row" gap={2} justifyContent="center" >
                <LeftLayout />
                <RightLayout >
                  <Outlet />
                </RightLayout>
            </Stack>
        </Box>
    </Box>
  )
}

export default Layout
