import { InputLabel, MenuItem, Select, TextareaAutosize, TextField, useMediaQuery, FormControl, Box, Dialog, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Footer from '../pages/LandingPage/Footer';
import { Link } from 'react-router-dom';
import logo from "../../assets/LogoHeader.svg";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Stack } from 'react-bootstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavBar from "../Navigation/NavBar";


function Institute() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');


    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);


    const [name, setName] = useState("");
    const [institutename, setInstitutename] = useState("");
    const [emailaddress, setEmailaddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [cityName, setCityName] = useState("");
    const [stateName, setStateName] = useState("");
    const [address, setAddress] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [jobType, setJobType] = useState("");
    const [instituteType, setInstituteType] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [salary, setSalary] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const [formNew , setFormNew] = useState([]);


    const handleJobType = (e) => setJobType(e.target.value);
    const handleSalary = (e) => setSalary(e.target.value);

   
    const handleformsubmitfirst  = async (e)=>
        { 
          e.preventDefault();
          setLoading(true)
          const minsalary = parseInt(min);
          const maxsalary =  parseInt(max);
        
          if (minsalary >= maxsalary) {
              toast.error("Minimum value must be less than maximum value. Please try again.");
              return false;
          } 
        
        let date = new Date;
        let randomnumber = Math.floor((Math.random() * 1000000));
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        let hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
        let minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
        let seconds = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);
        
        let CurrentDateTime = (`${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`);
        
        const formDatanew = new FormData();
        formDatanew.append("fullName", name );
        formDatanew.append("instituteName", institutename);
        formDatanew.append("email", emailaddress);
        formDatanew.append('mobile',phoneNumber)
        formDatanew.append('pincode',pinCode)
        formDatanew.append('city',cityName)
        formDatanew.append('state',stateName)
        formDatanew.append('address',address)
        formDatanew.append('jobTitle',jobTitle)
        formDatanew.append('jobType',jobType)
        formDatanew.append('instituteType',instituteType)
        formDatanew.append('description',jobDescription)
        formDatanew.append('salaryType',salary)
        formDatanew.append('minSalary',min)
        formDatanew.append('maxSalary',max)
        formDatanew.append('token', CurrentDateTime);
        formDatanew.append('smsType',"generalEnquiry");
        
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        
        try {
        const response = await axios.post(
          "https://app.kanhahometutions.com/api/v2/notifySMS",
          formDatanew,
          config
        );
        if(response.status === 200){
        //   setOpenNew(false);
          setOpenOtp(true);
          setFormNew([response.data.data]);
          setLoading(false)
        }else{
          setLoading(false)

        }
        } catch (error){
        
        }}

    const [openOtp, setOpenOtp] = useState(false);
    const [otp, setOtp] = useState('');

    const handleChangeOtp = (newValue) => {
        setOtp(newValue);
    };


const handleOpenotp =()=>{
setOpenOtp(true)
}

const handleCloseOtp = () => {
setOpenOtp(false);
setOtp("")
};

const  handleSubmitOtp = async (e) =>{
    e.preventDefault();
    
    let date = new Date;
    let randomnumber = Math.floor((Math.random() * 1000000));
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
    let minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
    let seconds = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);
    let CurrentDateTime = (`${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`);
    
    
    var smsID=  formNew.map(item => item.smsID.toString());
    
    
    const formDatanew = new FormData();
    formDatanew.append("fullName", name );
    formDatanew.append("instituteName", institutename);
    formDatanew.append("email", emailaddress);
    formDatanew.append('mobile',phoneNumber)
    formDatanew.append('pincode',pinCode)
    formDatanew.append('city',cityName)
    formDatanew.append('state',stateName)
    formDatanew.append('address',address)
    formDatanew.append('jobTitle',jobTitle)
    formDatanew.append('jobType',jobType)
    formDatanew.append('instituteType',instituteType)
    formDatanew.append('description',jobDescription)
    formDatanew.append('salaryType',salary)
    formDatanew.append('minSalary',min)
    formDatanew.append('maxSalary',max)
    formDatanew.append('token', CurrentDateTime);
    formDatanew.append('smsType',"generalEnquiry");
    formDatanew.append('smsId', smsID);
    formDatanew.append('smsOtp', otp);
    
    try {
    const response = await axios.post(
    "https://app.kanhahometutions.com/api/v2/verifyOtp",  formDatanew
    );
    
    if(response.data.success.toString() === 'true'){
    toast.success(response.data.message);
    setOpenOtp(false)
    setOtp("")
    }else{
    toast.error(response.data.message ?? "OTP does not match.");
    }
    } catch (error){
    toast.error(
      error?.response.data.message ?? "An error occurred. Please try again."
    );
    }
    }

    const handleResendOtp = async (e)=>{
        e.preventDefault();
        setLoading2(true)
      
      
        let date = new Date;
      let randomnumber = Math.floor((Math.random() * 1000000));
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      let hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
      let minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
      let seconds = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);
      let CurrentDateTime = (`${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`);
      
        var smsID =  formNew.map(item => item.smsID.toString());
        // var smsIDNEW =  formNew.map(item => item.smsID.toString());
      
      
        const formDatanew = new FormData();
        formDatanew.append('smsId', smsID);
        formDatanew.append('mobile',phoneNumber)
        formDatanew.append('smsType',"generalEnquiry");
        formDatanew.append('token', CurrentDateTime);
      
      
        try {
          const response = await axios.post(
          "https://app.kanhahometutions.com/api/v2/resendOTP",  formDatanew
          );
          
          if(response.data.success.toString() === "true"){
            toast.success(response.data.message);
            setFormNew([response.data.data]);
            setLoading2(false)
          
          }else{
            toast.error(response.data.message);
            setLoading2(false)
          }
          }
           catch (error){
          }
      }
      

    return (<>
<NavBar/>
<div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Post a Teacher Job Opening</div></div>

        <div style={{ display: 'flex', margin:'70px 0px', paddingLeft: isXs ?'3rem':'0rem', flexDirection:isXs? "":'column'}}>
            {/* Left Side Box */}
            <div style={{ paddingLeft: isXs ? "45px":'35px', width: isXs?  '45%':'100%',  display:'flex',gap:'30px', flexDirection:'column', paddingBottom:isXs ? "":'120px'}}>
<div style={{fontSize: isXs ?  '40px':'35px', color:'#774c33', fontWeight:'bold'}}>Why Kanha Home Tutions?</div>
<div style={{color:'black', fontSize:'16px', fontWeight:'500', width: isXs ? '500px':'330px'}}>Finding qualified, passionate teachers can be tough. Kanha Home Tutions makes it easy with a job portal for efficient hiring. Schools can post jobs and find candidates tailored to their needs across India.</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold' }}>Our Purpose</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}> 
<li style={{fontSize:'16px', fontWeight:'500'}}>Simplify and optimize teacher recruitment.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Offer a comprehensive service with personal assistance.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Use efficient technology to streamline hiring.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Provide tools for schools to hire the best teachers quickly.</li>
</div>
</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold'}}>What We Offer</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
<li style={{fontSize:'16px', fontWeight:'500'}}>Easy, stress-free teacher recruitment.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Simple job posting process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Continuous support throughout the hiring process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Receive instant alerts for new applications.</li>
</div>
</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold'}}>Simplified Job Posting</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
<li style={{fontSize:'16px', fontWeight:'500'}}>Easy job posting process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Our system matches you with qualified candidates.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Receive candidate profiles directly in your email inbox.</li>
</div>
</div>                        </div>

            {/* Right Side Form */}
            <div style={{  width: isXs?  '55%':'100%',  }}>
                <form onSubmit={handleformsubmitfirst} style={{ padding: isXs ? "20px 60px" : "0px 10px", }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '1px 1px 15px rgb(10, 5, 19, .3)' }}>
                        <div style={{ textAlign: 'start', fontSize: '16px', fontWeight: '500' }}>School / Institute Basic Information</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Full Name"  placeholder='Enter Your Full Name'  onChange={(e) => setName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth required/>
                                <TextField label="School / Institute Name"  placeholder='Enter Your Institute Name' required onChange={(e) => setInstitutename(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Email Address" type="email" placeholder='Enter Email Address' required onChange={(e) => setEmailaddress(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="Phone Number" type="tel" placeholder='Enter Phone Number' required value={phoneNumber} onChange={(e) => { const value = e.target.value; if (/^\d{0,10}$/.test(value)) { setPhoneNumber(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <TextField label="Address" required placeholder='Enter Your Address' onChange={(e) => setAddress(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Pin Code" required placeholder='Pin code' type="number" value={pinCode} onChange={(e) => { const value = e.target.value; if (/^\d{0,6}$/.test(value)) { setPinCode(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="City" required placeholder='City' onChange={(e) => setCityName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="State" required placeholder='State' onChange={(e) => setStateName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                        </div>
                        <div style={{ fontSize: '16px', fontWeight: '500' }}>Post a New Job</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <TextField label="Job Title" required placeholder='Example:Engilsh Teacher' onChange={(e) => setJobTitle(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl required fullWidth>
                                    <InputLabel id="job-type-label" style={{ fontSize: '12px' }}>Job Type</InputLabel>
                                    <Select labelId="job-type-label" value={jobType} onChange={handleJobType} style={{ height: '45px', fontSize: '12px' }} label="Job Type">
                                        <MenuItem value='Full Time'>Full Time</MenuItem>
                                        <MenuItem value='Part Time'>Part Time</MenuItem>
                                        <MenuItem value='Online Teaching'>Online Teaching</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="Institute Type" required placeholder='Example:CBSE Board School' onChange={(e) => setInstituteType(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <TextareaAutosize aria-label="Job Description" placeholder="Job Description" required onChange={(e) => setJobDescription(e.target.value)} style={{ height: "50px", fontSize: "12px", width: '100%', border: '2px solid #C4C4C4', borderRadius: '4px', padding: '8px', outline: 'none', }} />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl required fullWidth>
                                    <InputLabel id="salary-label" style={{ fontSize: '12px' }}>Salary</InputLabel>
                                    <Select labelId="salary-label" value={salary} onChange={handleSalary} style={{ height: '45px', fontSize: '12px' }} label="Salary">
                                        <MenuItem value='Year'>Year</MenuItem>
                                        <MenuItem value='Month'>Month</MenuItem>
                                        <MenuItem value='Week'>Week</MenuItem>
                                        <MenuItem value='Hour'>Hour</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="Min" type="number" placeholder='₹' value={min} required onChange={(e) => { const value = e.target.value; if (/^\d{0,11}$/.test(value)) { setMin(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="Max" type="number" placeholder='₹' value={max} required onChange={(e) => { const value = e.target.value; if (/^\d{0,11}$/.test(value)) { setMax(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '25px 0px', width: '100%', height: '1.5rem' }}>
  {loading ? (
    <CircularProgress color="success" />
  ) : (
    <button type="submit" style={{ backgroundColor: '#CB8B65', color: 'white', width: '100%', height: '2.5rem', border: 'none', borderRadius: '3px', fontSize: '16px', cursor: 'pointer' }}>
      Next
    </button>
  )}
</div>

                    </div>
                </form>
            </div>
        </div>
        

        <Dialog
            open={openOtp}
            onClose={() => {}}
            maxWidth="md"
            sx={{
              '& .MuiBackdrop-root': {
                backdropFilter: 'blur(1px)', 
              },
            }}
      
            PaperProps={{
              sx: {
                padding: "rem",
                overflowX: "hidden",
              },
            }}
          >
          
<Button onClick={handleCloseOtp} style={{ position: 'absolute', right: '0%', top:'0',  backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black', border:'1px solid black' }} >X</Button>
                     
           <div style={{padding:'1rem'}}>
            <form onSubmit={handleSubmitOtp} style={{ padding: isXs? "0px 10px":"0px 0px" }}>           
  <div style={{display: 'flex', flexDirection:'column'}}>
<div style={{fontSize:'30px', textAlign:"center"}}>OTP Verification </div>


<div style={{textAlign: 'center',}}>  A One-Time Password has been sent to {formNew.map(item => (
    <span key={item.smsID}>{item.smsMobile}</span>
))}</div> 
  <div >
   <MuiOtpInput style={{width: isXs? '400px':'270px', gap: isXs ? "20px":'5px', padding:'14px 3px', alignItems:'center' ,textAlign:'center', display:'flex', margin:'0px auto'}} value={otp} onChange={handleChangeOtp}  length={6} />
   </div>

   <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', gap: '10px', flexDirection: isXs? "":'column' }}>
      <div style={{ textAlign: isXs ? 'center' : 'left' }}>
        Didn’t Receive OTP?
      </div>
      {loading2 ? (
       
          <CircularProgress color="success" />
      
      ) : (
        <div 
          type='button' 
          style={{ color: '#cb8b65', textAlign: 'center', cursor: 'pointer' }} 
          onClick={handleResendOtp}
        >
          Resend OTP
        </div>
      )}
    </div>

  </div>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'70px' }}>
      <button style={{ backgroundColor: '#CB8B65', color: 'white', width: '30rem', height: '2.5rem', border: 'none', borderRadius: '3px' }}>
      Submit 
      </button>
    </div>

</form>
</div>
    </Dialog>

        
        <Footer/>
        </>
    );
}

export default Institute;
