import React, { useState ,useEffect } from 'react'
import NavBar from './NavBar'
import Footer from '../pages/LandingPage/Footer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, TextField, useMediaQuery } from '@mui/material';
import StudyMaterailForm from './StudyMaterailForm';
import studymaterailImg from '../../assets/bannerNew.png'
import axiosInstanceSM from '../../Utils/axiosSm';
import { Dropdown } from "rsuite";
import LogoHeader from "../../../src/assets/LogoHeader.svg";
import "rsuite/dist/rsuite.min.css";
import { Link, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function StudyMaterial() {
  const [Menu, setMenu] = useState(false);
  const [otpNew, setOtpNew] = useState("");
  const handleChangeOtpNew = (NewValue) => {
    setOtpNew(NewValue);
  };

  const [openOtpNew, setOpenOtpNew] = useState(false);

  const handleOpenotpNew = () => {
    setOpenOtpNew(true);
  };

  const handleCloseOtpNew = () => {
    setOpenOtpNew(false);
  };


  const toggleMenu = () => {
    setMenu((prevOpen) => !prevOpen);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const [smsIdNew, setSmsIdNew] = useState(null);
  const [studentQueryFormData, setStudentQueryFormData] = useState(null);
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
    pincode: yup.string().required(),
    area: yup.string().required(),
    city: yup.string().required(),
    mobileNumber: yup.string().required(),
    subject: yup.string().required(),
    course: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      classMode: "1",
    },
  });

  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const onSubmitHandler = async (data) => {
    setOtpNew("");
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {hour: "2-digit",hour12: false, }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(date);
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(date);
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("area", data.area);
    formData.append("fullName", data.fullName);
    formData.append("pincode", data.pincode);
    formData.append("course", data.course);
    formData.append("subject", data.subject);
    formData.append("classMode", data.classMode);
    formData.append("city", data.city);
    formData.append("mobile", data.mobileNumber);
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    setStudentQueryFormData(formData);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/notifySMS",
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setOpenOtpNew(true);
        setOpen(false);
        setSmsIdNew(response.data.data.smsID.toString());
        reset();
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
   
  }, []);

  const handleResendOtpNew = async (e) => {
    e.preventDefault();
    setLoading2(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const tempFormData = new FormData();
    tempFormData.append("smsId", smsIdNew);
    tempFormData.append("mobile", studentQueryFormData.get("mobile"));
    tempFormData.append("smsType", "generalQuery");
    tempFormData.append("token", CurrentDateTime);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/resendOTP",
        tempFormData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading2(false);
      } else {
        toast.error(response.data.message);
        setLoading2(false);
      }
    } catch (error) {}
  };

  const handleSubmitOtpNew = async (e) => {
    e.preventDefault();
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", studentQueryFormData.get("email"));
    formData.append("area", studentQueryFormData.get("area"));
    formData.append("fullName", studentQueryFormData.get("fullName"));
    formData.append("pincode", studentQueryFormData.get("pincode"));
    formData.append("course", studentQueryFormData.get("course"));
    formData.append("subject", studentQueryFormData.get("subject"));
    formData.append("classMode", studentQueryFormData.get("classMode"));
    formData.append("city", studentQueryFormData.get("city"));
    formData.append("mobile", studentQueryFormData.get("mobile"));
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    formData.append("smsId", smsIdNew);
    formData.append("smsOtp", otpNew);
    setStudentQueryFormData(formData);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/verifyOtp",
        formData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setOpenOtpNew(false);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading(false);
        setOtpNew("");
      } else {
        toast.error(response.data.message ?? "OTP does not match.");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading(false);
    }
  };

    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
    const [firstName, setFirstName] = useState('');
    const [className, setClassName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [data, setData] = useState([])
    const [ddTitles,setDdTitles] = useState([]);
    const [subjectData, setSubjectData ] = useState([]);
    const [nTitle , setNTitle ] = useState('')
    const [nDescription,setNDescription ] = useState('')
    const [cTitle , setCTitle] = useState('')
    const [decodedData, setDecodedData] = useState("");
 
    const navigate = useNavigate();
    useEffect(() => {
      const fetchData = async () => {
        const d = new Date();
        let text = d.toLocaleDateString();
        let token = `vkSoft1234${text}`; 
        const payload = {
          token: token,
        };



        try {
          const response = await axiosInstanceSM.post("api/sm/v1/boards-data", payload);
          setData(response.data.data)

          ///first check if local storage has data
          let localStorageData = localStorage.getItem('studyMaterial');
          if(localStorageData){
          let subdata = JSON.parse(atob(localStorageData));
          fetchDatasubjects(subdata.boardname,subdata.classname,subdata.subjectname,subdata.subjectidselect)
          }else{
          ///set the first data
          if(response.data.data.length>0){
            var item = response.data.data[0];
            var subClass = response.data.data[0].classes[0];
            var subjects = response.data.data[0].classes[0].subjects[0];

            fetchDatasubjects(item.boradName,subClass.className,subjects.subjectName,subjects.subjectId);  setClassName(item.boradName,subClass.className,subjects.subjectName,subjects.subjectId)

          }
         }

          

        } catch (error) {
        }
      };
      fetchData();
    }, []);


    const fetchDatasubjects = async (boardname,classname,subjectname,subjectidselect) => {
      setDdTitles([boardname,classname,subjectname]);
      let localdata = {'boardname':boardname ,"classname":classname ,"subjectname":subjectname,"subjectidselect":subjectidselect };
      localStorage.removeItem('studyMaterial');
      localStorage.setItem('studyMaterial',btoa(JSON.stringify(localdata)));

  
      const d = new Date();
      let text = d.toLocaleDateString();
      let token = `vkSoft1234${text}`; 
      const payload = {
        token: token,
        notesRequired:'1',
        subjectId: subjectidselect
      };
      try {
        const response = await axiosInstanceSM.post("api/sm/v1/subjects", payload);
        setSubjectData(response.data.data);
        var data = response.data.data
        const notestitle = (data[0].notes[0].notesTitle)
        const notesdescription = (data[0].notes[0].notesDescription)
        setNTitle(notestitle);
        setNDescription(notesdescription)
      } catch (error) {
      }
    };
      useEffect(() => {
        let localStorageData = localStorage.getItem('studyMaterial');
        if(localStorageData){
          let subdata = JSON.parse(atob(localStorageData));
          fetchDatasubjects(subdata.boardname,subdata.classname,subdata.subjectname,subdata.subjectidselect)
          console.log('subdata',subdata.boardname)
        }else{
          console.log('nodata')
        }
       
  }, []);

  return (<>
  <NavBar/>
  
  <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Study Material</div></div>
  <div
        style={{
          backgroundColor: "white",
          display: "flex",
          width: "100%",
          height: "60px",
          paddingLeft: "5rem",
          boxShadow: "5px 5px 10px rgb(10 ,10 ,10,.5)",
          alignItems: "center",
        }}
      >
        <Dropdown title="Study Material">
          {data.map((item) => (
            <Dropdown.Menu title={item.boradName}>
              {item.classes.map((subClass) => (
                <Dropdown.Menu title={subClass.className}>
                  {subClass.subjects.map((subjects) => (
                    <Dropdown.Item
                      onClick={() => { 
                       fetchDatasubjects(item.boradName,subClass.className,subjects.subjectName,subjects.subjectId);  setClassName(item.boradName,subClass.className,subjects.subjectName,subjects.subjectId)
                      }}
                    >
                      {subjects.subjectName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              ))}
            </Dropdown.Menu>
          ))}
        </Dropdown>

      </div>

  <div style={{padding:'30px 10% 100px 7%' ,display:'flex', gap:'30px', backgroundColor:'#f7f8f9' }}>
<div style={{ width:'70%', padding:'20px 20px 20px 20px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius:'5px', backgroundColor:'white'}}> 
<div style={{backgroundColor:'#EDF2FA', fontSize:'13px',height:'auto' , minHeight:'50px', alignItems:'center', display:'flex', paddingLeft:'10px'}}>
   { ddTitles.length>0 &&
     <p style={{display:"flex" ,flexWrap:"wrap"}}>
     <p style={{minWidth:"0px"}}>{ddTitles[0]}</p> <ArrowForwardIosIcon style={{fontSize:'15px'}} />
     <p style={{minWidth:"0px"}}>{ddTitles[1]}</p><ArrowForwardIosIcon style={{fontSize:'15px'}}/>
     <p style={{minWidth:"0px"}}>{ddTitles[2]}</p>
    </p> 
    }  
    { ddTitles.length>0?'':decodedData}

   
   </div>
<div style={{width:'100%', padding:'10px 0px',  }}><img style={{width:'100%', border:'1px solid black', height:'20vh', backgroundColor:'#CB8B65' }} src={LogoHeader} alt="" /></div>

<div style={{backgroundColor:'#F6EBDC', fontSize:'19px', fontWeight:'400',  marginBottom:'10px', height:'60px', alignItems:'center', display:'flex', paddingLeft:'10px'}}>
{nTitle}
</div>
<div style={{fontSize:'15px'}}> {nDescription}</div>


<div style={{width:'80%', display:'flex', margin:'0px auto', borderRadius:'6px', border:'1px solid', flexDirection:'column', marginTop:'20px'}}>
<div style={{fontSize:'20px', textAlign:'center', padding:'10px 0px' }}>{nTitle=="" ? nTitle : nTitle.concat((" (Chapter Wise)"))}
&nbsp; </div>
<div style={{ padding:'10px 0px 30px 30px'}}>


{subjectData.map((item)=>(
  <div>  
{
  item.chapters.map((chapter, index) => (<>
  <p style={{cursor:'pointer', color:"#1675E0"}}  onClick={()=>navigate('/study-material-chapter', { state: {"TitleList":ddTitles, "ChapterNames":chapter , "subjectDataNew":subjectData} })} key={index}>Chapter {index + 1} - {chapter.chapterTitle}</p>
    </> ))
}  </div>
))}

</div>

</div>
</div>

<div style={{display:'flex', flexDirection:'column', width:'30%', gap:'20px'}}>
  <div style={{backgroundColor:'white', height:'80px', alignItems:'center', justifyContent:'center', textAlign:'center', display:'flex', boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)'}}>
<div onClick={handleOpen} style={{display:'flex',justifyContent:'center', backgroundColor:'#CB8B65', color:'white', width:'80%', height:'40px', borderRadius:'3px', alignItems:'center', cursor:'pointer'}}>Get a Free Demo Class</div>
</div>
<Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        onClose={handleClose("backdropClick")}
        open={open}
        sx={{ 
          overflow: isXs ? "" : "auto",
          "& .MuiDialog-paper": {
            width: isXs ? '38%' : '600px', 
            maxWidth: 'none',
          }
        }}
        
      >
        <div
          className="modal-content"
          style={{
            marginTop: isXs ? "" : "0%",
            textAlign: isXs ? "" : "center",
            width: isXs ? "100%" : "100%",
          
          }}
        >
          <Button
            style={{
              position: "absolute",
              right: "0%",
              backgroundColor: "white",
              borderRadius: "100%",
              minWidth: "2rem",
              color: "black",
            }}
            onClick={() => handleClose("close")}
          >
            X
          </Button>
          <div
            className="text-center py-2 fw-bold"
            style={{
              backgroundColor: "#CB8B65",
              width: "100%",
              fontSize: isXs ? "28px" : "22px",
              marginBottom: "5%",
              display: "flex",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                color: "#FFDB7E",
              }}
            >
              FREE
            </div>
            <div
              style={{
                color: "white",
              }}
            >
              REGISTRATION
            </div>
          </div>
          {/* <img src={Group92} style={{display: isXs ? "" : ""}}/> */}
          <form   onSubmit={handleSubmit(onSubmitHandler)}>
            <Box
              className="d-flex justify-content-center mt-2"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("fullName")}
                      name="fullName"
                      id="fullName"
                      label="Full Name"
                      type="text"
                      placeholder="Enter your Full Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setFullName(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "12%" : "",
                      }}
                    >
                      {errors.fullName?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div>
                    <TextField
                      {...register("mobileNumber")}
                      name="mobileNumber"
                      id="mobileNumber"
                      label="Mobile Number"
                      type="number"
                      placeholder="Enter your Mobile Number"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setMobileNumber(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginLeft: isXs ? "5%" : "",
                      }}
                    >
                      {errors.mobileNumber?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex  mt-1 justify-content-between "
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("email")}
                      name="email"
                      id="email"
                      label="Email"
                      type='"email'
                      placeholder="Enter your Email"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      style={{ marginLeft: "3%" }}
                      // onChange={(e) =>
                      //     setEmail(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "20%" : "",
                      }}
                    >
                      {errors.email?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "4%" }}
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: "0%",
                        fontSize: isXs ? "0.9vw" : "3vw",
                        color: "black",
                        letterSpacing: "0px",
                      }}
                    >
                      Mode of Classes*
                    </FormLabel>
                    <Controller
                      // rules={{ required: true }}
                      control={control}
                      name="classMode"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-label="position"
                          {...field}
                          name="position"
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={
                              <span
                                style={{
                                  fontSize: isXs ? "1vw" : "3vw",
                                }}
                              >
                                Online
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={
                              <span
                                style={{
                                  fontSize: isXs ? "1vw" : "3vw",
                                }}
                              >
                                Offine
                              </span>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex justify-content-center mt-1"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      name="ccb"
                      id="ccb"
                      label="Course / Class/ Board"
                      type='"text'
                      placeholder="Enter Course You Need"
                      {...register("course")}
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setCource(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "17%" : "",
                      }}
                    >
                      {errors.course?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div>
                    <TextField
                      name="subject"
                      {...register("subject")}
                      id="subject"
                      label="Subject"
                      type="text"
                      placeholder="Enter Subject You Need"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setSubject(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginLeft: isXs ? "5%" : "",
                      }}
                    >
                      {errors.subject?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex justify-content-center mt-1"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "13.8ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: isXs ? "2px" : "2px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("city")}
                      name="city"
                      id="city"
                      label="City"
                      type='"text'
                      placeholder="Enter City Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setCity(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.city?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div style={{ textAlign: "center" }}>
                    <TextField
                      {...register("area")}
                      name="area"
                      id="area"
                      label="Area"
                      type="text"
                      placeholder="Enter Area Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setArea(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.area?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div tyle={{ textAlign: "start" }}>
                    <TextField
                      {...register("pincode")}
                      name="pincode"
                      id="pincode"
                      label="Pincode"
                      type="text"
                      placeholder="Enter Pincode"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setPincode(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.pincode?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div className="d-flex justify-content-center my-2">

              {loading ? (
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary py-2 w-75 fw-bold"
                  style={{
                    fontSize: "14px",
                    border: "1px solid #CB8B65",
                    backgroundColor: "#FFDB7E",
                    color: "#866D2C",
                    borderRadius: "0",
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </form>
          <div
            style={{
              height: "40px",
              textAlign: "center",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            {" "}
            <span style={{ fontWeight: "700", fontSize: "14px" }}>
              Are you a Tutor?
            </span>{" "}
            <a href="/signup-teacher">
              <span style={{ fontSize: "14px", fontWeight: "700" }}>
                Create Free Profile
              </span>
            </a>
          </div>

          <div className="d-flex flex-row justify-content-between w-full mb-3">
            <div
              className="flex-grow-1 text-center"
              style={{
                fontSize: "12px",
                fontWeight: "900",
              }}
            >
              <small className="block">
                <span className="">By signing up you agree to our </span>
                <a href="/terms-conditions" style={{ color: "#0582D2" }}>
                  {" "}
                  Terms and Conditions
                </a>
              </small>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openOtpNew}
        onClose={() => {}}
        maxWidth="md"
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(1px)", // Apply blur effect directly
          },
        }}
        PaperProps={{
          sx: {
            padding: "rem",
            overflowX: "hidden",
          },
        }}
      >
        <Button
          onClick={handleCloseOtpNew}
          style={{
            position: "absolute",
            right: "0%",
            top: "0",
            backgroundColor: "white",
            borderRadius: "100%",
            minWidth: "2rem",
            color: "black",
            border: "1px solid black",
          }}
        >
          X
        </Button>

        <div style={{ padding: "1rem" }}>
          <form
            onSubmit={handleSubmitOtpNew}
            style={{ padding: isXs ? "0px 10px" : "0px 0px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontSize: "30px", textAlign: "center" }}>
                OTP Verification{" "}
              </div>

              <div style={{ textAlign: "center" }}>
                {" "}
                A One-Time Password has been sent to{" "}
                {studentQueryFormData
                  ? studentQueryFormData.get("mobile")
                  : null}
              </div>
              <div>
                <MuiOtpInput
                  style={{
                    width: isXs ? "400px" : "270px",
                    gap: isXs ? "20px" : "5px",
                    padding: "14px 3px",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    margin: "0px auto",
                  }}
                  value={otpNew}
                  onChange={handleChangeOtpNew}
                  length={6}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  gap: "10px",
                  flexDirection: isXs ? "" : "column",
                }}
              >
                <div style={{ textAlign: "center" }}>Didn’t Receive OTP?</div>
                {loading2 ? (
                  <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                    <CircularProgress color="success" />
                  </Stack>
                ) : (
                  <div
                    type="button"
                    style={{ color: "#cb8b65", textAlign: "center" }}
                    onClick={handleResendOtpNew}
                  >
                    Resend OTP
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "70px",
              }}
            >
              {loading ? (
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <button
                  style={{
                    backgroundColor: "#CB8B65",
                    color: "white",
                    width: "30rem",
                    height: "2.5rem",
                    border: "none",
                    borderRadius: "3px",
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </Dialog>

<div style={{boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)',padding:'15px 15px', backgroundColor:'white' }}>
<StudyMaterailForm/>
</div>
</div>

  </div>
  
  <Footer />

  </>
  )
}

export default StudyMaterial