import React, { useEffect, useState } from "react";
import pencil from "../../../assets/pencil.svg";
import nbook from "../../../assets/nbook.svg";

import Box from "@mui/material/Box";
import { Steppers10 } from "./StepperWeb";
import StepperPhone, { Step10 } from "./StepperPhone";
import NextButton from "../../Buttons/NextButton";
import BackButton from "../../Buttons/BackButton";
import InquiryLayout from "./InquiryLayout";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  dropDownListings,
  storeBoardListing,
  storeClassId,
  storeClassType,
  storeDayType,
  storeFee,
  storeGender,
  storeLocation,
  storePreference,
  storeStartPlan,
  storeSubjects,
  studentCreatePost,
} from "../../features/student/listingSlice";
import { toast } from "react-toastify";

// const isXs = useMediaQuery('(min-width:600px)');

const Gender = () => {
  const [radioValue, setRadioValue] = useState("");
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const genderListings = async () => {
    dispatch(dropDownListings());
  };

  const handleClick = (value) => {
    // console.log(item);
    setRadioValue(value);
    // console.log('aaa',radioValue);
  };
  const studentPostData = useSelector((item) => item?.listing?.postPayload);
  const selectGenderPrefId = useSelector(
    (item) => item?.listing?.postPayload?.genderPreferenceId
  );

  useEffect(() => {
    setRadioValue(selectGenderPrefId);
  }, [selectGenderPrefId]);

  const user = JSON?.parse(localStorage?.getItem("user"));
  const userId = user.id;

  const classId = useSelector((item) => item?.listing?.postPayload?.classId);
  const boardId = useSelector((item) => item?.listing?.postPayload?.boardId);
  const classPreferenceId = useSelector(
    (item) => item?.listing?.postPayload?.classPreferenceId
  );
  const classDayTypeId = useSelector(
    (item) => item?.listing?.postPayload?.classDayTypeId
  );
  const classStartPlanTypeId = useSelector(
    (item) => item?.listing?.postPayload?.classStartPlanTypeId
  );

  const cost = useSelector((item) => item?.listing?.postPayload?.cost);
  const addressLineOne = useSelector(
    (item) => item?.listing?.postPayload?.addressLineOne
  );
  const addressLineTwo = useSelector(
    (item) => item?.listing?.postPayload?.addressLineTwo
  );
  const countryId = useSelector(
    (item) => item?.listing?.postPayload?.countryId
  );
  const stateId = useSelector((item) => item?.listing?.postPayload?.stateId);
  const cityId = useSelector((item) => item?.listing?.postPayload?.cityId);
  const pincode = useSelector((item) => item?.listing?.postPayload?.pincode);
  const subjectIds = useSelector(
    (item) => item?.listing?.postPayload?.subjectIds
  );
  const classTypeIds = useSelector(
    (item) => item?.listing?.postPayload?.classTypeIds
  );
//   console.log("asaazzzza", classTypeIds);

  const genderList = useSelector(
    (state) => state.listing.dropDownData.gender_preference
  );
  const genderValue = genderList?.map((item) => item?.value);

  const { classSelect, locationSelect, boardSelect, subjectSelectIds , subjectData ,classTypeSelect,postPayload , dropDownData} =
    useSelector((item) => item?.listing);

  const selectedSubjects = [];

  subjectData?.forEach((item) => {
    if (subjectSelectIds.includes(item.id)) {
      selectedSubjects.push(item.subjectName);
    }
  });

  const selectedClassPrefernce = dropDownData?.class_prference?.map((item , index)=>{
    if(item?.value==postPayload?.classPreferenceId){
        return item?.name
    }
  })

  const selectedClassDayType = dropDownData?.class_day_type?.map((item , index)=>{
    if(item?.value==postPayload?.classDayTypeId){
        return item?.name
    }
  })

  const selectedClassPlanType = dropDownData?.class_start_plan_type?.map((item , index)=>{
    if(item?.value==postPayload?.classStartPlanTypeId){
        return item?.name
    }
  })


 const selectedClassTypeMode = [];

 dropDownData.class_type?.forEach((item) => {
    if (postPayload?.classTypeIds?.includes(item.value)) {
        selectedClassTypeMode.push(item.name);
    }
  });

  const selectedGenderPrefernce = dropDownData?.gender_preference?.map((item , index)=>{
    if(item?.value==postPayload?.genderPreferenceId){
        return item?.name
    }
  })


  useEffect(() => {
    genderListings();
  }, []);

  const isNavigate = (event, value) => {
    if (radioValue == "" || radioValue == null || radioValue == 0) {
      toast.error("Input field is required.");
    } else {
      dispatch(storeGender({ genderPreferenceId: radioValue }));
      setOpen(true);

      
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  const loading = useSelector((state) => state.listing.loading);

  const navigate = useNavigate();
  const isXs = useMediaQuery("(min-width:600px)");

  const createPost = () =>{
// console.log('object', { userId: userId, classId: classId, boardId: boardId, classPreferenceId: classPreferenceId, classDayTypeId: classDayTypeId, classStartPlanTypeId: classStartPlanTypeId, cost: cost, addressLineOne: addressLineOne, addressLineTwo: addressLineTwo, countryId: countryId, stateId: stateId, cityId: cityId, pincode: pincode, subjectIds: subjectIds, classTypeIds: classTypeIds, genderPreferenceId: selectedGender })
      dispatch(studentCreatePost({ userId: userId, classId: classId, boardId: boardId, classPreferenceId: classPreferenceId, classDayTypeId: classDayTypeId, classStartPlanTypeId: classStartPlanTypeId, cost: cost, addressLineOne: addressLineOne, addressLineTwo: addressLineTwo, countryId: 101, stateId: stateId, cityId: cityId, pincode: pincode, subjectIds: subjectIds, classTypeIds: classTypeIds, genderPreferenceId: radioValue , created_by: 3 })).then((response)=>{
          if(response?.payload?.status==200){
              dispatch(storeClassId(''));
              dispatch(storeLocation(''));
              dispatch(storeBoardListing(''));
              dispatch(storeSubjects(''));
              dispatch(storePreference(''));
              dispatch(storeFee(''));
              dispatch(storeDayType(''));
              dispatch(storeStartPlan(''));
              dispatch(storeClassType());
              dispatch(storeGender(''));
              toast.success(response?.payload?.message)
              navigate('/dashboard/home');
          }
      })
  }

  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: isXs ? "90%" : "60%",
    // bgcolor: "background.paper",
    // boxShadow: 24,
    // p: 4,
    // borderRadius: "10px",
    // overflowX: "auto", // Enable vertical scrolling

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p:2,
    borderRadius: "8px",
    overflowY: "auto", // Enable vertical scrolling
    maxHeight: "90vh", // Limit height of the modal content
  };

  const handleClose = () => setOpen(false);

  return (
    <InquiryLayout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="section"
        sx={{
          p: 1,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#CB8B65",
          color: "white",
          fontSize: "22px",
          fontWeight: "normal",
        }}
      >
        <BackButton style={{ marginRight: "auto" }} onClick={goBack} />
        <div className="d-flex justify-content-center align-items-center mx-auto">
          Do you have any Tutor gender preference?
        </div>
      </Box>
      <div className="my-5" style={{ display: isXs ? "" : "none" }}>
        <Steppers10 />
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ display: isXs ? "none" : "" }}
      >
        <div className="my-5" style={{ display: isXs ? "none" : "" }}>
          <Step10 />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div
          className="container border border-secondary-subtle p-3"
          style={{ width: isXs ? "60%" : "80%", borderColor: "#FBFBFB" }}
        >
          <form>
            {genderList &&
              genderList.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <label for={item.value}>{item.name}</label>
                  <input
                    type="radio"
                    name="select"
                    value={item.value}
                    id={item.value}
                    onChange={() => handleClick(item.value)}
                    checked={radioValue === item.value}
                  />
                </div>
              ))}
          </form>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div
          style={{ marginTop: isXs ? "3%" : "5%", width: isXs ? "" : "70%" }}
        >
          <NextButton onClick={() => isNavigate()} />
        </div>
      </div>
      <div className="d-flex justify-content-between px-4">
        <img
          src={nbook}
          alt="vector"
          className="img-fluid"
          style={{ width: "13%" }}
        />
        <img
          src={pencil}
          alt="vector"
          className="img-fluid mt-4"
          style={{ width: "2%" }}
        />
      </div>
    <Modal
        open={open}
        
       
      >
        <Box sx={style}>
          <Box
            sx={{
              backgroundColor: "#CB8B65",
              display: "flex",
              justifyContent: "center",
              borderRadius: "5px",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: "500", fontSize: isXs ? "?" : "1.2rem" , fontFamily: "jost", color: "white", py: 1 }}
            >
              Requirement Preview
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontFamily: "jost", fontSize: isXs ? "?" : "1.2rem", mb: 2 }}>
              Requirement at a glance
            </Typography>
          </Box>

          <Box sx={{ display: isXs ? "" : "flex", mb: 1 }}>
            <Box display={'flex'}>
            <Container>
            <List>
            <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Class:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Address first:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Address second:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>State:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>City:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Pincode:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Board:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Subjects:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Preferred Class:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Max. Fee:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Availability:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Plan to Start:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Study Mode:</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>Gender Preference:</ListItemText>
                </ListItem>
              </List>
            </Container>
            <Container>
              <List>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{classSelect?.className}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{locationSelect?.addressLineOne}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{locationSelect?.addressLineTwo}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{locationSelect?.stateId?.stateName}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{locationSelect?.cityId?.cityName}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{locationSelect?.pincode}</ListItemText>
                </ListItem>
                 <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{boardSelect?.boardName}</ListItemText>
                </ListItem>
                <ListItem>
                  {selectedSubjects?.map((item, index) => (<ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }} key={index}>{item+','}</ListItemText>))}
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{selectedClassPrefernce}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{postPayload?.cost}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{selectedClassDayType}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{selectedClassPlanType}</ListItemText>
                </ListItem>
                <ListItem>
                  {selectedClassTypeMode?.map((item, index) => (<ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }} key={index}>{item+","}</ListItemText>))}
                </ListItem>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ fontFamily: 'jost', fontSize: '.9rem' }}>{selectedGenderPrefernce}</ListItemText>
                </ListItem>
              </List>
            </Container>
            </Box>
          </Box>

          


          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={handleClose}
              sx={{ fontFamily: "jost", color: "grey", width: "6rem", mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={createPost}
              variant="contained"
              sx={{
                backgroundColor: "#CB8B65",
                color: "white",
                fontFamily: "jost",
                width: "6rem",
                height: "2.4rem",
                borderRadius: ".2rem",
                border: "none",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </InquiryLayout>
  );
};

export default Gender;
