import React from 'react';
import class12 from '../../assets/12.jpg';
import Footer from '../pages/LandingPage/Footer';
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from "../../assets/LogoHeader.svg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function Blog() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
    
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "12rem",
                    backgroundColor: "#CB8B65",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "2rem",
                    gap: "1rem",
                }}
            >
                <Link to="/"><img width={200} src={logo} alt="Logo" /></Link>
                <h1
                    style={{
                        fontSize: isXs ? "3rem" : '2rem',
                        color: "white",
                        textTransform: "uppercase",
                    }}
                >
                    Blog
                </h1>
            </Box>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: '40px 0px 80px 0px' }}>
                {/* Card 1 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 1" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 July</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>

                {/* Card 2 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 2" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 Jan</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>

                {/* Card 3 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 3" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 Feb</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem sit consectetur adipisicing elit.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>

                {/* Card 4 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 4" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 Mar</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem ipsum dolor sit amet.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>

                {/* Card 5 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 5" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 Apr</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>

                {/* Card 6 */}
                <div style={{
                    width: isXs ? '48%' : '100%',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                    <img src={class12} alt="Card 6" style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                    <div style={{ padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CalendarMonthIcon style={{ fontSize: '18px' }} />
                            <div style={{ fontSize: '13px' }}>9 May</div>
                        </div>
                        <div style={{ fontSize: '15px', fontWeight: '500' }}>
                            Lorem ipsum dolor sit amet consectetur.
                        </div>
                        <div style={{ fontSize: '14px', color: '#3d4349', marginTop: '10px' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, consequuntur amet?
                        </div>
                        <div style={{
                            color: 'white',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            backgroundColor: '#CB8B65',
                            marginTop: '10px',
                            fontSize: '14px'
                        }}>Read More...</div>
                    </div>
                </div>
            </div>

            <div style={{ padding: '65px 0px 80px 60px' }}>
                <div style={{ fontSize: '25px', fontWeight: '500' }}>RECENT POSTS</div>
                {/* Recent Post 1 */}
                <div style={{ padding: '10px 0px', display: 'flex', gap: '25px' }}>
                    <div>
                        <img src={class12} alt="Recent Post 1" style={{ width: '100%', height: '60px', border: '1px solid black' }} />
                    </div>
                    <div style={{ width: '200px', fontSize: '14px', cursor: 'pointer' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                </div>
                {/* Recent Post 2 */}
                <div style={{ padding: '10px 0px', display: 'flex', gap: '25px' }}>
                    <div>
                        <img src={class12} alt="Recent Post 2" style={{ width: '100%', height: '60px', border: '1px solid black' }} />
                    </div>
                    <div style={{ width: '200px', fontSize: '14px', cursor: 'pointer' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                </div>
                {/* Repeat for more recent posts if needed */}
            </div>

            <Footer />
        </>
    );
}

export default Blog;
