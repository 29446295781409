import React, { useEffect, useState } from 'react';
import img from '../../../assets/img.jpg';
import Otp from '../../../assets/otp.svg';
import book from '../../../assets/book.svg';
import bulb from '../../../assets/bulb.svg';
import line from '../../../assets/line.svg';
import Otp1 from '../../../assets/Otp1.png';
import CurveLine from '../../../assets/CurveLine.png';
import wifi2 from '../../../assets/wifi2.png';
import LogoHeader from '../../../assets/LogoHeader.svg';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Backdrop, Box, Button, CircularProgress, TextField, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { updateStudentdata, verifyOtp } from '../../features/student/studentSlice';
import { signIn, storeUpdateNumber, verifyOtpTeacher } from '../../features/teacher/teacherAuthSlice';

const VerifyOtp = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // State to manage the remaining time
    const [timeLeft, setTimeLeft] = useState(60); // 2 minutes in seconds

    const userId = useSelector((state) => state?.teacherSignUp?.signUpResponseData?.id)
    console.log(userId, 'userId');
    const mobile = useSelector((state) => state?.teacherSignUp?.teacherMobileNumber);

    


    const handleSubmit = async (e) => {
        e.preventDefault();
            if (!otp) {
                toast.error('otp fields is required')
                return
            }
            dispatch(verifyOtpTeacher({ fcmToken: 'react fcmToken', roleId: 3, userId: userId, otp: otp }))
                .then((apiResponse) => {
                    if (apiResponse?.payload?.status == 200) {  
                        toast.success(apiResponse?.payload?.message);
                        localStorage.setItem('user', JSON.stringify(apiResponse?.payload?.data))
                        // navigate('/successfull')
                        console.log(apiResponse?.payload?.data?.classConductedCount , 'classConductedCount');
                        if(apiResponse?.payload?.data?.classConductedCount > 0 ){
                          navigate('/dashboard/dashboard-home');
                        }else{
                          navigate('/form-teacher');
                        }
                    } else {
                        const errotMessage = apiResponse?.payload?.message
                        toast.error(errotMessage)
                    }
                })
                .catch((error) => {
                    toast(error?.message || "An error occurred while logging in.");
                });
            // dispatch(updateStudentdata(firstName, lastName));
    };

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const handleClose = () =>{
        // window.location.href = 'https://kanhahometutions.com';
        navigate('/signin-teacher');
      }

    const isXs = useMediaQuery('(min-width:600px)');

    
    const resendOtp = () => {
        dispatch(signIn({mobile:mobile , roleId:3})).then((response)=>{
            if(response.payload.status == 200){
             toast.success(response.payload.message);
             dispatch(storeUpdateNumber(mobile))
             setIsButtonDisabled(true);
    setTimeLeft(120);
            }
         }).catch((error) => {
            console.log(error , 'somthing went wrong');
         })
    }

    const loading = useSelector((state) => state?.teacherSignUp?.loading);

    useEffect(() => {
        if (timeLeft === 0) {
          setIsButtonDisabled(false);
          return;
        }
    
        const timerId = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1);
        }, 1000);
    
        return () => clearInterval(timerId); // Cleanup on component unmount
      }, [timeLeft]);

      // Effect to start the countdown when the component mounts
  useEffect(() => {
    setIsButtonDisabled(true);
    setTimeLeft(60); // Set initial 2-minute countdown
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };


    return (
        <Box style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <Box className='d-flex flex-row justify-content-center align-items-center'>
                <Box className='container border-0'>
                    <Box className='row justify-content-center'>
                        <Box style={{ width: '895px', height: '560px' }}>
                            <Box style={{ marginTop: '10%', backgroundColor: '#ffffff' }}>
                                <Box className='row'>
                                    <Box className='col-md-6' style={{ display: isXs ? '' : 'none' }} >
                                        <Box >
                                            <img src={Otp} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                        </Box>
                                    </Box>
                                    <Box className='col-md-6' style={{ display: isXs ? 'none' : '' }}>
                                        <Box style={{ backgroundColor: '#CB8B65', height: '20rem', position: 'relative', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                            <Box className='d-flex justify-content-center'>
                                                <img src={LogoHeader} style={{ position: 'absolute', width: '40%', top: '1rem' }} />
                                                <Box style={{ position: 'absolute', color: 'white', top: '5.5rem', border: '2px solid white', borderRadius: '20px', width: '40%', textAlign: 'center' }}>For Teacher</Box>
                                                <img src={Otp1} style={{ position: 'absolute', bottom: 0, width: '50%' }} />
                                            </Box>
                                            <img src={wifi2} style={{ position: 'absolute', bottom: 0, left: 5 }} />
                                            <img src={book} style={{ position: 'absolute', top: 0, right: 5 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', bottom: 0, right: 0, rotate: '180deg' }} />
                                        </Box>
                                    </Box>
                                    <Box className='col-md-6' style={{position:'relative'}}>
                                    <Button style={{ position: 'absolute', right: '-1%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black' , marginTop:'-10px'}} onClick={() => handleClose()}>X</Button>

                                        <Box className='d-flex flex-row justify-content-between p-2'>

                                            <Box>
                                                <h3 className='pt-3' style={{ fontWeight: 'bold' }}>OTP Verification</h3>
                                                <img src={line} className='mb-4' />
                                            </Box>
                                            <img src={book} style={{ height: '12%', width: '12%' }} />
                                        </Box>
                                        <form onSubmit={handleSubmit}>
                                            <Box className='fw-bolder py-1 text-center' style={{ fontSize: '12px' }}>A One-Time Password has been sent to {mobile}</Box>
                                            <Box className="d-flex justify-content-center fw-bold py-2" style={{ marginTop: isXs ? '3%' : '2%', marginBottom: isXs ? '3%' : '' }}>
                                                <Box className='d-flex justify-content-center col gap-2' style={{ width: '100%' }}>
                                                    <MuiOtpInput value={otp} onChange={handleChange} length={6} style={{ width: isXs ? '90%' : '100%' }} />
                                                </Box>
                                            </Box>

                                            <Box className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                                <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>
                                            </Box>

                                        </form>
                                        <Box className="d-flex flex-row justify-content-between w-full my-2">
                                            <Box className="flex-grow-1 text-center" style={{ fontSize: '12px', fontWeight: '900' }}>
                                                {/* <small className="block">
                                                        <span className="">Entered a wrong number?</span>
                                                        <a href="#" style={{ color: '#AF6031' }}>Change</a>
                                                    </small> <br /> */}
                                               <small className="block">
                                                    <span className="">Didn’t Receive OTP?</span>
                                                    <span onClick={()=>resendOtp()} 
                                                    style={{

                                                        color: '#AF6031',
          marginLeft: '10px',
          fontSize: '13px',
          cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
          opacity: isButtonDisabled ? 0.5 : 1,
                                                         
                                                    }}>Resend {isButtonDisabled ? `(${formatTime(timeLeft)})` : ''} </span>
                                                </small>
                                            </Box>
                                            <Box>
                                                <img src={bulb} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default VerifyOtp
