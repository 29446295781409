import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";
// import axios from "../../../Utils/axios";
// import axios from "axios";
// import axiosInstance from "../../../Utils/axios";

export const studentLogin = createAsyncThunk(
  "otp/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`mobileOtpSend`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`verifyOtp`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);



const initialState = {
  studentMobile:'',
  studentData: [],
  verifyOptData:[],
  loading: false,
  error: null,
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    storeMobile: (state, { payload }) => {
      state.studentMobile = payload;
    },
    regUser: (state, { payload }) => {
      state.reguser = payload;
      state.error = "";
    },
    selectAvatar: (state, { payload }) => {
      state.avatar = payload;
    },
    updateStudentdata: (state,action) => {
      state.studentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(studentLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(studentLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.studentData = action.payload;
      state.error = null;
    });
    builder.addCase(studentLogin.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.verifyOptData = action.payload;
      state.error = null;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { storeMobile, updateStudentdata} =
studentSlice.actions;

export default studentSlice.reducer;
