import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import online from "../../../../assets/online.png";
import location2 from "../../../../assets/location2.svg";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentPost,
  storeStudentPostDetails,
  teacherDetails,
} from "../../../features/teacher/teacherListingSlice";
import SampleImage from "../../../../assets/SampleImage.svg";
import PinCodeCheckPopup from "../../../ModalPopups/PinCodeCheckPopup";


const filters = [
  { label: "All", value: "all" },
  { label: "This Weak", year: "weak" },
];

const DashboardHome = () => {
  const dispatch = useDispatch();
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [dialogPinCodeOpen, setDialogPinCodeOpen] = useState(false);

  const studentData = useSelector(
    (item) => item?.teacherListing?.studentPostListing
  );

  const loading = useSelector(
    (item) => item?.teacherListing?.loading
  );

  const handleCloseDialog = () => {
    setDialogPinCodeOpen(false);
  }


  // console.log(studentData);
  const listingStudentPost = (search, filter) => {
    dispatch(getStudentPost({ search: search, filter: filter }));
  };

  const getProfileDataListing = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    dispatch(teacherDetails({ userId: user?.id })).then((response) => {
      if(! response?.payload?.data?.teacher_profile?.degree_id){
        navigate("/form-teacher")
      }
      if (response?.payload?.status == 200) {
        if (! response?.payload?.data?.teacher_profile?.pincode) {
          setDialogPinCodeOpen(true)

        }

      }
    })
    return
  }
  useEffect(() => {
    listingStudentPost();
    getProfileDataListing()

  }, []);

  const isNavigate = (i) => {
    const selectedItem = studentData?.find(
      (item, index) => index === parseInt(i)
    );
    // dispatch(storeStudentPostDetails(selectedItem));
    const itemId = studentData[i]?.id;
    navigate(`/dashboard/dashboard-home-enquiry/${itemId}`);
  };

  const buttonStyle = {
    color: "black",
    borderRadius: "20px",
    width: "100%",
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // listingStudentPost(searchQuery,selectedFilter);
  };

  const handleFilterChange = (event, newValue) => {
    setSelectedFilter(newValue);
    console.log("Selected filter:", selectedFilter);
    // listingStudentPost(searchQuery,selectedFilter);
  };

  useEffect(() => {
    // console.log(selectedFilter , 'selectedFilter');
    listingStudentPost(searchQuery, selectedFilter?.year);
  }, [selectedFilter, searchQuery]);

  const randomColor = () => {
    // Generate a random hex color
    // return '#' + Math.floor(Math.random()*16777215).toString(16);
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };


  return (

    <>

      <Stack style={{ display: isXs ? "?" : "none" }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <PinCodeCheckPopup
          open={dialogPinCodeOpen}
          onClose={handleCloseDialog}
          onConfirm={() => navigate("/dashboard/address")}
          title="Complete Profile"
          message="Your complete address is required in order to proceed, please fill your address, state, city and pincode."

        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#F9F9F9",
            padding: 2,
          }}
        >
          <Box
            className="d-flex align-items-center"
            style={{
              width: isXs ? "80%" : "90%",
              marginLeft: "2%",
              position: "relative",
            }}
          >
            <input
              onChange={handleSearchChange}
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              style={{
                borderRadius: "100px",
                backgroundColor: "#F3F3F3",
                fontSize: "14px",
                padding: "15px",
              }}
            />
          </Box>
          <Box display={"flex"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {/* <FilterListRoundedIcon />{" "} */}
            </Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filters}
              value={selectedFilter}
              onChange={handleFilterChange}
              sx={{ ...buttonStyle }}
              renderInput={(params) => <TextField {...params} label="Filter" style={{ width: "10rem" }} />}
            />
          </Box>
        </Box>
        {studentData?.length > 0 ? studentData?.map((item, index) => (
          <button
            onClick={() => isNavigate(index)}
            key={index}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <Box className="d-flex">
              <Box style={{ width: isXs ? "95%" : "95%" }}>
                <Box
                  className="mt-3 py-3 shadow-sm"
                  style={{
                    border: "1px solid #D6D6D6",
                    backgroundColor: "white",
                    // marginBottom: "3%",
                    borderRadius: "10px",
                  }}
                >

                  {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? 'none' : '', marginRight:'5%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>10 mins ago</Box>
                                </Grid> */}

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <Box>
                        <Box
                          sx={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: item?.user?.profileImage ? 'transparent' : randomColor(),
                            overflow: 'hidden',
                            marginLeft: "25px"
                            // Ensure the Box has a defined size and that children are properly aligned
                          }}
                        >
                          {item?.user?.profileImage ? (
                            <img
                              src={item?.user?.profileImage}
                              alt="profile-img"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                // Ensure that the image covers the Box without stretching it
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h4"
                              sx={{ color: 'white', fontWeight: '500' }}
                            >
                              {`${item?.user?.firstName?.charAt(0)?.toUpperCase()}`}
                            </Typography>
                          )}
                        </Box>
                      </Box>




                      <Box>
                        <Box className="py-1 ">
                          <Typography style={{ fontSize: "1.4rem", fontFamily: "jost", textTransform: "capitalize", textAlign: "left" }}>

                            {item?.user?.firstName} {item?.user?.lastName}
                          </Typography>
                          <Box display={"flex"} gap={1}>
                            <Box fontSize={"14px"}>
                              {item?.classData?.class_name} ||{" "}
                            </Box>
                            {item?.subjects?.map((item, index) => (
                              <Box key={index} fontSize={"14px"}>
                                {" "}
                                {item?.subjectName?.subject_name},
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ display: "flex", gap: 2, marginTop: "2%" }}>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              {" "}
                              <img src={online} />{" "}
                            </Box>
                            {item?.classTypes?.map((item, index) => (
                              <Box key={index}>{item?.classTypeName?.name},</Box>
                            ))}
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              {" "}
                              <img src={location2} />{" "}
                            </Box>
                            <Box>
                              {item?.city}, {item?.state}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>




                    <Box sx={{ display: "flex", flexDirection: "column", gap: "2.6rem" }}>
                      {
                        item?.postLimitExceed == 0 ?
                          <Box sx={{ marginRight: "2rem", backgroundColor: "#24AA2A", width: "6rem", height: "2.5rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2rem", fontWeight: "500" }}>Active</Box>
                          :
                          <Box sx={{ marginRight: "2rem", backgroundColor: "red", width: "6rem", height: "2.5rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2rem", fontWeight: "500" }}>Expired</Box>

                      }

                      <Typography sx={{ marginRight: "2rem", fontSize: ".9rem", fontFamily: "jost", textAlign: "left" }}>{item?.createdAt}</Typography>

                    </Box>
                  </Box>

                  {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? '' : 'none' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start', marginRight:'5%' }}>10 mins ago</Box>
                                </Grid> */}
                </Box>
              </Box>
            </Box>


          </button>

        )) : 'no data found'}

      </Stack>

      <Stack style={{ display: isXs ? "none" : "?" }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#F9F9F9",
            padding: 2,
          }}
        >
          <Box
            className="d-flex align-items-center"
            style={{
              width: isXs ? "80%" : "90%",
              marginLeft: "0%",
              position: "relative",
            }}
          >
            <input
              onChange={handleSearchChange}
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              style={{
                borderRadius: "8px",
                backgroundColor: "#F3F3F3",
                fontSize: "14px",
                padding: "15px",
              }}
            />
          </Box>
          <Box display={"flex"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {/* <FilterListRoundedIcon />{" "} */}
            </Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filters}
              value={selectedFilter}
              onChange={handleFilterChange}
              sx={{ ...buttonStyle }}
              renderInput={(params) => <TextField {...params} label="Filter" style={{ width: "5rem" }} />}
            />
          </Box>
        </Box>
        {studentData?.length > 0 ? studentData?.map((item, index) => (
          <button
            onClick={() => isNavigate(index)}
            key={index}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <Box className="d-flex">
              <Box style={{ width: isXs ? "95%" : "100%" }}>
                <Box
                  className="mt-3 py-3 shadow-sm"
                  style={{
                    border: "1px solid #D6D6D6",
                    backgroundColor: "white",
                    // marginBottom: "3%",
                    borderRadius: "10px",
                  }}
                >

                  {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? 'none' : '', marginRight:'5%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>10 mins ago</Box>
                                </Grid> */}

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <Box>
                        <Box
                          sx={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: item?.user?.profileImage ? 'transparent' : randomColor(),
                            overflow: 'hidden',
                            marginLeft: "10px"
                            // Ensure the Box has a defined size and that children are properly aligned
                          }}
                        >
                          {item?.user?.profileImage ? (
                            <img
                              src={item?.user?.profileImage}
                              alt="profile-img"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                // Ensure that the image covers the Box without stretching it
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              sx={{ color: 'white', fontWeight: '500' }}
                            >
                              {`${item?.user?.firstName?.charAt(0)?.toUpperCase()}`}
                            </Typography>
                          )}
                        </Box>
                      </Box>




                      <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: ".2rem" }}>
                          <Typography style={{ fontSize: "1rem", fontFamily: "jost", fontWeight: "bold", textTransform: "capitalize", textAlign: "left" }}>

                            {item?.user?.firstName} {item?.user?.lastName}
                          </Typography>
                          <Box sx={{ flexDirection: "column", textAlign: "left" }} display={"flex"} gap={1}>
                            <Box fontSize={"12px"}>
                              {item?.classData?.class_name}{" "}
                            </Box>

                          </Box>


                          <Box
                            sx={{
                              display: "flex",
                              gap: ".1rem",
                              flexWrap: "wrap",
                              overflowX: "auto", // Allows horizontal scrolling if content overflows
                              maxWidth: "100%",
                              textAlign: 'left',
                              border: '1px solid lightgrey',
                              padding: '.5rem',
                              borderRadius: "10px" // Ensures it doesn't exceed the container width
                            }}
                          >
                            {item?.subjects?.map((subject, index) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  fontSize: {
                                    xs: "13px", // Font size for extra small screens
                                    sm: "13px", // Font size for small screens and up
                                  },
                                }}
                                key={index}
                              >
                                {subject?.subjectName?.subject_name},
                              </Box>
                            ))}
                          </Box>




                          <Box sx={{ display: "flex", gap: 2, marginTop: "0%" }}>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Box>
                                {" "}
                                <img src={location2} width={15} />{" "}
                              </Box>
                              <Box fontSize={"12px"}>
                                {item?.city}, {item?.state}
                              </Box>
                            </Box>
                          </Box>

                        </Box>

                      </Box>
                    </Box>




                    <Box sx={{ display: "flex", flexDirection: "column", gap: "2.6rem" }}>
                      {
                        item?.postLimitExceed == 0 ?
                          <Box sx={{ marginRight: "1rem", backgroundColor: "#24AA2A", width: "3.2rem", height: "1.2rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".2rem", fontWeight: "500", fontSize: ".7rem" }}>Active</Box>
                          :
                          <Box sx={{ marginRight: "1rem", backgroundColor: "red", width: "3.2rem", height: "1.2rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".2rem", fontWeight: "500", fontSize: ".7rem" }}>Expired</Box>

                      }

                      {/* <Typography sx={{ marginRight: "2rem", fontSize: ".9rem", fontFamily: "jost", textAlign: "left" }}>{item?.createdAt}</Typography> */}

                    </Box>
                  </Box>

                  {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? '' : 'none' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start', marginRight:'5%' }}>10 mins ago</Box>
                                </Grid> */}
                </Box>
              </Box>
            </Box>


          </button>

        )) : 'no data found'}

      </Stack>

    </>











  );
};

export default DashboardHome;
