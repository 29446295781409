import { Autocomplete, Box, Checkbox, TextField, Stack, Typography, useMediaQuery, Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { languageUpdate, teacherDetails, teacherLanguage } from '../../../../features/teacher/teacherListingSlice';

const Language = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const languageDetails = useSelector((item) => item?.teacherListing?.availableCredits);
    const loading = useSelector((item) => item?.teacherListing?.loading);

    const languageData = useSelector((item) => item?.teacherListing?.languageTeacher);

    const transformedLanguageDetails = languageDetails?.languages?.map(lang => ({
        languageName: lang.language_name,
        id: lang.id,
    }));

    useEffect(() => {
        if (transformedLanguageDetails) {
            const preSelectedLanguages = languageData.filter(language => transformedLanguageDetails.some(detail => detail.id === language.id));
            setSelectedLanguages(preSelectedLanguages);
        }
    }, [languageData]);


    const teacherLanguages = () => {
        const userId = JSON.parse(localStorage.getItem('user'));
        dispatch(teacherDetails({ userId: userId?.id }));
        dispatch(teacherLanguage());
    };

    useEffect(() => {
        teacherLanguages();
    }, []);

    const isNavigate = () => {
        const userId = JSON.parse(localStorage.getItem('user'));
        if(!selectedLanguages.length>0){
         toast.error('Language filed is required');
        }
        dispatch(languageUpdate({ userId: userId?.id, languageIds: selectedLanguages.map(value => value?.id) }))
            .then((response) => {
                if (response?.payload?.status === 200) {
                    toast.success(response?.payload?.message);
                }
            });
    };

    const handleLanguageChange = (event, selectedOptions) => {
        setSelectedLanguages(selectedOptions);
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <Box>
              <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}>
                        <ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} />
                    </button>
                    <Box>
                        <Typography variant='h5' style={{ fontFamily: 'jost' }}>Languages You Speak</Typography>
                        <Box>Please specify all languages that you speak.</Box>
                        <Box><img src={line} alt="line separator" /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={isNavigate} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>
                        Save Changes
                    </button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '95%', padding: 2 }}>
                    <Box>
                        <Typography sx={{ marginBottom: isXs ? '2%' : '5%', fontFamily: 'jost' }}>Language</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={languageData ?? []}
                            value={selectedLanguages}
                            disableCloseOnSelect
                            onChange={handleLanguageChange}
                            getOptionLabel={(option) => option?.languageName}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        checked={selected}
                                    />
                                    {option?.languageName}
                                </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Languages"
                                    placeholder="Select Languages"
                                />
                            )}
                        />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default Language;
