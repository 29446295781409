import React, { useEffect } from 'react'
import SampleImage from '../../../assets/SampleImage.svg';
import line from '../../../assets/line.svg';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Backdrop, Box, CircularProgress, FormGroup, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { teacherNotifications } from '../../features/teacher/teacherListingSlice';
import NotificationIcon from '../../../assets/NotificationIcon.svg'
import { notificationStudent } from '../../features/student/listingSlice';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#CB8B65',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const Notifications = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const notificationData = useSelector((item) => item?.listing?.notification)
    const loading = useSelector((item) => item?.listing?.loading)

    // console.log(notificationData);

    const notificationsTutor = () => {
        dispatch(notificationStudent({ userId: userId }))
    }

    useEffect(() => {
        notificationsTutor();
    }, [])
    return (
        <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <Box className="d-flex mt-4" style={{ width: '50%', borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }}>
                Notifications
                {/* <FormGroup className='d-flex align-items-center justify-content-center mx-3'>
                    <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                </FormGroup> */}
            </Box>
            <img src={line} className='mb-3' style={{ borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }} />
            <Box className="mt-2" style={{ marginLeft: '2%', overflowY: 'auto' }}>
                {notificationData?.map((item, index) => (
                    <Box className=" mt-2 px-3 py-2" style={{ backgroundColor: '#EEEEEE', marginBottom: '3%', width: '95%' }}>
                        <Box className='d-flex align-items-center'>
                            <Box style={{ fontSize: '12px', marginTop: '1%' }}>{item?.createdAt}</Box>
                        </Box>
                        <Box className='d-flex justify-content-between py-2' style={{ marginBottom: '1%' }}>
                            <Box className='d-flex' sx={{ alignItems: 'center' }}>
                                <img src={NotificationIcon} style={{ width: '5%', display: isXs ? '' : 'none' }} />
                                <div style={{ width: isXs ? '70%' : '100%', marginLeft: '2%' }}>{item?.content}</div>
                            </Box>
                        </Box>
                    </Box>))}
            </Box>
        </>
    )
}

export default Notifications
