import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";

export const accountCloseOtpSent = createAsyncThunk(
  "otp/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`accountCloseOtpSent`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountCloseVerifyOtp = createAsyncThunk(
  "otp/accountCloseVerifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`accountCloseVerifyOtp`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const resendCloseVerifyOtp = createAsyncThunk(
    "otp/resendCloseVerifyOtp",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`resendOtp`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );



const initialState = {
  accountClose: [],
  accountCloseVerify:[],
  resendOtp:[],
  loading: false,
  error: null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    storeMobile: (state, { payload }) => {
      state.studentMobile = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(accountCloseOtpSent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(accountCloseOtpSent.fulfilled, (state, action) => {
      state.loading = false;
      state.accountClose = action.payload;
      state.error = null;
    });
    builder.addCase(accountCloseOtpSent.rejected, (state, action) => {
      state.loading = false;
      state.accountClose = null;
      state.error = action.payload;
    }).addCase(accountCloseVerifyOtp.pending, (state) => {
        state.loading = true;
    })
    .addCase(accountCloseVerifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.accountCloseVerify = action.payload.data;
        state.error = null;
    })
    .addCase(accountCloseVerifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.accountCloseVerify = null;
        state.error = action.payload;
    }).addCase(resendCloseVerifyOtp.pending, (state) => {
        state.loading = true;
    })
    .addCase(resendCloseVerifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.resendOtp = action.payload.data;
        state.error = null;
    })
    .addCase(resendCloseVerifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.resendOtp = null;
        state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { storeMobile} =
commonSlice.actions;

export default commonSlice.reducer;
