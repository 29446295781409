import React from 'react'
import Delete1 from '../../../../../assets/delete1.svg'
import { Box, Modal, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';




const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));


const Delete = ({ onClose }) => {
    const classes = useStyles();
    const handlleClose = () => {
        onClose();
      }
    return (
        <Modal open={true} onClose={onClose} className={classes.modal}>
            <img src={Delete1} />
            <Box>
                <div>Are you sure you want to delete Graphic Designing? Any Associated Courses will also get deleted.</div>
            </Box>
            <Box>
                <Box className="mt-4 d-flex justify-content-evenly px-5" style={{ width: '100%' }}>
                    <Box style={{ width: '50%', marginRight: '8px' }}><button className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: 'white', color: '#7E7E7E', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }} onClick={handlleClose}>Cancel</button></Box>
                    <Box style={{ width: '50%', marginLeft: '8px' }}><button className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }} onClick={handlleClose}>Yes</button></Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default Delete
