import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

const PinCodeCheckPopup = ({ open, onClose, onConfirm, title, message }) => {
    return (
        <Dialog open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose();
                }
            }}
            disableEscapeKeyDown
        >
            <DialogTitle sx={{textAlign:"center",fontSize:"28px"}} >{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{paddingX:"40px" ,textAlign:"justify" , textAlignLast:'center'}}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={onClose} variant="contained" 
                sx={{backgroundColor:"#CB8B65"}}
                >
                    Later
                </Button>
                <Button onClick={onConfirm} variant="contained" 
                                sx={{backgroundColor:"#CB8B65"}}

                 autoFocus>
                Complete
                </Button> */}

                <Box className="mt-2 d-flex justify-content-evenly px-5" style={{ width: '100%' }}>

                    <Box style={{ width: '34%', margin: "0 0 10px 0px" }}><button className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '9', borderColor: '#CB8B65' }} onClick={onConfirm}>Continue</button></Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default PinCodeCheckPopup;
