import React, { useEffect, useState } from 'react';
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'
import study from '../../../assets/study.svg';
import location from '../../../assets/location.svg';
import tick from '../../../assets/tick.svg'

import Box from '@mui/material/Box'
import { Steppers9 } from './StepperWeb';
import StepperPhone, { Step9 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import InquiryLayout from './InquiryLayout';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownListings, storeClassType } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';

const Online = () => {

    const dispatch = useDispatch()
    const [classValueSelected, setClassValueSelected] = useState(0)
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [radioValue, setRadioValue] = useState(0); 
    const [selectedValues, setSelectedValues] = useState([]);
    console.log('before',checkboxStates);

    // const tutionListings = async () => {
    //     dispatch(dropDownListings({ classTypeIds:checkboxStates }))
    // }

    const classTypeList = useSelector((state) => state.listing.dropDownData.class_type)
    
    // useEffect(() => {
    //     tutionListings()
    // }, [])

    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');

    const user = JSON?.parse(localStorage?.getItem('user'))
    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)


    const isNavigate = () => {
        const selectedValue = [];
        if (typeof(checkboxStates) === 'string'){
            selectedValue.push(Number(checkboxStates))
        }else{
             selectedValue.push(parseInt(checkboxStates));
        }
        console.log('array issue', selectedValue);
        if (classValueSelected == '' || classValueSelected == null || classValueSelected == 0) {
            toast.error('Input field is required.');
        } else if(classValueSelected == '2' && (checkboxStates.length <= 0)) {
            toast.error('Input field is required.');
        }
        else {
        dispatch(storeClassType({ classTypeIds:checkboxStates , classValueSelected:classValueSelected , radioValue:radioValue }))
        if(postCount>0){
            navigate('/dashboard/gender')
            }else{
          navigate('/gender')
          }
        }
    }

    // const selectClassTypeIds = useSelector((item) => item?.listing?.postPayload.classTypeIds)
    // console.log(selectClassTypeIds);

    // useEffect(()=>{
    //     setRadioValue(selectGenderPrefId);
    // },[selectGenderPrefId])

    // const dispatchSelectedValues = (radioItem, checkboxItems) => {
    //     // console.log(checkboxItems);
    //     if(radioItem) {
    //         dispatch({ type: 'SELECTED_RADIO_VALUE', payload: radioItem.value });
    //     }
    //     if(checkboxItems.length > 0) {
    //         const selectedCheckboxValues = checkboxItems.map(item => item.value);
    //         dispatch({ type: 'SELECTED_CHECKBOX_VALUES', payload: selectedCheckboxValues });
    //     }
    // }

    const goBack = () => {
        navigate(-1);
    };

    const handleClick = (selectedValue) => {
        setClassValueSelected(selectedValue);
        setRadioValue(selectedValue); 
        if(selectedValue !== '2'){
            // setCheckboxStates(selectedValue);
            setCheckboxStates([]);
      setCheckboxStates((prevItems) => [...prevItems, selectedValue]);
        }
        if(selectedValue === '2'){
            setCheckboxStates([])
        }
    };

    const handleCheckboxChange = (value, isChecked) => {
        // console.log(value);
        const newCheckboxStates = [...checkboxStates];
        const index = newCheckboxStates.indexOf(value);
        if (isChecked) {
            newCheckboxStates.push(value);
        } else if (!isChecked && index !== -1) {
            newCheckboxStates.splice(index, 1);
        }
        setCheckboxStates(newCheckboxStates);
    };


    const selectClassType = useSelector((item) => item?.listing?.classTypeSelect)

    useEffect(()=>{
        setRadioValue(selectClassType?.radioValue);
        setClassValueSelected(selectClassType?.classValueSelected);
        setCheckboxStates(selectClassType?.classTypeIds);
    },[selectClassType])

    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                <Box className='d-flex justify-content-center align-items-center mx-auto' style={{ width: '80%' }}>How would you like to attend your Tuition Classes?</Box>
            </Box>
            <Box className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers9 />
            </Box>
            <Box className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <Box className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step9 />
                </Box>
            </Box>
            <Box className='d-flex justify-content-center'>
                <Box className='container border border-secondary-subtle ' style={{ width: isXs ? '60%' : '90%', borderColor: '#FBFBFB' }}>

                    <form >
                        {classTypeList && classTypeList
                            ?.filter((item) => item?.parentId === 0)
                            ?.map((filteredItem, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' , marginTop: '1rem'}}>
                                    <label htmlFor={filteredItem?.value}>
                                        {filteredItem?.name}
                                    </label>
                                    <input type="radio" name="select" value={filteredItem?.value} id={filteredItem?.value} onChange={() => handleClick(filteredItem?.value)}  checked={radioValue === filteredItem?.value}/>
                                </div>
                            ))
                        }
                        <div style={{display: classValueSelected === '2' ? 'block':'none'}}>
                        {classTypeList && classTypeList
                            ?.filter((item) => item?.parentId === 2)
                            ?.map((filteredItem, index) => (
                                <div key={index} style={{marginBottom: '.5rem'}}>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${filteredItem?.value}`}
                                        value={filteredItem?.value}
                                        name={`checkbox-${filteredItem?.value}`}
                                        onChange={(item) => handleCheckboxChange(item?.target?.value,item?.target?.checked)} 
                                        checked={checkboxStates?.includes(filteredItem?.value)}
                                    />
                                    <label htmlFor={`checkbox-${filteredItem?.value}`} style={{ marginLeft: '2%' }}>{filteredItem?.name}</label>
                                </div>
                            ))}

                        </div>                        
                    </form>
                </Box>
            </Box>
            <Box className='d-flex justify-content-center'>
                <Box style={{ marginTop: isXs ? '2.5%' : '5%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </Box>
            </Box>
            <Box className='d-flex justify-content-between px-4'>
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </Box>
        </InquiryLayout>
    )
}

export default Online
