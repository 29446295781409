import React from 'react';
import img from '../../../assets/img.jpg';
import rgsuccess from '../../../assets/rgsuccess.svg';
import wifi from '../../../assets/wifi.svg';
import bulb from '../../../assets/bulb.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Successful = () => {
    const navigate = useNavigate()

    const postData = useSelector((item) => item?.student?.verifyOptData)
    const postCount = postData?.postCount
    // console.log('qqqqqq', postCount);

    const isNavigate = () => {
        if (postCount == 0) {
            navigate('/subject')
        }else {
            navigate('/dashboard/home')
        }
    }
    return (
        <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '400px', height: '560px' }}>
                            <div style={{ marginTop: '20%', backgroundColor: '#ffffff' }}>
                                <div >
                                    <div >
                                        <img src={rgsuccess} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ color: "#CB8B65", fontSize: '20px', fontWeight: 'bold' }}>Registration Successful !</div>
                                    <div className="d-flex justify-content-center fw-bold py-2"><div style={{ width: '55%', textAlign: 'center', fontSize:'10px' }}>Congratulations, your account has been successfully created.</div></div>
                                    <div className="mt-5 d-flex justify-content-center mt-4 px-5" style={{ width: '100%' }}>
                                        <button onClick={isNavigate} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0' }}>Continue</button>
                                    </div>
                                    <div className='d-flex justify-content-between px-4 mt-4'>
                                        <div >
                                            <img src={wifi} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                        </div>
                                        <div >
                                            <img src={bulb} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Successful
