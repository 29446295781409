
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PrivateRouting from './components/pages/PrivateRouting';
import './components/pages/useAnalytics'

import SignupTeacher from "./components/pages/AccountTeacher/SignupTeacher";
import LoginTeacher from "./components/pages/AccountTeacher/LoginTeacher";
import FormTeacher from "./components/pages/AccountTeacher/FormTeacher";
import ProfileTeacher from "./components/pages/DashboardTeacher/DashboardProfile/ProfileInformation/ProfileTeacher";

function App() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxxl: 1920,
        xxl: 1600,
        xl: 1280,
        lg: 960,
        md: 600,
        sm: 480,
        xs: 0,
      },
    },
  });
  return (
    <div>
      <ThemeProvider theme={theme} >
        <PrivateRouting />
      </ThemeProvider>
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                // transition:Bounce
            />
    </div>
  );
}

export default App;
