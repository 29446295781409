import React, { useState } from 'react';
import { Box, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiOtpInput } from "mui-one-time-password-input";



function StudyMaterialForm() {
  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
  const [firstName, setFirstName] = useState('');
  const [className, setClassName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState(''); 
  const [showOtpInput, setShowOtpInput] = useState(false); 
  const [otpNew, setOtpNew] = useState("");
  const [smsIdNew, setSmsIdNew] = useState(null);
  const handleChangeOtpNew = (NewValue) => {
    setOtpNew(NewValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowOtpInput(true);

    const formData = new FormData();
    formData.append('smsType',"generalSMQuery")
    formData.append("fullName", firstName);
    formData.append("email", email);
    formData.append("mobile", mobileNumber);
    formData.append("course", className);
    formData.append("city", city);
    formData.append("token", 'snilasdf@ksdfhs23123');

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/notifySMS", formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setSmsIdNew(response.data.data.smsID.toString());
        
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
    }
  };

const handleVerifyOtp = async(e)=> {
e.preventDefault();

  const formData = new FormData();
  formData.append('smsType',"generalSMQuery")
  formData.append("fullName", firstName);
  formData.append("email", email);
  formData.append("mobile", mobileNumber);
  formData.append("course", className);
  formData.append("city", city);
  formData.append("token", 'snilasdf@ksdfhs23123');
  formData.append("smsId", smsIdNew);
  formData.append("smsOtp", otpNew);

  try {
    const response = await axios.post(
      "https://app.kanhahometutions.com/api/v2/verifyOtp",
      formData
    );

    if (response.data.success.toString() === "true") {
      toast.success(response.data.message);
      setFirstName('')
        setClassName('')
        setCity('')
        setEmail('')
        setMobileNumber('')
        setOtpNew(false);
        setShowOtpInput(false);
    } else {
      toast.error(response.data.message ?? "OTP does not match.");
    }
  } catch (error) {
    toast.error(error?.response?.data.message);
  }
}


const handleResendOtp = async(e)=> {
  e.preventDefault();
  
    const formData = new FormData();
    formData.append('smsType',"generalSMQuery")
    formData.append("fullName", firstName);
    formData.append("email", email);
    formData.append("mobile", mobileNumber);
    formData.append("course", className);
    formData.append("city", city);
    formData.append("smsId", smsIdNew);
    formData.append("smsOtp", otpNew);
    formData.append("token", 'snilasdf@ksdfhs23123');
  
    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/resendOTP",
        formData
      );
  
      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setSmsIdNew(response.data.data.smsID.toString());
        
      
      } else {
        toast.error(response.data.message ?? "OTP does not match.");
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  }
  

  return (
    <>
      <div
        style={{
          backgroundColor: '#F6EBDC',
          fontSize: '16px',
          fontWeight: '500',
          padding: '0px 20px',
          marginBottom: '20px',
          textAlign: 'center',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Join Kanha Home Tutions Learning Program
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          required
          placeholder="Enter Your Full Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          InputProps={{
            style: {
              height: '40px',
              fontSize: '12px',
              textAlign: 'center',
            },
          }}
          InputLabelProps={{
            style: {
              color: 'black',
              textAlign: 'center',
            },
          }}
          fullWidth
          color="grey"
          focused
          style={{ marginBottom: '16px' }}
        />
        
        <TextField
          label="Email"
          required
          placeholder="Enter Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: {
              height: '40px',
              fontSize: '12px',
              textAlign: 'center',
            },
          }}
          InputLabelProps={{
            style: {
              color: 'black',
              textAlign: 'center',
            },
          }}
          fullWidth
          color="grey"
          focused
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Class Name"
          required
          placeholder="Enter Your Class"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          InputProps={{
            style: {
              height: '40px',
              fontSize: '12px',
              textAlign: 'center',
            },
          }}
          InputLabelProps={{
            style: {
              color: 'black',
              textAlign: 'center',
            },
          }}
          fullWidth
          color="grey"
          focused
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="City"
          required
          placeholder="Enter Your City Name"
          value={city}
          onChange={(e) => setCity(e.target.value)} 
          InputProps={{
            style: {
              height: '40px',
              fontSize: '12px',
              textAlign: 'center',
            },
          }}
          InputLabelProps={{
            style: {
              color: 'black',
              textAlign: 'center',
            },
          }}
          fullWidth
          color="grey"
          focused
          style={{ marginBottom: '16px' }}
        />
     <div style={{ display: 'flex', alignItems: 'center', width: '100%', }}>
  <div style={{ flex: 1, marginRight: '8px' }}>
    <TextField
      label="Mobile Number"
      required
      placeholder="Enter Your Mobile Number"
      value={mobileNumber}
      onChange={(e) => setMobileNumber(e.target.value)}
      fullWidth
      InputProps={{
        style: {
          height: '40px',
          fontSize: '14px',
          textAlign: 'center',
        },
      }}
      InputLabelProps={{
        style: {
          color: 'black',
          textAlign: 'center',
        },
      }}
      color="grey"
      focused
    />
  </div>

  <div>
    <button
      type="submit"
      style={{
        backgroundColor: '#CB8B65',
        color: 'white',
        height: '40px',
        border: 'none',
        borderRadius: '3px',
        width: '100%',
        fontSize: '16px',
        cursor: 'pointer',
        padding:'0px 10px'
      }}
    >
      Send OTP
    </button>
  </div>
</div>


      </form>

      {showOtpInput && (
        <form onSubmit={handleVerifyOtp}>
          <div>
            <MuiOtpInput
              style={{
                width: '100%',
                gap: '5px',
                padding: '10px 3px',
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                margin: '0px auto',
              }}
              value={otpNew}
              onChange={handleChangeOtpNew}
              length={6}
            />
          </div>
          <div onClick={handleResendOtp} style={{color:'blue', textAlign:'end', margin:'5px'}}>Resend OTP</div>
          <button type='submit' style={{textAlign:'center' ,  backgroundColor: '#CB8B65',
        color: 'white',
        height: '40px',
        border: 'none',
        borderRadius: '3px',
        width: '100%',
        fontSize: '16px',
        cursor: 'pointer',
        padding:'0px 10px' }}>Submit </button>
        </form>
      )}
    </>
  );
}

export default StudyMaterialForm;
