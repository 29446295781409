import { Box, TextField, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import line from '../../../assets/line.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { helpAndSupport } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';


const HelpAndSupport = () => {
    const isXs = useMediaQuery('(min-width:600px)');

    const [description, setdescription] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userId = useSelector((item) => item?.listing?.postPayload?.userId)
    const reviewData = useSelector((item) => item?.listing?.newReview)
    console.log(reviewData);

    const support = () => {
        if ( description === '' || description === null || description === 0) {
            toast.error('Input field is required.');
        } else if (email === '' || email === null || email === 0){
            toast.error('Input field is required.');
        }else if (phone === '' || phone === null || phone === 0){
            toast.error('Input field is required.');
        }
        else {
            const user = JSON.parse(localStorage.getItem('user'));
        dispatch(helpAndSupport({ userId: user?.id, message: description, email: email, mobile: phone })).then((response)=>{
            if(response?.payload?.status==200){
                setdescription('');
                setEmail('');
                setPhone('');
                toast?.success(response?.payload?.message);
            }
        })
        }
    }

    const goBack = () => {
        navigate(-1);
    };

    const handleMobileChange = (event) => {
        setPhone(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setdescription(event.target.value);
    };

    return (
        <>
            <Box sx={{ marginLeft: '2%' }}>
                <Box className="d-flex mt-4" style={{ width: isXs ? '50%' : '80%', borderRadius: '0px', fontSize: '30px' }}>
                    Help and Support
                </Box>
                <img src={line} className='mb-3' style={{ borderRadius: '0px', fontSize: '30px' }} />
                <Box>
                    <Box>
                        <TextField
                            onChange={handleEmailChange}
                            value={email}
                            inputProps={{
                                type: "email",
                                pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$",
                                style: { height: '15px', fontSize: '16px' ,fontFamily: 'jost' }
                            }}
                            id="outlined-basic"
                            
                            variant="outlined"
                            placeholder='Email'
                            sx={{ width: isXs ? '40%' : '95%' , marginBottom: '0rem', marginTop: '1rem' }}
                        />
                    </Box>

                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { width: isXs ? '40%' : '95%', marginTop: '6%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField onChange={handleMobileChange} value={phone} type='number' id="outlined-basic" variant="outlined" placeholder='Phone Number' inputProps={{ style: { height: '15px', fontSize: '16px' ,fontFamily: 'jost' } }}/>
                    </Box>
                    {/* <Box sx={{ marginTop: '2%' }}>
                        <TextField onChange={handleEmailChange} id="outlined-basic" variant="outlined" placeholder='Phone Number' sx={{ width: isXs ? '40%' : '70%' }} />
                    </Box> */}
                </Box>
                <Box>
                    <TextField
                        sx={{ width: '95%', marginTop: '6%' }}
                        id="outlined-multiline-static"
                        multiline
                        InputProps={{style: { height: '10rem', fontSize: '16px' ,fontFamily: 'jost' }}}
                        rows={4}
                        
                        placeholder='Write a Message here'
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </Box>
                <Box>
                    <Box sx={{ marginTop: '6%', display: 'flex', justifyContent: 'start' }}>
                        <button onClick={support} style={{ backgroundColor: '#FFDB7E', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems:'center' ,padding: 2, width: isXs ? '20%' : '50%' , height: '3rem' }}>
                            <Box sx={{ fontSize: isXs ? '1.3 rem' : '5vw', color: '#866D2C' }}>Submit</Box>
                        </button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default HelpAndSupport
