import React, { useEffect, useState } from 'react';
import SampleImage from '../../../assets/SampleImage.svg';
import DashboardPost1 from '../../../assets/DashboardPost1.svg';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Rating, Select, Stack, Typography, useMediaQuery } from '@mui/material';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import line from '../../../assets/line.svg';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate, useParams } from 'react-router-dom';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherForReview } from '../../features/student/listingSlice';
// import Rating from '@mui/material/Rating';


const formStyle = {
    width: '14rem',
    backgroundColor: 'white',
}

const sxColor = {
    color: '#CB8B65'
}

const sxStyle = {
    display: 'inline',
    border: '1px solid #C7C7C7',
    fontSize: '12px',
    marginX: '1%',
}



const HomeClass = () => {
    const [itemData, setItemData] = useState(null);
    // const [sortCriteria, setSortCriteria] = useState({ field: '', order: '' });
    const [sortCriteria, setSortCriteria] = useState({ field: '', order: '' });


    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { itemId } = useParams();
    // console.log('aaa',itemId);

    const allData = useSelector((item) => item?.listing?.postData)
    // const selectedItem = allData?.find(item => item?.id === parseInt(itemId));

    // console.log('aaa',selectedItem);
    // const teacherDetails = Object.values(selectedItem?.teacherAllDetails);
    // const teacherUser = teacherDetails.user
    // console.log(selectedItem.id);
    // console.log(selectedItem.teacherAllData);

    const findItemData = () => {
        if (allData && itemId) {
            // const selectedItem = allData?.find(item => item?.id === parseInt(itemId));
            // console.log(selectedItem);
            setItemData(selectedPost);
        }
    };

    useEffect(() => {
        findItemData();
    }, [allData, itemId]);

    const selectedPost = useSelector((item) => item?.listing?.teacherSubscribeData)
    const teacherDetails = selectedPost?.teacherAllDetails

    const isNavigate = (i ,teacher_id) => {
        const selectedItem = teacherDetails?.find((item, index) => index === parseInt(i));
        // const selectedId = selectedItem.id
        // console.log('thisitem',selectedItem.id);
        dispatch(getTeacherForReview(selectedItem))
        navigate(`/dashboard/teacherreviews/${teacher_id}`)
    }

    const toNavigate = () => {
        navigate('/dashboard/enquirydetails')
    }
    const goBack = () => {
        navigate(-1);
    };

    const handleSortChange = (field, order) => {
        setSortCriteria({ field, order });
    };

    const sortedTeacherDetails = [...(teacherDetails || [])].sort((a, b) => {
        if (sortCriteria.field === 'cost') {
            const costA = a?.user?.teacher_profile?.conducted_class[0]?.cost || 0;
            const costB = b?.user?.teacher_profile?.conducted_class[0]?.cost || 0;
            return sortCriteria.order === 'asc' ? costA - costB : costB - costA;
        } else if (sortCriteria.field === 'experience') {
            const expA = a?.user?.teacher_profile?.experience || 0;
            const expB = b?.user?.teacher_profile?.experience || 0;
            return sortCriteria.order === 'asc' ? expA - expB : expB - expA;
        }
        return 0;
    });

    const randomColor = () => {
        // Generate a random hex color
        return '#' + Math.floor(Math.random()*16777215).toString(16);
      };

    return (
        <>
            <Box>
                <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                    <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                        <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                        <Box>
                            <Typography variant='h5'>{selectedPost?.classData?.class_name}</Typography>
                            <Box><img src={line} /></Box>
                        </Box>
                    </Box>
                    <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                        <button onClick={toNavigate} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>View Enquiry Details</button>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '35vh', width: '100%', textAlign: 'center' }}>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <FormControl sx={{ width: '40%', display: isXs ? 'none' : '' }}>
                            <InputLabel>Filters</InputLabel>
                            <Select label="filters">
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <FormControl sx={formStyle} size="small" >
                                            <InputLabel>Budget</InputLabel>
                                            <Select label="budget">
                                                <MenuItem>One</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={formStyle} size="small">
                                            <InputLabel>Language</InputLabel>
                                            <Select label="language">
                                                <MenuItem>Gender</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={formStyle} size="small">
                                            <InputLabel>Gender</InputLabel>
                                            <Select label="gender">
                                                <MenuItem>One</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={formStyle} size="small">
                                            <InputLabel>Location</InputLabel>
                                            <Select label="location">
                                                <MenuItem>One</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={formStyle} size="small">
                                            <InputLabel>Sort By</InputLabel>
                                            <Select label="sort">
                                                <MenuItem>One</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Select>
                        </FormControl>
                        <button style={{ border: '1px solid #CACACA', height: '3.5rem', color: '#0582D2', backgroundColor: 'white', width: '9rem', display: isXs ? 'none' : '' }}>Clear Filters</button>
                    </Box> */}

                 {teacherDetails?.length>1 ? <Box sx={{ display: isXs ? '' : 'none' }} >

<Box sx={{ display: 'flex', gap: 2, alignItems: 'center', }}>
    <Box sx={{ backgroundColor: '#F5F5F5', display: 'flex', justifyContent: 'space-around', width: '65%', padding: 1, marginLeft: '3%' }}>
        {/* <FormControl sx={formStyle} size="small">
            <InputLabel>Budget</InputLabel>
            <Select
                label="budget"
            >
                <MenuItem>One</MenuItem>
            </Select>
        </FormControl>

        <FormControl sx={formStyle} size="small">
            <InputLabel>Language</InputLabel>
            <Select
                label="language"
            >
                <MenuItem>Gender</MenuItem>
            </Select>
        </FormControl>

        <FormControl sx={formStyle} size="small">
            <InputLabel>Gender</InputLabel>
            <Select
                label="gender"
            >
                <MenuItem>One</MenuItem>
            </Select>
        </FormControl>

        <FormControl sx={formStyle} size="small">
            <InputLabel>Location</InputLabel>
            <Select
                label="location"
            >
                <MenuItem>One</MenuItem>
            </Select>
        </FormControl> */}

        {/* <FormControl sx={formStyle} size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
                label="sort"
            >
                <InputLabel>Price</InputLabel>
                <Select sx={{ width: '90%', height: '2.5rem' }}>
                    <MenuItem>Low to High</MenuItem>
                    <MenuItem>High to Low</MenuItem>
                </Select>
                <InputLabel>Experience</InputLabel>
                <Select sx={{ width: '90%', height: '2.5rem' }}>
                    <MenuItem>Low to High</MenuItem>
                    <MenuItem>High to Low</MenuItem>
                </Select>
            </Select>
        </FormControl> */}

<FormControl sx={formStyle} size="small">
                <InputLabel>Sort By</InputLabel>
                <Select
                    label="sort"
                    value={sortCriteria.field ? `${sortCriteria.field}-${sortCriteria.order}` : ''}
                    onChange={(e) => {
                        const [field, order] = e.target.value.split('-');
                        handleSortChange(field, order);
                    }}
                >
                    {/* <MenuItem value="">Select Filter</MenuItem> */}
                    <MenuItem value="cost-asc">Price: Low to High</MenuItem>
                    <MenuItem value="cost-desc">Price: High to Low</MenuItem>
                    <MenuItem value="experience-asc">Experience: Low to High</MenuItem>
                    <MenuItem value="experience-desc">Experience: High to Low</MenuItem>
                </Select>
            </FormControl>
    </Box>
    <Box>
        <button style={{ border: '1px solid #CACACA', height: '2.5rem', color: '#0582D2', backgroundColor: 'white', width: '10rem' }}>Clear Filters</button>
    </Box>
</Box>
</Box> : ' '}
                    

                    <Box>
                        {/* <Box sx={{ backgroundColor: '#F5F5F5', width: isXs ? '95%' : '95%', marginLeft: '2%', marginTop: '2%', padding: 2 }}>
                            <Box sx={{ margin: '0 auto', backgroundColor: 'white' }}>
                                <Grid container sx={{ marginLeft: '3%', width: '95%' }} >
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3} sx={{ marginTop: '5%' }}>
                                        <Box > <img src={SampleImage} style={{ width: isXs ? '65%' : '30%' }} /> </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ marginTop: '5%', textAlign: isXs ? 'left' : 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 3 }}>
                                            <Typography>Harshit Sharma</Typography>
                                            <Box display={'flex'} gap={1}>
                                                <Box><LaptopWindowsIcon /></Box>
                                                <Box sx={{ fontSize: '12px', marginTop: '1%' }}>Online Classes</Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box> <CallOutlinedIcon sx={sxColor} /> <Box sx={sxStyle}>3456787654</Box></Box>
                                            <Box> <MailOutlineOutlinedIcon sx={sxColor} /> <Box sx={sxStyle}>test@test.com</Box></Box>
                                            <Box> <AutoGraphOutlinedIcon sx={sxColor} /> <Box sx={sxStyle}>15 yrs of Exp</Box></Box>
                                            <Box> <FmdGoodOutlinedIcon sx={sxColor} /> <Box sx={sxStyle}>Jaipur, Rajasthan</Box></Box>
                                        </Box>
                                        <Typography variant='h5' sx={{ ...sxColor, marginTop: '5%' }}>₹500/hr</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3} sx={{ marginTop: '3%' }}>
                                        <button onClick={() => isNavigate()} style={{ textAlign: isXs ? 'end' : '', border: '1px solid #C7C7C7' }}>
                                            <Box>50 Reviews</Box>
                                        </button>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: isXs ? '2%' : '5%', textAlign: 'left' }}>
                                    <Box sx={{ fontSize: isXs ? '1vw' : '3vw', width: '95%' }}>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</Box>
                                </Box>
                            </Box>
                        </Box> */}

                        {sortedTeacherDetails?.length>0 ? sortedTeacherDetails?.map((item, index) => (
                            <Box key={index} sx={{ backgroundColor: '#F5F5F5', width: isXs ? '95%' : '95%', marginLeft: '2%', marginTop: '2%', padding: 2 }}>
                                <Box sx={{ margin: '0 auto', backgroundColor: 'white' }}>
                                    <Grid container sx={{ marginLeft: '3%', width: '95%' }}>
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Box sx={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: item?.user?.profile_image?.file_name ? 'transparent' : randomColor(),
                overflow: 'hidden',
              }}
                                            >
                                                {/* <img src={item?.user?.profile_image?.file_name} style={{ width: isXs ? '100%' : '100%' , objectFit:'cover' }} /> */}
                                                {item?.user?.profile_image?.file_name ? (
                  <img src={item?.user?.profile_image?.file_name} alt="profile-img" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                  <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>
                    {`${item?.user?.first_name?.charAt(0)?.toUpperCase()}`}
                  </Typography>
                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ marginTop: '5%', textAlign: isXs ? 'left' : 'center' }}>
                                            <Box sx={{ display: 'flex', gap: 3 , textTransform: 'capitalize' }}>
                                                <Typography>{item?.user?.first_name} {item?.user?.last_name}</Typography>   
                                            </Box>
                                            <Box>
                                                <Box><CallOutlinedIcon sx={sxColor} /><Box sx={sxStyle}>{item?.user?.mobile}</Box></Box>
                                                <Box><MailOutlineOutlinedIcon sx={sxColor} /><Box sx={sxStyle}>{item?.user?.email}</Box></Box>
                                                <Box><AutoGraphOutlinedIcon sx={sxColor} /><Box sx={sxStyle}>{item?.user?.teacher_profile?.experience} Years of Experience</Box></Box>
                                                <Box><FmdGoodOutlinedIcon sx={sxColor} /><Box sx={sxStyle}>({item?.user?.teacher_profile?.city?.city_name}, {item?.user?.teacher_profile?.state?.state_name})</Box></Box>
                                            </Box>
                                            <Typography variant='h5' sx={{ ...sxColor, marginTop: '5%' }}>₹{item?.user?.teacher_profile?.conducted_class[0]?.cost}/hr</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3} sx={{ marginTop: '3%' }}>
                                            <button onClick={() => isNavigate(index , item?.user_id)} style={{ textAlign: isXs ? 'end' : '', border: '1px solid #C7C7C7' }}>
                                            <Rating 
                                name="simple-controlled"
                                value={item?.average_rating}
                                size="small"
                              />
                              
                                                <Box sx={{marginLeft:'10px' , display:'flex' , justifyContent:'space-between'}}>
                                                 <Typography>Reviews</Typography>
                                                <Typography>{item?.reviews_count}</Typography>
                                                    </Box>

                                            </button>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: isXs ? '2%' : '5%', textAlign: 'left' }}>
                                        <Box sx={{ fontSize: item.isXs ? '0.5vw' : '1.5vw', width: '95%' }}>{item?.user?.teacher_profile?.experinceDesc}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        )): <p style={{marginTop:'100px'}}>No tutors connected at the moment, you'll be notified when someone connects!</p>}

                    </Box>


                </Stack>
            </Box>
        </>
    );
}

export default HomeClass;
