import { Box, Grid, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../pages/LandingPage/Footer";
import logo from "../../assets/LogoHeader.svg";
import { Link } from "react-router-dom";
import WhatAppMobile from "../pages/LandingPage/WhatAppMobile";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavBar from "../Navigation/NavBar";


export const Sitemap = () => {
  const [siteMapLinks, setSiteMapLinks] = useState([]);

  async function getSiteMapLinks() {
    try {
      const response = await axios.get(
        `https://app.kanhahometutions.com/api/v1/seo-pages`
      );
      var data = response.data.slugs;
      setSiteMapLinks(data);
    } catch (error) {
      console.error("Error fetching slugs:", error);
    }
  }
  useEffect(() => {
    getSiteMapLinks();
    window.scrollTo(0, 0); // Scrolls to the top of the page

  }, []);
  

  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');


  return (
    <>
                          <WhatAppMobile/>
                          <NavBar/>

      <div>
        <Box style={{ width: "100%" }}>
          <Box style={{ width: "100%" }}>
          <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Sitemap</div></div>
            <div class="grid-container">
              <Box
                sx={{
                  height: "auto",
                 
                  paddingLeft: isXs ? "6rem" : '2rem',
                  paddingTop:'3rem',
                  alignItems: "center",
                }}
              >
                <h3 style={{ marginBottom: isXs? "2rem":'1.5rem' }}>Pages</h3>

                <div
                  style={{
                    
                    display: "grid",
                    gridTemplateColumns: isXs ?"repeat(3, 1fr)": 'repeat(1, 1fr)',
                     gap: isXs?'1rem':'1rem',
                  }}
                >
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/">Home</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}}to="/signin-teacher">Login as teacher</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/student-signin">Login as Student</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/privacy-policy">Privacy Policy</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/refund-policy">Refund Policy</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/terms-conditions">Terms & Conditions</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/sitemap">Sitemap</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/post-teacher-job">Post Teacher Job</Link>

                </div>
              </Box>

              <Box
                sx={{
                  height: "auto",
                  marginBottom: isXs?'15rem':'4rem',
                  paddingLeft: isXs? "6rem":"2rem",
                  paddingTop:'2rem',
                  alignItems: "center",
                }}
              >
                <h3 style={{ marginBottom: isXs? "2rem":'1.5rem' }}>Find Tutor</h3>
                <Grid container spacing={2}>
                  {siteMapLinks
                    ? siteMapLinks.map((element) => (
                        <Grid item xs={12} sm={6} md={4} key={element.txt_slug}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "black",
                              textTransform: "capitalize",
                            }}
                            to={`/${element.txt_slug.toLowerCase().replaceAll(
                              " ",
                              "-"
                            )}`}
                          >
                            {element.txt_meta_title}
                          </Link>
                        </Grid>
                      ))
                    : console.log("data not found for site map")}
                </Grid>
              </Box>
            </div>
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
};
