import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";

import React, { useEffect, useState } from "react";
import img from "../../../assets/img.jpg";
import line2 from "../../../assets/line2.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  boardData,
  classData,
  dropDownListings,
  subjectData,
  teacherDegree,
  teacherLanguage,
} from "../../features/teacher/teacherListingSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { classConducted } from "../../features/teacher/teacherClassConductSlice";
// import { dropDownListings } from "../../features/student/listingSlice";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const backStyle = {
  backgroundColor: "#F3F3F3",
  border: "1px solid #DCDCDC",
  margin: "3%",
  borderLeft: "5px solid #CB8B65",
};
const titleStyle = {
  margin: "2%",
};
const contStyle = {
  marginLeft: "2%",
  marginBottom: "2%",
};

const FormTeacher = () => {
  const isXs = useMediaQuery("(min-width:600px)");
  const [isLanguage, setIsLanguage] = useState("");
  const [isDegree, setIsDegeree] = useState("");
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [classCheckbox, setClassCheckbox] = useState([]);
  const [className, setClassName] = useState("");
  const [languageIds, setLanguageIds] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [universityName, setUniversityName] = useState('');
  const [degreeStatus, setDegreeStatus] = useState(1);
  const [subjectIds, setSubjectIds] = useState([]);
  const [conductClassesIds, setConductClassesIds] = useState([]);
  const [taught, setTaught] = useState('');
  const [experinceDesc, setExperinceDesc] = useState('');
  const [cost, setCost] = useState('');
  const [experiance, setExperiance] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [overview, setOverview] = useState('');
  const [classPreferenceId, setClassPreferenceId] = useState('');


  const dispatch = useDispatch();
  const teacherProfile = () => {
    dispatch(teacherLanguage());
    dispatch(teacherDegree());
    dispatch(boardData());
    dispatch(classData());
    dispatch(dropDownListings());
  };

  useEffect(() => {
    teacherProfile();
  }, []);

  const languageData = useSelector(
    (item) => item?.teacherListing?.languageTeacher
  );


  
  const degreeData = useSelector((item) => item?.teacherListing?.degreeTeacher);
  const boardList = useSelector((item) => item?.teacherListing?.listBoard);
  const classList = useSelector((state) => state?.teacherListing?.listClass);
  const conductClass = useSelector((state) => state?.teacherListing?.dropDownData?.teacher_class_conduct_prefrence);
  const classPreference = useSelector((state) => state?.teacherListing?.dropDownData?.teacher_class_prefernce);


  const subjectList = useSelector(
    (state) => state?.teacherListing?.listSubject
  );



  // console.log('aaa', classList);

//   const handleLanguageChange = async (event, value) => {
//     const selectedLanguage = languageData?.find(
//       (item) => item?.languageName === value
//     );
//     setIsLanguage(selectedLanguage?.id);
//   };
  const handleDegreeChange = async (event, value) => {
    const selectedDegree = degreeData?.find(
      (item) => item?.degreeName === value
    );
    setIsDegeree(selectedDegree?.id);
    // console.log(selectedDegree?.id , 'isDegree');
  };
  const handleBoardChange = (value, isChecked) => {
    const newCheckboxStates = [...checkboxStates];
    const index = newCheckboxStates.indexOf(value);
    if (isChecked) {
      newCheckboxStates.push(value);
    } else if (!isChecked && index !== -1) {
      newCheckboxStates.splice(index, 1);
    } else if (checkboxStates.length <= 0) {
      toast.error("All compulsory field is required.");
    }
    setCheckboxStates(newCheckboxStates);
    // console.log(newCheckboxStates , 'checkboxStates');
  };


  const classHandleChange = (event, selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
    dispatch(subjectData({ classIds: selectedIds }));
    setClassIds(selectedIds)
  };

  const handleSubjectChange = (event, selectedOptions) => {
    const subjectIdsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
    setSubjectIds(subjectIdsGet);
    console.log(subjectIdsGet , 'subjectIdsGet');
  };

  const handleConductClassChange = (value, isChecked) => {
    // const conductClassIdsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
    // setConductClassesIds(conductClassIdsGet);
    // console.log(conductClassIdsGet , 'conductClassIdsGet');

    const newConductClassesIds = [...conductClassesIds];
    const index = newConductClassesIds.indexOf(value);
    if (isChecked) {
      newConductClassesIds.push(value);
    } else if (!isChecked && index !== -1) {
      newConductClassesIds.splice(index, 1);
    } else if (conductClassesIds.length <= 0) {
      toast.error("All compulsory field is required.");
    }
    setConductClassesIds(newConductClassesIds);
    console.log(newConductClassesIds , 'newConductClassesIds');
  };

  useEffect(() => {
    // console.log(languageIds , 'languageids');
    // console.log(classIds , 'languageids');
    // console.log(isDegree , 'isDegree');
    // console.log(subjectIds , 'isDegree');
    // console.log(conductClassesIds , 'conductClassesIds');
  }, [languageIds,classIds,isDegree,subjectIds,conductClassesIds]);

  const handleLanguageChange = (event, selectedOptions) => {
    const languageidsGet = selectedOptions.map((option) => option.id); // Assuming 'id' is the identifier for classId
    setLanguageIds(languageidsGet);
  };

  const handleRadioChange = (event) => {
    setDegreeStatus(event.target.value);
    console.log(degreeStatus , 'degreeStatus');
  };

  const handleSelectTaught = (event) => {
    const value = event.target.value;
    setTaught(value);
    console.log(value, 'selected value taught'); // 1 for Yes, 0 for No
  };

  const handleSelectExperiance = (event) => {
    const value = event.target.value;
    setExperiance(value);
    console.log(value, 'selected value EXper'); // 1 for Yes, 0 for No
  };

  const handleClassPrefeChange = (event) => {
    const value = event.target.value;
    setClassPreferenceId(value);
    console.log(value, 'selected value classPeferance'); // 1 for Yes, 0 for No
  };

  

  // const classHandleChange = async (event, value) => {
  //     const selectedClass = classList?.find((subject) => subject?.className === value)

  //     // console.log(selectedClass, 'selectedClass');
  //     const classId = selectedClass?.id
  //     // console.log(classId);
  //     setClassName(classId);
  //     // console.log(classId);

  //     // dispatch(storeClassId(selectedClass));
  // }

//   const handleClassChange = (id, isChecked) => {
//     console.log("zzzz", id, isChecked);
//     const checkboxClass = [...classCheckbox];
//     const index = checkboxClass.indexOf(id);
//     if (isChecked) {
//       checkboxClass.push(id);
//     } else if (!isChecked && index !== -1) {
//       checkboxClass.splice(index, 1);
//     } else if (classCheckbox.length <= 0) {
//       toast.error("Input field is required.");
//     }
//     setClassCheckbox(checkboxClass);
//   };

  // const handleSubjectChange = (id, isChecked) => {
  //   console.log("zzzz", id, isChecked);
  //   const checkboxClass = [...classCheckbox];
  //   const index = checkboxClass.indexOf(id);
  //   if (isChecked) {
  //     checkboxClass.push(id);
  //   } else if (!isChecked && index !== -1) {
  //     checkboxClass.splice(index, 1);
  //   } else if (classCheckbox.length <= 0) {
  //     toast.error("Input field is required.");
  //   }
  //   setClassCheckbox(checkboxClass);
  // };

  const getSubjectIdListSelectedClassId = (classId, subNameList) => {
    const sIdList = [];
    for (let a = 0; a < subjectList.length; a++) {
      for (let q = 0; q < subNameList.length; q++) {
        if (subjectList[a].id == subNameList[q]) {
          for (let b = 0; b < subjectList[a].duplicate_subject_name_ids.length; b++) {
            if (subjectList[a].duplicate_subject_name_ids[b].class_id == classId) {
              sIdList.push(subjectList[a].duplicate_subject_name_ids[b].subject_id);
              break;
            }
          }
        }
      }
    }
    return sIdList;
  };

  const navigate = useNavigate();
  const isNavigate = () => {
    // if (languageIds?.length<=0 || classIds?.length<=0 || subjectIds?.length<=0 || checkboxStates?.length<=0 || conductClassesIds?.length<=0 ,
    //    overview == ' ' || isDegree == ' ' || universityName == ' ' || degreeStatus == ' ' , experiance == ' ' , taught==' ') {
    //     toast.error("All fields is required.");
    //   return
    // }
   console.log(taught , 'taught');
  if (overview.length < 10) {
    toast.error('All compulsory field is required.');
    return;
}
    if (
      !languageIds?.length || 
      !classIds?.length || 
      !subjectIds?.length || 
      !checkboxStates?.length || 
      !conductClassesIds?.length ||
      overview == '' ||
      isDegree == '' ||
      universityName == '' ||
      experiance == '' ||
      cost == '' ||
      classPreferenceId == '' 
      // taught == ''
    ) {
      toast.error("All compulsory field is required.");
      return;
    }
    
  const userId = JSON.parse(localStorage.getItem('user'));

    const payload = {
        schoolName: schoolName,
        overview: overview,
        userId: userId?.id, 
        degreeId: isDegree, 
        universityName: universityName, 
        degreeStatus: degreeStatus, 
        experiance: experiance,
        taught: taught,
        experinceDesc: experinceDesc,
        language: JSON.stringify(languageIds?.map((langId) => ({
          langaugeId: langId.toString(),
        }))),
        class: JSON.stringify(classIds?.map((classId) => ({
          classId,
          cost: cost, // You can add a cost input field for each class if needed
          classPreferenceId: classPreferenceId, // Replace with the default class preference ID
          // subject: subjectIds?.map((subjectId) => ({
          //   subjectId,
          // })),
          subject: JSON.stringify(getSubjectIdListSelectedClassId(classId, subjectIds).map((subjectId) => ({
            subjectId,
          }))),
    
          board: JSON.stringify(checkboxStates?.map((boardId) => ({
            boardId,
          }))),
          classConductedPreference: JSON.stringify(conductClassesIds?.map((classConductedId) => ({
            classConductedId,
          }))), // Replace with the default class conducted preference ID
        }))),
      };
     
     dispatch(classConducted(payload)).then((response)=>{
       if(response?.payload?.status == 200){
        toast.success(response?.payload?.message);
        navigate('/dashboard/dashboard-home');
       }
     })

      // console.log(payload , 'payload');
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          minHeight: "full",
        }}
      >
        <Box className="d-flex flex-row justify-content-center align-items-center">
          <Box sx={{maxWidth: isXs ? "1330px":''}} className="container border-0">
            <Box className="row justify-content-center">
              <Box>
                <Box
                  sx={{
                    marginTop: isXs ? "2%" : "20%",
                    backgroundColor: "#ffffff",
                    marginBottom: isXs ? "2%" : "20%",
                  }}
                >
                  <Box sx={{ backgroundColor: "#A6653F", padding: "2%" }}>
                    <Box>
                      <Typography
                        sx={{ color: "white", fontSize: isXs ? "2vw" : "5vw" }}
                      >
                        Create your Tutor Profile*
                      </Typography>
                      <img src={line2} className="mb-4" />
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Select languages that you speak*
                    </Typography>
                    <Box sx={contStyle}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          {/* <Autocomplete
                            fullWidth
                            id="free-solo-demo"
                            freeSolo
                            onChange={handleLanguageChange}
                            options={languageData?.map(
                              (item) => item?.languageName
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Select" />
                            )}
                            disableClearable
                          /> */}


                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={languageData ?? []}
                            disableCloseOnSelect
                            onChange={handleLanguageChange}
                            getOptionLabel={(option) => option?.languageName}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                //   onChange={(event) =>
                                //     handleLanguageChange(
                                //       event,
                                //       event.target.checked,
                                //       option
                                //     )
                                //   }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  checked={selected}
                                />
                                {option?.languageName}
                              </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Language"
                                placeholder="Select Language"
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography sx={{ color: "#A6653F" }}>
                            Quick Select
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <FormGroup>
                              <FormControlLabel
                                value="english"
                                control={<Checkbox />}
                                label="English"
                                labelPlacement="end"
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                value="hindi"
                                control={<Checkbox />}
                                label="Hindi"
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </Box>
                        </Grid> */}
                      </Grid>
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Add your most recent Degree*
                    </Typography>
                    <Box sx={contStyle}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            fullWidth
                            id="free-solo-demo"
                            freeSolo
                            onChange={handleDegreeChange}
                            options={degreeData?.map(
                              (item) => item?.degreeName
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Select" />
                            )}
                            disableClearable
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            label="Enter University Name"
                            variant="outlined"
                            onChange={(e)=>setUniversityName(e.target.value)}
                            value={universityName}
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={degreeStatus}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Completed"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Pursuing"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Which Class and Subject do you want to Teach?
                    </Typography>
                    <Box sx={contStyle}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={classList ?? []}
                            disableCloseOnSelect
                            onChange={classHandleChange}
                            getOptionLabel={(option) => option?.className}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                //   onChange={(event) =>
                                //     handleClassChange(
                                //       event,
                                //       event.target.checked,
                                //       option
                                //     )
                                //   }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  checked={selected}
                                />
                                {option?.className}
                              </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Classes"
                                placeholder="Select Classes"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={subjectList ?? []}
                            disableCloseOnSelect
                            onChange={handleSubjectChange}
                            getOptionLabel={(option) => option?.subject_name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  // onChange={(event) =>
                                  //   handleSubjectChange(
                                  //     event,
                                  //     event?.target?.checked,
                                  //     option
                                  //   )
                                  // }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  checked={selected}
                                />
                                {option?.subject_name}
                              </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Subjects"
                                placeholder="Select Subjects"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Which school board do you teach for?
                    </Typography>
                    <Box sx={contStyle}>
                      {boardList?.map((item, index) => (
                        <FormGroup key={index} aria-label="position">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(item) =>
                                  handleBoardChange(
                                    item.target.value,
                                    item.target.checked
                                  )
                                }
                              />
                            }
                            label={item?.boardName}
                            value={item?.id}
                            labelPlacement="end"
                          />
                        </FormGroup>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      How would you like to conduct your classes?
                    </Typography>
                    <Box sx={contStyle}>
                      {/* <FormGroup>
                        <FormControlLabel
                          value="online"
                          control={<Checkbox />}
                          label="I can teach Live Online using Video Call"
                          labelPlacement="end"
                        />
                      </FormGroup> */}
                      {conductClass?.map((item, index) => (
                        <FormGroup key={index} aria-label="position">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(item) =>
                                  handleConductClassChange(
                                    item.target.value,
                                    item.target.checked
                                  )
                                }
                              />
                            }
                            label={item?.name}
                            value={item?.value}
                            labelPlacement="end"
                          />
                        </FormGroup>
                      ))}
                      {/* <FormGroup>
                        <FormControlLabel
                          value="student"
                          control={<Checkbox />}
                          label="I can teach at the student's home"
                          labelPlacement="end"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          value="teacher"
                          control={<Checkbox />}
                          label="I can teach at my home"
                          labelPlacement="end"
                        />
                      </FormGroup> */}
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Have you ever taught in any School or College? *
                    </Typography>
                    <Box sx={contStyle}>
                      <FormControl sx={{ width: "50%" }}>
                        <Select value={taught} onChange={handleSelectTaught}>
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    { taught ?  <Box sx={contStyle}>


                      
                    <TextField
                      placeholder="School/College Name"
                      // fullWidth
                      rows={4}
                      onChange={(e)=>setSchoolName(e.target.value)}
                      sx={{ marginBottom:'20px' , width:'50%'}}
                    />
                      <TextField
                        onChange={(e)=>setExperinceDesc(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Describe your experience in School or College*"
                      />
                    </Box> : ' '
                 }
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      Do you teach private or group classes? *
                    </Typography>
                    <Box sx={contStyle}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={classPreferenceId}
                          onChange={handleClassPrefeChange}
                        >
                          
                          {classPreference?.map((value, index) => (
  <FormControlLabel
    key={index} 
    value={value?.value}
    control={<Radio />}
    label={value?.name}
  />
))}
                          
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      What is your total experience (in years)? *
                    </Typography>
                    <FormControl
                      sx={{
                        width: "50%",
                        marginLeft: "2%",
                        marginBottom: "2%",
                      }}
                    >
                      <Select value={experiance} onChange={handleSelectExperiance}>
        
        {Array.from({ length: 21 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Select>
                    </FormControl>
                  </Box>

                  
                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      About/Overview
                    </Typography>
                    <TextField
                        onChange={(e)=>setOverview(e.target.value)}
                        value={overview}
                        fullWidth
                        sx={{padding:"10px 20px"}}
                        multiline
                        rows={4}
                        placeholder="About/Overview"
                      />
                    {/* <Typography sx={{ marginLeft: "2%", marginBottom: "2%" }}>
                      Typically tutors charge ₹400 - ₹600 per hour for Class 11
                      Tuition Classes
                    </Typography> */}
                     <Typography variant="caption" sx={{display:"flex",flexDirection:"row-reverse", margin: "0 40px", color: "#8e8e8e " }}   >
                        min. 250 character required.
                    </Typography>
                    <Box sx={{ marginTop: isXs ? '3%' : '8%', }}    p={2}>
                        <Box sx={{ color: '#CB8B65', marginBottom: '10px' }}>Need help with this? Here is a sample</Box>
                        <Box>I am an experienced, qualified teacher and tutor with over 15 years of experience in teaching Maths and Chemistry,
                            across different boards including CBSE, ICSE, IGCSE and State Board. Passionate about solving mathematical
                            problems and chemical equations, over the years I have helped thousands of students overcome their fear of Maths
                            and Chemistry. So far, I have worked as a Teacher with Presidency School (Bangalore), DPS (Bhagalpur) and
                            Kendriya Vidyalaya (Nagpur).</Box>
                    </Box>
                  </Box>

                  <Box sx={backStyle}>
                    <Typography sx={titleStyle}>
                      How do you charge? *
                    </Typography>
                    <TextField
                    type="number"
                      placeholder="  ₹ Enter Price (Per Hour)"
                      sx={{ marginLeft: "2%" , marginBottom: "2%"}}
                      onChange={(e)=>setCost(e.target.value)}
                      value={cost}
                    />
                    {/* <Typography sx={{ marginLeft: "2%", marginBottom: "2%" }}>
                      Typically tutors charge ₹400 - ₹600 per hour for Class 11
                      Tuition Classes
                    </Typography> */}
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography color={"#A6653F"}>Note:</Typography>
                    <Typography>
                      Please complete all mandatory fields before proceeding to
                      the next page.
                    </Typography>
                  </Box>

                  <Box>
                    <Box
                      className="py-4 d-flex justify-content-center px-5"
                      style={{ width: "100%" }}
                    >
                      <button
                        onClick={() => isNavigate()}
                        className="btn btn-primary py-2 w-100 border-0"
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#FFDB7E",
                          color: "#866D2C",
                          fontWeight: "600",
                          borderRadius: "0",
                          borderColor: "#CB8B65",
                        }}
                      >
                        Save
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FormTeacher;
