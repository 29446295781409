import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';  
import IconButton from '@mui/material/IconButton'; 
import { Dialog, TextField } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel,} from '@mui/material';
import axiosInstanceSM from "../../Utils/axiosSm";
import { classListV2 } from "../features/admin/accountPostSlice";
import { useDispatch } from "react-redux";
import { DialogActions, DialogContent, DialogTitle,  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";



// Custom switch component with styling
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
    '& .MuiSwitch-thumb': {
      color: theme.palette.action.disabled,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

const columns = [
  { id: "id", label: "Sr.No" },
  { id: "BoardName", label: "Board Name", minWidth: "160px" },
  { id: "Classes", label: "Classes", minWidth: "160px" },
  // { id: "Status", label: "Status", minWidth: "200px" },
  { id: "Action", label: "Action", minWidth: "200px" },
];

function ClassesStudymaterial() {
  const dispatch = useDispatch();
  const [calssData, setClassData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [boardName, setBoardName] = useState('');
  const [className, setClassName] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [openNew , setOpenNew] = useState()
  const [editId, setEditId] = useState(null); 
  const [editBoardId, setEditBoardId] = useState(''); 
  const [openDelete, setOpenDelete] = useState('');
  const [deleteId, setDeleteId] = useState('')


  const handleClose = () => {
    setOpenNew(false);  
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleChange = (event) => setSelectedValue(event.target.value);

  const [boardId, setBoardId] = useState('');

  const handleSubmitClass = async (e) => { 
    e.preventDefault();
    setIsDialogOpen(false)
    const formdata = new FormData();
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;
    formdata.append("className", className);
    formdata.append("token", token);
    formdata.append("boardId", boardId); 
    
    try {
      const response = await axiosInstanceSM.post("api/sm/v1/class", formdata);
      if (response.data.success == true) {
        toast.success(response.data.message)
        fetchDataClass()
      } else {
        toast.success(response.data.message)
      }
    } catch (error) {
      console.error("Error submitting Class:", error);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      const d = new Date();
      let text = d.toLocaleDateString();
      let token = `vkSoft1234${text}`; 
      const payload = {
        token: token,
      };
      try {
        const response = await axiosInstanceSM.post("api/sm/v1/boards", payload);
        setData(response.data.data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const fetchDataClass = async () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`; 
    const payload = {
      token: token,
      paginate :'1'
    };
    try {
      const response = await axiosInstanceSM.post("api/sm/v1/classes", payload);
      setClassData(response.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchDataClass();
  }, []);


  const classList = () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;

    const payload = {
      token: token,
      paginate: "1",
    };
    
    dispatch(classListV2(payload));
  };

  useEffect(() => {
    classList();
  }, []);

  const handleEditNew = (id,boardId) => {
    const item = calssData.find((i) => i.classId === id);
          setOpenNew(true); 
    if (item) {
      setClassName(item.className);
      setSelectedValue(item.board.boradName);
      setEditId(id); 
      setEditBoardId(boardId);  
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formDataNew = new FormData();
    formDataNew.append("token", "abcd123");
    formDataNew.append("_method", "PUT");
    formDataNew.append("className", className);
    formDataNew.append("boardId", boardId);
    try {
      const response = await axiosInstanceSM.post(`api/sm/v1/class/${editId}`, formDataNew);
      setClassData(prevData =>
        prevData.map(item => {
          if (item.classId === editId) {
            item.className = className;  
            item.boardName = selectedValue;
          }
          return item;
        })
      );
      if (response.data.success == true) {
        toast.success(response.data.message)
        fetchDataClass()
        setOpenNew(false);
      } 
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Something went wrong!');
      }    }
  };
 

  const deleteRow = async () => {
    if (!deleteId) return; 
  
    setOpenDelete(false);

    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;
    const payload = {
      token: token,
      _method: 'DELETE',
    };
      
    try {
      const response = await axiosInstanceSM.post(`api/sm/v1/class/${deleteId}`, payload );
      if (response.data.success = true) {
        setClassData((prevData) => prevData.filter((row) => row.id !== deleteId));
         toast.success(response.data.message);
         fetchDataClass()
      } 
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Something went wrong!');
      }
    }
  };


  return (
    <>
      <div style={{
        width: "100%",
        height: "4rem",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        marginTop: "0rem",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        justifyContent: "space-between",
        boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
      }}>
        <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
          Classes
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup>
            <Form.Control
              placeholder="Search...."
              aria-label="Recipient's userBoardName"
              aria-describedby="basic-addon2"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
              <img src={search} alt="" />
            </Button>
          </InputGroup>
        </div>
      </div>
      
      <div style={{ marginLeft: "2rem", paddingTop: "30px", paddingBottom: "10px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontSize: "1.5rem" }}>Classes List</div>
          <div 
            onClick={openDialog} 
            style={{
              marginRight: '2rem', 
              color: 'white', 
              borderRadius: '5px', 
              backgroundColor: '#CB8B65', 
              padding: '6px 10px', 
              cursor: 'pointer'
            }}
          >
            Add New
          </div>
        </div>
      </div>

      {/* Backdrop for the modal */}
      {isDialogOpen && (
        <div 
          id="backdrop" 
          style={{
            position: "fixed", top: "0", left: "0", right: "0", bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "999", 
            display: "flex", justifyContent: "center", alignItems: "center",
          }}
        >
          <div 
            style={{
              position: "relative", backgroundColor: "white", padding: "20px", borderRadius: "8px", width: "400px",
            }}
          >
            <button 
              onClick={closeDialog} 
              style={{
                position: "absolute", top: "10px", right: "10px", background: "none", 
                border: "none", fontSize: "20px", cursor: "pointer"
              }}
            >
              &times;
            </button>
            <h3 style={{marginBottom:'15px'}}>Add New Classes</h3>
            <form onSubmit={handleSubmitClass}>
              <div>
                <FormControl fullWidth>
                  <InputLabel style={{margintop:'15px'}} id="dropdown-label">Board Name</InputLabel>
                  <Select
                  required
                    labelId="dropdown-label"
                    value={selectedValue}
                    onChange={handleChange}
                    label="Board Name"
                  >
                  {Array.isArray(data) && data.map((item, index) => (
  <MenuItem key={index} value={item.boardId} onClick={() => setBoardId(item.boardId)}>
{item.boradName}  </MenuItem>
))}
                  </Select>
                </FormControl>

                <TextField
                  label="Class Name"
                  placeholder="Enter Class Name"
                  fullWidth
                  required
                  onChange={(e) => setClassName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                />
              </div>
              <DialogActions >
              <button 
                type="submit" 
                style={{
                  backgroundColor: "#CB8B65", 
                  color: "white", 
                  padding: "7px 15px", 
                  borderRadius: "5px"
                }}
              >
                Submit
              </button>
              </DialogActions>
            </form>
          </div>
        </div>
      )} 
      <Dialog open={openNew} onClose={handleClose}>
  <DialogTitle >Edit Class</DialogTitle>
  <DialogContent>
  
  <FormControl style={{paddingTop:'10px'}} fullWidth>
                  <InputLabel style={{paddingTop:'15px'}}  id="dropdown-label">Board Name</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    defaultValue={editBoardId}
                    onChange={handleChange}  
                 Select label="Board Name"
                  >
                  {Array.isArray(data) && data.map((item, index) => (
  <MenuItem key={index} value={item.boardId} onClick={() => setBoardId(item.boardId)}>
{item.boradName}  </MenuItem>
))}
                  </Select>
                </FormControl>
    <TextField
      label="Class Name"
      placeholder="Enter Class Name"
      fullWidth
      value={className} 
      onChange={(e) => setClassName(e.target.value)} 
      variant="outlined"
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <button
      onClick={handleClose}
      style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}
    >
      Cancel
    </button>
    <button
      onClick={handleEditSubmit}
      style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}
    >
      Save
    </button>
  </DialogActions>
</Dialog>


<Dialog  open={openDelete} onClose={handleCloseDelete}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px'  }}>
          <CloseIcon style={{ color: 'red', fontSize: '60px', padding:'10px', marginBottom: '20px', borderRadius:'50%', border:'2px solid red'   }} />
          <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
            Are you sure ?
          </div>
          <div style={{textAlign: 'center',width:'320px' , marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button 
              onClick={handleCloseDelete} 
              style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width:'100px', border:'0px' }}
            >
              Cancel
            </button>
            <button 
              onClick={deleteRow} 
              style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width:'100px', border:"0px"}}
            >
              Yes
            </button>
          </DialogActions>
        </div>
      </Dialog>


      <Paper sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth", marginLeft: "2rem" }}>
        <TableContainer sx={{ maxHeight: 360 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#CB8B65",
                      fontWeight: "500",
                      color: "#ffffff",
                      fontSize: "1.1rem",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(calssData) && calssData.map((items) => (
                <TableRow hover role="checkbox" key={items.id}>
                  <TableCell>{items.classId}</TableCell>
                  <TableCell>{items.board.boradName}</TableCell>
                  <TableCell>{items.className}</TableCell>
                  <TableCell> 
                  <IconButton onClick={() => handleEditNew(items.classId, items.board.boardId)} color="warning">
  <EditIcon />
</IconButton>
                    <IconButton onClick={() => { setDeleteId(items.classId); setOpenDelete(true); }} color="warning">
          <DeleteIcon />
        </IconButton>
        </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </>
  );
}

export default ClassesStudymaterial;
