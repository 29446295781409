import {
    createSlice,
    createAsyncThunk,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";
import { useSelector } from "react-redux";
import axios from "axios";

export const findSubject = createAsyncThunk(
    "classListing/subject",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("classListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const userState = createAsyncThunk(
    "state/location",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("states", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const userCity = createAsyncThunk(
    "city/location",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("cities", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const studentBoardListing = createAsyncThunk(
    "boardListing/subject",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("boardListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const studentCreatePost = createAsyncThunk(
    "createpost/subject",
    async (data, { rejectedWithValue }) => {
        try {
            console.log('create',data)
            const response = await axiosInstance.post("studentCreatePost", data);
            console.log('create data',response)
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const dropDownListings = createAsyncThunk(
    "preferenceListing/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("dropDownListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const studentFeedback = createAsyncThunk(
    "review&FeedbackStudent/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("studentGetReview", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const teacherFeedback = createAsyncThunk(
    "review&FeedbackTeacher/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherGetReview", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const studentProfileDetails = createAsyncThunk(
    "studentDetails/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("studentDetails", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const accountClose = createAsyncThunk(
    "accountClose/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("closeAccount", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const accountLogout = createAsyncThunk(
    "accountLogout/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("logout", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const allPostData = createAsyncThunk(
    "allPostData/student",
    async (data, { rejectedWithValue }) => {
        try {
            // console.log('data',data);
            const response = await axiosInstance.post("studentGetPost", data);
            // console.log('response',response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const closePostList = createAsyncThunk(
    "closePostList/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("studentClosePost", data);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const createReview = createAsyncThunk(
    "createReview/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("studentCreateReview", data);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const profileUpdate = createAsyncThunk(
    "studentProfileUpdate/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("studentProfileUpdate", data);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const helpAndSupport = createAsyncThunk(
    "helpAndSupport/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("supportTicketCreate", data);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const notificationStudent = createAsyncThunk(
    "notifications/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("notificationGet", data);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const getPinCodeStatus = createAsyncThunk(
    "pinCode/location",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${data}`);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);


const user = JSON.parse(localStorage.getItem('user'))
const idUser = user?.id
// console.log(user);


const initialState = {
    classData: [],
    subjectData: [],
    stateData: [],
    cityData: [],
    boardData: [],
    dropDownData: [],
    postPayload : {
        userId :idUser,
        classId:'',
        boardId:'',
        classPreferenceId:'',
        classDayTypeId:'',
        classStartPlanTypeId:'',
        genderPreferenceId:'',
        cost:'',
        addressLineOne:'',
        addressLineTwo:'',
        countryId:101,
        stateId:'',
        cityId:'',
        pincode:'',
        subjectIds:[],
        classTypeIds:[],
    },
    studentGetFeedback:[],
    teacherGetFeedback:[],
    profileInfoDetails:[],
    accountCloseStudent:[],
    postData:[],
    closePost:[],
    newReview:[],
    helpSupport:[],
    teacherSubscribeData:[],
    profileUpdateStudent:[],
    notification:[],
    teacherForReview:[],
    classSelect:{},
    locationSelect:'',
    boardSelect:'',
    subjectSelectIds:[],
    classTypeSelect:{},
    logout:'',
    loading: false,
    error: null,

};

export const listingSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        storeMobile: (state, { payload }) => {
            state.studentMobile = payload;
        },
        // storeClassId: (state, action) => {
        //     // console.log(action.payload , 'action.payload.subjects');
        //     state.postPayload.classId = action?.payload?.id;
        //     // alert('11111')
        //     state.subjectData = action?.payload?.subjects;
        //     state.postPayload.subjectIds = action?.payload?.subjects;
        //     state.classSelect = action?.payload
        //     // console.log('aqqq',action.payload);
        // },
        // storeLocation: (state, action) => {
        //     // console.log(action.payload);
        //     state.locationSelect = action?.payload
        //     state.postPayload.stateId = action.payload.stateId?.id;
        //     state.postPayload.cityId = action.payload.cityId?.id;
        //     state.postPayload.addressLineOne = action.payload.addressLineOne;
        //     state.postPayload.addressLineTwo = action.payload.addressLineTwo;
        //     state.postPayload.pincode = action.payload.pincode;
        // },
        // storeBoardListing : (state, action) => {
        //     // console.log(action.payload , 'action.payload');
        //     state.boardSelect = action.payload
        //     state.postPayload.boardId = action.payload?.id;
        // },
        // storeSubjects : (state, action) => {
        //     // console.log('aaaaa',action.payload);
        //     state.postPayload.subjectIds = action.payload.subjectIds
        //     state.subjectSelectIds = action.payload.subjectIds

        // },
        // storePreference : (state, action) => {
        //     // console.log('in slice', action.payload);
        //     state.postPayload.classPreferenceId = action.payload.classPreferenceId
        // },
        // storeDayType : (state, action) => {
        //     state.postPayload.classDayTypeId = action.payload.classDayTypeId
        // },
        // storeStartPlan : (state, action) => {
        //     state.postPayload.classStartPlanTypeId = action.payload.classStartPlanTypeId
        // },
        // storeClassType : (state, action) => {
        //     console.log(action.payload , 'djsadjsadjsdasdjk');
        //     // state.postPayload.classTypeIds = action.payload;
        //     state.classTypeSelect = action.payload
        // },
        // storeFee : (state, action) => {
        //     console.log(action.payload.cost , 'dsadsadsadsad');
        //     // state.postPayload.cost = action.payload.cost
        //     state.postPayload.cost = action.payload;

        // },
        // storeGender : (state, action) => {
        //     state.postPayload.genderPreferenceId = action?.payload?.genderPreferenceId;
        // },
        storeClassId: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                classId: action.payload.id,
                subjectIds: action.payload.subjects,
            };
            state.subjectData = action.payload.subjects;
            state.classSelect = action.payload;
        },
        storeLocation: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                stateId: action.payload.stateId?.id,
                cityId: action.payload.cityId?.id,
                addressLineOne: action.payload.addressLineOne,
                addressLineTwo: action.payload.addressLineTwo,
                pincode: action.payload.pincode,
            };
            state.locationSelect = action.payload;
        },
        storeBoardListing: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                boardId: action.payload.id,
            };
            state.boardSelect = action.payload;
        },
        storeSubjects: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                subjectIds: action.payload.subjectIds,
            };
            state.subjectSelectIds = action.payload.subjectIds;
        },
        storePreference: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                classPreferenceId: action.payload.classPreferenceId,
            };
        },
        storeDayType: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                classDayTypeId: action.payload.classDayTypeId,
            };
        },
        storeStartPlan: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                classStartPlanTypeId: action.payload.classStartPlanTypeId,
            };
        },
        storeClassType: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                classTypeIds: action?.payload?.classTypeIds,
            };
            state.classTypeSelect = action.payload;
        },
        storeFee: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                cost: action.payload,
            };
        },
        storeGender: (state, action) => {
            state.postPayload = {
                ...state.postPayload,
                genderPreferenceId: action.payload.genderPreferenceId,
            };
        },
        updateSubjectData: (state, action) => {
            state.subjectData = action.payload;
        },
        teacherSubscribe: (state, action) => {
            state.teacherSubscribeData = action.payload;
        },
        getTeacherForReview: (state, action) => {
            // console.log('actionitem', action.payload);
            state.teacherForReview = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(findSubject.pending, (state) => {
                state.loading = true;
            })
            .addCase(findSubject.fulfilled, (state, action) => {
                state.loading = false;
                state.classData = action.payload.data;
                state.error = null;
                state.studentData = {
                    ...state.studentData,
                    classId: action.payload.data.classId,
                };
            })
            .addCase(findSubject.rejected, (state, action) => {
                state.loading = false;
                state.classData = null;
                state.error = action.payload;
            })
            .addCase(userState.pending, (state) => {
                state.loading = true;
            })
            .addCase(userState.fulfilled, (state, action) => {
                state.loading = false;
                state.stateData = action.payload.data;
                state.error = null;
            })
            .addCase(userState.rejected, (state, action) => {
                state.loading = false;
                state.stateData = null;
                state.error = action.payload;
            })
            .addCase(userCity.pending, (state) => {
                state.loading = true;
            })
            .addCase(userCity.fulfilled, (state, action) => {
                state.loading = false;
                state.cityData = action.payload.data;
                state.error = null;
            })
            .addCase(userCity.rejected, (state, action) => {
                state.loading = false;
                state.cityData = null;
                state.error = action.payload;
            })
            .addCase(studentBoardListing.pending, (state) => {
                state.loading = true;
            })
            .addCase(studentBoardListing.fulfilled, (state, action) => {
                state.loading = false;
                state.boardData = action.payload.data;
                state.error = null;
            })
            .addCase(studentBoardListing.rejected, (state, action) => {
                state.loading = false;
                state.boardData = null;
                state.error = action.payload;
            })
            .addCase(studentCreatePost.pending, (state) => {
                state.loading = true;
            })
            .addCase(studentCreatePost.fulfilled, (state, action) => {
                state.loading = false;
                console.log('this create post',action.payload);
                state.postPayload = action.payload.data;
                state.error = null;
            })
            .addCase(studentCreatePost.rejected, (state, action) => {
                state.loading = false;
                state.postPayload = null;
                state.error = action.payload;
            })
            .addCase(dropDownListings.pending, (state) => {
                state.loading = true;
            })
            .addCase(dropDownListings.fulfilled, (state, action) => {
                state.loading = false;
                state.dropDownData = action.payload.data;
                state.error = null;
            })
            .addCase(dropDownListings.rejected, (state, action) => {
                state.loading = false;
                state.dropDownData = null;
                state.error = action.payload;
            })
            .addCase(studentFeedback.pending, (state) => {
                state.loading = true;
            })
            .addCase(studentFeedback.fulfilled, (state, action) => {
                state.loading = false;
                state.studentGetFeedback = action.payload.data;
                state.error = null;
            })
            .addCase(studentFeedback.rejected, (state, action) => {
                state.loading = false;
                state.studentGetFeedback = null;
                state.error = action.payload;
            })
            .addCase(teacherFeedback.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherFeedback.fulfilled, (state, action) => {
                state.loading = false;
                state.teacherGetFeedback = action.payload.data;
                state.error = null;
            })
            .addCase(teacherFeedback.rejected, (state, action) => {
                state.loading = false;
                state.teacherGetFeedback = null;
                state.error = action.payload;
            })
            .addCase(studentProfileDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(studentProfileDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.profileInfoDetails = action.payload.data;
                state.error = null;
            })
            .addCase(studentProfileDetails.rejected, (state, action) => {
                state.loading = false;
                state.profileInfoDetails = null;
                state.error = action.payload;
            })
            .addCase(accountClose.pending, (state) => {
                state.loading = true;
            })
            .addCase(accountClose.fulfilled, (state, action) => {
                state.loading = false;
                state.accountCloseStudent = action.payload.data;
                state.error = null;
            })
            .addCase(accountClose.rejected, (state, action) => {
                state.loading = false;
                state.accountCloseStudent = null;
                state.error = action.payload;
            })
            .addCase(accountLogout.pending, (state) => {
                state.loading = true;
            })
            .addCase(accountLogout.fulfilled, (state, action) => {
                state.loading = false;
                state.accountCloseStudent = action.payload.data;
                state.error = null;
            })
            .addCase(accountLogout.rejected, (state, action) => {
                state.loading = false;
                state.accountCloseStudent = null;
                state.error = action.payload;
            })
            .addCase(allPostData.pending, (state) => {
                state.loading = true;
            })
            .addCase(allPostData.fulfilled, (state, action) => {
                state.loading = false;
                state.postData = action.payload.data;
                state.error = null;
            })
            .addCase(allPostData.rejected, (state, action) => {
                state.loading = false;
                state.postData = null;
                state.error = action.payload;
            })
            .addCase(closePostList.pending, (state) => {
                state.loading = true;
            })
            .addCase(closePostList.fulfilled, (state, action) => {
                state.loading = false;
                state.closePost = action.payload.data;
                state.error = null;
            })
            .addCase(closePostList.rejected, (state, action) => {
                state.loading = false;
                state.closePost = null;
                state.error = action.payload;
            })
            .addCase(createReview.pending, (state) => {
                state.loading = true;
            })
            .addCase(createReview.fulfilled, (state, action) => {
                state.loading = false;
                console.log('aaaaaa', action.payload);
                state.newReview = action.payload.data;
                state.error = null;
            })
            .addCase(createReview.rejected, (state, action) => {
                state.loading = false;
                state.newReview = null;
                state.error = action.payload;
            })
            .addCase(helpAndSupport.pending, (state) => {
                state.loading = true;
            })
            .addCase(helpAndSupport.fulfilled, (state, action) => {
                state.loading = false;
                state.helpSupport = action.payload.data;
                state.error = null;
            })
            .addCase(helpAndSupport.rejected, (state, action) => {
                state.loading = false;
                state.helpSupport = null;
                state.error = action.payload;
            })
            .addCase(profileUpdate.pending, (state) => {
                state.loading = true;
            })
            .addCase(profileUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.profileUpdateStudent = action.payload.data;
                state.error = null;
            })
            .addCase(profileUpdate.rejected, (state, action) => {
                state.loading = false;
                state.profileUpdateStudent = null;
                state.error = action.payload;
            })
            .addCase(notificationStudent.pending, (state) => {
                state.loading = true;
            })
            .addCase(notificationStudent.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = action.payload.data;
                state.error = null;
            })
            .addCase(notificationStudent.rejected, (state, action) => {
                state.loading = false;
                state.notification = null;
                state.error = action.payload;
            }) 
            .addCase(getPinCodeStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPinCodeStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = action.payload.data;
                state.error = null;
            })
            .addCase(getPinCodeStatus.rejected, (state, action) => {
                state.loading = false;
                state.notification = null;
                state.error = action.payload;
            });
    },
});


export const {
    storeMobile,
    updateStudentClass,
    storeLocationId,
    storeClassId,
    storeLocation,
    storeSubjects,
    storeBoardListing,
    storePreference,
    storeDayType,
    storeClassType,
    storeStartPlan,
    storeGender,
    storeFee,
    teacherSubscribe,
    getTeacherForReview
} = listingSlice.actions;

export default listingSlice.reducer;
