import React from 'react';
import teacher from '../../assets/TeacherNew.svg';
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from "../../assets/LogoHeader.svg";
import Student from '../../assets/TeacherNew.svg';
import Footer from '../pages/LandingPage/Footer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavBar from "../Navigation/NavBar";

function ForStudent() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');

    return (
        <>
        <NavBar/>
             <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>For Student</div></div>

            <div style={{ padding: isXs ? '60px 0' : '30px 0', paddingBottom: '150px' }}>
                <div style={{ fontSize: isXs ? '30px' : '18px', textAlign: 'center', padding:'0px 10px' }}>
                    Grow Your Learning with Kanha Home Tuitions: Find the Perfect Tutor for You
                </div>
                <div style={{ padding: isXs ? '20px 180px' : '10px 20px', fontSize: '14px', textAlign: 'center' }}>
                    At Kanha Home Tuitions, we connect students with qualified and passionate tutors who help them excel academically. Whether you need help with a specific subject or want to improve your overall performance, our platform offers flexible options to find the best tutor for your needs. Simply browse through our list of experienced tutors and choose one that fits your learning style, schedule, and goals. With verified tutors and easy-to-use features, your academic success is just a few clicks away!
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <button style={{ backgroundColor: '#CB8B65', width: "180px", color: 'white', border: 'none', padding: '8px 12px', borderRadius: '3px', fontSize: '17px' }}>
                        <Link style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }} to="/student-signin">Post Your Learning Need</Link>
                    </button>
                </div>
                
                <div style={{ fontSize: '25px', padding: isXs ? "30px 30px 0px 0px" : "15px 10px", textAlign: 'center', color: '#CB8B65', fontWeight: '500' }}>
                    How It Works: Find the Right Tutor for You
                </div>
                
                <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', alignItems: 'center' }}>
                    <div style={{ width: isXs ? '60%' : '100%', padding: isXs ? "0 100px" : "0 20px" }}>
                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>1. Search for a Tutor</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Start by exploring our extensive list of tutors. Use filters like subject, location, and availability to find the best match for your learning needs. Each tutor’s profile includes their qualifications, experience, and teaching style, making it easy for you to make an informed decision.
                        </div>

                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>2. Connect with a Tutor</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Once you find a tutor who fits your requirements, contact them directly through our platform. Discuss the details such as timing, fees, and any specific learning goals you have. Set up your lessons and begin your personalized learning journey!
                        </div>

                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>3. Enjoy Flexible Learning Options</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Whether you prefer online or in-person tutoring, our platform supports both. You can schedule lessons that fit your routine and learning pace, ensuring you get the most out of every session.
                        </div>
                    </div>
                    <div style={{ textAlign: 'start', width: isXs ? '40%' : '100%', display: 'flex', justifyContent: 'center', paddingTop: isXs ? '0' : '20px' }}>
                        <img style={{ height: isXs? '520px':'360px', width: isXs? '520px':'360px' }} src={Student} alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForStudent;
