import { Backdrop, Box, CircularProgress, InputAdornment, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import line from '../../../../../assets/line.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentPost, getTeacherClasses, priceUpdateTeacher } from '../../../../features/teacher/teacherListingSlice';
import { toast } from 'react-toastify';


const ClassCourse = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [isCost, setIsCost] = useState('');

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const handleChange = (event) => {
        setIsCost(event.target.value)
    }

    const handleClick = () => {
        if (isCost === '' || isCost === null || isCost === 0) {
            toast.error('Price field is required.');
        } else {
            dispatch(priceUpdateTeacher({ userId: userId, cost: +isCost })).then((response)=>{
                if(response?.payload?.status==200){
                    toast.success(response?.payload?.message);
                     handlePrice()
                }
            })
        }
    }

    const handlePrice = () => {
        dispatch(getTeacherClasses({userId:userId}))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    console.log(response?.payload?.data, 'sahbdas')
                    setIsCost(response?.payload?.data[0]?.cost)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    const loading = useSelector((state) => state?.teacherListing?.loading);


    useEffect(()=>{
        handlePrice()
    },[])

    // const goBack = () => {
    //     navigate(-1);
    // };
    return (
        <Box>
            <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between', marginLeft: '2%' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    {/* <button style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button> */}
                    <Box>
                        <Typography variant='h5' style={{fontFamily: 'jost'}}>Price</Typography>
                        <Box>Pricing & Class Packs</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '30vh', width: '100%', padding: 3 }}>
                    <Typography style={{ fontFamily: 'jost'}}>The Hourly Price is displayed on your profile, so a Student can compare your profile with other Tutors.</Typography>
                    <Box display={'flex'} gap={2} sx={{ marginTop: isXs ? '3%' : '8%' }}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { width: '93%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                onChange={handleChange}
                                value={isCost}
                                type='number'
                                InputProps={{

                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ fontSize: '18px' }}>
                                            <p style={{fontSize: '1.5rem', marginTop: '1rem' , color: '#CB8B65'}}>₹</p> 
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            Per Hour*
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            WebkitAppearance: "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    },
                                }}
                                InputLabelProps={{ sx: { color: 'black' } }}
                                color='grey'
                                focused
                            />
                        </Box>
                        <button onClick={handleClick} className="btn btn-primary py-2 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '5px', borderColor: '#CB8B65', width: isXs ? '10%' : '40%' }}>Save</button>
                    </Box>
                    <Box sx={{ fontSize: '14px', marginTop: isXs ? '3%' : '8%' }}>Typically tutors charge ₹300.0 - ₹500.0 per hour for Graphic Designing Classes.</Box>
                </Stack>
            </Box>
        </Box>

    )
}

export default ClassCourse
