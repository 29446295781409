import React, { useEffect, useState } from "react";
import line from "../../../../../assets/line.svg";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import {
  profileUpdateTeachers,
  teacherDetails,
} from "../../../../features/teacher/teacherListingSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ProfileEdit = () => {
  const navigate = useNavigate();
  const isXs = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [genderId, setGenderId] = useState("");
  const [dob, setDob] = useState();

  const teacherLanguage = () => {
    const userId = JSON.parse(localStorage.getItem("user"));
    dispatch(teacherDetails({ userId: userId?.id })).then((response) => {
      if (response?.payload?.status == 200) {
        console.log(response?.payload?.data, "sahvd");
        setFirstName(response?.payload?.data?.first_name);
        setLastName(response?.payload?.data?.last_name);
        setEmail(response?.payload?.data?.email);
        setGenderId(response?.payload?.data?.teacher_profile?.gender_id);
        const fetchedDate = dayjs(
          response?.payload?.data?.teacher_profile?.dob
        );
        setDob(fetchedDate);
        // setDob(response?.payload?.data?.teacher_profile?.dob)
      }
    });
  };

  useEffect(() => {
    teacherLanguage();
  }, []);

  const handleGenderChange = (e) => {
    setGenderId(e.target.value);
  };

  const profileUpdate = () => {
    const userId = JSON.parse(localStorage.getItem("user"));

    function formatDateForMySQL(isoDateString) {
      const date = new Date(isoDateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    const payload = {
      userId: userId?.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      genderId: genderId,
      dob: formatDateForMySQL(dob),
    };
    dispatch(profileUpdateTeachers(payload)).then((response) => {
      if (response?.payload?.success) {
        toast.success(response?.payload?.message);
      }
    });
  };

  const loading = useSelector((item) => item?.teacherListing?.loading);


  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{ display: isXs ? "flex" : "", justifyContent: "space-between" }}
      >
        <Box
          className="mt-3"
          sx={{ marginLeft: "1%", display: "flex", gap: 1 }}
        >
          <button
            onClick={goBack}
            style={{
              border: "none",
              background: "transparent",
              display: "flex",
              alignItems: "start",
            }}
          >
            <ArrowBackRoundedIcon
              sx={{
                color: "white",
                backgroundColor: "#CB8B65",
                borderRadius: "10px",
                width: "2rem",
                height: "2rem",
                padding: "5px",
              }}
            />
          </button>
          <Box>
            <Typography variant="h5">Profile Information</Typography>
            <Box>
              Update your Personal Information to be displayed on your Profile
            </Box>
            <Box>
              <img src={line} />
            </Box>
          </Box>
        </Box>
        <Box
          className="py-4 text-end px-5"
          style={{ width: isXs ? "30%" : "100%" , display: isXs? "" :  "none"}}
        >
          <button
            onClick={() => profileUpdate()}
            className="btn btn-primary py-2 w-100 border-0"
            style={{
              fontSize: "14px",
              backgroundColor: "#FFDB7E",
              color: "#866D2C",
              fontWeight: "600",
              borderRadius: "0",
              borderColor: "#CB8B65",
            }}
          >
            Save Changes
          </button>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
        <Stack
          sx={{
            backgroundColor: "#FBFBFB",
            minHeight: "55vh",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Grid
            container
            marginTop={isXs ? "5%" : "10%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                label="First Name"
                InputProps={{ style: { fontFamily: "jost" } }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                inputProps={{ style: { height: "10px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "40px",
                    borderColor: "grey",
                  },
                  minWidth: isXs ? "80%" : "100%",
                }}
              />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              marginTop={isXs ? "" : "10%"}
            >
              <TextField
                label="Last Name"
                InputProps={{ style: { fontFamily: "jost" } }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                inputProps={{ style: { height: "10px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "40px",
                    borderColor: "grey",
                  },
                  minWidth: isXs ? "80%" : "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={isXs ? "5%" : "10%"}
            sx={{ marginLeft: isXs ? "" : "8%" }}
          >
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                label="Email"
                InputProps={{ style: { fontFamily: "jost" } }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{ style: { height: "10px" } }}
                InputLabelProps={{ sx: { color: "black" } }}
                color="grey"
                focused
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "40px",
                    borderColor: "grey",
                  },
                  minWidth: isXs ? "80%" : "100%",
                }}
              />
            </Grid>

            
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: 'column',
              textAlign: 'left',
              marginLeft: isXs ? '3.2rem' : '2rem',
              gap: '1.5rem',
              
              marginTop: isXs ? "5%" : "8%",
            }}
          >
            <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5}>
              
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: isXs ? "10%" : "2%",
                  }}
                >
                
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Date of birth"
                        value={dob}
                        onChange={(newValue) => setDob(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                
              </Box>
              <Grid
              item
              xs={10}
              sm={5}
              md={5}
              lg={10}
              xl={10}
              xxl={5}
              sx={{ marginTop: isXs ? "8%" : "8%" }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender 
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={genderId}
                  onChange={handleGenderChange}
                  style={{display: "flex"}}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={<Typography style={{fontFamily: 'jost'}}>Male</Typography>}
                    
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={<Typography style={{fontFamily: 'jost'}}>Female</Typography>}

                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Box
          className="py-4 "
          style={{ width: "15rem" , display: isXs? "none" :  ""}}
        >
          <button
            onClick={() => profileUpdate()}
            className="btn btn-primary py-2 w-100 border-0"
            style={{
              fontSize: "14px",
              backgroundColor: "#FFDB7E",
              color: "#866D2C",
              fontWeight: "600",
              borderRadius: "0",
              borderColor: "#CB8B65",
            }}
          >
            Save Changes
          </button>
        </Box>
            </Grid>
            
          </Grid>


        </Stack>
      </Box>
    </Box>
  );
};

export default ProfileEdit;
