import React, { useEffect, useState } from "react";
import SampleImage from "../../../assets/SampleImage.svg";
import DashboardPost1 from "../../../assets/DashboardPost1.svg";
import { Backdrop, Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allPostData,
  closePostList,
  studentCreatePost,
  teacherSubscribe,
} from "../../features/student/listingSlice";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";

// import "./Dashboard.css";
import "../AccountAdmin/AdminCss/AdminDashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../../assets/logo.png";
import icon from "../../../assets/ham.png";
import notification from "../../../assets/notification.png";
import search from "../../../assets/search.png";
import studenticon from "../../../assets/student-icon.svg";
import teachericon from "../../../assets/teacher-icon.svg";
import directpost from "../../../assets/direct-post-icon.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
// import { adminDashboardData } from "../../features/admin/adminAuthSlice";
import { adminDashboardData } from "../../features/admin/accountPostSlice";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isXs = useMediaQuery("(min-width:600px)");


  const dashboardDataGet = () => {
    dispatch(adminDashboardData());
  };

  useEffect(() => {
    dashboardDataGet();
  }, []);

  const handleNavigate = () =>{
      navigate('/dashboard/createPost');
  }

  const {dashboardData , loading} = useSelector((state) => state?.accountPost);


  return (
    <Stack sx={{ alignItems: isXs ? "" : "center" }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
      <div className="bar">
        <span>Dashboard</span>
        <div className="search">
          <div className="right">
            <button onClick={handleNavigate} className="create-btn">+ Create Post</button>
          </div>
        </div>
      </div>
      <div className="container-2" style={{ padding: "0px 30px" }}>
        <div
          className="total-student-box"
          style={{ backgroundColor: "#6A73FA" }}
        >
          <img src={studenticon} alt="" id="student-icon" />
          <div className="total-student-text">
            <span>TOTAL STUDENTS</span>
            <span className="number-of-students">{dashboardData?.data?.studentCount}</span>
          </div>
        </div>
        <div
          className="total-student-box"
          style={{ backgroundColor: "#22C04F" }}
        >
          <img src={teachericon} alt="" id="student-icon" />
          <div className="total-student-text">
            <span>TOTAL TEACHERS</span>
            <span className="number-of-students">{dashboardData?.data?.teacherCount}</span>
          </div>
        </div>
        <div
          className="total-student-box"
          style={{ backgroundColor: "#FF1616" }}
        >
          <img src={directpost} alt="" id="student-icon" />
          <div className="total-student-text">
            <span>ACTIVE POST</span>
            <span className="number-of-students">{dashboardData?.data?.activePostCount}</span>
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default AdminDashboard;
