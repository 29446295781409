import { Box, Button, Typography, useMediaQuery ,Modal} from '@mui/material'
import React from 'react'
import line from '../../../../../assets/line.svg'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileTeacher from './ProfileTeacher';


const ProfileInformation = () => {
  const navigate = useNavigate()
  const isXs = useMediaQuery('(min-width:600px)');

  const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const profileInformationNavigate = () => {
    navigate('/dashboard/profileedit')
  }
  // const profiletaglineNavigate = () => {
  //   navigate('/dashboard/profiletagline')
  // }
  const phoneNumberNavigate = () => {
    navigate('/dashboard/phonenumber')
  }
  const addressNavigate = () => {
    navigate('/dashboard/address')
  }
  const profilePictureNavigate = () => {
    // navigate('/dashboard/profileedit')
    setOpen(true);
  }
  const qualificationNavigate = () => {
    navigate('/dashboard/qualification')
  }
  // const schoolNavigate = () => {
  //   navigate('/dashboard/schoollocation')
  // }
  const tutoringNavigate = () => {
    navigate('/dashboard/tutoring')
  }

  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user?.id
  const mobile = user?.mobile
  const teacherDetails = useSelector((state) => state?.teacherListing?.availableCredits)
  console.log(teacherDetails);



  return (
    <Box >
      <Box className="mt-3" sx={{ marginLeft: '3%' }}>
        <Typography variant='h5'>Profile Information</Typography>
        <Box>Update your Personal Information to be displayed on your Profile</Box>
        <Box><img src={line} /></Box>
      </Box>

      <Box className="d-flex justify-content-center">
        <Box style={{ width: isXs ? '95%' : '95%' }}>
          {/* Personal Information */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => profileInformationNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><PersonOutlineOutlinedIcon sx={{ color: '#CB8B65' }} /> </Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Personal Information</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>{user?.firstName+" "+user?.lastName+","+"  "+user?.email}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box>
          {/* Profile Tagline */}
          {/* <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => profiletaglineNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box> <ContactsOutlinedIcon sx={{ color: '#CB8B65' }} /> </Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Profile Tagline</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>Not Available</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box> */}
          {/* Phone Number */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => phoneNumberNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><CallOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Phone Number</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>+91-{mobile}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box>
          {/* Address */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => addressNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><EventNoteOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Address</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>{teacherDetails?.teacher_profile?.adress_line_one}, {teacherDetails?.teacher_profile?.adress_line_two}, {teacherDetails?.teacher_profile?.city?.city_name}, {teacherDetails?.teacher_profile?.state?.state_name}, India-{teacherDetails?.teacher_profile?.pincode}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box>
          {/* Profile Picture */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => profilePictureNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><AccountCircleOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Profile Picture</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>Not Available</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box>
          {/* Qualification & Experience */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => qualificationNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><SchoolOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Qualification & Experience</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>{teacherDetails?.teacher_profile?.degree?.degree_name}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box>
          {/* Schools that your students are from */}
          {/* <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => schoolNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box> <CorporateFareOutlinedIcon sx={{ color: '#CB8B65' }} /> </Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Schools that your students are from</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>0 Schools</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
            </Box>
          </Box> */}
          {/* Tutoring Information */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '90%' }}>
              <button onClick={() => tutoringNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: 8 }}>
                    <Box><InfoOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                    <Box>
                      <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Tutoring Information</Box>
                      <Box style={{ textAlign: 'start', fontSize: '14px' }}>{teacherDetails?.teacher_profile?.job_type == 1 ? 'Full Time' : teacherDetails?.teacher_profile?.job_type == 2 ? 'Part Time' : 'Not Available'}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                  </Box>
                </Box>
              </button>
              <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ProfileTeacher setOpen={setOpen}/>
                </Modal>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default ProfileInformation
