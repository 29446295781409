import React, { useEffect, useState } from 'react'
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'

import Box from '@mui/material/Box'
import { Steppers6 } from './StepperWeb';
import StepperPhone, { Step6 } from './StepperPhone';
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import InquiryLayout from './InquiryLayout';
import Slider from '@mui/material/Slider';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownListings, storeFee } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';

const Fee = () => {

    const dispatch = useDispatch()
    const feeListings = async () => {
        dispatch(dropDownListings())
    }

    const [amount, setamount] = useState(100);

    const handleChange = (event, newValue) => {
        console.log(newValue , 'storeFee');
        
        setamount(newValue);
    };

    // const feeDetail = useSelector((state) => state.listing.dropDownData.student_max_fee_range)
    // console.log(feeDetail);

    useEffect(() => {
        feeListings()
    }, [])

    const user = JSON?.parse(localStorage?.getItem('user'))
    const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)


    const isNavigate = () => {
        // const selectedAmount = amount.find((item) => item.value === value);
        console.log(amount , 'amount');
        if (amount == '' || amount == null || amount == 0) {
            toast.error('Input field is required.');
          }else{
        dispatch(storeFee(amount));
        if(postCount>0){
            navigate('/dashboard/days')
            }else{
          navigate('/days')
          }
        }
    }

    const goBack = () => {
        navigate(-1);
    };

    // const fee = useSelector((state) => state?.listing?.postPayload?.cost)

    // useEffect(()=>{
    //     setamount(fee);
    // },[fee])



    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                <div className='d-flex justify-content-center align-items-center mx-auto'>What is the maximum fee that you are willing to pay?</div>
            </Box>
            <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers6 />
            </div>
            <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step6 />
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='container' style={{ width: isXs ? '60%' : '80%' }}>
                    <Slider onChange={handleChange} value={amount} min={100} max={5000} aria-label="Default" sx={{ color: '#CB8B65' }} valueLabelDisplay="auto" />
                    <div className='d-flex justify-content-between'>
                        <div className='border border-secondary-subtle' style={{ borderRadius: '10px', width: isXs ? '15%' : '25%', textAlign: 'center' }}>₹ 100</div>
                        <div className='border border-secondary-subtle' style={{ borderRadius: '10px', width: isXs ? '15%' : '25%', textAlign: 'center' }}>₹ 5000</div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center fw-bold' style={{ marginTop: isXs ? '2%' : '15%', fontSize: isXs ? '2vw' : '8vw' }}> <div> ₹ {amount} </div>
            </div>
            <div className='d-flex justify-content-center' >Per hour</div>
            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '2%' : '13%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </div>
            </div>
            <div className='d-flex justify-content-between px-4'>
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </div>
        </InquiryLayout>
    )
}

export default Fee
