import React from 'react'
import logo from "../../../assets/TClogo.png";
import { useMediaQuery } from "@mui/material";
import { Box, padding } from "@mui/system";
import Footer from '../LandingPage/Footer';
import {Link, useNavigate } from 'react-router-dom';
import WhatAppMobile from '../LandingPage/WhatAppMobile';
import teacherimage from '../../../assets/TeacherNew.svg';
import NavBar from '../../Navigation/NavBar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const TermsAndConditions = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const navigateLanding = () => {
        navigate('/')
    }

    return (
        <>
                      <WhatAppMobile/>
<NavBar/>
            <Box style={{ width: '100%' }}>
                <Box style={{ width: '100%' }}>
                <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Terms & Conditions</div></div>

                    <Box style={{ paddingLeft: isXs ? "6rem":'2rem', paddingTop:'3rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width:isXs? '70%':'100%' }}>
                        <Box>
                            <p>
                                Welcome to Kanha Home Tutions ("we," "our," or "us"). These Terms
                                and Conditions "Terms" govern your use of our website
                                kanhahometutions.com and the services provided through it. By
                                accessing or using our Website, you agree to be bound by these
                                Terms. If you do not agree with any part of these Terms, please do
                                not use our Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>1. Acceptance of Terms</h1>
                        </Box>

                        <Box>
                            <p>
                                By accessing and using our Website, you agree to comply with and
                                be legally bound by these Terms, as well as our Privacy Policy. If
                                you do not agree to these Terms, you must not use our services.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>2. Services Provided</h1>
                        </Box>
                        <Box>
                            <p>
                                Kanha Home Tutions is a platform that connects students with
                                tutors based on their specific needs. Students and tutors can
                                discuss and agree on the timing and fees for the classes. Tutors
                                can register their profiles and verify them by paying a
                                verification fee. Additionally, tutors need to pay a fee to unhide
                                student inquiry details.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>3. User Registration</h1>
                        </Box>
                        <Box>
                            <p>
                                To use certain features of our Website, you must register for an
                                account. When registering, you agree to:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Provide accurate, current, and complete information.</li>
                                <li>Maintain and promptly update your account information.</li>
                                <li>
                                    Maintain the security of your password and accept all risks of
                                    unauthorized access to your account.
                                </li>
                                <li>
                                    Notify us immediately of any unauthorized use of your account.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>4. Fees and Payments</h1>
                        </Box>

                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Verification Fees: Tutors are required to pay a verification fee
                                    to verify their profiles. This fee is processed through a
                                    third-party API.
                                </li>
                                <li>
                                    Inquiry Fees: Tutors must pay a fixed fee to unhide student
                                    inquiry details.
                                </li>
                                <li>
                                    Payment Methods: All payments must be made through the payment
                                    methods available on our Website. You agree to provide current,
                                    complete, and accurate billing information.
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>5. User Responsibilities</h1>
                        </Box>
                        <Box>
                            <p>As a user of our Website, you agree to:</p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Use the Website only for lawful purposes and in accordance with
                                    these Terms.
                                </li>
                                <li>
                                    Not engage in any activity that interferes with or disrupts the
                                    Website.
                                </li>
                                <li>
                                    Not attempt to gain unauthorized access to any part of the
                                    Website or any other user's account.
                                </li>
                                <li>
                                    Not use the Website to transmit any harmful or malicious code
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>6. Tutor and Student Interactions</h1>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Agreement: The terms of any tutoring arrangement, including
                                    timing, fees, and location, are to be agreed upon between the
                                    student and the tutor.
                                </li>
                                <li>
                                    Conduct: Both students and tutors are expected to conduct
                                    themselves professionally and respectfully.
                                </li>
                                <li>
                                    Disputes: We are not responsible for any disputes or
                                    disagreements between students and tutors. However, we may offer
                                    assistance to help resolve issues at our discretion
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>7. Intellectual Property</h1>
                        </Box>
                        <Box>
                            <p>
                                All content on the Website, including text, graphics, logos, and
                                software, is the property of Kanha Home Tutions or its licensors
                                and is protected by intellectual property laws. You may not use,
                                reproduce, or distribute any content from the Website without our
                                prior written permission.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>8. Third-Party Links</h1>
                        </Box>
                        <Box>
                            <p>
                                Our Website may contain links to third-party websites or services
                                that are not owned or controlled by Kanha Home Tutions. We have no
                                control over, and assume no responsibility for, the content,
                                privacy policies, or practices of any third-party websites or
                                services. You acknowledge and agree that Kanha Home Tutions shall
                                not be responsible or liable, directly or indirectly, for any
                                damage or loss caused or alleged to be caused by or in connection
                                with the use of any such content, goods, or services available on
                                or through any such websites or services.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>9. Limitation of Liability</h1>
                        </Box>
                        <Box>
                            <p>
                                To the maximum extent permitted by law, Kanha Home Tutions shall
                                not be liable for any indirect, incidental, special,
                                consequential, or punitive damages, or any loss of profits or
                                revenues, whether incurred directly or indirectly, or any loss of
                                data, use, goodwill, or other intangible losses, resulting from:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Your use or inability to use the Website.</li>
                                <li>
                                    Any unauthorized access to or use of our servers and/or any
                                    personal information stored therein.
                                </li>
                                <li>
                                    Any interruption or cessation of transmission to or from the
                                    Website.
                                </li>
                                <li>
                                    Any bugs, viruses, trojan horses, or the like that may be
                                    transmitted to or through our Website by any third party.
                                </li>
                                <li>
                                    Any errors or omissions in any content or for any loss or damage
                                    incurred as a result of the use of any content posted, emailed,
                                    transmitted, or otherwise made available through the Website.
                                </li>
                                <li>
                                    User interactions and arrangements made through the Website.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>10. Indemnification</h1>
                        </Box>
                        <Box>
                            <p>
                                You agree to defend, indemnify, and hold harmless Kanha Home
                                Tutions, its affiliates, and their respective directors, officers,
                                employees, and agents, from and against any claims, liabilities,
                                damages, losses, and expenses, including, without limitation,
                                reasonable legal and accounting fees, arising out of or in any way
                                connected with:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Your access to or use of the Website.</li>
                                <li>Your violation of these Terms.</li>
                                <li>
                                    Your violation of any third-party right, including without
                                    limitation any intellectual property, property, or privacy
                                    right.
                                </li>
                                <li>
                                    Any claim that your use of the Website caused damage to a third
                                    party.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>11. Termination</h1>
                        </Box>
                        <Box>
                            <p>
                                We may terminate or suspend your account and bar access to the
                                Website immediately, without prior notice or liability, under our
                                sole discretion, for any reason whatsoever and without limitation,
                                including but not limited to a breach of these Terms. If you wish
                                to terminate your account, you may simply discontinue using the
                                Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>12. Governing Law</h1>
                        </Box>
                        <Box>
                            <p>
                            These Terms shall be governed and construed in accordance with the
                                laws of Rajasthan, without regard to its conflict of
                                law provisions. Our failure to enforce any right or provision of
                                these Terms will not be considered a waiver of those rights. If
                                any provision of these Terms is held to be invalid or
                                unenforceable by a court, the remaining provisions of these Terms
                                will remain in effect. These Terms constitute the entire agreement
                                between us regarding our Website, and supersede and replace any
                                prior agreements we might have had between us regarding the
                                Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>13. Changes to These Terms</h1>
                        </Box>
                        <Box>
                            <p>
                                We reserve the right, at our sole discretion, to modify or replace
                                these Terms at any time. If a revision is material, we will try to
                                provide at least 30 days' notice prior to any new terms taking
                                effect. What constitutes a material change will be determined at
                                our sole discretion. By continuing to access or use our Website
                                after any revisions become effective, you agree to be bound by the
                                revised terms. If you do not agree to the new terms, you are no
                                longer authorized to use the Website.
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <div style={{ padding: isXs ? '60px 0px' : '30px 0px', paddingBottom: '150px', backgroundColor:'#fcf9f4' }}>
            <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20vh',
      }}
    >
      <div
        style={{
          fontSize: '30px',
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          backgroundColor: '#CB8B65',
          width: '20rem',
          height: '50px',
          borderRadius:'7px',
        }}
      >
        FOR TEACHERS
      </div>
    </div> 
                <div style={{ fontSize: isXs ? '30px' : '18px', textAlign: 'center' }}>
                    Grow Your Career with Kanha Home Tutions: Start Your Tutoring Journey
                </div>
                <div style={{ padding: isXs ? '20px 180px' : '20px 20px', fontSize: '14px', textAlign: 'center' }}>
                    Become a tutor with Kanha Home Tuitions and make a difference in students' lives while growing your teaching career. By joining our platform, you can set your own schedule, teach subjects you're passionate about, and connect with students in your area. Creating a free tutor profile is easy—simply share your qualifications, experience, and availability. Our system provides you with verified tuition inquiries, ensuring you receive genuine student leads. Plus, with flexible and secure subscription options, you can access premium features that highlight your profile to attract more students. Start your journey today, join our network of dedicated tutors, and help shape the future of education!
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <button style={{ backgroundColor: '#CB8B65', width: isXs ? "150px" : "160px", color: 'white', border: 'none', padding: '8px 12px', borderRadius: '3px', fontSize: isXs ? '17px' : '14px' }}>
                        <a style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }} href="/signup-teacher"> Create Your Profile</a>
                    </button>
                </div>

                <div style={{ fontSize: isXs ? '25px' : '20px', padding: isXs ? "30px 30px 0px 0px":"40px 0px 0px 0px", textAlign: 'center', color: '#CB8B65', fontWeight: '500' }}>
                    How It Works: Your Path to Becoming a Kanha Tutor
                </div>

                <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', alignItems: 'center' }}>
                    <div style={{ width: isXs ? '60%' : '100%', padding: isXs ? '0 100px' : '0 20px' }}>
                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                1. Create Your Tutor Profile and Register
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                To get started, simply create your tutor profile. It’s easy, quick, and free! Click on the Create Your Free Tutor Profile link and fill out the required information, including your name, qualifications, subjects you teach, and your availability. Upload a professional photo and complete your registration by submitting any necessary documents to showcase your credibility.
                            </div>
                        </div>

                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                2. Get Verified Tuition Enquiries
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                Once your profile is verified, you’ll begin receiving genuine inquiries from students or parents in your area. You’ll be notified about new tutoring opportunities based on your subjects and location preferences. Simply browse the inquiries, connect with students or parents, and finalize the details like timing, fees, and lesson plans. Confirm the lessons and start teaching!
                            </div>
                        </div>

                        <div>
                            <div style={{ fontSize: isXs ? '18px' : '16px', paddingTop: '25px', fontWeight: '500' }}>
                                3. Easy Payment Options for Subscription
                            </div>
                            <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                                Unlock premium features with our flexible subscription plans, designed to meet your tutoring needs. Choose the plan that suits you, and securely pay through options like UPI, credit/debit cards, or net banking. Enjoy uninterrupted access to premium inquiries and tools that help grow your tutoring career!
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'start', width: isXs ? '40%' : '100%', display: 'flex', justifyContent: 'center', marginTop: isXs ? '0' : '20px' }}>
                        <img style={{ height: isXs ? '520px' : '360px', width: isXs ? '520px' : '360px', paddingRight: isXs? '100px':'0px' }} src={teacherimage} alt="Teacher" />
                    </div>
                </div>

                <div style={{
                    margin: isXs ?'0% 7%':"0% 2%",
                    width: isXs ? "85%":'95%',
                    marginTop: '30px',
                    boxShadow: '0 4px 12px rgba(203, 139, 101, 0.4)',
                    borderRadius: '8px',
                    padding: isXs ? '60px':'30px',
                    backgroundColor:"white"
                    
                }}>
                    <div style={{ fontWeight: '500', fontSize: '18px', paddingTop:'10px' }}>
                        Subscription Plans for Teachers
                    </div>
                    <div style={{ fontSize: '16px', paddingTop: '10px' }}>We offer two flexible subscription plans to meet your tutoring needs:</div>
                    <div style={{ fontSize: '15px', paddingTop: '10px', paddingLeft: '15px' }}>1. Rs 500/- Plan: Receive 3 credits.</div>
                    <div style={{ fontSize: '15px', paddingTop: '10px', paddingLeft: '15px' }}>2. Rs 1000/- Plan: Receive 9 credits.</div>
                    <div style={{ fontWeight: '500', fontSize: '18px', paddingTop: '20px' }}>Key Benefits</div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px',paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Guaranteed Leads :</div>
                        <div style={{ fontSize: '14px' }}>If you subscribe and do not secure tuition from us, we will extend your subscription plan until you do.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Lead Information :</div>
                        <div style={{ fontSize: '14px' }}>We provide student inquiry leads with the main phone numbers and email.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px'  }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Refundable Credits :</div>
                        <div style={{ fontSize: '14px' }}>Credits will be refunded if both the email and phone number provided are incorrect.</div>
                    </div>

                    <div style={{ display: isXs? 'flex':'', gap: "10px", paddingLeft: '15px', paddingTop: isXs ? '10px':'20px' }}>
                        <div style={{ fontWeight: '500', fontSize: '16px' }}>Non-Refundable Credits :</div>
                        <div style={{ fontSize: '14px' }}>If a demo is conducted but the outcome is unsuccessful, credits will also not be refunded.</div>
                    </div>

                    <div style={{ fontSize: '14px', padding: '15px', paddingBottom:'10px' }}>These terms ensure fairness and help you focus on growing your tutoring career!</div>
                </div>
            </div>

            
            <div style={{ padding: isXs ? '60px 0' : '30px 0', paddingBottom: '150px' }}>
            <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20vh',
      }}
    >
      <div
        style={{
          fontSize: '30px',
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          backgroundColor: '#CB8B65',
          width: '20rem',
          height: '50px',
          borderRadius:'7px',
        }}
      >
        FOR STUDENT
      </div>
    </div>                <div style={{ fontSize: isXs ? '30px' : '18px', textAlign: 'center', padding:'0px 10px' }}>
                    Grow Your Learning with Kanha Home Tuitions: Find the Perfect Tutor for You
                </div>
                <div style={{ padding: isXs ? '20px 180px' : '10px 20px', fontSize: '14px', textAlign: 'center' }}>
                    At Kanha Home Tuitions, we connect students with qualified and passionate tutors who help them excel academically. Whether you need help with a specific subject or want to improve your overall performance, our platform offers flexible options to find the best tutor for your needs. Simply browse through our list of experienced tutors and choose one that fits your learning style, schedule, and goals. With verified tutors and easy-to-use features, your academic success is just a few clicks away!
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <button style={{ backgroundColor: '#CB8B65', width: "180px", color: 'white', border: 'none', padding: '8px 12px', borderRadius: '3px', fontSize: '17px' }}>
                        <Link style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }} to="/student-signin">Post Your Learning Need</Link>
                    </button>
                </div>
                
                <div style={{ fontSize: '25px', padding: isXs ? "30px 30px 0px 0px" : "15px 10px", textAlign: 'center', color: '#CB8B65', fontWeight: '500' }}>
                    How It Works: Find the Right Tutor for You
                </div>
                
                <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', alignItems: 'center' }}>
                    <div style={{ width: isXs ? '60%' : '100%', padding: isXs ? "0 100px" : "0 20px" }}>
                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>1. Search for a Tutor</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Start by exploring our extensive list of tutors. Use filters like subject, location, and availability to find the best match for your learning needs. Each tutor’s profile includes their qualifications, experience, and teaching style, making it easy for you to make an informed decision.
                        </div>

                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>2. Connect with a Tutor</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Once you find a tutor who fits your requirements, contact them directly through our platform. Discuss the details such as timing, fees, and any specific learning goals you have. Set up your lessons and begin your personalized learning journey!
                        </div>

                        <div style={{ fontSize: '18px', paddingTop: '25px', fontWeight: '500' }}>3. Enjoy Flexible Learning Options</div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            Whether you prefer online or in-person tutoring, our platform supports both. You can schedule lessons that fit your routine and learning pace, ensuring you get the most out of every session.
                        </div>
                    </div>
                    <div style={{ textAlign: 'start', width: isXs ? '40%' : '100%', display: 'flex', justifyContent: 'center', paddingTop: isXs ? '0' : '20px' }}>
                        <img style={{ height: isXs? '520px':'360px', width: isXs? '520px':'360px' }} src={teacherimage} alt="" />
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default TermsAndConditions
