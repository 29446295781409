import { Box, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';
import { profileUpdateTeachers, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';


const Summary = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const [isSummary, setIsSummary] = useState('');

    const profileData = useSelector((item) => item?.teacherListing?.profileUpdateTeacher);
    console.log(profileData);

    const handleChange = (e) => {
        setIsSummary(e.target.value)
    }

    const handleSummary = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setIsSummary(response?.payload?.data?.teacher_profile?.overview)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    useEffect(() => {
        handleSummary()
    }, [userId])

    const handleClick = () => {

        if (isSummary.length < 250) {
            toast.error('min. 250 character required !');
            return;
        }
        if (isSummary === '' || isSummary === null || isSummary === 0) {
            toast.error('Input field is required.');
        } else {
            dispatch(profileUpdateTeachers({ userId: userId, overview: isSummary }))
                .then((response) => {
                    if (response?.payload?.success == true) {
                        setIsSummary(response?.payload?.data?.teacher_profile?.overview)
                        toast.success('Submitted');
                    }
                }).catch((error) => {
                    console.log('getting error', error);
                })
        }
    }
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Box>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5' style={{ fontFamily: 'jost' }}>About/Overview</Typography>
                        <Box>Write about your background and your experience in teaching.</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={handleClick} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '60vh', width: '100%', padding: 2 }}>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={5}
                        value={isSummary}
                        onChange={handleChange}
                        sx={{ width: isXs ? '70%' : '100%' }}
                        placeholder='Add summary'
                        inputProps={{ style: { fontFamily: 'jost' } }}
                    />
                    <Typography variant="caption" sx={{display:"flex" , width: '100%' ,margin: isXs ? "0 500px " : "0 120px", marginTop: '.5rem'  ,color: "#8e8e8e " }}   >
                        min. 250 character required.
                    </Typography>
                    <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                        <Box sx={{ color: '#CB8B65', marginBottom: '10px' }}>Need help with this? Here is a sample</Box>
                        <Box>I am an experienced, qualified teacher and tutor with over 15 years of experience in teaching Maths and Chemistry,
                            across different boards including CBSE, ICSE, IGCSE and State Board. Passionate about solving mathematical
                            problems and chemical equations, over the years I have helped thousands of students overcome their fear of Maths
                            and Chemistry. So far, I have worked as a Teacher with Presidency School (Bangalore), DPS (Bhagalpur) and
                            Kendriya Vidyalaya (Nagpur).</Box>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default Summary
