import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import { Steppers3 } from './StepperWeb';
import StepperPhone, { Step3 } from './StepperPhone';
import { toast } from 'react-toastify';

import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import InquiryLayout from './InquiryLayout';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeBoardListing, studentBoardListing } from '../../features/student/listingSlice';


const Education = () => {

    const dispatch = useDispatch()
    const [isBoard, setIsBoard] = useState('')
    

    const boardData = useSelector((state) => state?.listing?.boardData)
    const boardId = boardData?.map((item) => item?.id)
    
    const boardListings = async () => {
        dispatch(studentBoardListing())
    };

    useEffect(() => {
        boardListings()
    }, [])

    const handleChange = async (event, value) => {
        const selectedBoard = boardData.find((item) => item?.boardName === value);
        setIsBoard(selectedBoard)
        dispatch(storeBoardListing(selectedBoard))
    }

  const selectedBoard = useSelector((item) => item?.listing?.boardSelect)
  const user = JSON?.parse(localStorage?.getItem('user'))

  const {postCount} = useSelector((item) => item?.listing?.profileInfoDetails)

    // console.log(isBoard);
    const isNavigate = () => {
        // dispatch(storeBoardListing({boardId:boardId}))
        console.log(selectedBoard?.id , 'selectedBoard');
        if (selectedBoard?.id == '' || selectedBoard?.id == null || selectedBoard?.id == 0) {
            toast.error('Input field is required.');
          }else{
        if(postCount>0){
            navigate('/dashboard/subsubjects')
            }else{
          navigate('/subsubjects')
          }
        }
    }

    const goBack = () => {
        navigate(-1);
    };




    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    return (
        <InquiryLayout>
            <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
                <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
                <div className='d-flex justify-content-center align-items-center mx-auto'>Which board of education are you looking for?</div>
            </Box>
            <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
                <Steppers3/>
            </div>
            <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
                <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
                    <Step3 />
                </div>
            </div>

            <div className='d-flex justify-content-center' style={{ marginTop: '5%' }}>
                <div className='d-flex' style={{ width: isXs ? '60%' : '80%', borderRadius: '0px' }}>
                    <Autocomplete
                        fullWidth
                        id="free-solo-demo"
                        freeSolo
                        value={selectedBoard?.boardName}
                        onChange={handleChange}
                        options={boardData?.map((option) => option?.boardName)}
                        renderInput={(params) => <TextField {...params} label="Search board of education" />}
                        disableClearable
                    />
                </div>
            </div>
            {/* <div className='d-flex justify-content-center'>
                <div class="d-flex justify-content-evenly" style={{ width: isXs ? '50%' : '90%' }}>
                    <FormControl style={{ display: 'flex', justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ display: 'flex', justifyContent: 'space-evenly', gap: isXs ? '25px' : '20px' }}
                        >
                            <FormControlLabel
                                value="CBSE"
                                control={<Radio />}
                                label={<span style={{ fontSize: '14px' }}>CBSE</span>}
                                sx={{ '& .MuiSvgIcon-root': { color: '#CB8B65', }, }}
                                style={{ border: '1px solid silver', borderRadius: '5px', paddingRight: '10px' }}
                            />
                            <FormControlLabel
                                value="State"
                                control={<Radio />}
                                label={<span style={{ fontSize: '14px' }}>State</span>}
                                sx={{ '& .MuiSvgIcon-root': { color: '#CB8B65', }, }}
                                style={{ border: '1px solid silver', borderRadius: '5px', paddingRight: '10px' }}
                            />
                            <FormControlLabel
                                value="IGCSE"
                                control={<Radio />}
                                label={<span style={{ fontSize: '14px' }}>IGCSE</span>}
                                sx={{ '& .MuiSvgIcon-root': { color: '#CB8B65', }, }}
                                style={{ border: '1px solid silver', borderRadius: '5px', paddingRight: '10px' }}
                            />
                            <FormControlLabel
                                value="International Baccalaureate"
                                control={<Radio />}
                                label={<span style={{ fontSize: '14px' }}>International Baccalaureate</span>}
                                sx={{ '& .MuiSvgIcon-root': { color: '#CB8B65', }, }}
                                style={{ border: '1px solid silver', borderRadius: '5px', paddingRight: '10px' }}
                            />
                            <FormControlLabel
                                value="ISC/ICSE"
                                control={<Radio />}
                                label={<span style={{ fontSize: '14px' }}>ISC/ICSE</span>}
                                sx={{ '& .MuiSvgIcon-root': { color: '#CB8B65', }, }}
                                style={{ border: '1px solid silver', borderRadius: '5px', paddingRight: '10px' }}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div> */}
            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: isXs ? '7%' : '12%', width: isXs ? '' : '70%' }} >
                    <NextButton onClick={() => isNavigate()} />
                </div>
            </div>
            <div className='d-flex justify-content-between px-4'>
                <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
                <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
            </div>
        </InquiryLayout>
    );
}

export default Education;
