
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Stack,
  
  Switch,
  FormControlLabel,
  TextField,
  Typography,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
// import Switch from "../../switch";
import online from "../../../assets/online.png";
import location2 from "../../../assets/location2.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentPost,
  getStudentPostV2,
} from "../../features/teacher/teacherListingSlice";
import { studentCloseAndOpenPost } from "../../features/admin/accountPostSlice";
import { styled } from '@mui/system';
const filters = [
  { label: "All", value: "all" },
  { label: "This Weak", year: "weak" },
];

const ManageEnquiry = () => {
  const dispatch = useDispatch();
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [checked, setChecked] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = useState(1);


  const studentData = useSelector(
    (item) => item?.teacherListing?.studentPostListing
  );
  const loading = useSelector(
    (item) => item?.teacherListing?.loading
  );

  const listingStudentPost = (search, filter) => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}` 
    
    const paylaod={
      token: token,
       page: currentPage,
        pageCount: rowsPerPage,
        search: search,
         filter: filter
        }
      //   if (searchTermQuery.length > 0) {
      //    paylaod.search = searchTermQuery
      //    paylaod.page = 1
      //  }


    dispatch(getStudentPostV2(paylaod));
  };


  const handlePostStatus = (e, itemList) => {

    let user = JSON.parse(localStorage.getItem("user"));
    const payload = { postStatus: e.target.checked == true ? 1 : 0, postId: itemList.id, userId: user.id };

    dispatch(studentCloseAndOpenPost(payload)).then((res) => {

      if (res.payload.success) {
        setChecked(!checked)
      }
    });

  }

  // const isNavigate = (i) => {
  //   const selectedItem = studentData?.find(
  //     (item, index) => index === parseInt(i)
  //   );
  //   // dispatch(storeStudentPostDetails(selectedItem));
  //   const itemId = studentData[i]?.id;
  //   navigate(`/dashboard/dashboard-home-enquiry/${itemId}`);
  // };

  const buttonStyle = {
    color: "black",
    borderRadius: "20px",
    width: "100%",
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0);
    setRowsPerPage(10);
    // listingStudentPost(searchQuery,selectedFilter);
  };

  const handleFilterChange = (event, newValue) => {
    setSelectedFilter(newValue);
    console.log("Selected filter:", selectedFilter);
    setRowsPerPage(10);
    setCurrentPage(0);
    // listingStudentPost(searchQuery,selectedFilter);
  };

  // useEffect(() => {
  //   listingStudentPost();
  // }, []);


  useEffect(() => {
    // console.log(selectedFilter , 'selectedFilter');
    listingStudentPost(searchQuery, selectedFilter?.year);
  }, [currentPage,rowsPerPage,selectedFilter, searchQuery, checked]);

  const randomColor = () => {
    // Generate a random hex color
    // return '#' + Math.floor(Math.random()*16777215).toString(16);
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      color: 'red', // Default color when switch is off
      '&.Mui-checked': {
        color: 'blue', // Color when switch is on
        '& + .MuiSwitch-track': {
          backgroundColor: 'blue', // Background color of the track when switch is on
        },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'red', // Background color of the track when switch is off
    },
  }));

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(1);
  };

  return (
    <Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#F9F9F9",
          padding: 2,
        }}
      >
        <Box
          className="d-flex align-items-center"
          style={{
            width: isXs ? "80%" : "90%",
            marginLeft: "2%",
            position: "relative",
          }}
        >
          <input
            onChange={handleSearchChange}
            className="form-control me-2"
            type="search"
            placeholder="Search My Posts"
            aria-label="Search"
            style={{
              borderRadius: "100px",
              backgroundColor: "#F3F3F3",
              fontSize: "14px",
              padding: "15px",
            }}
          />
        </Box>
        <Box display={"flex"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {/* <FilterListRoundedIcon />{" "} */}
          </Box>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filters}
            value={selectedFilter}
            onChange={handleFilterChange}
            sx={{ ...buttonStyle }}
            renderInput={(params) => <TextField {...params} label="Filter" style={{ width: "10rem" }} />}
          />
        </Box>
      </Box>
      {studentData?.data?.length > 0 ? studentData?.data?.map((item, index) => {

        return (<button
          // onClick={() => isNavigate(index)}
          key={index}
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          <Box className="d-flex">
            <Box style={{ width: isXs ? "95%" : "95%" }}>
              <Box
                className="mt-3 py-3 shadow-sm"
                style={{
                  border: "1px solid #D6D6D6",
                  backgroundColor: "white",
                  // marginBottom: "3%",
                  borderRadius: "10px",
                }}
              >

                {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? 'none' : '', marginRight:'5%' }}>s
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>10 mins ago</Box>
                                </Grid> */}

                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ display: "flex", gap: "1rem", width: "80%" }}>
                    <Box>
                      <Box
                        sx={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: item?.user?.profileImage ? 'transparent' : randomColor(),
                          overflow: 'hidden',
                          marginLeft: "25px"
                          // Ensure the Box has a defined size and that children are properly aligned
                        }}
                      >
                        {item?.user?.profileImage ? (
                          <img
                            src={item?.user?.profileImage}
                            alt="profile-img"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              // Ensure that the image covers the Box without stretching it
                            }}
                          />
                        ) : (
                          <Typography
                            variant="h4"
                            sx={{ color: 'white', fontWeight: '500' }}
                          >
                            {`${item?.user?.firstName?.charAt(0)?.toUpperCase()}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <Box className="py-1 ">

                        <Box style={{ display: "flex", gap: "1rem", alignItems: "center" }} >
                          <Typography style={{ fontSize: "1.4rem", fontFamily: "jost", textTransform: "capitalize", textAlign: "left" }}>

                            {item?.user?.firstName} {item?.user?.lastName}
                          </Typography>
                          {
                            item?.postLimitExceed == 0 ?
                              <Box sx={{ marginRight: "2rem", paddingBottom: ".1rem", fontSize: ".8rem", backgroundColor: "#24AA2A", width: "3.5rem", height: "1.2rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2rem", fontWeight: "500" }}>Active</Box>
                              :
                              <Box sx={{ marginRight: "2rem", paddingBottom: ".1rem", backgroundColor: "red", fontSize: ".8rem", width: "3.5rem", height: "1.2rem", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2rem", fontWeight: "500" }}>Expired</Box>

                          }
                        </Box>

                        <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={1}>
                          <Box fontSize={"14px"}>
                            {item?.classData?.class_name} ||{" "}
                          </Box>
                          {item?.subjects?.map((item, index) => (
                            <Box key={index} fontSize={"14px"}>
                              {" "}
                              {item?.subjectName?.subject_name},
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Divider />
                      <Box sx={{ display: "flex", gap: 2, marginTop: "2%" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box>
                            {" "}
                            <img src={online} />{" "}
                          </Box>
                          {item?.classTypes?.map((item, index) => (
                            <Box key={index}>{item?.classTypeName?.name},</Box>
                          ))}
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Box>
                            {" "}
                            <img src={location2} />{" "}
                          </Box>
                          <Box>
                            {item?.city}, {item?.state}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: "1.3rem", marginTop: ".8rem",marginRight:"12px" }}>


                    {/* <Switch checked={item?.postStatus}
                      onChange={(e) => {
                        setChecked(item?.postStatus);
                        return handlePostStatus(e, item)
                      }} /> */}

                    
 <Stack direction="row" spacing={1} alignItems="center">
       { <Typography>{"Close"}</Typography>}
        <FormControlLabel
                      control={
                        <CustomSwitch 
                        checked={item?.postStatus}
                        onChange={(e) => {
                          setChecked(item?.postStatus);
                          return handlePostStatus(e, item)
                        }}                         />
                      }
                    />    
       {<Typography>{"Open"}</Typography>}
       </Stack>
                    <Typography sx={{ marginRight: "2rem", fontSize: ".8rem", width: "100%", textAlign: "left", fontFamily: "jost" }}>{item?.createdAt}</Typography>
                  </Box>
                </Box>

                {/* <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ display: isXs ? '' : 'none' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start', marginRight:'5%' }}>10 mins ago</Box>
                                </Grid> */}
              </Box>
            </Box>
          </Box>

        </button>)

      }) :
        <Typography sx={{ fontSize: "1rem", fontFamily: "jost", textAlign: "center", marginTop: "5px" }}>{'no data found'}</Typography>
      }
<TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={studentData?.meta?.total}
              rowsPerPage={rowsPerPage}
              page={!studentData?.meta?.total || studentData?.meta?.total <= 0 ? 0 : currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
    </Stack>
  );
};

export default ManageEnquiry