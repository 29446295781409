import React from 'react'
import { Autocomplete, Box, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';


const SchoolLocation = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1);
    };
    
    return (
        <Box>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5'>Schools that your students are from</Typography>
                        <Box>More Students can find you based on the School that you have experience</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '90%', padding: 2 }}>
                    <Typography>Which Schools have you taught Students from?</Typography>
                    <Autocomplete
                        sx={{ width: isXs ? '60%' : '100%', marginTop: '2%' }}
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search for School Name"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                    <Box sx={{ width: isXs ? '40%' : '100%', marginTop: isXs ? '3%' : '8%' }}>You have not added any schools. Only add those
                        Schools from where you have taught Students
                        previously or are currently teaching.</Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default SchoolLocation
