import { Backdrop, Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import line from '../../../../../assets/line.svg'
import Rating from '@mui/material/Rating';
import SampleImage from '../../../../../assets/SampleImage.svg';
import { teacherReviews } from '../../../../features/teacher/teacherClassConductSlice';
import { useDispatch, useSelector } from 'react-redux';


const personalInfoData = [
    { title: "Invite for Reviews" },
    { title: "Manage Review" },
];

// teacherGetReview



const TabReviews = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const [value, setValue] = React.useState(0);
    const [review, setReview] = React.useState([]);
    const dispatch = useDispatch();


    const handleReviews = () => {
        const user = JSON.parse(localStorage.getItem('user'))
        const userId = user?.id
        dispatch(teacherReviews({ teacherId: userId }))
            .then((response) => {
                if (response?.payload?.success) {
                    setReview(response?.payload?.data)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }
    useEffect(() => {
        handleReviews()
    }, [])

    const randomColor = () => {
        // Generate a random hex color
        return '#' + Math.floor(Math.random()*16777215).toString(16);
      };

    const loading = useSelector((item) => item?.teacherClassConduct?.loading);

    return (
        <Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            {/* <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '2%', display: 'flex'}}>
                    <Box>
                        <Typography variant='h5'>Reviews</Typography>
                    </Box>
                </Box>
            </Box> */}

            <Box className="d-flex justify-content-center mt-2">
                <Box style={{ width: isXs ? '95%' : '95%' }}>
                    <Box className="d-flex row" >
                        <Box style={{ width: isXs ? '45%' : '95%' }}>
                            { review && review.length>0 ? review.map((info , index)=>{
                                return (
<Box className=" mt-2 px-3 py-2 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%' }}>
                                <Box className='d-flex justify-content-between py-1' style={{ marginBottom: '1%' }}>
                                    {/* <Box className='d-flex align-items-center' style={{width:'210px'}}>
                                        <img src={info?.file?.file_name ?? SampleImage} style={{ width: '30%', marginLeft: '1%' }} />
                                        <Box sx={{ '& > legend': { mt: 2 }, marginLeft: '5%' }} >
                                            <Rating
                                                name="simple-controlled"
                                                value={info.rating}
                                                // onChange={(event, newValue) => { setValue(info?.rating) }}
                                                size="small" />
                                        </Box>
                                    </Box> */}
                                         <Box
                 sx={{
                   width: '50px',
                   height: '50px',
                   borderRadius: '50%',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   backgroundColor: info?.file?.file_name ? 'transparent' : randomColor(),
                   overflow: 'hidden',
                  //  marginLeft:"25px",
                   cursor: "pointer"
                   // Ensure the Box has a defined size and that children are properly aligned
                 }}
               >
                 {info?.file?.file_name ? (
                   <img
                     src={info?.file?.file_name}
                     alt="profile-img"
                     style={{
                       width: '100%',
                       height: '100%',
                       objectFit: 'cover',
                       // Ensure that the image covers the Box without stretching it
                     }}
                   />
                 ) : (
                   <Typography
                     variant="h5"
                     sx={{ color: 'white', fontWeight: 'bold' }}
                   >
                     {`${info?.student?.first_name?.charAt(0)?.toUpperCase()}`}
                   </Typography>
                 )}

               </Box>
               <Box
                              sx={{ marginRight:'70px'  }}
                            >
                              <Rating
                                name="simple-controlled"
                                value={info.rating}
                                // onChange={(event, newValue) => { setValue(info?.rating) }}
                                size="small"
                              />
                            </Box>
                                    <Box sx={{ fontSize: '14px' }}>{info?.createdAt}</Box>
                                </Box>
                                <Box className='d-flex py-1 '>
                                    <Box>{info?.student?.first_name+" "+info?.student?.last_name}</Box>
                                </Box>
                                <Box className='py-1' style={{ fontSize: '13px' }}>{info?.description}</Box>
                            </Box>
                                )
                            }): loading ? '' : 'No Data Found' }
                            
                        </Box>
                    </Box>

                </Box>
            </Box>

        </Box>
    )
}

export default TabReviews
