import React from 'react'
import img from '../../../assets/img.jpg';
import loginstudent from '../../../assets/loginstudent.svg';
import line from '../../../assets/line.svg';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate()
    return (
        <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '400px', height: '560px' }}>
                            <div style={{ marginTop: '20%', backgroundColor: '#ffffff' }}>
                                <div >
                                    <div >
                                        <img src={loginstudent} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <div className='d-flex flex-row justify-content-center p-2'>
                                        <div>
                                            <h3 className="d-flex justify-content-center mt-3" style={{ fontSize: '30px', fontWeight: 'bold' }}>Login as Student</h3>
                                            <img src={line} className='mb-4' />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center fw-bold py-2">
                                        <Box
                                            component="form"
                                            sx={{ borderColor: 'white', borderRadius: 0}}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField label="Email or Phone Number" size='small' placeholder='Enter your Email or Phone Number' inputProps={{ style: { width: '300px', borderRadius:'0' } }} color='grey' focused />
                                        </Box>
                                    </div>
                                    <div className="mt-3 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                        <button onClick={()=> navigate('loginOtp')} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor:'#CB8B65' }}>Continue</button>
                                    </div>
                                    <div className="flex-grow-1 text-center py-4" style={{ fontSize: '12px', fontWeight: '900' }}>
                                        <small className="block">
                                            <span className="">New User ?</span>
                                            <a href="#" style={{ color: '#AF6031' }}>Sign Up Now !</a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
