import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { teacherDetails } from '../../../../features/teacher/teacherListingSlice';

const personalInfoData = [
    { image: <SchoolOutlinedIcon sx={{ color: '#CB8B65' }} />, title: "Education", content: "0 Degree, 0 Certificate" },
    { image: <TranslateOutlinedIcon sx={{ color: '#CB8B65' }} />, title: "Language you Speak", content: "Not Available" },
    { image: <SummarizeOutlinedIcon sx={{ color: '#CB8B65' }} />, title: "About Overview", content: "Not Available" },
];

const QualificationExp = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [education , setEducation] = useState();
    const [languages , setLanguage] = useState([]);
    const [overview , setOverview] = useState();

    const profileEducationNavigate =()=> {
        navigate('/dashboard/profileeducation')
    }
    const languageNavigate =()=> {
        navigate('/dashboard/language')
    }
    const summaryNavigate =()=> {
        navigate('/dashboard/summary')
    }
     const goBack = () => {
        navigate(-1);
      };

      const handleAddress = () => {
        const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setEducation(response?.payload?.data?.teacher_profile?.degree?.degree_name)
                    setOverview(response?.payload?.data?.teacher_profile?.overview)
                    setLanguage(response?.payload?.data?.languages)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })

            
    }

    const languageNames = languages.map(lang => lang.language_name).join(', ');


    useEffect(() => {
        handleAddress()
    }, [])
    return (
        <Box>
        <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
            <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
            <button onClick={goBack} style={{border:'none', background:'transparent', display:'flex', alignItems:'start'}}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h5' style={{fontFamily: 'jost'}}>Qualification & Experience</Typography>
                    <Box>Update information about your Qualification & Experience.</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
        </Box>
        <Box style={{ width: isXs ? '100%' : '100%'}}>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ width: '100%' }}>
                        <button onClick={() => profileEducationNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' , padding: '.5rem' }}>
                                <Box style={{ display: 'flex', gap: 8 }}>
                                    <Box><SchoolOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                                    <Box>
                                        <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Education</Box>
                                        {/* <Box style={{ textAlign: 'start', fontSize: '14px' }}>0 Degree, 0 Certificate</Box> */}
                                        <Box style={{ textAlign: 'start', fontSize: '14px' }}>{education}</Box>

                                    </Box>
                                </Box>
                                <Box>
                                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                                </Box>
                            </Box>
                        </button>
                    </Box>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ width: '100%' }}>
                        <button onClick={() => languageNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' , padding: '.5rem'  }}>
                                <Box style={{ display: 'flex', gap: 8 }}>
                                    <Box><TranslateOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                                    <Box>
                                        <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>Language you Speak</Box>
                                        {/* <Box style={{ textAlign: 'start', fontSize: '14px' }}>Not Available</Box> */}
                                        <Box style={{ textAlign: 'start', fontSize: '14px' }}>{languages.length > 0 ? languageNames : 'Not Available'}</Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                                </Box>
                            </Box>
                        </button>
                    </Box>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ width: '100%' }}>
                        <button onClick={() => summaryNavigate()} style={{ backgroundColor: '#FBFBFB', marginTop: '2%', width: '100%', border: 'none', height: '5rem', padding: 10 }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' , padding: '.5rem'  }}>
                                <Box style={{ display: 'flex', gap: 8 }}>
                                    <Box><SummarizeOutlinedIcon sx={{ color: '#CB8B65' }} /></Box>
                                    <Box>
                                        <Box style={{ textAlign: 'start', fontSize: '18px', fontWeight: '500' }}>About/Overview</Box>
                                        <Box style={{ textAlign: 'start', fontSize: '14px' }}>{overview ? 'Available' : 'Not Available'}</Box>

                                        {/* <Box style={{ textAlign: 'start', fontSize: '14px' }}>Not Available</Box> */}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box style={{ color: '#D1734A', backgroundColor: '#FFDB7E', border: '1px solid #CB8B65', borderRadius: '50%', padding: '3px' }}> <ArrowForwardIosRoundedIcon /> </Box>
                                </Box>
                            </Box>
                        </button>
                    </Box>
                </Box>

        </Box>
    </Box>
    )
}

export default QualificationExp
