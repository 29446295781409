import React, { useState } from "react";
import logo from "../../../assets/TClogo.png";
import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import accountDelete from "../../../assets/accountDelete.svg";
import Footer from "../LandingPage/Footer";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { accountCloseOtpSent, accountCloseVerifyOtp, resendCloseVerifyOtp } from "../../features/common/commonSlice";

function AccountDeleteTeacher() {
  const isXs = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = React.useState(false);
  const [resendCounter, setResendCounter] = useState(30);
  const dispatch = useDispatch();
  const handleOpen = () => {
    if (fullName == "" || email == "" || mobile == "" || desc == "") {
      toast.error("All fields are required");
      return;
    }
    if(validateMobileNumber(mobile)){
       setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const validateMobileNumber = (number) => {
    // Indian mobile number regex: starts with 7, 8, or 9 and has 10 digits
    const regex = /^[789]\d{9}$/;

    if (number.length > 10) {
      toast.error('Invalid mobile number: More than 10 digits.');
      return false;
    } else if (!regex.test(number)) {
      toast.error('Invalid mobile number: Must start with 7, 8, or 9 and have 10 digits.');
      return false;
    }
    return true;
  };

  const [openVerifyOtp, setOpenVerifyOtp] = React.useState(false);
  const handleOpenVerifyOtp = () => {
    setOpen(false);
    dispatch(
      accountCloseOtpSent({
        fullName: fullName,
        email: email,
        mobile: mobile,
        desc: desc,
        roleId: 3,
      })
    ).then((response) => {
      if (response?.payload?.status == 200) {
        toast.success(response?.payload?.message);
        setOpenVerifyOtp(true);
      }else{
        toast.success(response?.payload?.message);
      }
    });
  };
  const handleCloseVerifyOtp = () => setOpenVerifyOtp(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [desc, setDesc] = useState("");
  const [otp, setOtp] = useState("");

  const handleOtpSubmit = () =>{
    if (otp == "") {
        toast.error("Otp field is required");
        return;
      }
      dispatch(
        accountCloseVerifyOtp({
          mobile: mobile,
          roleId: 3,
          otp:otp,
          description: desc,
        })
      ).then((response) => {
        if (response?.payload?.status == 200) {
          toast.success(response?.payload?.message);
          setOpenVerifyOtp(false);
          setFullName('');
          setEmail('');
          setDesc('');
          setMobile('');
          setOtp('');
        }else{
          toast.success(response?.payload?.message);
        }
      });
  }

  const handleResendOtp = () => {
    dispatch(resendCloseVerifyOtp({mobile:mobile , roleId:3 , type:2})).then((response)=>{
        if(response.payload.status==200){
            toast.success(response?.payload?.message);
            setResendCounter(30); // Reset the counter
        }
    })
  };

  React.useEffect(() => {
    let timer;
    if (resendCounter > 0) {
      timer = setInterval(() => {
        setResendCounter((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [resendCounter]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              width: "100%",
              height: "12rem",
              backgroundColor: "#CB8B65",
              padding: isXs ? "2rem" : "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="" width={200} />
            <h1
              style={{
                fontSize: "2rem",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Account Deletion Request - Tutor
              </h1>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <Grid container spacing={3} sx={{ width: "100%" }}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  label="Full Name"
                  variant="outlined"
                  sx={{ width: isXs ? "80%" : "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  variant="outlined"
                  sx={{ width: isXs ? "80%" : "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { width: isXs ? "80%" : "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Phone number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    sx={{
                      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      "input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    type="number"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            style={{
              padding: isXs ? "2rem" : "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1.3rem",
              width: isXs ? "70%" : "100%",
              marginLeft: isXs ? "2%" : "",
              textAlign: isXs ? "" : "center",
            }}
          >
            <Box>
              <h1 style={{ fontSize: "1.2rem", fontWeight: 600 }}>
                Reason For Delete Account
              </h1>
            </Box>
            <Box sx={{ textAlign: isXs ? "" : "center" }}>
              Before you leave, please tell us why you'd like to delete your
              account. This information will help us improve.
            </Box>
            <Box>
              <TextField
                id="outlined-multiline-static"
                onChange={(e) => setDesc(e.target.value)}
                value={desc}
                multiline
                rows={4}
                sx={{ width: isXs ? "80%" : "100%" }}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                  backgroundColor: "#F4180A",
                  "&:hover": {
                    backgroundColor: "#F4180A",
                  },
                  width: isXs ? "30%" : "90%",
                }}
              >
                Delete My Account
              </Button>
            </Box>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={accountDelete} style={{ width: "60%" }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    width: "90%",
                    textAlign: "center",
                    fontSize: isXs ? "" : "1.3rem",
                  }}
                >
                  Are You Sure You Want To Delete Your Account?
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: isXs ? "5%" : "10%",
                  marginBottom: isXs ? "5%" : "10%",
                }}
              >
                <Box
                  className="mt-4 d-flex justify-content-evenly px-5"
                  style={{ width: "100%" }}
                >
                  <Box style={{ width: "50%", marginRight: "8px" }}>
                    <button
                      onClick={handleClose}
                      className="btn btn-primary py-2 w-100 border-1"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#E9E9E9",
                        color: "#2E363D",
                        fontWeight: "600",
                        borderRadius: "0",
                        borderColor: "#CB8B65",
                      }}
                    >
                      No
                    </button>
                  </Box>
                  <Box style={{ width: "50%", marginLeft: "8px" }}>
                    <button
                      onClick={handleOpenVerifyOtp}
                      className="btn btn-primary py-2 w-100 border-1"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#CB8B65",
                        color: "white",
                        fontWeight: "600",
                        borderRadius: "0",
                        borderColor: "#CB8B65",
                      }}
                    >
                      Yes
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={openVerifyOtp}
            onClose={handleCloseVerifyOtp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  OTP Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, width: "90%", textAlign: "center" }}
                >
                  Please enter the OTP sent to your registered mobile number.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <TextField
                  label="OTP"
                  variant="outlined"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  sx={{ width: "50%", textAlign: "center" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <Button
                  variant="contained"
                    onClick={handleOtpSubmit}
                  sx={{ backgroundColor: "#CB8B65", color: "white" }}
                >
                  Verify OTP
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <Typography>
                  Didn't receive the OTP?{" "}
                  <Button
                    variant="text"
                      onClick={handleResendOtp}
                      disabled={resendCounter > 0}
                    sx={{ color: "#CB8B65", textTransform: "none" }}
                  >
                    Resend OTP {resendCounter > 0 && `(${resendCounter}s)`}
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default AccountDeleteTeacher;
