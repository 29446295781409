import axios from "axios";
const APP_URL = 'https://app.kanhahometutions.com/';


const axiosInstanceSM = axios.create({
    timeout: 1000000,
    baseURL: APP_URL,
  });


axiosInstanceSM.interceptors.request.use(function (config) {
  // Do something before request is sent
   const user = JSON.parse(localStorage.getItem('user'))
   if(user){
    const token = user.accessToken
    if(token){
        config.headers['Authorization'] = 'Bearer ' + token;
    }
   }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


export default axiosInstanceSM;
