import { combineReducers } from 'redux';
import studentReducer from '../features/student/studentSlice';
import stepperReducer from '../features/student/listingSlice';
import teacherDropDownReducer from '../features/teacher/teacherListingSlice';
import teacherAuthReducer from '../features/teacher/teacherAuthSlice';
import teacherClassConductReducer from '../features/teacher/teacherClassConductSlice';
import adminReducer from '../features/admin/adminAuthSlice';
import accountPostReducer from '../features/admin/accountPostSlice';


export const rootReducer = combineReducers({
    student: studentReducer,
    listing: stepperReducer,
    teacherListing: teacherDropDownReducer,
    teacherSignUp: teacherAuthReducer,
    teacherClassConduct: teacherClassConductReducer,
    admin:adminReducer,
    accountPost:accountPostReducer
  });
  