import React, { useState } from 'react'
import img from '../../../assets/img.jpg';
import line from '../../../assets/line.svg';
import Otp1 from '../../../assets/Otp1.png';
import studentverify from '../../../assets/studentverify.svg'
import CurveLine from '../../../assets/CurveLine.png';
import wifi2 from '../../../assets/wifi2.png';
import LogoHeader from '../../../assets/LogoHeader.svg';
import book from '../../../assets/book.svg';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoginOtp = () => {

    const [otp, setOtp] = useState('')

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');

    return (
        <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '400px', height: '560px' }}>
                            <div style={{ marginTop: '20%', backgroundColor: '#ffffff' }}>
                                <div >
                                    <div style={{display: isXs ? '' : 'none'}}>
                                        <img src={studentverify} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <div className='col-md-6' style={{display: isXs ? 'none' : ''}}>
                                        <div style={{backgroundColor:'#CB8B65', height: '20rem', position:'relative', borderBottomLeftRadius:'20px', borderBottomRightRadius:'20px'}}>
                                            <div className='d-flex justify-content-center'>
                                                <img src={LogoHeader} style={{ position:'absolute', width:'40%', top:'1rem'}}/>
                                                <div style={{ position:'absolute', color:'white', top:'5.5rem', border:'2px solid white', borderRadius:'20px', width:'40%', textAlign:'center'}}>For Students</div>
                                                <img src={Otp1} style={{ position:'absolute', bottom:0, width:'50%'}}/>
                                            </div>
                                            <img src={wifi2} style={{ position:'absolute', bottom:0, left:5}}/>
                                            <img src={book} style={{ position:'absolute', top:0, right:5}}/>
                                            <img src={CurveLine} style={{ position:'absolute', top:0, left:0}}/>
                                            <img src={CurveLine} style={{ position:'absolute', bottom:0, right:0, rotate:'180deg'}}/>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row justify-content-center'>
                                        <div>
                                            <h3 className="d-flex justify-content-center mt-4" style={{ fontSize: '22px', fontWeight: 'bold' }}>OTP Verification</h3>
                                            <img src={line} className='mb-4' />
                                        </div>
                                    </div>
                                    <div className='fw-bolder text-center' style={{ fontSize: '12px', display: isXs ? '' : 'none' }}>Please enter the One-Time Password to verify your account</div>
                                    <div className='fw-bolder py-1 text-center' style={{ fontSize: '10px' }}>A One-Time Password has been sent to 985312121*32</div>
                                    <div className="d-flex justify-content-center fw-bold py-2">
                                        <div className='d-flex justify-content-center col gap-4' style={{ width: '100%' }}>
                                            <MuiOtpInput value={otp} onChange={handleChange} length={4} style={{ width: '70%' }} />
                                        </div>
                                    </div>
                                    <div className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                        <button className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>
                                    </div>
                                    
                                    <div className="flex-grow-1 text-center py-3" style={{ fontSize: '12px' }}>
                                        <small className="block">
                                            <span className="">Entered a wrong number? </span>
                                            <a href="#" style={{ color: '#AF6031', fontWeight:'bold' }}>Resend.</a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginOtp
