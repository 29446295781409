import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";
// import axios from "../../../Utils/axios";
// import axios from "axios";
// import axiosInstance from "../../../Utils/axios";

export const adminLogin = createAsyncThunk(
  "otp/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`adminAuthLogin`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminVerifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`adminAuthVerifyOtp`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);



const initialState = {
  adminDetails: [],
  verifyOptData:[],
  adminMobile:'',
  loading: false,
  error: null,
};

export const adminAuthSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    storeMobile: (state, { payload }) => {
      state.adminMobile = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.adminDetails = action.payload;
      state.error = null;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.loading = false;
      state.adminDetails = null;
      state.error = action.payload;
    });
    builder.addCase(adminVerifyOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adminVerifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.verifyOptData = action.payload;
      state.error = null;
    });
    builder.addCase(adminVerifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.verifyOptData = null;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { storeMobile} =
adminAuthSlice.actions;

export default adminAuthSlice.reducer;
