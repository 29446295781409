import logo from "../../../assets/TClogo.png";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import Footer from "../LandingPage/Footer";
import NavBar from "../../Navigation/NavBar";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


function NotFound() {
    const isXs = useMediaQuery('(min-width:600px)');

    return (
        <>
            <Box style={{ width: '100%' }}>
            <NavBar/>
                  <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>404 Not Found</div></div>
                <Box sx={{alignItems: 'center', justifyContent: 'center'}} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
               


                    <Box sx={{alignItems: 'center', justifyContent: 'center'}} style={{ padding:isXs? '2rem':'1rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width: isXs?'70%':'100%', height: '30rem'}}>
                    <h1 style={{textAlign: 'center'}}>404 Not Found</h1>
                    <p style={{textAlign: 'center'}}>The page you are looking for does not exist.</p>
                </Box>
            </Box>
            </Box>
           <Footer/>
        </>
    );
}

export default NotFound;
