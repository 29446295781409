import React, { useEffect, useState } from 'react';
import img from '../../../assets/BG_NEW.jpg';
import { Backdrop, CircularProgress, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { studentProfileDetails } from '../../features/student/listingSlice';

const InquiryLayout = (props) => {
const isXs = useMediaQuery('(min-width:600px)');

const [postCount , setPostCount] = useState();




const user = JSON?.parse(localStorage?.getItem('user'))
// console.log(user?.postCount , 'usersss');

const dispatch = useDispatch()

    const studentDetails = () => {
        dispatch(studentProfileDetails({ userId: user?.id })).then((response) => {
            if (response?.payload?.status == 200) {
              setPostCount(response?.payload.data.postCount)
            }
        })
            .catch((error) => {
                console.log('getting error', error);
            });
    }

    useEffect(() => {
      studentDetails()
    }, [])

    const loading = useSelector((state) => state?.listing?.loading)

    console.log(postCount , 'postCountasssssss-inquiry');


  return (
    // <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
          <div style={{
            backgroundImage: postCount == 0 ? `url(${img})` : 'none',
            backgroundSize: 'cover',
            height: '100vh',
          }}>

<div className='d-flex flex-row justify-content-center align-items-center'>
        <div className='container border-0'>
          <div className='row justify-content-center'>
            <div style={{ width: '800px', height: '560px'}}>
                <div style={{marginTop: isXs ? (postCount == 0 ? "10%" : "4%") : '10%',backgroundColor: '#ffffff'}}>
                    {props.children}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default InquiryLayout
