import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';  
import DeleteIcon from '@mui/icons-material/Delete';  
import IconButton from '@mui/material/IconButton'; 
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, TextField } from '@mui/material';
import axiosInstanceSM from "../../Utils/axiosSm";
import { classListV2, subjectListV2 } from "../features/admin/accountPostSlice";
import { useDispatch } from "react-redux";
import { DialogActions, DialogContent, DialogTitle, Dialog  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";



const CustomSwitch = styled(Switch)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
    '& .MuiSwitch-thumb': {
      color: theme.palette.action.disabled,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

const columns = [
  { id: "id", label: "Sr.No" },
  { id: "BoardName", label: "Board Name", minWidth: "160px" },
  { id: "Classes", label: "Classes", minWidth: "160px" },
  { id: "Subject", label: "Subject", minWidth: "160px" },
  // { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

function SubjectsStudyMaterial() {
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueNew, setSelectedValueNew] = useState('');
  const [subject, setSubjectName] = useState("")
  const dispatch = useDispatch();
  const [subjectData ,setSubjectData] = useState([])
  const [data , setData]= useState([]);
  const [classData , setClassData]= useState([]);
  const [openNew , setOpenNew] = useState()
const [boardId, setBoardId] = useState('');
const [classId , setClassId] = useState('')
const [editId, setEditId] = useState(null);
const [editBoardId, setEditBoardId]=useState('')
const [className, setClassName] = useState('');
const [deleteId, setDeleteId] = useState('')
const [openDelete, setOpenDelete] = useState('');

const handleClose = () => {
  setOpenNew(false);  
};
const handleCloseDelete = () => {
  setOpenDelete(false);
};

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);}

  const handleChangeNew = (event) => {
    setSelectedValueNew(event.target.value);}

    useEffect(() => {
      const fetchData = async () => {
        const d = new Date();
        let text = d.toLocaleDateString();
        let token = `vkSoft1234${text}`; 
        const payload = {
          token: token,
        };
        try {
          const response = await axiosInstanceSM.post("api/sm/v1/boards", payload);
          setData(response.data.data);
        } catch (error) {
        }
      };
      fetchData();
    }, []);

      const fetchDatasubjects = async () => {
        const d = new Date();
        let text = d.toLocaleDateString();
        let token = `vkSoft1234${text}`; 
        const payload = {
          token: token,
          notesRequired:'1'
        };
        try {
          const response = await axiosInstanceSM.post("api/sm/v1/subjects", payload);
          setSubjectData(response.data.data);

        } catch (error) {
        }
      };
    useEffect(() => {
      fetchDatasubjects();
    }, []);

    const handleClassData = async (boardId)=>{
        const d = new Date(); 
        let text = d.toLocaleDateString();
        let token = `vkSoft1234${text}`; 
        const payload = {
          token: token,
          paginate :'1',
          'boardId':boardId
        };
        try {
          const response = await axiosInstanceSM.post("api/sm/v1/classes", payload);
          setClassData(response.data.data);
        } catch (error) {
          
        }

    }
    
    const handleSubmitSubject = async (e) => { 
      e.preventDefault();
      setIsDialogOpen(false);

      const formdata = new FormData();
      const d = new Date();
      let text = d.toLocaleDateString();
      let token = `vkSoft1234${text}`;
      formdata.append("classId", classId)
      formdata.append("token", token);
      formdata.append("boardId", boardId );
      formdata.append("subjectName", subject)
      
      try {
        const response = await axiosInstanceSM.post("api/sm/v1/subject", formdata);
        if (response.data.success = true) {
          toast.success(response.data.message)
          fetchDatasubjects()
          setSelectedValue('')
          setSelectedValueNew('')
        } else {
          toast.error(response.data.message)
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message || 'Something went wrong!');
        }        }
    };

    const handleEditbutton = (item) => {
            setOpenNew(true);    
        handleClassData(item.board.boardId)
        setClassName(item.class.className);
        setSelectedValue(item.board.boardId);
        setClassId(item.class.classId);
        setEditId(item.subjectId);
        setSubjectName(item.subjectName)     
    };
  
    const handleEditSubmit = async (e) => {
      e.preventDefault();
      const formDataNew = new FormData();
      formDataNew.append("token", "abcd123");
      formDataNew.append("_method", "PUT");
      formDataNew.append("classId", classId);
      formDataNew.append("boardId", selectedValue);
      formDataNew.append("subjectName", subject);    
      try {
        const response = await axiosInstanceSM.post(`api/sm/v1/subject/${editId}`, formDataNew);
    
        setClassData(prevData =>
          prevData.map(item => {
            if (item.subjectId === editId) {
              item.className = className;  
              item.boardId = selectedValue;
              item.subjectName = subject
            }
            return item;
          })
        );
        if (response.data.success == true) {
          toast.success(response.data.message)
          fetchDatasubjects()
        } else {
          toast.success(response.data.message)
        }
        setOpenNew(false);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message || 'Something went wrong!');
        }        }

    };
    
    const deleteRow = async () => {
      if (!deleteId) return; 
  
      setOpenDelete(false);

      const d = new Date();
      let text = d.toLocaleDateString();
      let token = `vkSoft1234${text}`;
      const payload = {
        token: token,
        _method: 'DELETE',
      };
        
      try {
        const response = await axiosInstanceSM.post(`api/sm/v1/subject/${deleteId}`, payload );
  
        if (response.data.success = true) {
          setSubjectData((prevData) => prevData.filter((row) => row.id !== deleteId));
          toast.success(response.data.message)
          fetchDatasubjects()
        } else {
          toast.error(response.data.message)
        }
      } catch (error) {
      }
    };
    const subjectList = () => {
      const d = new Date();
      let text = d.toLocaleDateString();
      let token = `vkSoft1234${text}`;
  
      const payload = {
        token: token,
        paginate: "1",
      };
      dispatch(subjectListV2(payload));
    };
  
    useEffect(() => {
      subjectList();
    }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4rem",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          marginTop: "0rem",
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          justifyContent: "space-between",
          boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
        }}
      >
        <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
Subjects
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup>
            <Form.Control
              placeholder="Search..."
              aria-label="Search"
            />
            <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
              <img src={search} alt="" />
            </Button>
          </InputGroup>
        </div>
      </div>
      <div style={{ marginLeft: "2rem", paddingTop: "30px", paddingBottom: "10px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontSize: "1.5rem" }}>Subjects List</div>
          <div 
            onClick={openDialog} 
            style={{
              marginRight: '2rem', 
              color: 'white', 
              borderRadius: '5px', 
              backgroundColor: '#CB8B65', 
              padding: '6px 10px', 
              cursor: 'pointer'
            }}
          >
            Add New
          </div>
        </div>
      </div>

      {isDialogOpen && (
        <div
          id="backdrop"
          style={{
            position: "fixed",
            top: "0", left: "0", right: "0", bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "999",
            display: "flex", justifyContent: "center", alignItems: "center",
          }}
        >
          <div 
            style={{
              position: "relative", backgroundColor: "white", padding: "20px", borderRadius: "8px", width: "400px",
            }}
          >
            <button 
              onClick={closeDialog} 
              style={{
                position: "absolute", top: "10px", right: "10px", background: "none", border: "none", fontSize: "20px", cursor: "pointer"
              }}
            >
              &times;
            </button>
            <h3 style={{marginBottom:'15px'}}>Add New Subjects</h3>
            <form onSubmit={handleSubmitSubject} >
        <FormControl fullWidth>
      <InputLabel id="dropdown-label">Board Name</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedValue}
        onChange={handleChange}
        required
        label="Board Name"
      >
        { data.map((item, index) => (
   <MenuItem key={index}  value={item.boardId}  onClick={() =>[handleClassData(item.boardId), setBoardId(item.boardId)]}>  {item.boradName}</MenuItem>
         ))}
      </Select>
    </FormControl>
    
    <FormControl style={{marginTop:'20px'}} fullWidth>
      <InputLabel id="dropdown-label">Class Name</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedValueNew}
        onChange={handleChangeNew}
        required
        label="Class Name"
        >
       {Array.isArray(classData) && classData.map((item, index) => (
      <MenuItem key={index} value={item.classId} onClick={() => setClassId(item.classId)}>{item.className}</MenuItem>
   ))}
      </Select>
    </FormControl>

    <div>
  <TextField
    label="Subject"
    placeholder="Enter Subject Name"
    fullWidth
    required
    variant="outlined"
    onChange={(e) => setSubjectName(e.target.value)}
    margin="normal"
  />
</div>
<DialogActions >
      <button type="submit" style={{ backgroundColor: "#CB8B65", color: "white", padding: "7px 15px", borderRadius: "5px", }}>Submit</button>
      </DialogActions>
            </form>
          </div>
        </div>
      )}

<Dialog open={openNew} onClose={handleClose}>
  <DialogTitle> Edit Subject</DialogTitle>
  <DialogContent >
  
  <FormControl style={{paddingTop:'10px'}} fullWidth>
      <InputLabel style={{paddingTop:'15px'}} id="dropdown-label">Board Name</InputLabel>
      <Select
        labelId="dropdown-label"
        defaultValue={selectedValue}
        onChange={handleChange}
        label="Board Name"
      >
        {Array.isArray(data) && data.map((item, index) => (
   <MenuItem key={index}  value={item.boardId} onClick={() => [ handleClassData(item.boardId), setBoardId(item.boardId)]}> {item.boradName}</MenuItem>
         ))}
      </Select>
    </FormControl>
    
    <FormControl style={{marginTop:'20px'}} fullWidth>
      <InputLabel id="dropdown-label">Class Name</InputLabel>
      <Select
        labelId="dropdown-label"
        defaultValue={classId}
        onChange={handleChangeNew}
        label="Class Name"
        >
       {Array.isArray(classData) && classData.map((item, index) => (
      <MenuItem key={index} value={item.classId} onClick={() => setClassId(item.classId)}>{item.className}</MenuItem>
   ))}
      </Select>
    </FormControl>

    <div>
  <TextField
    label="Subject"
    placeholder="Enter Subject Name"
    fullWidth
    required
    value={subject}
    variant="outlined"
    onChange={(e) => setSubjectName(e.target.value)}
    margin="normal"
  />
</div>
  </DialogContent>
  <DialogActions>
    <button
      onClick={handleClose}
      style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}
    >
      Cancel
    </button>
    <button
      onClick={handleEditSubmit}
      style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}
    >
      Save
    </button>
  </DialogActions>
</Dialog>

<Dialog  open={openDelete} onClose={handleCloseDelete}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px'  }}>
          <CloseIcon style={{ color: 'red', fontSize: '60px', padding:'10px', marginBottom: '20px', borderRadius:'50%', border:'2px solid red'   }} />
          <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
            Are you sure ?
          </div>
          <div style={{textAlign: 'center',width:'320px' , marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button 
              onClick={handleCloseDelete} 
              style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width:'100px', border:'0px' }}
            >
              Cancel
            </button>
            <button 
              onClick={deleteRow} 
              style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width:'100px', border:"0px"}}
            >
              Yes
            </button>
          </DialogActions>
        </div>
      </Dialog>

      <Paper sx={{ width: "95%", overflow: "hidden", marginLeft: "2rem" }}>
        <TableContainer sx={{ maxHeight: 360 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align} style={{minWidth: column.minWidth, backgroundColor: "#CB8B65",
                      fontWeight: "500",
                      color: "#ffffff",
                      fontSize: "1.1rem", }} >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                {Array.isArray(subjectData) && subjectData.map((item) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                    <TableCell>{item.subjectId}</TableCell>
                    <TableCell>{item.board.boradName}</TableCell>
                    <TableCell>{item.class.className}</TableCell>
                    <TableCell>{item.subjectName}</TableCell> 

                     {/* <TableCell>
                        <CustomSwitch
                            checked={item.accountStatus}
                            color="warning"
                          /></TableCell> */}
                        <TableCell> 
                        <IconButton
                        color="warning"
                        style={{ marginRight: "10px" }}
                        onClick={() => handleEditbutton(item)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton color="warning" onClick={() => { setDeleteId(item.subjectId); setOpenDelete(true); }}>
                        <DeleteIcon />
                      </IconButton></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          page={page}
        /> */}
      </Paper>
    </>
  );
}

export default SubjectsStudyMaterial;
