import React from 'react';
import plane from '../../../assets/plane.svg';
import Logout1 from '../../../assets/Logout.svg';
import arrow1 from '../../../assets/arrow1.svg';
import { Box, Modal, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { accountLogout } from '../../features/student/listingSlice';
import { useNavigate } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import store from '../../store/store';
// import store from "./components/store/store";



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Logout = ({ onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const persistor = persistStore(store);
  const classes = useStyles();
  const isXs = useMediaQuery('(min-width:600px)');


  const userLogout = () => {
    dispatch(accountLogout())
    persistor.purge().then(() => {
      localStorage.clear();
      localStorage.removeItem('persist:root');
      window.location.href = 'https://kanhahometutions.com';
    });
  }

  const handlleClose = () => {
    onClose();
  }

  return (
    <Modal open={true} onClose={onClose} className={classes.modal} >
      <Box style={{ width: '400px' }}>
        <Box style={{ backgroundColor: '#ffffff', width: isXs ? '' : '90%', marginLeft: isXs ? '' : '5%' }}>
          <Box>
            <Box>
              <img src={Logout1} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />
            </Box>
            <Box className='d-flex justify-content-end'>
              <img src={plane} alt='vector' className='img-fluid' />
            </Box>
            <Box className="d-flex justify-content-center fw-bold">
              <Box className="d-flex justify-content-center" style={{ color: "#CB8B65", fontSize: '28px', fontWeight: 'bold' }}>Logout !</Box>
            </Box>
            <Box className="d-flex justify-content-center"><Box style={{ width: '65%', textAlign: 'center', fontSize: '16px', color: '#000000' }}>Are you sure you want to log out?</Box></Box>
            <Box className="mt-4 d-flex justify-content-evenly px-5" style={{ width: '100%' }}>
              <Box style={{ width: '50%', marginRight: '8px' }}><button className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: 'white', color: '#7E7E7E', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }} onClick={handlleClose}>Cancel</button></Box>
              <Box style={{ width: '50%', marginLeft: '8px' }}><button className="btn btn-primary py-2 w-100 border-1" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }} onClick={userLogout}>Yes</button></Box>
            </Box>
            <img src={arrow1} alt='vector' className='img-fluid mt-3' style={{ height: '14%', width: '14%' }} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default Logout;
