import React, { useEffect } from 'react'
import SampleImage from '../../../assets/SampleImage.svg';
import line from '../../../assets/line.svg';


import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { Backdrop, CircularProgress, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { studentFeedback } from '../../features/student/listingSlice';



const Feedback = () => {

    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch()

    const userId = useSelector((item) => item?.listing?.postPayload?.userId)
    // console.log(userId);


    const reviewData = useSelector((state) => state?.listing?.studentGetFeedback)
    const loading = useSelector((state) => state?.listing?.loading)

    console.log(reviewData , 'reviewData');


    const studentReviews = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        dispatch(studentFeedback({userId:user?.id}))
    }
    
    useEffect(() => {
        studentReviews()
      }, [])

    const isXs = useMediaQuery('(min-width:600px)');

    const randomColor = () => {
        // Generate a random hex color
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
      };

    return (
        <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <Box className="d-flex mt-4" style={{ width:isXs ? '50%' : '80%', borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }}>
                Feedback and Ratings
            </Box>
            <img src={line} className='mb-3' style={{ borderRadius: '0px', marginLeft: '2%', fontSize: '30px' }} />
            {/* <Box className="d-flex row mt-3" style={{ marginLeft: '2%', overflowY: 'auto' }}>

                {reviewData?.map((item, index) => (
                    <Box key={index} style={{ width: isXs ? '45%' : '95%' }}>
                        <Box className=" mt-2 px-3 py-2 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%' }}>
                            <Box className='d-flex justify-content-between py-1' style={{ marginBottom: '1%' }}>
                                <Box className='d-flex align-items-center'>
                                    <img src={item?.file?.file_name ?? SampleImage} style={{ width: '30%', marginLeft: '1%' }} alt="" />
                                    <Box sx={{ '& > legend': { mt: 2 }, marginLeft: '5%' }} >
                                        <Rating
                                            name={`rating-${index}`}
                                            value={value}
                                            onChange={(event, newValue) => { setValue(newValue) }}
                                            size="small" />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='d-flex py-1 '>
                                <Box>{item?.teacher?.first_name}</Box>
                                <Box style={{ color: '#CB8B65' }}> - {item?.profession}</Box>
                            </Box>
                            <Box className='py-1' style={{ fontSize: '13px' }}>{item?.description}</Box>
                        </Box>
                    </Box>
                ))}
            </Box> */}
              <Box className="d-flex row mt-3" style={{ marginLeft: '2%', overflowY: 'auto' }}>
      {reviewData?.map((item, index) => (
        <Box key={index} style={{ width: isXs ? '45%' : '95%', marginBottom: '20px' }}>
          <Box className="mt-2 px-3 py-2 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white' }}>
            <Box className='d-flex justify-content-between py-1' style={{ marginBottom: '1%' }}>
              <Box className='d-flex align-items-center'>
                <div style={{ width: '50px', height: '50px', borderRadius: '50%', overflow: 'hidden', marginRight: '10px' }}>
                  {item?.file?.file_name ? (
                    <img src={item?.file?.file_name} alt="profile-img" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  ) : (
                    <div style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: randomColor(),
                      borderRadius: '50%',
                      color: 'white',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      textTransform: 'uppercase'
                    }}>
                      {`${item?.teacher?.first_name?.charAt(0).toUpperCase()}`}
                    </div>
                  )}
                </div>
                <Box sx={{ marginLeft: '5%' }}>
                  <Rating
                    name={`rating-${index}`}
                    readOnly 
                    value={item?.rating}
                    // onChange={(event, newValue) => { setValue(newValue) }}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
            <Box className='d-flex py-1'>
              <Box sx={{fontWeight: 'bold'}}>{item?.teacher?.first_name}</Box>
              <Box sx={{marginLeft: '4px' , fontWeight: 'bold'}}>{item?.teacher?.last_name}</Box>
              <Box style={{ color: '#CB8B65', marginLeft: '5px' }}> - {item?.profession}</Box>
            </Box>
            <Box className='py-1' style={{ fontSize: '13px' }}>{item?.description}</Box>
          </Box>
        </Box>
      ))}
    </Box>
        </>
    )
}

export default Feedback
