import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { userLists,helpSupportDataList } from "../../features/admin/accountPostSlice";
import {
  Backdrop, CircularProgress, Switch, styled, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popover,
  Typography,
  Box,
  Container,
} from "@mui/material";

const columns = [
  { id: "id", label: "ID" },
  { id: "name", label: "Person Name", minWidth: "160px" },
  { id: "mobile", label: "Mobile" },
  { id: "email", label: "Email" },
  { id: "message", label: "Message", minWidth: "200px" },
  { id: "createdRole", label: "Category" },
  { id: "createdAt", label: "Created At", minWidth: "200px" },
  // { id: "action", label: "Action" },
];

export default function ManageFeedback() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [swtichToggle, setSwtichToggle] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredReview, setHoveredReview] = useState('');

  const handlePopoverOpen = (event, review) => {
    setAnchorEl(event.currentTarget);
    setHoveredReview(review);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredReview('');
  };

  const open2 = Boolean(anchorEl);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // Handle the confirmation action here
    setSwtichToggle(!swtichToggle)

    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setRowsPerPage(+rowsPerPage);
    setPage(0);
  };

  const helpSupportList = () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token=`vkSoft1234${text}`
    console.log("x",token)
    dispatch(helpSupportDataList({ token:token })).then((item)=>{
      return(console.log("dd",item))}
    );
  };

  useEffect(() => {
    helpSupportList();
  }, []);

  const { helpSupportData, loading } = useSelector((state) => state?.accountPost);
console.log("s",helpSupportData)
  const helpSupportRows = helpSupportData?.data?.map((help) => {
    
    return {
      id: "HS00" + help?.id,
      name:help?.createdBy,
      mobile: help?.createdMobile,
      email: help?.createdEmail || "N/A",
      message:help?.messge || "",
      createdRole:help?.createdRole?.charAt(0).toUpperCase() + help?.createdRole?.slice(1).toLowerCase(),
      createdAt:help?.createdAt,
    };
  });

  
  const filteredRows = helpSupportRows?.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.mobile.includes(searchTerm) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.createdRole.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  
  const handleSwitchChange = (id) => {
    setOpen(true)
  };
  const handleActionBtn = (row) => {
    return (
      <CustomSwitch
        checked={swtichToggle}
        //row.enabled}
        onChange={() => handleSwitchChange(row.id)}
        color="warning"
      />
    )
  }
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      '& .MuiSwitch-thumb': {
        color: theme.palette.action.disabled,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  }));


  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        height: "100vh",
        borderRadius: "10px",
      }}
    >
      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            position: 'absolute',
            top: 50, // Align the dialog to the top
            m: 0 // Remove the default margin
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to make this change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ background: "#f3b054" }}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} style={{ background: "#f3b054" }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
      <main
        style={{
          marginLeft: "0rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "#ffffff",

            borderRadius: "10px",
            marginTop: "0rem",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "space-between",
            boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
          }}
        >
          <span
            style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}
          >
            Help & Support
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputGroup>
              <Form.Control
                placeholder="Search...."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
                <img src={search} alt="" />
              </Button>
            </InputGroup>
          </div>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <div>
            <p style={{ fontSize: "1.5rem" }}>Help & Support
              List </p>
          </div>
          <Paper
            sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth" }}
          >
            <TableContainer sx={{ maxHeight: 360 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#CB8B65",
                          fontWeight: "500",
                          color: "#ffffff",
                          fontSize: "1.1rem",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {loading ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <TableBody>
                    {filteredRows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              
                              const value = row[column.id];
                              return (
                                
                                column.id=="message" ?
                                <TableCell
                                sx={{cursor:"pointer"}}
                                onMouseEnter={(e) => handlePopoverOpen(e,value)}
                                onMouseLeave={handlePopoverClose}
                              >
                                {`${value.slice(0,16)}...`}
                              </TableCell>
                                :  <TableCell key={column.id} align={column.align}>

                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}

                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredRows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* Popover for displaying review on hover */}
      <Popover
        sx={{
          pointerEvents: 'none',border:"black"
        }}
        open={open2}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
            <Container maxWidth="md">

          <Box component="section" 
          width={180}
      sx={{ my:2, border: '2px solid grey' }}>
          <Typography sx={{ p: 1 }}>{hoveredReview}</Typography>
          </Box>
          </Container>

      </Popover>
        </div>
      </main>
    </div>
  );
}
