import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import DoneIcon from '../../../assets/done.svg';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#F4C95B',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#F4C95B',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'black',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <img src={DoneIcon} alt="Done" /> : null}
    </ColorlibStepIconRoot>
  );
}


ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];


export default function AllSteppers() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Steppers1() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export function Steppers2() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers3() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={2} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers4() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers5() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers6() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={5} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers7() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={6} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers8() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={7} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers9() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={8} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}

export  function Steppers10() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <div className='px-5'>
        <Stepper alternativeLabel activeStep={9} connector={<ColorlibConnector />}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </div>
    </Stack>
  );
}