import React from 'react'
import NavBar from '../Navigation/NavBar'
import Footer from '../pages/LandingPage/Footer'
import { useMediaQuery } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SchoolIcon from '@mui/icons-material/School';
import schoolImg from '../../assets/schoolNew.png'


function TeacherJobs() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
  return (
    <>
    <NavBar/>
    <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ?'6rem':'1rem', gap:'10px', height:'50px'}}> 
  <div><a style={{ color: 'white', textDecoration: 'none', fontSize:'1rem' }} href="/">Home</a></div><ArrowForwardIosIcon style={{fontSize:'14px', color:'white'}}/>
  <div style={{fontSize:'1rem ', color:'white'}}>Teacher Jobs</div></div>

  <div style={{paddingLeft:'6rem', fontSize:'20px', fontWeight:'500', backgroundColor:'#F4F9FF', display:'flex',alignItems:'center', height:'60px'}}>Search School Jobs</div>
  <div style={{display:'flex', justifyContent:'center', gap:'10px', backgroundColor:'#F6EBDC', marginTop:'30px', height:'80px', alignItems:'center'}}><input style={{width:'80%', height:'40px', fontSize:'16px',paddingLeft:'10px' }} type="text" placeholder='Enter Teaching Subject or Post' /><SearchIcon style={{backgroundColor:'#CB8B65', color:'white', fontSize:'40px', width:'90px'}}/> </div>

  <div style={{display:'flex', padding:"30px 5.5rem", gap:'30px', backgroundColor:'#F7F8F9'}}>
<div style={{width:'20%', border:'1px solid black', height:'200px'}}></div>
<div style={{width:'80%', display:'flex',gap:'20px', flexDirection:'column'}}>
    <div style={{width:'100%', borderRadius:'5px', height:'150px', position:'relative', backgroundColor:'white',boxShadow: '0 4px 8px rgba(0,0,0,0.2)'}}>
        <div style={{fontWeight:'500', fontSize:'18px', paddingLeft:'20px', paddingTop:'10px'}}>School Principal</div>
        <div style={{ paddingLeft:'20px'}}>XXXXXXX</div>
        <div style={{display:'flex',paddingLeft:'20px', paddingTop:'5px', gap:'30px',}}> <div style={{fontSize:'16px'}}><LocationOnIcon style={{color:'#CB8B65', fontSize:'18px'}}/> Jaipur, Rajsthan</div><div style={{fontSize:'16px'}}><AccessTimeIcon style={{color:'#CB8B65', fontSize:'18px'}}/> 3+ years experience </div> <div style={{fontSize:'16px'}}><CurrencyRupeeIcon style={{color:'#CB8B65', fontSize:'18px'}}/>15,000 - 50,000 </div> <div style={{fontSize:'16px'}}><SchoolIcon style={{color:'#CB8B65', fontSize:'18px'}}/> M.Ed in education</div></div>
        <div style={{position:'absolute' , right:'30px',top:'45px'}} ><img style={{width:'70px', height:'70px',borderRadius:'50%', border:'1px solid black', objectFit:'contain',}} src={schoolImg} alt=""/></div>
    </div>
    <div style={{width:'100%', borderRadius:'5px', height:'150px', backgroundColor:'white',boxShadow: '0 4px 8px rgba(0,0,0,0.2)'}}><div style={{fontWeight:'500', fontSize:'18px', paddingLeft:'20px', paddingTop:'10px'}}>Primary School English Teacher</div></div>
    <div style={{width:'100%', borderRadius:'5px', height:'150px', backgroundColor:'white',boxShadow: '0 4px 8px rgba(0,0,0,0.2)'}}><div style={{fontWeight:'500', fontSize:'18px', paddingLeft:'20px', paddingTop:'10px'}}>English High School Teacher Needs</div></div>
    <div style={{width:'100%', borderRadius:'5px', height:'150px', backgroundColor:'white',boxShadow: '0 4px 8px rgba(0,0,0,0.2)'}}><div style={{fontWeight:'500', fontSize:'18px', paddingLeft:'20px', paddingTop:'10px'}}>English Teacher required at Goodley Public School</div></div>

</div>

  </div>
    </>
  )
}

export default TeacherJobs