// ModalTable.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

function StudentOverviewPopup({ open, onClose, data, title, DataColumn }) {
  const dataRowItem=data?.map((items)=>{
    return {
      date: items?.createdAt,
      board_name: items?.board?.board_name,
      class_name: items?.classData?.class_name,
      subjects:items?.subjects?.map((subItem)=>subItem?.subjectName?.subject_name)?.join(", "),
      classPreference:items?.classPreference?.name,
       maxFee: items?.cost,
       classDayType: items?.classDayType?.name,
       classStartPlanType: items?.classStartPlanType?.name,
       classTypes: items?.classTypes?.map((subItem)=>subItem?.classTypeName?.name)?.join(", "),
       genderPreference: items?.genderPreference?.name,
       addressOne:items?.addressOne,
       addressTwo:items?.addressTwo,
       state:items?.state,
       city:items?.city,
       pincode:items?.pincode,
       postStatus:items?.postStatus

    }
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}

        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "relative",
            left: "725px",
            bottom: "17px",
            backgroundColor: "#d2d7c1",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} sx={{ scrollBehavior: "smooth" }}
        >
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#CB8B65", }}>
                {DataColumn?.map((item, index) => (
                  <TableCell key={index} 
                  style={{minWidth:"200px" ,color:"white"}}
                  >{item}</TableCell>

                ))}

              </TableRow>
            </TableHead>
            <TableBody>
              {dataRowItem.length>0 ?dataRowItem?.map((item) => (
                <TableRow key={item.id }>
                  <TableCell>{item?.date ?? "-"}</TableCell>
                  <TableCell>{item?.board_name ?? "-"}</TableCell>
                  <TableCell>{item?.class_name ?? "-"}</TableCell>
                  <TableCell>{item?.subjects ?? "-"}</TableCell>
                  <TableCell>{item?.classPreference ?? "-"}</TableCell>
                  <TableCell>{item?.maxFee ?? "-"}</TableCell>
                  <TableCell>{item?.classDayType ?? "-"}</TableCell>
                  <TableCell>{item?.classStartPlanType ?? "-"}</TableCell>
                  <TableCell>{item?.classTypes ?? "-"}</TableCell>
                  <TableCell>{item?.genderPreference ?? "-"}</TableCell>
                  <TableCell>{item?.addressOne ?? "-"}</TableCell>
                  <TableCell>{item?.addressTwo ?? "-"}</TableCell>
                  <TableCell>{item?.state ?? "-"}</TableCell>
                  <TableCell>{item?.city ?? "-"}</TableCell>
                  <TableCell>{item?.pincode ?? "-"}</TableCell>
                  <TableCell>{item?.postStatus?"Open":"Closed" ?? "-"}</TableCell>
                </TableRow> 
               
              )) :
              <TableRow>       <TableCell>{"no data found"}</TableCell>
</TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}


export default StudentOverviewPopup
