import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Divider, List, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { teacherDetails } from '../../../../features/teacher/teacherListingSlice';



const TabProfile = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const [overview , setOverview] = useState('');
    const [state , setState] = useState('');
    const [city , setCity] = useState('');
    const [taught , setTaught] = useState('');
    const [expDesc , setExpDesc] = useState('');
    const [exp , setExperience] = useState('');
    const [gender , setGender] = useState('');
    const [dob , setDob] = useState('');
    const [languages , setLanguage] = useState([]);
    const [degree , setDegree] = useState();
    const [university , setUniversity] = useState();
    const [jobType , setJobType] = useState();

    const handleAddress = () => {
        const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setDegree(response?.payload?.data?.teacher_profile?.degree?.degree_name)
                    setOverview(response?.payload?.data?.teacher_profile?.overview)
                    setLanguage(response?.payload?.data?.languages)
                    setState(response?.payload?.data?.teacher_profile?.state?.state_name)
                    setCity(response?.payload?.data?.teacher_profile?.city?.city_name)
                    setTaught(response?.payload?.data?.teacher_profile?.taught)
                    setExpDesc(response?.payload?.data?.teacher_profile?.experinceDesc)
                    setExperience(response?.payload?.data?.teacher_profile?.experience)
                    setGender(response?.payload?.data?.teacher_profile?.gender_id==0 ? 'Male' :'Female')
                    setDob(response?.payload?.data?.teacher_profile?.dob)
                    setUniversity(response?.payload?.data?.teacher_profile?.university_name)
                    setJobType(response?.payload?.data?.teacher_profile?.job_type==1 ? 'Full Time' : 'Part Time')
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    const languageNames = languages.map(lang => lang.language_name).join(', ');

    const loading = useSelector((item) => item?.teacherListing?.loading);

    useEffect(() => {
        handleAddress()
    }, [])
    return (
        <Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Box>
            <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Overview</Typography>

                
                <Box>
                   {overview}
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Address</Typography>
                    <Box>{state+" "+city}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>School Taught</Typography>
                    <Box>{taught==1 ? 'Yes' : 'No'}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Summary of the Experience</Typography>
                    <Box>{expDesc}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Total Experience</Typography>
                    <Box>{exp}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Gender</Typography>
                    <Box>{gender}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Date of Birth</Typography>
                    <Box>{dob}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Language</Typography>
                    <Box>{languageNames}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Degree</Typography>
                    <Box>{degree}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>University Name</Typography>
                    <Box>{university}</Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography style={{fontFamily: 'jost' , fontWeight: 'bold'}}>Tutoring Info</Typography>
                    <Box>{jobType}</Box>
                </Box>
                {/* <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography>Verified Info</Typography>
                    <Box display={'flex'}>
                        <Box> <VerifiedIcon sx={{ color: '#00D566' }} /> </Box>
                        <Box> Education Verified </Box>
                    </Box>
                    <Box display={'flex'}>
                        <Box> <VerifiedIcon sx={{ color: '#00D566' }} /> </Box>
                        <Box> Phone Verified </Box>
                    </Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography>Teachers</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <Box sx={{ backgroundColor: '#FBFBFB', minHeight: '30vh', width: '90%', padding: 2 }}>
                            <Typography>Graphic Designing Classes</Typography>
                            <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                            <Typography sx={{ marginTop: isXs ? '3%' : '8%' }}>Class Location</Typography>
                            <Box display={'flex'}>
                                <Box><DoneIcon /></Box>
                                <Box>Online Classes (Video Call via Kanha Home Tutions)</Box>
                            </Box>
                            <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                            <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                                <Typography>Years of Experience in Graphic Designing Classes</Typography>
                                <Box>4</Box>
                            </Box>
                            <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                            <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                                <Typography>Teaching Experience in detail in Graphic Designing Classes</Typography>
                                <Box>I started graphic designing in 2019</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} />
                <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography>Reviews</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box textAlign={'center'}>
                            <Box fontSize={'14px'} >No Reviews yet! </Box>
                            <Button>Be the first one to Review</Button>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ borderBottom: '1px solid #000', marginTop: isXs ? '3%' : '8%' }} /> */}
                {/* <Box sx={{ marginTop: isXs ? '3%' : '8%' }}>
                    <Typography>FAQs</Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>1. Which classes do you teach?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                I teach Graphic Designing Class.
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box> */}
            </Box>
        </Box>
    )
}

export default TabProfile

