// ModalTable.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';


function CreditHistoryPopup({ open, onClose, dataRow,title ,DataColumn}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}

      <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            left: "541px",
            top: "5px",
            backgroundColor:"#d2d7c1",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent             sx={{ overflow: "hidden", scrollBehavior: "smooth" }}
 >
        <TableContainer component={Paper} >
          <Table>
            <TableHead>
              <TableRow style={{  backgroundColor: "#CB8B65",}}>
                {DataColumn.map((item,index)=>(
                <TableCell style={{color:"white"}} key ={index}>{item}</TableCell>

                ))}
             
              </TableRow>
            </TableHead>
            <TableBody>
             { title=="Credit History"?dataRow.length>0? dataRow?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item?.studentName ??"-"}</TableCell>
                  <TableCell>{item?.mobile ??"-"}</TableCell>
                  <TableCell>{item?.class_data ??"-"}</TableCell>
                  <TableCell>{item?.subject ??"-"}</TableCell>
                  <TableCell>{item?.date ??"-"}</TableCell>
                </TableRow>
              )):                       <TableRow >     <TableCell colspan={5}>no data found </TableCell></TableRow>
              
              :
              title=="Subscription Plans"? dataRow.length>0? dataRow?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item?.planName ??"-"}</TableCell>
                  <TableCell>{item?.credit ??"-"}</TableCell>
                  <TableCell>{item?.price ??"-"}</TableCell>
                  <TableCell>{item?.created_at ??"-"}</TableCell>
                </TableRow>
              )):  <TableRow >     <TableCell colspan={4} >no data found </TableCell></TableRow>:""}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}


export default CreditHistoryPopup
