import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, IconButton, Drawer, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import HomeIcon from '../../../../assets/HomeIcon.svg';
import LearningNeedsIcon from '../../../../assets/LearningNeedsIcon.svg';
import NotificationIcon from '../../../../assets/NotificationIcon.svg';
import FeedbackIcon from '../../../../assets/FeedbackIcon.svg';
import HelpIcon from '../../../../assets/HelpIcon.svg';
import SettingsIcon from '../../../../assets/SettingsIcon.svg';
import AccountProfile from '../../../../assets/AccountProfile.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoHeader from '../../../../assets/LogoHeader.svg'
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Logout from '../Logout';
import Class from '../../../../assets/class.svg';
import Price from '../../../../assets/price.svg';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import logout2 from '../../../../assets/Logout-2.svg';
import help from '../../../../assets/help.svg';



const StyledListItemButton = styled(ListItemButton)({
  backgroundColor: 'white',
  '&.active': {
    backgroundColor: '#CB8B65',
  },
  '& .MuiListItemText-primary': {
    color: 'black',
    fontWeight: 'regular'
  },
  '&.active .MuiListItemText-primary': {
    color: 'white',
  },
});

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#CB8B65'
});

function ResponsiveAppBar() {
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);

  const handleModal = () => {
    setOpenModal(true)
  }

  const handleClick = (index) => {
    setActiveItem(index);
  };


  const handleDropdownToggle = () => {
    setOpen(!open);
    if (accountOpen) {
      setAccountOpen(false);
    }
  };

  const handleAccountDropdownToggle = () => {
    setAccountOpen(!accountOpen);
    if (open) {
      setOpen(false);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const iconStyle = {
    marginLeft: '2%',
    color: '#CB8B65'
  }
  
  const user = JSON.parse(localStorage.getItem("user"));
  const roleId = user?.roleId;

  return (
    <>
      <AppBar position="sticky" >
        <StyledToolbar>
          <IconButton
            color="#CB8B65"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            <img src={LogoHeader} width={'60%'} />
          </Typography>
          <Header />
        </StyledToolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        
      >
        <Box sx={{ width: 250 }}>
          
        {roleId == 1 &&
      (<List>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/admin')} className={activeItem === 0 ? 'active' : ''}>
            <img src={HomeIcon} alt="Home Icon" style={{ marginLeft: '1%' }} />
            <ListItemText primary="Dashboard" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/studentList')} className={activeItem === 1 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Student List" />
            <ListItemText primary="Student List" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/teacherList')} className={activeItem === 2 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Notification Icon"/>
            <ListItemText primary="Teacher List" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/manageCredit')} className={activeItem === 3 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Notification Icon"/>
            <ListItemText primary="Manage Credits" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/manageEnquiry')} className={activeItem === 4 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Notification Icon"/>
            <ListItemText primary="Manage Enquiry" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/manageFeedback')} className={activeItem === 5 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Notification Icon"/>
            <ListItemText primary="Help & Support" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/feedback')} className={activeItem === 4 ? 'active' : ''}>
            <img src={FeedbackIcon} alt="Feedback Icon" style={{ marginLeft: '2%' }} />
            <ListItemText primary="Feedback and Ratings" style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          <StyledListItemButton onClick={handleModal} className={activeItem === 9 ? 'active' : ''}>
            <img src={logout2} alt="Logout" style={{ marginLeft: '2%' }} />
            <ListItemText primary="Logout" style={{ marginLeft: '7%' }} />
          </StyledListItemButton>
        </ListItem>
        {openModal && <Logout onClose={() => setOpenModal(false)} />}
      </List>)}
      {roleId == 2 &&
      (<List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader">

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/home')} className={activeItem === 0 ? 'active' : ''}>
            <img src={HomeIcon} alt="Home Icon" style={{ marginLeft: '1%' }} />
            <ListItemText primary="Home" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/subject')} className={activeItem === 1 ? 'active' : ''}>
            <img src={LearningNeedsIcon} alt="Learning Needs Icon" />
            <ListItemText primary="Learning Needs" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/notifications')} className={activeItem === 3 ? 'active' : ''}>
            <img src={NotificationIcon} alt="Notification Icon" style={{ marginLeft: '3%' }} />
            <ListItemText primary="Notifications"  primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '7%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/feedback')} className={activeItem === 4 ? 'active' : ''}>
            <img src={FeedbackIcon} alt="Feedback Icon" style={{ marginLeft: '2%' }} />
            <ListItemText primary="Feedback and Ratings" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={handleDropdownToggle}>
            <img src={SettingsIcon} alt="Settings Icon" style={{ marginLeft: '2%' }} />
            <ListItemText primary="Account" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '6%' }} />
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <StyledListItemButton onClick={()=> navigate('/dashboard/profile')} className={activeItem === 6 ? 'active' : ''}>
                <img src={AccountProfile} alt="AccountProfile" style={{ marginLeft: '15%' }} />
                <ListItemText primary="Profile" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton onClick={()=> navigate('/dashboard/settings')} className={activeItem === 7 ? 'active' : ''}>
                <img src={SettingsIcon} alt="Settings Icon" style={{ marginLeft: '15%' }} />
                <ListItemText primary="Settings" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <StyledListItemButton onClick={()=> navigate('/dashboard/help-support')} className={activeItem === 8 ? 'active' : ''}>
            <img src={help} alt="Help Icon" style={{ marginLeft: '2%' }} />
            <ListItemText primary="Help and Support" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={handleModal} className={activeItem === 9 ? 'active' : ''}>
            <img src={logout2} alt="Logout" style={{ marginLeft: '3%' }} />
            <ListItemText primary="Logout" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}  style={{ marginLeft: '5%' }}/>
          </StyledListItemButton>
        </ListItem>
        {openModal && <Logout onClose={() => setOpenModal(false)} />}
      </List>)}
      {roleId == 3 &&
      (<List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      
    >
        <ListItem disablePadding>
          <StyledListItemButton onClick={() => navigate('/dashboard/dashboard-home')} className={activeItem === 0 ? 'active' : ''}>
            <DashboardOutlinedIcon sx={iconStyle} />
            <ListItemText primary="Home" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}style={{ marginLeft: '5%'}} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={handleDropdownToggle}>
            <SwitchAccountOutlinedIcon sx={iconStyle} />
            <ListItemText primary="Profile" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '6%' }} />
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/profileinformation')} className={activeItem === 7 ? 'active' : ''}>
                <PersonOutlineOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65', fontSize: "1.4rem" }} />
                <ListItemText primary="Profile Information" primaryTypographyProps={{fontSize: '15px', fontFamily: "jost"}} style={{ marginLeft: '4%' }} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/classconducted')} className={activeItem === 6 ? 'active' : ''}>
                <img src={Class} alt="class" style={{ marginLeft: '15%', width: '10%' , fontSize: "1.4rem" }} />
                <ListItemText primary="Class Conducted" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/classcourse')} className={activeItem === 7 ? 'active' : ''}>
                <img src={Price} alt="Pricing & Packs" style={{ marginLeft: '15%', width: '10%' , fontSize: "1.4rem"  }} />
                <ListItemText primary="Pricing & Packs" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/reviews')} className={activeItem === 7 ? 'active' : ''}>
                <ReviewsOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65' , fontSize: "1.2rem"  }} />
                <ListItemText primary="Reviews" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/tutorprofile')} className={activeItem === 7 ? 'active' : ''}>
                <PersonOutlineOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65' , fontSize: "1.4rem"  }} />
                <ListItemText primary="Profile" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton onClick={handleAccountDropdownToggle}>
            <ManageAccountsOutlinedIcon sx={iconStyle} />
            <ListItemText primary="Account" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '6%' }} />
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse in={accountOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <StyledListItemButton  onClick={() => navigate('/dashboard/support')} className={activeItem === 6 ? 'active' : ''}>
                <HelpOutlineOutlinedIcon sx={{ marginLeft: '15%', color: '#CB8B65' , fontSize: "1.2rem"  }} />
                <ListItemText primary="Help & Support" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '3%' }} />
              </StyledListItemButton>
            </ListItem>
          
          
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/closeaccount')} className={activeItem === 6 ? 'active' : ''}>
                <CancelOutlinedIcon sx={{ marginLeft: '15%', color: '#CB8B65' , fontSize: "1.2rem"  }} />
                <ListItemText primary="Close Account" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}}style={{ marginLeft: '3%' }} />
              </StyledListItemButton>
            </ListItem>
          
          </List>
        </Collapse>


  <ListItem disablePadding>
          <StyledListItemButton onClick={() => navigate('/dashboard/notification')} className={activeItem === 3 ? 'active' : ''}>
            <img src={NotificationIcon} alt="Notification Icon"/>
            <ListItemText primary="Notifications" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '7%' }} />
          </StyledListItemButton>
        </ListItem>
        
        

        <ListItem disablePadding>
          <StyledListItemButton onClick={() => navigate('/dashboard/credithistory')} className={activeItem === 9 ? 'active' : ''}>
            <MonetizationOnOutlinedIcon sx={iconStyle} />
            <ListItemText primary="Credit History" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={() => navigate('/dashboard/subscriptionplan')} className={activeItem === 9 ? 'active' : ''}>
            <DiamondOutlinedIcon sx={iconStyle} />
            <ListItemText primary="My Subscriptions" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <StyledListItemButton onClick={handleModal} className={activeItem === 9 ? 'active' : ''}>
            <LogoutOutlinedIcon sx={iconStyle} />
            <ListItemText primary="Logout" primaryTypographyProps={{fontSize: '15px' , fontFamily: "jost"}} style={{ marginLeft: '5%' }} />
          </StyledListItemButton>
        </ListItem>

      
        {openModal && <Logout onClose={() => setOpenModal(false)} />}
      </List>)}

          {/* <List>

            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/home')} className={activeItem === 0 ? 'active' : ''}>
                <img src={HomeIcon} alt="Home Icon" style={{ marginLeft: '1%' }} />
                <ListItemText primary="Home" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/learningneeds')} className={activeItem === 1 ? 'active' : ''}>
                <img src={LearningNeedsIcon} alt="Learning Needs Icon" />
                <ListItemText primary="Learning Needs" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={()=> navigate('/dashboard/notifications')} className={activeItem === 3 ? 'active' : ''}>
                <img src={NotificationIcon} alt="Notification Icon" style={{ marginLeft: '2%' }} />
                <ListItemText primary="Notifications" style={{ marginLeft: '7%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={()=> navigate('/dashboard/feedback')} className={activeItem === 4 ? 'active' : ''}>
                <img src={FeedbackIcon} alt="Feedback Icon" style={{ marginLeft: '2%' }} />
                <ListItemText primary="Feedback and Ratings" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={handleDropdownToggle}>
                <img src={SettingsIcon} alt="Settings Icon" style={{ marginLeft: '2%' }} />
                <ListItemText primary="Account" style={{ marginLeft: '6%' }} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={()=> navigate('/dashboard/profile')} className={activeItem === 6 ? 'active' : ''}>
                    <img src={AccountProfile} alt="AccountProfile" style={{ marginLeft: '15%' }} />
                    <ListItemText primary="Profile" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={()=> navigate('/dashboard/settings')} className={activeItem === 7 ? 'active' : ''}>
                    <img src={SettingsIcon} alt="Settings Icon" style={{ marginLeft: '15%' }} />
                    <ListItemText primary="Settings" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
              </List>
            </Collapse>

            <ListItem disablePadding>
              <StyledListItemButton onClick={()=> navigate('/dashboard/learningneeds')} className={activeItem === 8 ? 'active' : ''}>
                <img src={HelpIcon} alt="Help Icon" style={{ marginLeft: '2%' }} />
                <ListItemText primary="Help and Support" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={handleModal} className={activeItem === 9 ? 'active' : ''}>
                <img src={HelpIcon} alt="Logout" style={{ marginLeft: '2%' }} />
                <ListItemText primary="Logout" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            {openModal && <Logout onClose={() => setOpenModal(false)} />}
          </List> */}

          {/* <List>
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/tabhome')} className={activeItem === 0 ? 'active' : ''}>
                <DashboardOutlinedIcon sx={iconStyle} />
                <ListItemText primary="Dashboard" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={handleDropdownToggle}>
                <SwitchAccountOutlinedIcon sx={iconStyle} />
                <ListItemText primary="Profile" style={{ marginLeft: '6%' }} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/profileinformation')} className={activeItem === 7 ? 'active' : ''}>
                    <PersonOutlineOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65' }} />
                    <ListItemText primary="Profile Information" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/classconducted')} className={activeItem === 6 ? 'active' : ''}>
                    <img src={Class} alt="class" style={{ marginLeft: '15%', width: '10%' }} />
                    <ListItemText primary="Class Conducted" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/pricingclass')} className={activeItem === 7 ? 'active' : ''}>
                    <img src={Price} alt="Pricing & Packs" style={{ marginLeft: '15%', width: '10%' }} />
                    <ListItemText primary="Pricing & Packs" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/reviews')} className={activeItem === 7 ? 'active' : ''}>
                    <ReviewsOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65' }} />
                    <ListItemText primary="Reviews" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/tutorprofile')} className={activeItem === 7 ? 'active' : ''}>
                    <PersonOutlineOutlinedIcon style={{ marginLeft: '15%', color: '#CB8B65' }} />
                    <ListItemText primary="Profile" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
              </List>
            </Collapse>

            <ListItem disablePadding>
              <ListItemButton onClick={handleAccountDropdownToggle}>
                <ManageAccountsOutlinedIcon sx={iconStyle} />
                <ListItemText primary="Account" style={{ marginLeft: '6%' }} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={accountOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <StyledListItemButton className={activeItem === 6 ? 'active' : ''}>
                    <HelpOutlineOutlinedIcon sx={{ marginLeft: '15%', color: '#CB8B65' }} />
                    <ListItemText primary="Help & Support" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <StyledListItemButton onClick={() => navigate('/dashboard/closeaccount')} className={activeItem === 6 ? 'active' : ''}>
                    <CancelOutlinedIcon sx={{ marginLeft: '15%', color: '#CB8B65' }} />
                    <ListItemText primary="Close Account" style={{ marginLeft: '5%' }} />
                  </StyledListItemButton>
                </ListItem>
              </List>
            </Collapse>

            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/credithistory')} className={activeItem === 9 ? 'active' : ''}>
                <MonetizationOnOutlinedIcon sx={iconStyle} />
                <ListItemText primary="Credit History" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/dashboard/subscriptionplan')} className={activeItem === 9 ? 'active' : ''}>
                <DiamondOutlinedIcon sx={iconStyle} />
                <ListItemText primary="My Subscriptions" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <StyledListItemButton onClick={handleModal} className={activeItem === 9 ? 'active' : ''}>
                <LogoutOutlinedIcon sx={iconStyle} />
                <ListItemText primary="Logout" style={{ marginLeft: '5%' }} />
              </StyledListItemButton>
            </ListItem>
            {openModal && <Logout onClose={() => setOpenModal(false)} />}
          </List> */}

        </Box>
      </Drawer>
    </>
  );
}

export default ResponsiveAppBar;



// <ListItem disablePadding>
//   <StyledListItemButton onClick={() => handleClick(3)} className={activeItem === 3 ? 'active' : ''}>
//     <img src={NotificationIcon} alt="Notification Icon" style={{ marginLeft: '2%' }} />
//     <ListItemText primary="Notifications" style={{ marginLeft: '7%' }} />
//   </StyledListItemButton>
// </ListItem>