import {
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import navlogo from "../../assets/KHT Nav Logo.svg";
import { ExpandMoreOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  const toggleBox = () => {
    if (isOpen) {
        setIsOpen(false); // Close the box
        window.location.reload(); // Refresh the page after closing
    } else {
        setIsOpen(true); // Open the box
    }
};

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClickDropdown = () => {
    setOpenDropdown((prev) => !prev);
  };
  useEffect(() => {
    if (isOpen) {
      setDropdownHeight(document.getElementById('dropdownContent').Height);
    } else {
      setDropdownHeight(0);
    }
  }, [isOpen]);
  const handleCloseDropdown = () => {
    setOpenDropdown(false);
  };
  const [openDropdownNew, setOpenDropdownNew] = useState(false);

  const handleClickDropdownNew = () => {
    setOpenDropdownNew((prev) => !prev);
  };

  const handleCloseDropdownNew = () => {
    setOpenDropdownNew(false);
  };

  const isXs = useMediaQuery("(min-width:600px) and (max-width:3000px)");
  const [Menu, setMenu] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const themeColors = ["#ff5733", "#3498db", "#2ecc71"];

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const [otpNew, setOtpNew] = useState("");
  const handleChangeOtpNew = (NewValue) => {
    setOtpNew(NewValue);
  };

  const [openOtpNew, setOpenOtpNew] = useState(false);

  const handleOpenotpNew = () => {
    setOpenOtpNew(true);
  };

  const handleCloseOtpNew = () => {
    setOpenOtpNew(false);
  };

  const toggleMenu = () => {
    setMenu((prevOpen) => !prevOpen);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const [smsIdNew, setSmsIdNew] = useState(null);
  const [studentQueryFormData, setStudentQueryFormData] = useState(null);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
    pincode: yup.string().required(),
    area: yup.string().required(),
    city: yup.string().required(),
    mobileNumber: yup.string().required(),
    subject: yup.string().required(),
    course: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      classMode: "1",
    },
  });

  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const onSubmitHandler = async (data) => {
    setOtpNew("");
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {hour: "2-digit",hour12: false, }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(date);
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(date);
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("area", data.area);
    formData.append("fullName", data.fullName);
    formData.append("pincode", data.pincode);
    formData.append("course", data.course);
    formData.append("subject", data.subject);
    formData.append("classMode", data.classMode);
    formData.append("city", data.city);
    formData.append("mobile", data.mobileNumber);
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    setStudentQueryFormData(formData);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/notifySMS",
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setOpenOtpNew(true);
        setOpen(false);
        setSmsIdNew(response.data.data.smsID.toString());
        reset();
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * themeColors.length);
    setSelectedColor(themeColors[randomIndex]);
  }, []);

  const handleResendOtpNew = async (e) => {
    e.preventDefault();
    setLoading2(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const tempFormData = new FormData();
    tempFormData.append("smsId", smsIdNew);
    tempFormData.append("mobile", studentQueryFormData.get("mobile"));
    tempFormData.append("smsType", "generalQuery");
    tempFormData.append("token", CurrentDateTime);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/resendOTP",
        tempFormData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading2(false);
      } else {
        toast.error(response.data.message);
        setLoading2(false);
      }
    } catch (error) {}
  };

  const handleSubmitOtpNew = async (e) => {
    e.preventDefault();
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", studentQueryFormData.get("email"));
    formData.append("area", studentQueryFormData.get("area"));
    formData.append("fullName", studentQueryFormData.get("fullName"));
    formData.append("pincode", studentQueryFormData.get("pincode"));
    formData.append("course", studentQueryFormData.get("course"));
    formData.append("subject", studentQueryFormData.get("subject"));
    formData.append("classMode", studentQueryFormData.get("classMode"));
    formData.append("city", studentQueryFormData.get("city"));
    formData.append("mobile", studentQueryFormData.get("mobile"));
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    formData.append("smsId", smsIdNew);
    formData.append("smsOtp", otpNew);
    setStudentQueryFormData(formData);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/verifyOtp",
        formData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setOpenOtpNew(false);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading(false);
        setOtpNew("");
      } else {
        toast.error(response.data.message ?? "OTP does not match.");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: isXs ? "flex" : "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: isXs ? "100%" : "100%",
          height: "90px",
          backgroundColor: "#F6EBDC",
          alignItems: "center",
          padding: isXs ? "1rem 2rem 1rem 6rem" : "1rem 1rem",
        }}
      >
        <a href="/">
        <img width={160} src={navlogo} alt="Logo" />
        </a>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: isXs ? "40px" : "30px",
          }}
        >
          <div style={{ display: isXs ? 'flex':"none", fontWeight:'500', fontSize:'1rem'}}> <a style={{ color:'black', textDecoration:'none'}}href="/">Home</a></div>
          <Dropdown align="start" drop="center">
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
                display: isXs ? "" : "none",
                fontSize: "1rem",
                fontWeight: "500",
                padding: "3px 13px",
                position: "relative",
              }}
            >
              One to One Class
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  borderRadius: "4px",
                  marginLeft: "10px",
                  position: "absolute",
                  right: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "#CB8B65",
                }}
              >
                <span
                  style={{
                    display: "block",
                    width: "0",
                    height: "0",
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                    borderTop: "5px solid white", 
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                fontSize: "12px",
                borderRadius: "5px",
                display: isXs ? "" : "none",
                padding: "15px 7px",
              }}
            >
              <Dropdown.Item
                style={{ padding: " 0.2rem 1rem" }}
                onClick={handleOpen}
              >
                Online Tuition
              </Dropdown.Item>
              <Dropdown.Item
                style={{ padding: " 0.2rem 1rem" }}
                onClick={handleOpen}
              >
                Home Tuition
              </Dropdown.Item>
              <Dropdown.Item
                style={{ padding: " 0.2rem 1rem" }}
                onClick={handleOpen}
              >
                Hire a Personal Tutor
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown align="start" drop="center">
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
                fontSize: "1rem",
                fontWeight: "500",
                display: isXs ? "" : "none",
                padding: "3px 13px",
                position: "relative",
              }}
            >
              Online Courses
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  borderRadius: "4px",
                  marginLeft: "10px",
                  position: "absolute",
                  right: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "#CB8B65",
                }}
              >
                <span
                  style={{
                    display: "block",
                    width: "0",
                    height: "0",
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                    borderTop: "5px solid white", // Changed to white
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ borderRadius: "5px", padding: "15px 7px" }}>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class IIT-JEE maths{" "}
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class IIT-JEE physics
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class IIT-JEE chemistry
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class NEET physics
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class NEET chemistry
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class NEET biology
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class 12th maths CBSE
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class 12 physics
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                Online class 12 chemistry
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "12px" }} onClick={handleOpen}>
                {" "}
                Online class 12 biology
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "0",
              fontSize: "1rem",
              fontWeight: "500",
              display: isXs ? "" : "none",
            }}
          >
            <Link
              to="/student-signin"
              style={{
                color: "black",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Login as Student
            </Link>
          </button>

          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "0",
              fontSize: "1rem",
              fontWeight: "500",
              display: isXs ? "" : "none",
            }}
          >
            <Link
              to="/signin-teacher"
              style={{
                color: "black",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Login as Tutor
            </Link>
          </button>

          <div style={{ position: "relative" }}>
            <div
              onClick={toggleMenu}
              style={{
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: "500",
                display: isXs ? "flex" : "none",
              }}
            >
              More
              <ExpandMoreOutlined
                style={{ fontSize: "20px", transform: "rotate(360deg)" }}
              />
            </div>
            {Menu && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  borderRadius: "5px",
                  minWidth: "170px",
                  backgroundColor: "white",
                  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                  zIndex: 1000,
                }}
              >
                <div style={{ padding: "15px 7px" }}>
                <div style={{ padding: "0.2rem 1rem", fontSize: "12px" }}>
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href="/study-material"
                    >
                      Study Material
                    </a>
                  </div>
                  <div style={{ padding: "0.2rem 1rem", fontSize: "12px" }}>
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href="/for-student"
                    >
                      For Student
                    </a>
                  </div>
                  <div style={{ padding: "0.2rem 1rem", fontSize: "12px" }}>
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href="/for-teacher"
                    >
                      For Teacher
                    </a>
                  </div>
                  <div style={{ padding: "0.2rem 1rem", fontSize: "12px" }}>
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href="/contact-us"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
      <div onClick={toggleBox}>
        <MenuIcon style={{ display: isXs?  "none":'flex',  fontSize: "40px", color: "#CB8B65" }} />
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            right:'-16px',
            
            backgroundColor: "white",
            zIndex: 1,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            minWidth: "100vw",
            padding: "15px 0px 40px 0px",
            overflow: "hidden",
            marginTop: "25px",
            transition: `height 0.3s ease, opacity 0.3s ease`,
            height: dropdownHeight,
            opacity: isOpen ? 1 : 0,
          }}
          id="dropdownContent"
        >
          <div style={{ display: "flex", gap: "5px", flexDirection: "column", justifyContent:'center',marginLeft:'0rem'  }}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1.2rem', alignItems: 'center', padding: '0rem 0rem', marginBottom: '.6rem' }}>
              <div style={{ backgroundColor: '#CB8B65', padding: '.5rem 1rem', borderRadius: '20px' }}>
                <Link to="/student-signin" style={{ textDecoration: "none", color: "white", fontSize: "16px", fontWeight: "500" }}>
                  Login as Student
                </Link>
              </div>
              <div style={{ backgroundColor: '#CB8B65', padding: '.5rem 1rem', borderRadius: '20px' }}>
                <Link to="/signin-teacher" style={{ textDecoration: "none", color: "white", fontSize: "16px", fontWeight: "500" }}>
                  Login as Tutor
                </Link>
              </div>
            </div>
            <div style={{height:"2px", width:'100%', backgroundColor:'#FFDB7E', marginBottom:'10px'}}></div>
<div style={{marginLeft:'1rem', display:'flex', flexDirection:'column', gap:'5px'}}>
            <div style={{ display: isXs ? 'none':"flex", fontWeight:'500', fontSize:'16px'}}> <a style={{ color:'black', textDecoration:'none'}}href="/">Home</a></div>
            <hr style={{ padding: '0px', margin:'0px'}} />

            <List sx={{ padding: "0px 0px" }}>
              <ListItem sx={{ padding: "0px", display: 'flex', justifyContent: 'space-between' }} disablePadding onClick={handleClickDropdown}>
                <ListItemText primaryTypographyProps={{ fontFamily: "jost", fontSize: "16px", fontWeight: "500", marginBottom: "-6px" }} primary="One to One Class" />
                <div style={{ paddingRight: '40px' }}>
                  {openDropdown ? <RemoveIcon /> : <AddIcon sx={{ fontSize: "19px" }} />}
                </div>
              </ListItem>

              <Collapse in={openDropdown} timeout="auto" unmountOnExit>
                <List style={{ paddingLeft: "20px" }} component="div" disablePadding>
                  <ListItem disablePadding>
                    <ListItemText onClick={handleOpen} primaryTypographyProps={{ fontFamily: "jost", fontSize: "14px", fontWeight: "500" }} primary="Online Tuition" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText onClick={handleOpen} primaryTypographyProps={{ fontFamily: "jost", fontSize: "14px", fontWeight: "500" }} primary="Home Tuition" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText onClick={handleOpen} primaryTypographyProps={{ fontFamily: "jost", fontSize: "14px", fontWeight: "500" }} primary="Hire a Personal Tutor" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            <hr style={{ padding: '0px', margin:'0px'}} />


            <List sx={{ padding: "0px 0px" }}>
              <ListItem sx={{ padding: "0px", justifyContent: 'space-between' }} disablePadding onClick={handleClickDropdownNew}>
                <ListItemText primaryTypographyProps={{ fontFamily: "jost", fontSize: "16px", fontWeight: "500", marginBottom: "-6px" }} primary="Online Courses" />
                <div style={{ paddingRight: '40px' }}>
                  {openDropdownNew ? <RemoveIcon /> : <AddIcon sx={{ fontSize: "19px" }} />}
                </div>
              </ListItem>
              <Collapse in={openDropdownNew} timeout="auto" unmountOnExit>
                <List onClick={handleOpen} style={{ paddingLeft: "20px" }} component="div" disablePadding>
                  {["IIT-JEE maths", "IIT-JEE physics", "IIT-JEE chemistry", "NEET physics", "NEET chemistry", "NEET biology", "12th maths CBSE", "12 physics", "12 chemistry", "12 biology"].map(course => (
                    <ListItem disablePadding key={course}>
                      <ListItemText primaryTypographyProps={{ fontFamily: "jost", fontSize: "14px", fontWeight: "500" }} primary={`Online class ${course}`} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </List>
            <hr style={{ padding: '0px', margin:'0px'}} />


            <div style={{ }}>
              <a style={{ textDecoration: "none", color: "black", fontSize: "16px", fontWeight: "500" }} href="/for-student">
                For Student
              </a>
            </div>            <hr style={{ padding: '0px', margin:'0px'}} />

            <div style={{ }}>
              <a style={{ textDecoration: "none", color: "black", fontSize: "16px", fontWeight: "500" }} href="/for-teacher">
                For Teacher
              </a>
            </div> 
            <hr style={{ padding: '0px', margin:'0px'}} />

            <div >
              <a style={{ textDecoration: "none", color: "black", fontSize: "16px", fontWeight: "500" }} href="/contact-us">
                Contact Us
              </a>
            </div>
            <hr style={{ padding: '0px', margin:'0px'}} />
            {/* <div >
              <a style={{ textDecoration: "none", color: "black", fontSize: "16px", fontWeight: "500" }} href="/study-material">
Study Material
              </a>
            </div>
            <hr style={{ padding: '0px', margin:'0px'}} /> */}
            </div>

          </div>
        </div>
      )}
    </div>
        </div>
      </div>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        onClose={handleClose("backdropClick")}
        open={open}
        sx={{ 
          overflow: isXs ? "" : "auto",
          "& .MuiDialog-paper": {
            width: isXs ? '38%' : '600px', 
            maxWidth: 'none',
          }
        }}
        
      >
        <div
          className="modal-content"
          style={{
            marginTop: isXs ? "" : "0%",
            textAlign: isXs ? "" : "center",
            width: isXs ? "100%" : "100%",
          
          }}
        >
          <Button
            style={{
              position: "absolute",
              right: "0%",
              backgroundColor: "white",
              borderRadius: "100%",
              minWidth: "2rem",
              color: "black",
            }}
            onClick={() => handleClose("close")}
          >
            X
          </Button>
          <div
            className="text-center py-2 fw-bold"
            style={{
              backgroundColor: "#CB8B65",
              width: "100%",
              fontSize: isXs ? "28px" : "22px",
              marginBottom: "5%",
              display: "flex",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                color: "#FFDB7E",
              }}
            >
              FREE
            </div>
            <div
              style={{
                color: "white",
              }}
            >
              REGISTRATION
            </div>
          </div>
          {/* <img src={Group92} style={{display: isXs ? "" : ""}}/> */}
          <form   onSubmit={handleSubmit(onSubmitHandler)}>
            <Box
              className="d-flex justify-content-center mt-2"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("fullName")}
                      name="fullName"
                      id="fullName"
                      label="Full Name"
                      type="text"
                      placeholder="Enter your Full Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setFullName(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "12%" : "",
                      }}
                    >
                      {errors.fullName?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div>
                    <TextField
                      {...register("mobileNumber")}
                      name="mobileNumber"
                      id="mobileNumber"
                      label="Mobile Number"
                      type="number"
                      placeholder="Enter your Mobile Number"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setMobileNumber(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginLeft: isXs ? "5%" : "",
                      }}
                    >
                      {errors.mobileNumber?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex  mt-1 justify-content-between "
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("email")}
                      name="email"
                      id="email"
                      label="Email"
                      type='"email'
                      placeholder="Enter your Email"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      style={{ marginLeft: "3%" }}
                      // onChange={(e) =>
                      //     setEmail(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "20%" : "",
                      }}
                    >
                      {errors.email?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "4%" }}
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: "0%",
                        fontSize: isXs ? "0.9vw" : "3vw",
                        color: "black",
                        letterSpacing: "0px",
                      }}
                    >
                      Mode of Classes*
                    </FormLabel>
                    <Controller
                      // rules={{ required: true }}
                      control={control}
                      name="classMode"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-label="position"
                          {...field}
                          name="position"
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={
                              <span
                                style={{
                                  fontSize: isXs ? "1vw" : "3vw",
                                }}
                              >
                                Online
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={
                              <span
                                style={{
                                  fontSize: isXs ? "1vw" : "3vw",
                                }}
                              >
                                Offine
                              </span>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex justify-content-center mt-1"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "23ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.5px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      name="ccb"
                      id="ccb"
                      label="Course / Class/ Board"
                      type='"text'
                      placeholder="Enter Course You Need"
                      {...register("course")}
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setCource(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginRight: isXs ? "17%" : "",
                      }}
                    >
                      {errors.course?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <div>
                    <TextField
                      name="subject"
                      {...register("subject")}
                      id="subject"
                      label="Subject"
                      type="text"
                      placeholder="Enter Subject You Need"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setSubject(e.target.value)
                      // }
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginLeft: isXs ? "5%" : "",
                      }}
                    >
                      {errors.subject?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="d-flex justify-content-center mt-1"
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: isXs ? "13.8ch" : "75%",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: isXs ? "2px" : "2px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div style={{ textAlign: isXs ? "end" : "center" }}>
                    <TextField
                      {...register("city")}
                      name="city"
                      id="city"
                      label="City"
                      type='"text'
                      placeholder="Enter City Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setCity(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.city?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div style={{ textAlign: "center" }}>
                    <TextField
                      {...register("area")}
                      name="area"
                      id="area"
                      label="Area"
                      type="text"
                      placeholder="Enter Area Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setArea(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.area?.message}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div tyle={{ textAlign: "start" }}>
                    <TextField
                      {...register("pincode")}
                      name="pincode"
                      id="pincode"
                      label="Pincode"
                      type="text"
                      placeholder="Enter Pincode"
                      required
                      inputProps={{
                        style: {
                          height: "10px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: "black" },
                      }}
                      color="grey"
                      focused
                      // onChange={(e) =>
                      //     setPincode(e.target.value)
                      // }
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.pincode?.message}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div className="d-flex justify-content-center my-2">
              {/* <button type="submit" className="btn btn-primary py-2 w-75 fw-bold" style={{ fontSize: '14px', border: '1px solid #CB8B65', backgroundColor: '#FFDB7E', color: '#866D2C', borderRadius: '0', zIndex: 3 }}>Get Free Demo Class</button> */}

              {loading ? (
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary py-2 w-75 fw-bold"
                  style={{
                    fontSize: "14px",
                    border: "1px solid #CB8B65",
                    backgroundColor: "#FFDB7E",
                    color: "#866D2C",
                    borderRadius: "0",
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </form>
          <div
            style={{
              height: "40px",
              textAlign: "center",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            {" "}
            <span style={{ fontWeight: "700", fontSize: "14px" }}>
              Are you a Tutor?
            </span>{" "}
            <a href="/signup-teacher">
              <span style={{ fontSize: "14px", fontWeight: "700" }}>
                Create Free Profile
              </span>
            </a>
          </div>

          <div className="d-flex flex-row justify-content-between w-full mb-3">
            <div
              className="flex-grow-1 text-center"
              style={{
                fontSize: "12px",
                fontWeight: "900",
              }}
            >
              <small className="block">
                <span className="">By signing up you agree to our </span>
                <a href="/terms-conditions" style={{ color: "#0582D2" }}>
                  {" "}
                  Terms and Conditions
                </a>
              </small>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openOtpNew}
        onClose={() => {}}
        maxWidth="md"
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(1px)", // Apply blur effect directly
          },
        }}
        PaperProps={{
          sx: {
            padding: "rem",
            overflowX: "hidden",
          },
        }}
      >
        <Button
          onClick={handleCloseOtpNew}
          style={{
            position: "absolute",
            right: "0%",
            top: "0",
            backgroundColor: "white",
            borderRadius: "100%",
            minWidth: "2rem",
            color: "black",
            border: "1px solid black",
          }}
        >
          X
        </Button>

        <div style={{ padding: "1rem" }}>
          <form
            onSubmit={handleSubmitOtpNew}
            style={{ padding: isXs ? "0px 10px" : "0px 0px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontSize: "30px", textAlign: "center" }}>
                OTP Verification{" "}
              </div>

              <div style={{ textAlign: "center" }}>
                {" "}
                A One-Time Password has been sent to{" "}
                {studentQueryFormData
                  ? studentQueryFormData.get("mobile")
                  : null}
              </div>
              <div>
                <MuiOtpInput
                  style={{
                    width: isXs ? "400px" : "270px",
                    gap: isXs ? "20px" : "5px",
                    padding: "14px 3px",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    margin: "0px auto",
                  }}
                  value={otpNew}
                  onChange={handleChangeOtpNew}
                  length={6}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  gap: "10px",
                  flexDirection: isXs ? "" : "column",
                }}
              >
                <div style={{ textAlign: "center" }}>Didn’t Receive OTP?</div>
                {loading2 ? (
                  <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                    <CircularProgress color="success" />
                  </Stack>
                ) : (
                  <div
                    type="button"
                    style={{ color: "#cb8b65", textAlign: "center" }}
                    onClick={handleResendOtpNew}
                  >
                    Resend OTP
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "70px",
              }}
            >
              {loading ? (
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <button
                  style={{
                    backgroundColor: "#CB8B65",
                    color: "white",
                    width: "30rem",
                    height: "2.5rem",
                    border: "none",
                    borderRadius: "3px",
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}

export default NavBar;
