import { Box, Button, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mobileUpdateTeacher, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { toast } from 'react-toastify';
import { storeUpdateNumber } from '../../../../features/teacher/teacherAuthSlice';


const PhoneNumber = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isXs = useMediaQuery('(min-width:600px)');
    const [isPhone, setIsPhone] = useState('')
    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id
    const mobile = user?.mobile

    const numberData = useSelector((item) => item?.teacherListing?.teacherMobileUpdate)
    // console.log('adsfadsas', numberData);

    const teacherData = useSelector((item) => item?.teacherListing?.availableCredits)
    // console.log('adsfadsas', teacherData);


    const teacherDetail = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                // console.log('hahahaha', response);
                if (response?.payload?.status == 200) {
                    setIsPhone(response?.payload?.data?.mobile)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    useEffect(() => {
        teacherDetail()
    }, [userId])

    const handleSubmit = () => {

        dispatch(mobileUpdateTeacher({ userId: userId, mobile: isPhone }))
            .then((response) => {
                toast.success(response?.payload?.message);
                if (response?.payload?.status == 200) {
                    if(response?.payload?.data){
                        // console.log('laslala', response);
                        dispatch(storeUpdateNumber(isPhone))
                        navigate('/update-number')
                    }                    
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }
    const phoneChange = (e) => {
        // setIsPhone(e.target.value)
        const value = e.target.value;
        // Restrict input to 10 digits
        if (value.length <= 10) {
            setIsPhone(value.replace(/\D/, '')); // Remove non-digit characters
        }
    }

    const goBack = () => {
        navigate(-1);
    };
    return (
        <Box>
        <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
            <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h5' style={{fontFamily: 'jost'}}>Phone Number</Typography>
                    <Box>Add or remove phone numbers associated with your profile.</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                <button onClick={handleSubmit} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
            <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '35vh', width: '100%', textAlign: 'center' }}>
                <Grid container marginTop={isXs ? '5%' : '15%'} sx={{ marginLeft: isXs ? '' : '8%' }}>

                    <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <TextField
                            label="Phone Number"
                            inputProps={{ style: { height: '10px' } }}
                            InputLabelProps={{ sx: { color: 'black' } }}
                            color='grey'
                            focused
                            value={isPhone}
                            onChange={phoneChange}
                            variant="outlined"
                            
                            
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    borderColor: 'grey',
                                    fontFamily: 'jost',
                                },
                                minWidth: '80%'
                            }}
                        />
                        <Box display={'flex'} gap={2} justifyContent={'center'} sx={{ marginTop: isXs ? '5%' : '10%' }}>
                            <Box display={'flex'} gap={'.4rem'}>
                                <Box> <VerifiedIcon sx={{ color: '#00D566' , fontSize: '1.2rem' }} /> </Box>
                                <Box>Verified</Box>
                            </Box>
                            <Box display={'flex'} gap={'.4rem'}>
                                <Box> <DoneIcon sx={{ color: '#D1734A' }} /> </Box>
                                <Box>SMS Alter</Box>
                            </Box>
                            <Box display={'flex'} gap={'.4rem'}>
                                <Box> <DoneIcon sx={{ color: '#D1734A' }} /> </Box>
                                <Box>Phone Class</Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Box>
    )
}

export default PhoneNumber
