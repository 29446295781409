import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { teacherCreditAddAndRemove, userLists } from "../../features/admin/accountPostSlice";
import { Backdrop, Box, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Popover } from '@mui/material';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const columns = [
  { id: "id", label: "ID" },
  { id: "name", label: "Teacher Name" },
  { id: "mobile", label: "Mobile" },
  { id: "email", label: "Email" },
  { id: "city_name", label: "City" },
  { id: "state_name", label: "State" },
];

export default function ManageCredit() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);
  const [creditValue, setCreditValue] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");
  const [teacherId, setTeacherId] = useState();

  const [anchorEl, setAnchorEl] = useState(null);

const handlePopoverOpen = (event) => {
  setAnchorEl(event.currentTarget);
};

const handlePopoverClose = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);



  const handleCloseVerifyOtp = () => setOpenVerifyOtp(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    if (searchTerm.length >= 3) {
      setSearchActive(true);
      filterRows();
    }
  };

  const studentList = () => {
    dispatch(userLists({ roleId: 3 }));
  };

  useEffect(() => {
    studentList();
  }, []);

  const { userListData, loading } = useSelector((state) => state?.accountPost);

  const rows = userListData?.data?.map((student) => ({
    id: "#TUTOR" + student?.id,
    name: student?.first_name && student?.last_name ? `${student?.first_name} ${student?.last_name}` : "N/A",
    mobile: student.mobile,
    email: student?.email || "N/A",
    city_name: student?.teacher_profile?.city?.city_name,
    state_name: student?.teacher_profile?.state?.state_name,
  }));

  const filterRows = () => {
    const filtered = rows?.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.mobile.includes(searchTerm) ||
        row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.city_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.state_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredRows(filtered);
  };

  const handleRowClick = (id) => {
    setOpenVerifyOtp(true);
    setTeacherId(id);
  };

  const handleCreditChange = (event) => {
    setCreditValue(event.target.value);
  };

  const handleConfirmAction = () => {
    if (creditValue.includes(".")) {
        toast.error('not accept decimal values')
        return;
      }
      if(!creditValue){
        toast.error('input filed is required')
        return;
      }
      if(creditValue == 0){
        toast.error('not accept 0 values')
        return;
      }
    if (Number(creditValue) > 0) {
      setConfirmAction(`Are you sure you want to add ${creditValue} credits?`);
      
    } else if (Number(creditValue) < 0) {
      setConfirmAction(`Are you sure you want to remove ${Math.abs(creditValue)} credits?`);
    }
    setOpenConfirmationModal(true);
  };

  const handleModalClose = () => {
    setOpenConfirmationModal(false);
    setCreditValue("");
  };

  const handleCreditAdd = () => {
    const id = teacherId.match(/\d+/)[0];
    dispatch(teacherCreditAddAndRemove({userId:id , credit:+creditValue})).then((response)=>{
        if(response?.payload?.status==200){
            toast.success(response?.payload?.message);
             setCreditValue("");
             setOpenConfirmationModal(false);
             setOpenVerifyOtp(false);
        }else{
            toast.error(response?.payload?.message);
        }
    })
  };

  

  return (
    <div style={{ backgroundColor: "#FFFFFF", height: "100vh", borderRadius: "10px" }}>
      <main
        style={{
          marginLeft: "0rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            marginTop: "0rem",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "space-between",
            boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
          }}
        >
          <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
            Manage Credits
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputGroup>
              <Form.Control
                placeholder="Search Teacher Name"
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button
                style={{ backgroundColor: "#CB8B65", border: 0 }}
                onClick={handleSearchClick}
                disabled={searchTerm.length < 3}
              >
                <img src={search} alt="Search" />
              </Button>
            </InputGroup>
          </div>
        </div>
        {searchActive && (
          <div style={{ marginLeft: "2rem" }}>
            {filteredRows.length > 0 ? (
              <>
                <div>
                  <p style={{ fontSize: "1.5rem" }}>All Teacher List</p>
                </div>
                <Paper sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth" }}>
                  {loading ? (
                    <Backdrop
                      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <TableContainer sx={{ maxHeight: 320 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#CB8B65",
                                  fontWeight: "500",
                                  color: "#ffffff",
                                  fontSize: "1.1rem",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredRows
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                  onClick={() => handleRowClick(row.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                      <TableCell key={column.id}>
                                        {column.format && typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </>
            ) : (
              <div style={{ marginLeft: "2rem" }}>
                <p style={{ fontSize: "1.5rem" }}>No Data Found</p>
              </div>
            )}
          </div>
        )}
      </main>

      <Modal
        open={openConfirmationModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Action
            </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
            }}
          >
            <Typography id="modal-modal-description"
  sx={{ mt: 2, width: "90%", textAlign: "center" }}
          >
            {confirmAction && (
              <Typography>{confirmAction}</Typography>
            )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
            }}
          >
            {/* <Button
              variant="contained"
              onClick={handleCreditAdd} // Add handleConfirm function
              sx={{ backgroundColor: "#CB8B65", color: "white", marginRight: "1rem" }}
            >
              Yes
            </Button> */}
            
            <div className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                                <button onClick={handleCreditAdd} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>YES</button>
                                            </div>
                                            
            <div className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                                <button onClick={handleModalClose} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>NO</button>
                                            </div>
            {/* <Button
              variant="contained"
              onClick={handleModalClose}
              sx={{ backgroundColor: "#CB8B65", color: "white" }}
            >
              No
            </Button> */}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openVerifyOtp}
        onClose={handleCloseVerifyOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Credit
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
            }}
          >
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, width: "90%", textAlign: "center" }}
            >
              Please enter the credits when adding them to the teacher.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4%",
            }}
          >
            <TextField
              label="Credit"
              variant="outlined"
              value={creditValue}
              onChange={handleCreditChange}
              type="number"
              inputProps={{ min: 1 }}
              sx={{ width: "50%", textAlign: "center" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
            }}
          >
            {/* <Button
              variant="contained"
              onClick={handleConfirmAction} // Handle the credit confirmation action
              sx={{ backgroundColor: "#FFDB7E", color: "white" }}
            >
              Submit
              
            </Button> */}

            <div className="mt-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                                <button onClick={handleConfirmAction} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>
                                            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}