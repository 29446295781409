import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';
import { profileUpdateTeachers, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const Tutoring = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [isRadio, setIsRadio] = useState('');


    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const teacher = useSelector((item) => item?.teacherListing?.availableCredits)
    console.log(teacher);

    const handleTutoring = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setIsRadio(response?.payload?.data?.teacher_profile?.job_type)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    useEffect(() => {
        handleTutoring()
    }, [userId])

    const handleSaveChanges = () => {
        dispatch(profileUpdateTeachers({ userId: userId, jobType:isRadio }))
            .then((response) => {
                if (response?.payload?.success) {
                    setIsRadio(response?.payload?.data?.teacher_profile?.job_type)
                    toast.success(response?.payload?.message);
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    const handleRadioClick = (e) => {
        setIsRadio(e.target.value)
    }

    const goBack = () => {
        navigate(-1);
    };
    return (
        <Box>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5' style={{fontFamily: 'jost'}}>Tutoring Information</Typography>
                        <Box>Update your tutoring information, to help us serve you better.</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={handleSaveChanges} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '95%', padding: 2 }}>
                    <Typography style={{fontFamily: 'jost'}}>You are a full-time tutor / part-time tutor?</Typography>
                    <FormControl>
                        <RadioGroup
                            value={isRadio}
                            onChange={handleRadioClick}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="1" control={<Radio size='small' />} label={<Typography style={{fontFamily: 'jost'}}>Full - Time</Typography>}/>
                            <Box sx={{ fontSize: '14px', marginLeft: isXs ? '4%' : '10%' }}>If taking tuitions is your full-time profession, please select this option</Box>
                            <FormControlLabel value="2" control={<Radio size='small' />}  label={<Typography style={{fontFamily: 'jost'}}>Part - Time</Typography>}/>
                            <Box sx={{ fontSize: '14px', marginLeft: isXs ? '4%' : '10%' }}>If you work full-time somewhere and take tuitions as a passion or for additional source of income, please select this option.</Box>
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </Box>
        </Box>
    )
}

export default Tutoring
