import {
    createSlice,
    createAsyncThunk,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";
import axios from "axios";
import axiosInstanceV2 from "../../../Utils/axiosv2";


export const citiesListing = createAsyncThunk(
    "teacher/citiesListing",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherCities", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const teacherLanguage = createAsyncThunk(
    "teacherLanguage/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("languageListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const teacherDegree = createAsyncThunk(
    "teacherDegree/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("degreeListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const boardData = createAsyncThunk(
    "boardList/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("boardListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const classData = createAsyncThunk(
    "classData/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("classListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const getStudentPost = createAsyncThunk(
    "getStudentPost/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherGetStudentPost", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const getStudentPostV2 = createAsyncThunk(
    "getStudentPostV2/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            let pageno=data?.page ?? 1;
            delete data.page;
            const response = await axiosInstanceV2.post(`teacherGetStudentPost?page=${pageno}`, data);
            return response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const creditHistory = createAsyncThunk(
    "creditHistory/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherCreditHistory", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const subscription = createAsyncThunk(
    "subscription/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("subscriptionPlan", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const subscriptionPurchase = createAsyncThunk(
    "subscriptionPurchase/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherSubscriptionPurchase", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const historySubscriptionTeacher = createAsyncThunk(
    "historySubscriptionTeacher/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherSubscriptionGet", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const teacherDetails = createAsyncThunk(
    "creditsAvailableTeacher/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherProfileDeatils", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const subjectData = createAsyncThunk(
    "subjectData/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherSubjectListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const languageUpdate = createAsyncThunk(
    "languageUpdate/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherLanguageUpdate", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const profileUpdateTeachers = createAsyncThunk(
    "profileUpdateTeacher/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherProfileUpdate", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const postSubscribeTeacher = createAsyncThunk(
    "postSubscribeTeacher/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherPostSubscribe", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const priceUpdateTeacher = createAsyncThunk(
    "priceUpdateTeacher/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherClassePriceUpdate", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const getTeacherClasses = createAsyncThunk(
    "getTeacherClasses/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherClassesGet", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const deleteTeacherClasses = createAsyncThunk(
    "deleteTeacherClasses/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherCondcutedClassDelete", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return   error.response.data;

        }
    }
);
export const teacherNotifications = createAsyncThunk(
    "teacherNotifications/teacher",
    async (data, { rejectedWithValue }) => {
        try {
            // console.log('before', data);
            const response = await axiosInstance.post("notificationGet", data);
            // console.log('after', response );
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const dropDownListings = createAsyncThunk(
    "preferenceListing/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("dropDownListing", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const mobileUpdateTeacher = createAsyncThunk(
    "mobileUpdateTeacher/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherMobileUpdate", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const numberUpdateVerifyOtp = createAsyncThunk(
    "numberUpdateVerifyOtp/student",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherMobileUpdateVerifyOtp", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const createClassesTeacher = createAsyncThunk(
    "createClassesTeacher/student",
    async (data, { rejectedWithValue }) => {
        try {
            // console.log('before', data);
            const response = await axiosInstance.post("teacherClasseCreate", data);
            // console.log('after', response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const statesListing = createAsyncThunk(
    "statesListing/student",
    async (data, { rejectedWithValue }) => {
        try {
            // console.log('before', data);
            const response = await axiosInstance.post("states", data);
            // console.log('after', response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);
export const cityListing = createAsyncThunk(
    "cityListing/student",
    async (data, { rejectedWithValue }) => {
        try {
            // console.log('before', data);
            const response = await axiosInstance.post("cities", data);
            // console.log('after', response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const postDetails = createAsyncThunk(
    "teacher/postDetails",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherGetStudentPostDeatils", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const genrateOrderId = createAsyncThunk(
    "razorpay/razorpayOrderId",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("razorpay", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const paymentVerify = createAsyncThunk(
    "razorpay/paymentVerify",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("verify", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const refundPayment = createAsyncThunk(
    "razorpay/paymentRefund",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("refundPayment", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const getPinCodeStatus = createAsyncThunk(
    "pinCode/location",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${data}`);
            // console.log(response);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);




// const user = JSON.parse(localStorage.getItem('user'))
// const idUser = user?.id


const initialState = {
    postDetail:'',
    dropDownData:[],
    citiesListing: [],
    languageTeacher:[],
    degreeTeacher:[],
    listBoard:[],
    listClass:[],
    listSubject:[],
    studentPostListing:[],
    studentPostDetails:[],
    historyCredit:[],
    planSubscribtion:[],
    purchaseSubscription:[],
    subscriptionHistory:[],
    availableCredits:[],
    updateLanguageTeacher:[],
    profileUpdateTeacher:[],
    updatePrice:'',
    postSubscribe:[],
    getClassTeacher:[],
    deleteConductedClass:[],
    notifications:[],
    teacherMobileUpdate:[],
    updateTeacherNumber:[],
    createClasses:[],
    stateList:[],
    cityList:[],
    razorpayOrderId:[],
    paymentVerifyData:[],
    refundPaymentData:[],
    loading: false,
    error: null,

};


export const teacherListingSlice = createSlice({
    name: "teacherListingSlice",
    initialState,
    reducers: {
        storeMobile: (state, { payload }) => {
            state.studentMobile = payload;
        },
        storeStudentPostDetails: (state, action) => {
                console.log('student data',action.payload);
                state.studentPostDetails = action.payload;
            },
        storeClassId: (state, action) => {
            state.postPayload.classId = action.payload.id;
            state.subjectData = action.payload.subjects;
            state.postPayload.subjectIds = action.payload.subjects;
        },
        storeLocation: (state, action) => {
            // console.log(action.payload);
            state.postPayload.stateId = action.payload.stateId;
            state.postPayload.cityId = action.payload.cityId;
            state.postPayload.addressLineOne = action.payload.addressLineOne;
            state.postPayload.addressLineTwo = action.payload.addressLineTwo;
            state.postPayload.pincode = action.payload.pincode;
        },
        storeBoardListing : (state, action) => {
            state.postPayload.boardId = action.payload.boardId;
        },
        storeSubjects : (state, action) => {
            console.log(action.payload);
            state.postPayload.subjectIds = action.payload.subjectIds
        },
        storePreference : (state, action) => {
            state.postPayload.classPreferenceId = action.payload.classPreferenceId
        },
        storeDayType : (state, action) => {
            state.postPayload.classDayTypeId = action.payload.classDayTypeId
        },
        storeStartPlan : (state, action) => {
            state.postPayload.classStartPlanTypeId = action.payload.classStartPlanTypeId
        },
        storeFee : (state, action) => {
            state.postPayload.cost = action.payload.cost
        },
        storeGender : (state, action) => {
            state.postPayload.genderPreferenceId = action.payload.genderPreferenceId
        },
        updateSubjectData: (state, action) => {
            state.subjectData = action.payload;
        },
        teacherSubscribe: (state, action) => {
            // console.log(action.payload);
            state.teacherSubscribeData = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(citiesListing.pending, (state) => {
                state.loading = true;
            })
            .addCase(citiesListing.fulfilled, (state, action) => {
                state.loading = false;
                state.citiesListing = action.payload.data;
                state.error = null;
            })
            .addCase(citiesListing.rejected, (state, action) => {
                state.loading = false;
                state.citiesListing = null;
                state.error = action.payload;
            })
            .addCase(teacherLanguage.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherLanguage.fulfilled, (state, action) => {
                state.loading = false;
                state.languageTeacher = action.payload.data;
                state.error = null;
            })
            .addCase(teacherLanguage.rejected, (state, action) => {
                state.loading = false;
                state.languageTeacher = null;
                state.error = action.payload;
            })
            .addCase(teacherDegree.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherDegree.fulfilled, (state, action) => {
                state.loading = false;
                state.degreeTeacher = action.payload.data;
                state.error = null;
            })
            .addCase(teacherDegree.rejected, (state, action) => {
                state.loading = false;
                state.degreeTeacher = null;
                state.error = action.payload;
            })
            .addCase(boardData.pending, (state) => {
                state.loading = true;
            })
            .addCase(boardData.fulfilled, (state, action) => {
                state.loading = false;
                state.listBoard = action.payload.data;
                state.error = null;
            })
            .addCase(boardData.rejected, (state, action) => {
                state.loading = false;
                state.listBoard = null;
                state.error = action.payload;
            })
            .addCase(classData.pending, (state) => {
                state.loading = true;
            })
            .addCase(classData.fulfilled, (state, action) => {
                state.loading = false;
                state.listClass = action.payload.data;
                state.error = null;
            })
            .addCase(classData.rejected, (state, action) => {
                state.loading = false;
                state.listClass = null;
                state.error = action.payload;
            })
            .addCase(subjectData.pending, (state) => {
                state.loading = true;
            })
            .addCase(subjectData.fulfilled, (state, action) => {
                state.loading = false;
                state.listSubject = action.payload.data;
                state.error = null;
            })
            .addCase(subjectData.rejected, (state, action) => {
                state.loading = false;
                state.listSubject = null;
                state.error = action.payload;
            })
            .addCase(getStudentPost.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStudentPost.fulfilled, (state, action) => {
                state.loading = false;
                state.studentPostListing = action.payload.data;
                state.error = null;
            })
            .addCase(getStudentPost.rejected, (state, action) => {
                state.loading = false;
                state.studentPostListing = null;
                state.error = action.payload;
            })
            .addCase(getStudentPostV2.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStudentPostV2.fulfilled, (state, action) => {
                state.loading = false;
                state.studentPostListing = action.payload.data;
                state.error = null;
            })
            .addCase(getStudentPostV2.rejected, (state, action) => {
                state.loading = false;
                state.studentPostListing = null;
                state.error = action.payload;
            })
            .addCase(creditHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(creditHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.historyCredit = action.payload.data;
                state.error = null;
            })
            .addCase(creditHistory.rejected, (state, action) => {
                state.loading = false;
                state.historyCredit = null;
                state.error = action.payload;
            })
            .addCase(subscription.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscription.fulfilled, (state, action) => {
                state.loading = false;
                state.planSubscribtion = action.payload.data;
                state.error = null;
            })
            .addCase(subscription.rejected, (state, action) => {
                state.loading = false;
                state.planSubscribtion = null;
                state.error = action.payload;
            })
            .addCase(subscriptionPurchase.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscriptionPurchase.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseSubscription = action.payload.data;
                state.error = null;
            })
            .addCase(subscriptionPurchase.rejected, (state, action) => {
                state.loading = false;
                state.purchaseSubscription = null;
                state.error = action.payload;
            })
            .addCase(historySubscriptionTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(historySubscriptionTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionHistory = action.payload.data;
                state.error = null;
            })
            .addCase(historySubscriptionTeacher.rejected, (state, action) => {
                state.loading = false;
                state.subscriptionHistory = null;
                state.error = action.payload;
            })
            .addCase(teacherDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.availableCredits = action.payload.data;
                state.error = null;
            })
            .addCase(teacherDetails.rejected, (state, action) => {
                state.loading = false;
                state.availableCredits = null;
                state.error = action.payload;
            })
            .addCase(dropDownListings.pending, (state) => {
                state.loading = true;
            })
            .addCase(dropDownListings.fulfilled, (state, action) => {
                state.loading = false;
                state.dropDownData = action.payload.data;
                state.error = null;
            })
            .addCase(dropDownListings.rejected, (state, action) => {
                state.loading = false;
                state.dropDownData = null;
                state.error = action.payload;
            })
            .addCase(languageUpdate.pending, (state) => {
                state.loading = true;
            })
            .addCase(languageUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.updateLanguageTeacher = action.payload.data;
                state.error = null;
            })
            .addCase(languageUpdate.rejected, (state, action) => {
                state.loading = false;
                state.updateLanguageTeacher = null;
                state.error = action.payload;
            })
            .addCase(profileUpdateTeachers.pending, (state) => {
                state.loading = true;
            })
            .addCase(profileUpdateTeachers.fulfilled, (state, action) => {
                state.loading = false;
                // console.log('actionpayload', action.payload);
                state.profileUpdateTeacher = action.payload.data;
                state.error = null;
            })
            .addCase(profileUpdateTeachers.rejected, (state, action) => {
                state.loading = false;
                state.profileUpdateTeacher = null;
                state.error = action.payload;
            })
            .addCase(postSubscribeTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(postSubscribeTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.postSubscribe = action.payload.data;
                state.error = null;
            })
            .addCase(postSubscribeTeacher.rejected, (state, action) => {
                state.loading = false;
                state.postSubscribe = null;
                state.error = action.payload;
            })
            .addCase(priceUpdateTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(priceUpdateTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.updatePrice = action.payload.data;
                state.error = null;
            })
            .addCase(priceUpdateTeacher.rejected, (state, action) => {
                state.loading = false;
                state.updatePrice = null;
                state.error = action.payload;
            })
            .addCase(getTeacherClasses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTeacherClasses.fulfilled, (state, action) => {
                state.loading = false;
                state.getClassTeacher = action.payload.data;
                state.error = null;
            })
            .addCase(getTeacherClasses.rejected, (state, action) => {
                state.loading = false;
                state.getClassTeacher = null;
                state.error = action.payload;
            })
            .addCase(teacherNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherNotifications.fulfilled, (state, action) => {
                state.loading = false;
                // console.log('actionpayload', action.payload);
                state.notifications = action.payload.data;
                state.error = null;
            })
            .addCase(teacherNotifications.rejected, (state, action) => {
                state.loading = false;
                state.notifications = null;
                state.error = action.payload;
            })
            .addCase(mobileUpdateTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(mobileUpdateTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.teacherMobileUpdate = action.payload.data;
                state.error = null;
            })
            .addCase(mobileUpdateTeacher.rejected, (state, action) => {
                state.loading = false;
                state.teacherMobileUpdate = null;
                state.error = action.payload;
            })
            .addCase(numberUpdateVerifyOtp.pending, (state) => {
                state.loading = true;
            })
            .addCase(numberUpdateVerifyOtp.fulfilled, (state, action) => {
                state.loading = false;
                state.updateTeacherNumber = action.payload.data;
                state.error = null;
            })
            .addCase(numberUpdateVerifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.updateTeacherNumber = null;
                state.error = action.payload;
            })
            .addCase(createClassesTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(createClassesTeacher.fulfilled, (state, action) => {
                state.loading = false;
                console.log('action', action.payload);
                state.createClasses = action.payload.data;
                state.error = null;
            })
            .addCase(createClassesTeacher.rejected, (state, action) => {
                state.loading = false;
                state.createClasses = null;
                state.error = action.payload;
            })
            .addCase(statesListing.pending, (state) => {
                state.loading = true;
            })
            .addCase(statesListing.fulfilled, (state, action) => {
                state.loading = false;
                state.stateList = action.payload.data;
                state.error = null;
            })
            .addCase(statesListing.rejected, (state, action) => {
                state.loading = false;
                state.stateList = null;
                state.error = action.payload;
            })
            .addCase(cityListing.pending, (state) => {
                state.loading = true;
            })
            .addCase(cityListing.fulfilled, (state, action) => {
                state.loading = false;
                state.cityList = action.payload.data;
                state.error = null;
            })
            .addCase(cityListing.rejected, (state, action) => {
                state.loading = false;
                state.cityList = null;
                state.error = action.payload;
            })
            .addCase(postDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(postDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.postDetail = action.payload.data;
                state.error = null;
            })
            .addCase(postDetails.rejected, (state, action) => {
                state.loading = false;
                state.postDetail = null;
                state.error = action.payload;
            })
            .addCase(genrateOrderId.pending, (state) => {
                state.loading = true;
            })
            .addCase(genrateOrderId.fulfilled, (state, action) => {
                state.loading = false;
                state.razorpayOrderId = action.payload.data;
                state.error = null;
            })
            .addCase(genrateOrderId.rejected, (state, action) => {
                state.loading = false;
                state.razorpayOrderId = null;
                state.error = action.payload;
            })
            .addCase(paymentVerify.pending, (state) => {
                state.loading = true;
            })
            .addCase(paymentVerify.fulfilled, (state, action) => {
                state.loading = false;
                state.paymentVerifyData = action.payload.data;
                state.error = null;
            })
            .addCase(paymentVerify.rejected, (state, action) => {
                state.loading = false;
                state.paymentVerifyData = null;
                state.error = action.payload;
            })
            .addCase(refundPayment.pending, (state) => {
                state.loading = true;
            })
            .addCase(refundPayment.fulfilled, (state, action) => {
                state.loading = false;
                state.refundPaymentData = action.payload.data;
                state.error = null;
            })
            .addCase(refundPayment.rejected, (state, action) => {
                state.loading = false;
                state.refundPaymentData = null;
                state.error = action.payload;
            })
            .addCase(getPinCodeStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPinCodeStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = action.payload.data;
                state.error = null;
            })
            .addCase(getPinCodeStatus.rejected, (state, action) => {
                state.loading = false;
                state.notification = null;
                state.error = action.payload;
            })




            // .addCase(userState.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(userState.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.stateData = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(userState.rejected, (state, action) => {
            //     state.loading = false;
            //     state.stateData = null;
            //     state.error = action.payload;
            // })
            // .addCase(userCity.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(userCity.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.cityData = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(userCity.rejected, (state, action) => {
            //     state.loading = false;
            //     state.cityData = null;
            //     state.error = action.payload;
            // })
            // .addCase(studentBoardListing.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(studentBoardListing.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.boardData = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(studentBoardListing.rejected, (state, action) => {
            //     state.loading = false;
            //     state.boardData = null;
            //     state.error = action.payload;
            // })
            // .addCase(dropDownListings.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(dropDownListings.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.dropDownData = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(dropDownListings.rejected, (state, action) => {
            //     state.loading = false;
            //     state.dropDownData = null;
            //     state.error = action.payload;
            // })
            // .addCase(studentFeedback.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(studentFeedback.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.studentGetFeedback = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(studentFeedback.rejected, (state, action) => {
            //     state.loading = false;
            //     state.studentGetFeedback = null;
            //     state.error = action.payload;
            // })
            // .addCase(teacherFeedback.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(teacherFeedback.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.teacherGetFeedback = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(teacherFeedback.rejected, (state, action) => {
            //     state.loading = false;
            //     state.teacherGetFeedback = null;
            //     state.error = action.payload;
            // })
            // .addCase(studentProfileDetails.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(studentProfileDetails.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.profileInfoDetails = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(studentProfileDetails.rejected, (state, action) => {
            //     state.loading = false;
            //     state.profileInfoDetails = null;
            //     state.error = action.payload;
            // })
            // .addCase(accountClose.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(accountClose.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.accountCloseStudent = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(accountClose.rejected, (state, action) => {
            //     state.loading = false;
            //     state.accountCloseStudent = null;
            //     state.error = action.payload;
            // })
            // .addCase(accountLogout.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(accountLogout.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.accountCloseStudent = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(accountLogout.rejected, (state, action) => {
            //     state.loading = false;
            //     state.accountCloseStudent = null;
            //     state.error = action.payload;
            // })
            // .addCase(allPostData.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(allPostData.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.postData = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(allPostData.rejected, (state, action) => {
            //     state.loading = false;
            //     state.postData = null;
            //     state.error = action.payload;
            // })
            // .addCase(closePostList.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(closePostList.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.closePost = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(closePostList.rejected, (state, action) => {
            //     state.loading = false;
            //     state.closePost = null;
            //     state.error = action.payload;
            // })
            // .addCase(createReview.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(createReview.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.newReview = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(createReview.rejected, (state, action) => {
            //     state.loading = false;
            //     state.newReview = null;
            //     state.error = action.payload;
            // })
            // .addCase(helpAndSupport.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(helpAndSupport.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.helpSupport = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(helpAndSupport.rejected, (state, action) => {
            //     state.loading = false;
            //     state.helpSupport = null;
            //     state.error = action.payload;
            // })
            // .addCase(profileUpdate.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(profileUpdate.fulfilled, (state, action) => {
            //     state.loading = false;
            //     console.log('aaaaz',action.payload);
            //     state.profileUpdateStudent = action.payload.data;
            //     state.error = null;
            // })
            // .addCase(profileUpdate.rejected, (state, action) => {
            //     state.loading = false;
            //     state.profileUpdateStudent = null;
            //     state.error = action.payload;
            // });
    },
});


export const {
    storeMobile,
    storeStudentPostDetails,
    updateStudentClass,
    storeLocationId,
    storeClassId,
    storeLocation,
    storeSubjects,
    storeBoardListing,
    storePreference,
    storeDayType,
    storeStartPlan,
    storeGender,
    storeFee,
    teacherSubscribe,
} = teacherListingSlice.actions;

export default teacherListingSlice.reducer;
