import React from 'react'
import whatsappicon from "../../../assets/floatingwhatsappicon.png";
import phoneicon from "../../../assets/floatingphoneicon.png";
import { useMediaQuery } from '@mui/material';

const WhatAppMobile = () => {

    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');

  return (
    <div style={{ display: 'flex', gap: '13px', flexDirection: 'column', position: 'fixed', bottom: isXs ? '52px' : '68px', right: '0', paddingRight: isXs ? '20px' : '15px', zIndex: '1000' }}>
    <div>
      <a href="https://wa.me/916376400386" target="_blank" rel="noopener noreferrer">
        <img src={whatsappicon} alt="whatsapp-img" />
      </a>
    </div>
    <div>
      <a href="tel:+91-6376400386" target="_blank" rel="noopener noreferrer">
        <img src={phoneicon} alt="phone-img" />
      </a>
    </div>
  </div>
  )
}

export default WhatAppMobile
