import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import StepperWeb from './StepperWeb';
import StepperPhone, { Step2 } from './StepperPhone';
import pencil from '../../../assets/pencil.svg'
import nbook from '../../../assets/nbook.svg'
import NextButton from '../../Buttons/NextButton';
import BackButton from '../../Buttons/BackButton';
import { toast } from 'react-toastify';

import InquiryLayout from './InquiryLayout';

import { Autocomplete, Grid, Stack, TextField, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeLocation, storeLocationId, userCity, userState, getPinCodeStatus } from '../../features/student/listingSlice';
import { Steppers2 } from './StepperWeb';
import { set } from 'react-hook-form';


const Location = () => {
  const navigate = useNavigate()
  const isXs = useMediaQuery('(min-width:600px)');
  const dispatch = useDispatch()
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [pincode, setPincode] = useState('')
  const [isState, setIsState] = useState()
  const [isCity, setIsCity] = useState()
  const [isPincodeChecked, setIsPincodeChecked] = useState(true)

  const handleAddressLine1Change = (event) => {
    setAddressLine1(event?.target?.value);
  };
  const handleAddressLine2Change = (event) => {
    setAddressLine2(event?.target?.value);
    // console.log(addressLine2);
  };
  const handlePincode = async (e) => {
    const re = /^[0-9\b]+$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      setPincode(e?.target?.value);

    }

  }

  const findState = async () => {
    dispatch(userState({ countryId: 101 }))
  };

  const stateData = useSelector((state) => state?.listing?.stateData)
  const cityData = useSelector((state) => state?.listing?.cityData)

  const stateId = stateData?.map((item) => item.id)
  const cityId = cityData?.map((item) => item.id)

  const handleStateClick = async (event, value) => {
    // setIsState(event.target.value)
    const selectedState = stateData?.find((state) => state?.stateName === value);
    dispatch(userCity({ stateId: selectedState?.id }))
    // dispatch(storeLocation(selectedState))
    setIsState(selectedState);
  }


  const handleCityClick = async (event, value) => {
    setIsCity(event?.target?.value)
    const selectedCity = cityData?.find((city) => city?.cityName === value);
    // dispatch(storeLocationId({ cityId: selectedCity.id }))
    setIsCity(selectedCity)
  }
  // console.log(isCity);

  useEffect(() => {
    findState()
  }, [])

  useEffect(() => {
    console.log(isState, 'isState');
  }, [isState, isCity])

  const selectedLocation = useSelector((item) => item?.listing?.locationSelect)


  const user = JSON?.parse(localStorage?.getItem('user'))

  const { postCount } = useSelector((item) => item?.listing?.profileInfoDetails)

  const isNavigate = (event, value) => {
    const selectedState = stateData?.find((state) => state?.stateName === value);
    const selectedCity = cityData?.find((state) => state?.cityName === value);
    if (addressLine1 == '' || addressLine1 == null || addressLine1 == 0) {
      toast.error('Address First Field is Required.');
    } else if (addressLine2 == '' || addressLine2 == null || addressLine2 == 0) {
      toast.error('Address Second Field is Required.');
    } else if (pincode == '' || pincode == null || pincode == 0) {
      toast.error('Pincode Field is Required.');
    } else if (isState == '' || isState == null || isState == 0) {
      toast.error('State Field is Required.');
    } else if (isCity == '' || isCity == null || isCity == 0) {
      toast.error('City Field is Required.');
    } else if (isPincodeChecked == false) {
      toast.error("Please Fill Right Pincode !")
    }
    else {
      dispatch(storeLocation({ addressLineOne: addressLine1, addressLineTwo: addressLine2, pincode: pincode, stateId: isState, cityId: isCity }))
      // const selectedLocation = useSelector((item) => item?.listing?.locationSelect)
      if (postCount > 0) {
        navigate('/dashboard/education')
      } else {
        navigate('/education')

      }
      // toast.success('Submitted')

    }
  }

  const handlePinCodeCheck = async (e) => {
    setIsPincodeChecked(false)
    try {
      const response = await dispatch(getPinCodeStatus(e.target.value))

      if (response?.payload[0]?.Status == "Success") {

        const res1 = response?.payload[0]?.PostOffice.some((item) => (isState.stateName.toLowerCase().toString() == item.State.toLowerCase().toString()));
        const res2 = response?.payload[0]?.PostOffice.some((item) => (isCity.cityName.toLowerCase().toString() == item.District.toLowerCase().toString()));
        const res3 = response?.payload[0]?.PostOffice.some((item) => (isCity.cityName.toLowerCase().toString() == item.Division.toLowerCase().toString()));
        const res4 = response?.payload[0]?.PostOffice.some((item) => (isCity.cityName.toLowerCase().toString() == item.Region.toLowerCase().toString()));
        const res5 = response?.payload[0]?.PostOffice.some((item) => (isCity.cityName.toLowerCase().toString() == item.Block.toLowerCase().toString()));
        const res6 = response?.payload[0]?.PostOffice.some((item) => (item.Name.toLowerCase().toString().includes(isCity.cityName.toLowerCase().toString())));
        // console.log({"res1":res1,"res2":res2,"res3":res3,"res4":res4,"res5":res5,"res6":res6})

        const res = res1 && res2 || res3 || res4 || res5 || res6

        if (res == false) {
          setIsPincodeChecked(false)
          toast.error("Please fill right PinCode !")

        } else if (res == true) {
          setIsPincodeChecked(true);
          toast.success("Pincode is right !")
        }

      } else {
        toast.error("No Pincode available !")
        setIsPincodeChecked(false)

      }

    } catch (error) {
      console.log("bk", error)
    }

  }

  useEffect(() => {
    setAddressLine1(selectedLocation?.addressLineOne)
    setAddressLine2(selectedLocation?.addressLineTwo)
    setPincode(selectedLocation?.pincode)
    setIsState(selectedLocation?.stateId)
    setIsCity(selectedLocation?.cityId)
  }, [selectedLocation])

  const goBack = () => {
    navigate(-1);
  };

  return (
    // <InquiryLayout>

    //   <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
    //     <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
    //     <div className='d-flex justify-content-center align-items-center mx-auto'>What is your location?</div>
    //   </Box>
    //   <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
    //     <Steppers2 />
    //   </div>
    //   <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
    //     <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
    //       <Step2 />
    //     </div>
    //   </div>

    //   <Grid container sx={{ textAlign: 'center', width: '100%' }}>
    //     <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
    //       <TextField value={addressLine1} onChange={handleAddressLine1Change} id="outlined-basic" label="Address Line 1" variant="outlined" sx={{ width: '75%' }} />
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: isXs ? '' : '5%' }}>
    //       <TextField value={addressLine2} onChange={handleAddressLine2Change} id="outlined-basic" label="Address Line 2" variant="outlined" sx={{ width: '75%' }} />
    //     </Grid>
    //   </Grid>


    //   <Stack sx={{ textAlign: 'center' }}>

    //     <Grid container sx={{ justifyContent: 'center', width: '100%', marginTop: isXs ? '3%' : '5%', gap: 3 }}>

    //       <Grid item xs={9} sm={3} md={3} lg={3} xl={3}>
    //         <Autocomplete
    //           fullWidth
    //           id="free-solo-demo"
    //           freeSolo
    //           value={selectedLocation?.stateId?.stateName}
    //           onChange={handleStateClick}
    //           options={stateData?.map((option) => option?.stateName)}
    //           renderInput={(params) => <TextField {...params} label="State" sx={{width: '14.2rem'}}/>}
    //           disableClearable
    //         />
    //       </Grid>

    //       <Grid item xs={9} sm={3} md={3} lg={3} xl={3}>
    //         <Autocomplete
    //           fullWidth
    //           id="free-solo-demo"
    //           freeSolo
    //           value={selectedLocation?.cityId?.cityName}
    //           onChange={handleCityClick}
    //           options={cityData?.map((option) => option?.cityName)}
    //           renderInput={(params) => <TextField {...params} label="City" sx={{width: '14.2rem'}}/>}
    //           disableClearable
    //         />
    //       </Grid>

    //       <Grid item xs={9} sm={4} md={4} lg={4} xl={4}>
    //         <TextField fullWidth value={pincode} onChange={handlePincode} id="outlined-basic" label="Pincode" variant="outlined" />
    //       </Grid>

    //     </Grid>

    //   </Stack>

    //   <div className='d-flex justify-content-center'>
    //     <div style={{ marginTop: isXs ? '3%' : '5%', width: isXs ? '' : '70%' }} >
    //       <NextButton onClick={() => isNavigate()} />
    //     </div>
    //   </div>
    //   <div className='d-flex justify-content-between px-4' style={{ marginTop: isXs ? '' : '13%' }}>
    //     <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
    //     <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
    //   </div>
    // </InquiryLayout>


    <InquiryLayout>

      <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
        <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />
        <div className='d-flex justify-content-center align-items-center mx-auto'>What is your location?</div>
      </Box>
      <div className='my-5' style={{ display: isXs ? '' : 'none' }}>
        <Steppers2 />
      </div>
      <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
        <div className='my-5' style={{ display: isXs ? 'none' : '' }} >
          <Step2 />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: isXs ? "row" : "column" , justifyContent: 'center', alignItems: "center", gap: '1rem', marginBottom: '1.6rem' }}>
        <div>

          <TextField value={addressLine1} onChange={handleAddressLine1Change} id="outlined-basic" label="Address Line 1" variant="outlined" sx={{ width: isXs ? "22rem" : '19rem' }} />
        </div>

        <div>
          <TextField value={addressLine2} onChange={handleAddressLine2Change} id="outlined-basic" label="Address Line 2" variant="outlined" sx={{ width: isXs ? "22rem" : '19rem'  }} />
        </div>
      </div>


      <Stack sx={{ textAlign: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' , flexDirection: isXs ? "row" : "column"  }}>

          <div>
            <Autocomplete

              id="free-solo-demo"
              freeSolo
              value={selectedLocation?.stateId?.stateName}
              onChange={handleStateClick}
              options={stateData?.map((option) => option?.stateName)}
              renderInput={(params) => <TextField {...params} label="State" sx={{ width: isXs ? '14.2rem' : '19rem' }} />}
              disableClearable
            />
          </div>

          <div>
            <Autocomplete

              id="free-solo-demo"
              freeSolo
              value={selectedLocation?.cityId?.cityName}
              onChange={handleCityClick}
              options={cityData?.map((option) => option?.cityName)}
              renderInput={(params) => <TextField {...params} label="City" sx={{ width: isXs ? '14.2rem' : '19rem' }} />}
              disableClearable
            />
          </div>

          <div>
            <TextField
              value={pincode}
              inputProps={{ maxLength: 6, mixLength: 6 }}

              onChange={(e) => {
                e.target.value.length >= 6 && handlePinCodeCheck(e);
                handlePincode(e)
              }} id="outlined-basic" label="Pincode" variant="outlined" sx={{width: isXs ? '14.2rem' : '19rem' }}

            />
          </div>

        </div>

      </Stack>

      <div className='d-flex justify-content-center'>
        <div style={{ marginTop: isXs ? '3%' : '5%', width: isXs ? '' : '70%' }} >
          <NextButton onClick={() => isNavigate()} />
        </div>
      </div>
      <div className='d-flex justify-content-between px-4' style={{ marginTop: isXs ? '' : '5%' }}>
        <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
        <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
      </div>
    </InquiryLayout>
  );
}

export default Location;
