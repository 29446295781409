import { Box, Rating, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import line from '../../../assets/line.svg';
import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useDispatch, useSelector } from 'react-redux';
import { createReview } from '../../features/student/listingSlice';
import { toast } from 'react-toastify';



const CreateReview = () => {
    const [value, setValue] = useState(0);
    const [description, setdescription] = useState('')


    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const userId = useSelector((item) => item?.listing?.postPayload?.userId)

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const reviewData = useSelector((item) => item?.listing?.newReview)
    const teacherData = useSelector((item) => item?.listing?.teacherForReview)
    const teacherId = teacherData.id
    console.log(teacherId);

    const createNewReview = () => {
        if (value <= 0) {
            toast.error('Input field is required.');
        } else if (description === '' || description === null || description === 0) {
            toast.error('Input field is required.');
        }
        else {
            dispatch(createReview({ userId: userId, rating: value, description: description, teacherId: teacherId }))
                .then((response) => {
                    if (response?.payload?.success == true) {
                        toast.success(response?.payload?.message);
                    }
                }).catch((error) => {
                    console.log('getting error', error);
                });
        }
    }

    const goBack = () => {
        navigate(-1);
    };

    const handleChange = (event) => {
        setdescription(event.target.value);
    };

    const isXs = useMediaQuery('(min-width:600px)');
    return (
        <>
            <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h5'>Write a Review</Typography>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            <Box sx={{ marginLeft: '2%', marginTop: '2%' }}>
                <Box>
                    <Typography>What is your Rate?</Typography>
                </Box>
                <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => { setValue(newValue) }}
                    sx={{ fontSize: '4rem' }} />
            </Box>
            <Box sx={{ marginLeft: '2%', marginTop: '2%' }}>
                <Typography>Write a Review</Typography>
                <TextField
                    sx={{ width: '95%', marginTop: '2%' }}
                    id="outlined-multiline-static"
                    multiline
                    rows={5}
                    placeholder='Write Your Review'
                    onChange={handleChange}
                />
            </Box>
            <Box>
                <Box sx={{ marginTop: '2%', display: 'flex', justifyContent: 'center' }}>
                    <button onClick={createNewReview} style={{ backgroundColor: '#FFDB7E', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', padding: 2, width: '60%' }}>
                        <Box sx={{ fontSize: isXs ? '2vw' : '5vw', color: '#866D2C' }}>Submit</Box>
                    </button>
                </Box>
            </Box>
        </>
    )
}

export default CreateReview
