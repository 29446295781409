import React, { useEffect, useState } from "react";
import SampleImage from "../../../assets/SampleImage.svg";
import DashboardPost1 from "../../../assets/DashboardPost1.svg";
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allPostData,
  closePostList,
  studentCreatePost,
  teacherSubscribe,
} from "../../features/student/listingSlice";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disabledButtons, setDisabledButtons] = useState([]);
  const [search, setSearch] = useState("");

  const isXs = useMediaQuery("(min-width:600px)");

  // const studentPostData = useSelector((item) => item?.listing?.postPayload)
  // console.log('aaa',studentPostData);
  const allData = useSelector((item) => item?.listing?.postData);
  const loading = useSelector((item) => item?.listing?.loading);

  // const userId = useSelector((item) => item?.listing?.postPayload?.userId)
  // console.log('thisdata',allData);
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.id;

  const listPostData = async () => {
    dispatch(allPostData({ userId: userId, search: search }));
  };

  useEffect(() => {
    listPostData();
  }, [search]);

  const closeEnquiry = async (id, index) => {
    dispatch(closePostList({ userId: userId, postId: id }))
      .then((response) => {
        console.log(response);
        if (response?.payload?.status === 201)
          // alert('dsadbsa');
          listPostData();
      })
      .catch((error) => {
        console.log(error, "abc");
      });
    setDisabledButtons([...disabledButtons, index]);
  };

  const isNavigate = (i) => {
    // console.log(index, 'index');
    const selectedItem = allData?.find((item, index) => index === parseInt(i));
    console.log(selectedItem, "jhfdsf");
    dispatch(teacherSubscribe(selectedItem));
    const itemId = allData[i]?.id;
    navigate(`/dashboard/homeclass/${itemId}`);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Stack sx={{ alignItems: isXs ? "" : "center" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        className="d-flex align-items-center mt-4"
        style={{
          width: isXs ? "50%" : "90%",
          borderRadius: "50px",
          marginLeft: "2%",
        }}
      >
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search My Posts"
          aria-label="Search"
          style={{
            borderRadius: "50px",
            backgroundColor: "#F3F3F3",
            fontSize: "12px",
            padding: "10px",
          }}
          onChange={handleSearch}
        />
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "17%",
          right: "10%",
          maxWidth: "25%",
          height: "70%",
          fontSize: "1vw",
          display: isXs ? "" : "none",
        }}
      >
        <Box
          style={{
            backgroundImage: `url(${DashboardPost1})`,
            backgroundSize: "cover",
            height: "30%",
          }}
        >
          <Box
            className="p-2"
            style={{
              width: "70%",
              marginLeft: "3%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Do you need help in finding the best teacher matching your
            requirements?
          </Box>
          <Box>
            <button
              onClick={() => navigate("/dashboard/learningneeds")}
              style={{
                backgroundColor: "#FFDB7E",
                fontWeight: "bold",
                fontSize: ".8rem",
                borderRadius: "3px",
                border: "none",
                marginLeft: "6%",
              }}
            >
              {" "}
              Post Your Learning Need
            </button>
          </Box>
        </Box>
        <Box
          className="px-3 mt-4"
          style={{
            position: "absolute",
            backgroundColor: "#F6F0DF",
            border: "1px solid #CB8B65",
            borderRadius: ".5rem",
            width: "98%",
            height: "19rem",
            marginLeft: "1%",
            flexDirection: "column",
            justifyContent: "center",
            display: isXs ? "flex" : "none",
          }}
        >
          <Box style={{ width: "100%", fontSize: "1.2rem" }}>
            Our Popular academic classes for tuitions
          </Box>
          <hr style={{ width: "100%" }}></hr>
          <ul style={{ lineHeight: "1.6rem" }}>
            <li>Pre Primary To 12th</li>
            <li>CBSE/ICSE/IB/State Board</li>
            <li>English, French, German, Kannada</li>
            <li>Competitive Exams</li>
            <li>Non-Academic Learning</li>
          </ul>
        </Box>
      </Box>

      <Box sx={{ marginTop: "5%", display: "flex", justifyContent: "center" }}>
        <Box
          style={{
            backgroundImage: `url(${DashboardPost1})`,
            backgroundSize: "cover",
            height: "7rem",
            width: "90%",
            display: isXs ? "none" : "",
          }}
        >
          <Box
            className="p-2"
            style={{
              width: "70%",
              marginLeft: "3%",
              color: "white",
              fontWeight: "bold",
              fontSize: "3.5vw",
            }}
          >
            Do you need help in finding the best teacher matching your
            requirements?
          </Box>
          <Box>
            <button
              onClick={() => navigate("/dashboard/learningneeds")}
              style={{
                backgroundColor: "#FFDB7E",
                fontWeight: "bold",
                fontSize: "14px",
                borderRadius: "3px",
                fontSize: "3.5vw",
                border: "none",
                marginLeft: "6%",
              }}
            >
              {" "}
              Post Your Learning Need
            </button>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          marginLeft: "3%", 
          overflowY: "auto",
          width: isXs ? "50%" : "90%",
          marginTop: isXs ? "0" : "1rem",
        }}
      >
        <Box sx={{ display: isXs ? 'none': '' , marginBottom: '1rem' }}> <button onClick={() => navigate("/dashboard/learningneeds")} style={{ border: "none" , backgroundColor: "#CB8B65" , color: "white" , width: "12rem" , height: "2rem" , borderRadius: ".2rem" , boxShadow: "1px 2px 5px 2px #d3d3d2"}}>+Add Learning Needs</button> </Box>

        
        <Box style={{ fontSize: "1.3rem" }}>My Learning Needs</Box>
        <Box sx={{position: "absolute" , display: isXs ? '': 'none' , top: "13rem" , left: "39rem" , zIndex: "2" }}> <button onClick={() => navigate("/dashboard/learningneeds")} style={{ border: "none" , backgroundColor: "#CB8B65" , color: "white" , width: "12rem" , height: "2rem" , borderRadius: ".2rem" , boxShadow: "1px 2px 5px 2px #d3d3d2"}}>+Add Learning Needs</button> </Box>

        {/* {allData?.map((item, index) => (
                    <button onClick={() => isNavigate(index)} key={index} className="card mt-2 px-3" style={{ backgroundColor: '#FCFCFC', borderColor: '#D1D1D1', marginBottom: '1%', width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box className='d-flex align-items-center mt-2' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box style={{ color: '#CB8B65', fontWeight: 'bold' }}>{item?.classData?.class_name}</Box>
                                <Box sx={{ height: '20px', display: item.postStatus === 0 ? 'none' : 'block' }}>
                                    <button onClick={(event) => { event.stopPropagation(); closeEnquiry(item?.id) }
                                    } style={{ border: '1px solid #CECECE', marginLeft: '3%', fontSize: '12px', width: '6.5rem', zIndex: 2 }}>Close Enquiry</button>
                                </Box>
                            </Box>
                            <Box className='py-1 mt-2 px-1' style={{ color: 'black', display: 'flex', width: isXs ? '60%' : '100%', fontSize: '12px', border: '1px solid #7E7E7E', borderRadius: '10px' }}>
                                {item?.subjects?.map((item, index) => (<Box key={index} >{item?.subjectName?.subject_name},</Box>))}
                            </Box>
                            <Box className='px-2 mt-2' style={{ fontSize: '12px', display: 'flex' }}> <LaptopMacOutlinedIcon />
                                {item?.classTypes?.map((item, index) => (<Box key={index}>{item?.classTypeName?.name}</Box>))}
                            </Box>
                            <Box className='d-flex justify-content-between py-1' style={{ marginBottom: '1%' }}>
                                <Box className='d-flex justify-content-center mt-1' style={{ border: '1px solid #CECECE', borderRadius: '10px', padding: '3px', backgroundColor: '#F1F1F1', width: '35%', height: '10%', fontSize: '12px' }}>{item.teacherAllDetails.length} Connected</Box>
                                <Box sx={{ display: isXs ? 'flex' : 'none', width: '10rem' }}>
                                    {item?.teacherAllDetails?.slice(0, 3).map((teacher, index) => (
                                        <Box key={index} display={'flex'} alignItems="center">
                                            {teacher?.user && typeof teacher.user === 'object' && (
                                                <Box sx={{ marginBottom: '20%' }}>
                                                    {teacher.user?.profile_image && typeof teacher.user.profile_image === 'object' && (
                                                        <Box >
                                                            <img
                                                                src={teacher.user.profile_image.file_name}
                                                                style={{
                                                                    width: '1.5rem',
                                                                    height: '1.5rem',
                                                                    objectFit: 'cover',
                                                                    marginLeft: '3%',
                                                                    borderRadius: '50%'
                                                                }}
                                                                alt={`Profile ${index + 1}`}
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                    {item?.teacherAllDetails?.length > 3 && (
                                        <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '1.5rem', height: '1.5rem', marginLeft: '3%', borderRadius: '50%', cursor: 'pointer', marginTop: '2%' }}>
                                            <Box>+{item.teacherAllDetails.length - 3}</Box>
                                        </Box>
                                    )}
                                </Box>


                                <Box>
                                    <div key={index} className='d-flex justify-content-center gap-2' style={{ border: '1px solid #CECECE', borderRadius: '5px', width: '100%', fontSize: '12px', backgroundColor: item.postStatus === 0 ? '#FF0000' : '#20C012' }}>
                                        <Box sx={{ color: 'white' }}>
                                            Status:
                                        </Box>
                                        <Box style={{ border: 'None', paddingTop: '0%', color: 'white' }}>
                                            {item.postStatus === 0 ? 'Close' : 'Open'}
                                        </Box>
                                    </div>

                                    <Box style={{ fontSize: '10px' }}>{item.createdAt}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </button>
                ))} */}

        {allData?.length > 0
          ? allData?.map((item, index) => (
              <button
                key={index}
                className="card mt-3 px-3"
                style={{
                  backgroundColor: "#FCFCFC",
                  borderColor: "#D1D1D1",
                  marginBottom: "5%",
                  width: "100%",
                }}
                disabled={disabledButtons.includes(index)}
              >
                <div
                  onClick={() => isNavigate(index)}
                  style={{ width: "100%" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: ".2rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      className="d-flex align-items-center mt-2"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        style={{
                          color: "#CB8B65",
                          fontWeight: "500",
                          fontSize: "1.1rem",
                        }}
                      >
                        {item?.classData?.class_name}
                      </Box>
                      <Box
                        sx={{
                          height: "20px",
                          display: item.postStatus === 0 ? "none" : "block",
                        }}
                      >
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            closeEnquiry(item?.id);
                          }}
                          style={{
                            border: "1px solid #CECECE",
                            marginLeft: "3%",
                            fontSize: "12px",
                            width: "6.5rem",
                            zIndex: 2,
                          }}
                        >
                          Close Enquiry
                        </button>
                      </Box>
                    </Box>
                    <Box
                      className="py-1 mt-2 px-1"
                      style={{
                        color: "black",
                        display: "flex",
                        width: isXs ? "60%" : "100%",
                        fontSize: "14px",
                        border: "1px solid #c3c4c3",
                        borderRadius: "4px",
                      }}
                    >
                     <Box
  sx={{
    display: "flex",
    gap: ".5rem",
    flexWrap: "wrap",
    overflowX: "auto", // Allows horizontal scrolling if content overflows
    maxWidth: "100%", // Ensures it doesn't exceed the container width
  }}
>
  {item?.subjects?.map((subject, index) => (
    <Box
      sx={{
        display: "flex",
        fontSize: {
          xs: "10px", // Font size for extra small screens
          sm: "12px", // Font size for small screens and up
        },
      }}
      key={index}
    >
      {subject?.subjectName?.subject_name},
    </Box>
  ))}
</Box>
                    </Box>
                    <Box
                      className="px-1 mt-2"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        gap: ".5rem",
                      }}
                    >
                      {" "}
                      <LaptopMacOutlinedIcon
                        style={{ fontSize: "1.1rem", fill: "#CB8B65" }}
                      />
                      {item?.classTypes?.map((classType, index) => (
                        <Box key={index}>{classType?.classTypeName?.name}</Box>
                      ))}
                    </Box>
                    <Box
                      className="d-flex justify-content-between py-1 align-items-center"
                      style={{ marginBottom: "1%" }}
                    >
                      <Box
                        className="d-flex justify-content-center align-items-center mt-0"
                        style={{
                          border: "1px solid #CECECE",
                          borderRadius: "10px",
                          padding: "3px",
                          backgroundColor: "#F1F1F1",
                          width: isXs ? "25%" : "40%",
                          height: "2rem",
                          fontSize: "12px",
                        }}
                      >
                        {item.teacherAllDetails.length} Connected
                      </Box>
                      <Box
                        sx={{ display: isXs ? "flex" : "none", width: "10rem" }}
                      >
                        {item?.teacherAllDetails
                          ?.slice(0, 3)
                          .map((teacher, index) => (
                            <Box
                              key={index}
                              display={"flex"}
                              alignItems="center"
                            >
                              {teacher?.user &&
                                typeof teacher.user === "object" && (
                                  <Box sx={{ marginBottom: "20%" }}>
                                    {teacher.user?.profile_image &&
                                      typeof teacher.user.profile_image ===
                                        "object" && (
                                        <Box>
                                          <img
                                            src={
                                              teacher.user.profile_image
                                                .file_name
                                            }
                                            style={{
                                              width: "1.5rem",
                                              height: "1.5rem",
                                              objectFit: "cover",
                                              marginLeft: "3%",
                                              borderRadius: "50%",
                                            }}
                                            alt={`Profile ${index + 1}`}
                                          />
                                        </Box>
                                      )}
                                  </Box>
                                )}
                            </Box>
                          ))}
                        {item?.teacherAllDetails?.length > 3 && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "3%",
                              borderRadius: "50%",
                              cursor: "pointer",
                              marginTop: "2%",
                            }}
                          >
                            <Box>+{item.teacherAllDetails.length - 3}</Box>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}
                      >
                        <div
                          key={index}
                          className="d-flex justify-content-center gap-2 align-items-center"
                          style={{
                            border: "1px solid #CECECE",
                            borderRadius: "5px",
                            width: "100%",
                            fontSize: "13px",
                            backgroundColor:
                              item.postStatus === 0 ? "#FF0000" : "#20C012",
                          }}
                        >
                          <Box sx={{ color: "white" }}>Status:</Box>
                          <Box
                            style={{
                              border: "None",
                              padding: "0%",
                              color: "white",
                              height: "2rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.postStatus === 0 ? "Close" : "Open"}
                          </Box>
                        </div>
                        <Box style={{ fontSize: "12px" }}>{item.createdAt}</Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </button>
            ))
          : "No Data Found"}
      </Box>
    </Stack>
  );
};

export default Home;
