import React, { useEffect, useState } from 'react'
import SampleImage from '../../../assets/SampleImage.svg';
import line from '../../../assets/line.svg';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { teacherFeedback } from '../../features/student/listingSlice';
import { useNavigate, useParams } from 'react-router-dom';



const TeacherReviews = () => {

    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [reviewStatus , setReviewStatus] = useState(false);

    const { teacherId } = useParams();


    // const userId = useSelector((item) => item.listing.postPayload.userId)
    // console.log(userId);
    const teacherData = useSelector((item) => item?.listing?.teacherForReview)
    // console.log(teacherData  ,'teacherDatasss');
    // const teacherId = teacherData.id


    const reviewData = useSelector((state) => state.listing.teacherGetFeedback)

    const checkReview = () =>{
        const user = JSON.parse(localStorage.getItem('user'));
        const hasReviewed = reviewData?.some((item) => item?.userId === user?.id);
        setReviewStatus(hasReviewed);
    }


    const teacherReviews = () => {
        dispatch(teacherFeedback({ teacherId: teacherId }))
    }

    useEffect(() => {
        teacherReviews()
        checkReview();
    }, [])

    const isNavigate = (event, value) => {
        navigate('/dashboard/write-review')
    }

    const goBack = () => {
        navigate(-1);
    };

    const isXs = useMediaQuery('(min-width:600px)');

    return (
        <>
            <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                <Box>
                    <Typography variant='h5'>{teacherData?.user?.first_name+' '+teacherData?.user?.last_name}</Typography>
                    <Box>Feedbacks & Ratings</Box>
                    <Box><img src={line} /></Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                {reviewStatus ? null : <button onClick={() => isNavigate()} style={{ backgroundColor: '#FFDB7E', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', padding: 2, width:'60%' }}>
                    <Box sx={{ fontSize: isXs ? '2vw' : '5vw', color: '#866D2C' }}>Write a Review</Box>
                </button>}
            </Box>
            <Box className="d-flex row mt-3" style={{ marginLeft: '2%', overflowY: 'auto' }}>

                {/* {reviewData?.map((item, index) => (
                    <Box key={index} style={{ width: isXs ? '45%' : '95%' }}>
                        <Box className=" mt-2 px-3 py-2 shadow-sm" style={{ border: '1px solid #D6D6D6', backgroundColor: 'white', marginBottom: '3%' }}>
                            <Box className='d-flex justify-content-between py-1' style={{ marginBottom: '1%' }}>
                                <Box className='d-flex align-items-center'>
                                    <img src={item?.file?.file_name} style={{ width: '30%', marginLeft: '1%' }} alt="Sample" />
                                    <Box sx={{ '& > legend': { mt: 2 }, marginLeft: '5%' }} >
                                        <Rating
                                            name={`rating-${index}`}
                                            value={item?.rating}
                                            size="small" />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='d-flex py-1 '>
                                <Box>{item.student?.first_name} {item.student?.last_name}</Box>
                                <Box style={{ color: '#CB8B65' }}> - {item?.profession}</Box>
                            </Box>
                            <Box className='py-1' style={{ fontSize: '13px' }}>{item?.description}</Box>
                        </Box>
                    </Box>
                ))} */}

<Box style={{ width: isXs ? "45%" : "95%" }}>
              {reviewData && reviewData.length > 0
                ? reviewData.map((info, index) => {
                    return (
                      <Box
                        className=" mt-2 px-3 py-2 shadow-sm"
                        style={{
                          border: "1px solid #D6D6D6",
                          backgroundColor: "white",
                          marginBottom: "3%",
                        }}
                      >
                        <Box
                          className="d-flex justify-content-between py-1"
                          style={{ marginBottom: "1%" }}
                        >
                          <Box
                            className="d-flex align-items-center"
                            style={{ width: "210px" }}
                          >
                            <img
                              src={info?.file?.file_name ?? SampleImage}
                              style={{ width: "30%", marginLeft: "1%" }}
                            />
                            <Box
                              sx={{ "& > legend": { mt: 2 }, marginLeft: "5%" }}
                            >
                              <Rating
                                name="simple-controlled"
                                value={info.rating}
                                // onChange={(event, newValue) => { setValue(info?.rating) }}
                                size="small"
                              />
                            </Box>
                          </Box>
                          <Box sx={{ fontSize: "14px" }}>{info?.createdAt}</Box>
                        </Box>
                        <Box className="d-flex py-1 ">
                          <Box>
                            {info?.student?.first_name +
                              " " +
                              info?.student?.last_name}
                          </Box>
                        </Box>
                        <Box className="py-1" style={{ fontSize: "13px" }}>
                          {info?.description}
                        </Box>
                      </Box>
                    );
                  })
                : "No Data Found"}
            </Box>
            </Box>
        </>
    )
}

export default TeacherReviews
