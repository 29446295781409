import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Protected(props) {
    const {Component} = props;
    const navigate = useNavigate();
    useEffect(()=>{
     const user = JSON.parse(localStorage.getItem('user'))
     if(!user?.accessToken){
        navigate('/');
     }
    },[]);
  return (
    <div>
        <Component/>
    </div>
  )
}
