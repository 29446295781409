import { Autocomplete, Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import line from '../../../../../assets/line.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cityListing, profileUpdateTeachers, statesListing, teacherDetails ,getPinCodeStatus} from '../../../../features/teacher/teacherListingSlice';
import { toast } from 'react-toastify';
import { userCity } from '../../../../features/student/listingSlice';


const Address = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = JSON.parse(localStorage.getItem('user'))
    const userId = user?.id

    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [isCity, setIsCity] = useState('')
    const [isState, setIsState] = useState('')
    const [isPincode, setIsPincode] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [isPincodeChecked, setIsPincodeChecked] = useState(true)


    // const [isRadio, setIsRadio] = useState('');


    const teacher = useSelector((item) => item?.teacherListing?.availableCredits)
    const stateData = useSelector((state) => state?.teacherListing?.stateList)
    // console.log(stateData , 'stateData');
    const cityData = useSelector((state) => state?.teacherListing?.cityList)

    const handleAddress = () => {
        dispatch(teacherDetails({ userId: userId }))
            .then((response) => {
                if (response?.payload?.status == 200) {
                    setAddressLine1(response?.payload?.data?.teacher_profile?.adress_line_one)
                    setAddressLine2(response?.payload?.data?.teacher_profile?.adress_line_two)
                    setIsPincode(response?.payload?.data?.teacher_profile?.pincode)
                    setState({
                        id: response?.payload?.data?.teacher_profile?.state?.state_id,
                        stateName: response?.payload?.data?.teacher_profile?.state?.state_name
                    })
                    setCity({
                        id: response?.payload?.data?.teacher_profile?.city?.city_id,
                        cityName: response?.payload?.data?.teacher_profile?.city?.city_name
                    })
                    setIsState(response?.payload?.data?.teacher_profile?.state?.state_id)
                    setIsCity(response?.payload?.data?.teacher_profile?.city?.city_id)
                }
            }).catch((error) => {
                console.log('getting error', error);
            })
    }

    const handleSaveChanges = () => {
        if (addressLine1 === '' || addressLine1 === null || addressLine1 === 0) {
            toast.error('address first is required.');
        } else if (addressLine2 === '' || addressLine2 === null || addressLine2 === 0) {
            toast.error('address second is required.');
        } else if (isPincode === '' || isPincode === null || isPincode === 0) {
            toast.error('pincode  is required.');
        } else if (isState === '' || isState === null || isState === 0) {
            toast.error('state  is required.');
        } else if (isCity === '' || isCity === null || isCity === 0) {
            toast.error('city  is required.');
        } else if (isPincodeChecked == false) {
            toast.error("Please fill right Pincode !")
          }
        else {
            dispatch(profileUpdateTeachers({ userId: userId, addressLineOne: addressLine1, addressLineTwo: addressLine2, pincode: isPincode, cityId: isCity, stateId: isState, countryId: 101 })).then((response) => {
                if (response?.payload?.success) {
                    toast.success(response?.payload?.message);

                }
            })
        }
    }

    const findState = async (event, value) => {
        dispatch(statesListing({ countryId: 101 }))
    }

    // const handleStateClick = async (event, value) => {
    //     // setIsState(event.target.value)
    //     const selectedState = stateData?.find((state) => state?.stateName === value);
    //     // console.log(selectedState , 'selectedState');
    //     setState(selectedState);
    //     console.log(state , 'dnjsadjs?a');
    //     dispatch(cityListing({ stateId: selectedState?.id }))
    //     // dispatch(storeLocation(selectedState))
    //     setIsState(selectedState?.id);
    // }

    const handleStateClick = (event, value) => {
        const selectedState = stateData?.find((state) => state?.stateName === value);
        setState(selectedState || { stateName: '', id: null });
        if (selectedState) {
            dispatch(cityListing({ stateId: selectedState.id }));
            setIsState(selectedState.id);
        }
        setIsPincode("")

    };


    const handleCityClick = async (event, value) => {
        const selectedCity = cityData?.find((city) => city?.cityName === value);
        setCity(selectedCity || { cityName: '', id: null });
        if (selectedCity) {
            setIsCity(selectedCity.id);
        }
        setIsPincode("")
    }
    const handlePinCodeCheck = async (e) => {
        setIsPincodeChecked(false)
        try {
          const response = await dispatch(getPinCodeStatus(e.target.value))
    
          if (response?.payload[0]?.Status == "Success") {
    
            const res1 = response?.payload[0]?.PostOffice.some((item) => (state.stateName.toLowerCase().toString() == item.State.toLowerCase().toString()));
            const res2 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.District.toLowerCase().toString()));
            const res3 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Division.toLowerCase().toString()));
            const res4 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Region.toLowerCase().toString()));
            const res5 = response?.payload[0]?.PostOffice.some((item) => (city.cityName.toLowerCase().toString() == item.Block.toLowerCase().toString()));
            const res6 = response?.payload[0]?.PostOffice.some((item) => (item.Name.toLowerCase().toString().includes(city.cityName.toLowerCase().toString())));
            // console.log({"res1":res1,"res2":res2,"res3":res3,"res4":res4,"res5":res5,"res6":res6})
    
            const res = res1 && res2 || res3 || res4 || res5 || res6
    
            if (res == false) {
              setIsPincodeChecked(false)
              toast.error("Please fill right PinCode !")
    
            } else if (res == true) {
              setIsPincodeChecked(true);
              toast.success("Pincode is right !")
            }
    
          } else {
            toast.error("No Pincode available !")
            setIsPincodeChecked(false)
    
          }
    
        } catch (error) {
          console.log("bk", error)
        }
    
      }



    useEffect(() => {
        handleAddress()
        findState()
    }, [userId])

    const handleAddress1 = (e) => {
        setAddressLine1(e.target.value)
    }

    const handleAddress2 = (e) => {
        setAddressLine2(e.target.value)
    }

    const handlePincode = async (e) => {
        const re = /^[0-9\b]+$/; //rules
        if (e.target.value === "" || re.test(e.target.value)) {
            setIsPincode(e.target.value);
    
        }
    
      }


    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        // console.log(classPreferenceId , 'classPreferenceId');
        console.log(state, "state data");
    }, [
        state,
        isCity
    ]);
    return (
        <Box>
            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5' style={{ fontFamily: 'jost' }}>Address</Typography>
                        <Box>Set your exact address, so we can send you nearby enquires.</Box>
                        <Box><img src={line} /></Box>
                    </Box>
                </Box>
                <Box className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    <button onClick={handleSaveChanges} className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Save Changes</button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '93%', padding: 2, display: 'flex', gap: '.6rem' }}>
                    <Box>
                        <TextField value={addressLine1} onChange={handleAddress1} id="outlined-basic" InputLabelProps={{ style: { fontFamily: 'jost' } }} label="Address line 1" variant="outlined" sx={{ width: '100%' }} />
                    </Box>

                    <Box sx={{ marginTop: isXs ? '2%' : '5%' }}>
                        <TextField value={addressLine2} onChange={handleAddress2} id="outlined-basic" InputLabelProps={{ style: { fontFamily: 'jost' } }} label="Address line 2" variant="outlined" sx={{ width: '100%' }} />
                    </Box>

                    <Box sx={{ display: isXs ? 'flex' : '', marginTop: isXs ? '2%' : '5%', gap: 4, justifyContent: 'start' }}>
                        <Box sx={{ display: isXs ? '' : 'flex', justifyContent: 'start' }}>
                            {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: isXs ? '10rem' : '100%' }}
                            renderInput={(params) => <TextField {...params} label="State" />} /> */}
                            {/* <Autocomplete
                            fullWidth
                            id="free-solo-demo"
                            freeSolo
                            value={isState}
                            sx={{ width: isXs ? '10rem' : '100%' }}
                            onChange={handleStateClick}
                            options={stateData?.map((option) => option.stateName)}
                            renderInput={(params) => <TextField {...params} label="State" InputProps={{style: {fontFamily: 'jost'}}} />}
                            disableClearable
                        /> */}

                            <Autocomplete
                                fullWidth
                                id="free-solo-demo"
                                freeSolo
                                value={state?.stateName || ''}
                                sx={{ width: isXs ? '10rem' : '100%' }}
                                onChange={handleStateClick}
                                options={stateData?.map((option) => option.stateName) || []}
                                renderInput={(params) => <TextField {...params} label="State" />}
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderOption={(props, option) => (
                                    <li {...props} key={option}>
                                        {option}
                                    </li>
                                )}
                            />
                        </Box>

                        <Box sx={{ marginTop: isXs ? '' : '5%', display: isXs ? '' : 'flex', justifyContent: 'start' }}>
                            {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: isXs ? '10rem' : '100%' }}
                            renderInput={(params) => <TextField {...params} label="City" />}/> */}
                            {/* <Autocomplete
                            fullWidth
                            id="free-solo-demo"
                            freeSolo
                            value={isCity}
                            onChange={handleCityClick}
                            sx={{ width: isXs ? '10rem' : '100%' }}
                            options={cityData?.map((option) => option?.cityName)}
                            renderInput={(params) => <TextField {...params} label="City" InputProps={{style: {fontFamily: 'jost'}}}/>}
                            disableClearable
                            
                        /> */}

                            <Autocomplete
                                fullWidth
                                id="free-solo-demo"
                                freeSolo
                                value={city?.cityName || ''}
                                onChange={handleCityClick}
                                sx={{ width: isXs ? '10rem' : '100%' }}
                                options={cityData?.map((option) => option?.cityName)}
                                renderInput={(params) => <TextField {...params} label="City" />}
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderOption={(props, option) => (
                                    <li {...props} key={option}>
                                        {option}
                                    </li>
                                )}
                            />
                        </Box>

                        <Box sx={{ marginTop: isXs ? '' : '5%' }}>

                        
                            <TextField
                                value={isPincode}
                                inputProps={{ maxLength: 6, mixLength: 6 }}
                                onChange={(e) => {
                                    e.target.value.length >= 6 && handlePinCodeCheck(e);
                                    handlePincode(e)
                                }}
                                id="outlined-basic"
                                label="Pincode"
                                variant="outlined"
                                sx={{ width: isXs ? '10rem' : '100%' }} />
                        </Box>
                    </Box>
                    <Divider />
                    {/* <Box sx={{ marginTop: isXs ? '2%' : '8%' }}>
                    <Typography>Address display preference</Typography>
                    <FormControl>
                        <RadioGroup
                            value={isRadio}
                            onChange={handleRadioClick}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="aa" control={<Radio />} label="Display just the locality (Recommended for privacy) Indira Nagar, Jaipur, India - 302017" />
                            <FormControlLabel value="bb" control={<Radio />} label="Display full address" />
                        </RadioGroup>
                    </FormControl>
                </Box> */}

                </Stack>
            </Box>
        </Box>
    )
}

export default Address
