import React, { useEffect, useState } from 'react'
// import line from '../../../../../assets/line.svg'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
// import { profileUpdateTeachers, teacherDetails } from '../../../../features/teacher/teacherListingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { profileUpdateTeachers, teacherDetails } from '../../../features/teacher/teacherListingSlice';
import { FloatingLabel } from 'react-bootstrap';
import { helpAndSupport } from '../../../features/student/listingSlice';

import {
    Backdrop, CircularProgress, Switch, styled, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const HelpSupport = () => {
    const profileinformation  = JSON.parse(localStorage.getItem('user'))

console.log("profileinformation",profileinformation)
    const navigate = useNavigate()
    const isXs = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const [email, setEmail] = useState(profileinformation?.email);
    const [mobile, setMobile] = useState(profileinformation?.mobile);
    const [issue, setIssue] = useState('');

    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);


    const isValidIndianMobileNumber = (mobile) => {
        const indianMobileNumberPattern = /^[6-9]\d{9}$/;
        return indianMobileNumberPattern.test(mobile);
    };
    const state = useSelector((state) => state.teacherListing);


    const handleEmailChange = (event) => {
        setError("");
        setEmail(event.target.value);
    };
    const handleSupport = () => {

        //
        if (email === '' || email === null || email === 0) {
            setError("Email field is required.");
            setOpen(true);
            // toast.error('Input Email field is required.');
        } else if (mobile === '' || mobile === null || mobile === 0) {

            toast.error('Input Mobile field is required.');
        } else if (issue === '' || issue === null || issue === 0) {
            toast.error('Input Description field is required.');
        }
        else {

            setError("");

            const userId = JSON.parse(localStorage.getItem('user'));
            const payload = {
                userId: userId?.id,
                mobile: mobile,
                email: email,
                message: issue
            }

            if (isValidIndianMobileNumber(mobile)) {
                dispatch(helpAndSupport(payload)).then((response) => {
                    if (response?.payload?.success) {
                        toast.success(response?.payload?.message);
                    }
                })
            } else {
                toast.error('Please enter a valid  mobile number.');
            }

        }
        //

    }

    const goBack = () => {
        navigate(-1);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        navigate('/dashboard/profileedit');
        // Handle the confirmation action here

        setOpen(false);
    };

    return (
        <Box >
            {/*  */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: 50, // Align the dialog to the top
                        m: 0 // Remove the default margin
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Change"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please complete your profile information!       </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{}}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} style={{}} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/*  */}


            <Box sx={{ display: isXs ? 'flex' : '', justifyContent: 'space-between' }}>
                <Box className="mt-3" sx={{ marginLeft: '1%', display: 'flex', gap: 1 }}>
                    <button onClick={goBack} style={{ border: 'none', background: 'transparent', display: 'flex', alignItems: 'start' }}><ArrowBackRoundedIcon sx={{ color: 'white', backgroundColor: '#CB8B65', borderRadius: '10px', width: '2rem', height: '2rem', padding: '5px' }} /></button>
                    <Box>
                        <Typography variant='h5'>Help & Supporst</Typography>
                        {/* <Box>Update your Personal Information to be displayed on your Profile</Box> */}
                        {/* <Box><img src={line} /></Box> */}
                    </Box>
                </Box>
                <Box onClick={() => handleSupport()} className="py-4 text-end px-5" style={{ width: isXs ? '30%' : '100%' }}>
                    {/* <button onClick={() => handleSupport()}className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button> */}
                    <button className="btn btn-primary py-2 px-4 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Submit</button>

                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Stack sx={{ backgroundColor: '#FBFBFB', minHeight: '55vh', width: '100%', textAlign: 'center' }}>
                    <Grid container marginTop={isXs ? '5%' : '15%'} sx={{ marginLeft: isXs ? '' : '8%' }}>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <TextField
                            disabled={true}
                                label="Email"
                                value={email}
                                onChange={(e) => handleEmailChange()}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'black' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px',
                                        borderColor: 'grey',
                                    },
                                    minWidth: '80%'
                                }}
                            />
                            <Box my={2} />

                            {error && <span style={{ color: 'red', marginTop: '5px', margin: "5px 190px 0 0" }}>{error}</span>}

                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={6} xl={6} xxl={6} marginTop={isXs ? '' : '15%'}>
                            <TextField
                            disabled={true}
                                label="Mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                inputProps={{ style: { height: '10px' } }}
                                InputLabelProps={{ sx: { color: 'black' } }}
                                color='grey'
                                focused
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px',
                                        borderColor: 'grey',
                                    },
                                    minWidth: '80%'
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Box style={{ marginTop: "1.5rem", textAlign: "left", marginLeft: "2rem" }}>
                        <p style={{ marginLeft: "1rem" }}>Write Your Support</p>
                        <TextField
                            value={issue}
                            onChange={(e) => setIssue(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            placeholder="Issue/Query"
                        />
                    </Box>
                </Stack>
            </Box>

        </Box>
    )
}

export default HelpSupport
