import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import { Steppers1 } from './StepperWeb';
import StepperPhone, { Step1 } from './StepperPhone';
import pencil from '../../../assets/pencil.svg';
import nbook from '../../../assets/nbook.svg';
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import InquiryLayout from './InquiryLayout';
import { useNavigate } from 'react-router-dom';
import NextButton from '../../Buttons/NextButton';
import { findSubject, storeClassId, studentProfileDetails } from '../../features/student/listingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BackButton from '../../Buttons/BackButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Landing from '../LandingPage/Landing';
import bg_new from '../../../assets/BG_NEW.jpg';




const Subject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isXs = useMediaQuery('(min-width:600px)');
  const [postCount, setPostCount] = useState();


  const user = JSON.parse(localStorage.getItem('user'));
  const listData = useSelector((state) => state?.listing?.classData);

  const findSubjects = async () => {
    dispatch(findSubject());
  };

  const studentDetails = () => {
    dispatch(studentProfileDetails({ userId: user?.id })).then((response) => {
      if (response?.payload?.status === 200) {
        setPostCount(response?.payload.data.postCount);
      }
    }).catch((error) => {
      console.log('getting error', error);
    });
  };
  const [className, setClassName] = useState('');

  const handleChange = async (event, value) => {
    const selectedClass = listData?.find((subject) => subject?.className === value);
    const classId = selectedClass.id;
    setClassName(selectedClass);
    dispatch(storeClassId(selectedClass));
  };

  useEffect(() => {
    findSubjects();
    studentDetails();
  }, []);

  const selectedClass = useSelector((item) => item?.listing?.classSelect);

  const isNavigate = () => {
    if (!selectedClass?.id) {
      toast.error('Class field is required.');
    } else {
      navigate(postCount > 0 ? '/dashboard/location' : '/location');
    }
  };


  const goBack = () => {
    navigate(-1);
  };


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        
           backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
       
        
        
      >
        <div style={{display: "flex" , flexDirection: "column" , alignItems: "center" , justifyContent: "space-between" , paddingBottom: "2rem"}}>
        <Modal.Body style={{display: "flex" , flexDirection: "column" , gap: "1rem" , padding: "2rem" , alignItems: "center"}} >
          <h4>Are you sure you want to exit?</h4>
          <p style={{fontSize: "1.1rem" , textAlign: "center" , width: "60%"}}>You’ll  need to log in again if you leave this screen.</p>
          
        </Modal.Body>
        
        <div style={{display: "flex" , justifyContent: "space-between" , gap: "2rem"}}>

       <a style={{border: "none" , width: "70%" , backgroundColor: "#CB8B65",color: "white" , textDecoration: 'none' , padding: ".5rem 2.5rem"}} href="https://kanhahometutions.com/">Yes</a> 
        <button style={{border: ".5px solid lightgrey", width: "70%" , backgroundColor: "white" , padding: ".5rem 2rem"}} onClick={props.onHide}>Cancel</button>
        </div>
        </div>
        
      </Modal>
    );
  }
  


  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <InquiryLayout>
        <Box sx={{ position: "relative" }}>
          <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#CB8B65', color: 'white', fontSize: '22px', fontWeight: 'normal' }}>
            {user?.postCount > 0 && <BackButton style={{ marginRight: 'auto' }} onClick={goBack} />}
            <div className='d-flex justify-content-center align-items-center mx-auto'>What do you want to Learn?</div>
          </Box>
          <div className='my-5' style={{ display: isXs ? '' : 'none' }}> 
            <Steppers1 />
          </div>
          <div className='d-flex justify-content-center' style={{ display: isXs ? 'none' : '' }}>
            <div className='my-5' style={{ display: isXs ? 'none' : '' }}>
              <Step1 />
            </div>
          </div>
          <Box>
            <div className='d-flex justify-content-center' style={{ marginTop: '5%' }}>
              <div className='d-flex' style={{ width: isXs ? '60%' : '80%', borderRadius: '0px' }}>
                <Autocomplete
                  fullWidth
                  id="free-solo-demo"
                  freeSolo
                  value={selectedClass?.className}
                  onChange={handleChange}
                  options={listData?.map((option) => option.className)}
                  renderInput={(params) => <TextField {...params} label="Eg: English, Science, Accounts etc" />}
                  disableClearable
                />
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <div style={{ marginTop: '6%', width: isXs ? '' : '70%' }}>
                <NextButton onClick={isNavigate} />
              </div>
            </div>
          </Box>
          <div className='d-flex justify-content-between px-4'>
            <img src={nbook} alt='vector' className='img-fluid' style={{ width: '13%' }} />
            <img src={pencil} alt='vector' className='img-fluid mt-4' style={{ width: '2%' }} />
          </div>
        </Box>
      </InquiryLayout>

      {window.location.pathname!=="/dashboard/learningneeds" ?(
        <button type='button' onClick={() => setModalShow(true)} style={{ width: "2rem", height: "2rem", backgroundColor: "white", borderRadius: "20px", position: "absolute", top: "10%", left: isXs ? "77%" : "90%", border: "none" }}>
        X
      </button>):null}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Subject;
