import {
    createSlice,
    createAsyncThunk,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";

export const classConducted = createAsyncThunk(
    "teacher/classConducted",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherClassConducted", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const teacherReviews = createAsyncThunk(
    "teacher/reviews",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherGetReview", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);


const initialState = {
    postDetail:'',
    classConduct:[],
    teacherReviews:[],
    loading: false,
    error: null,
};


export const teacherClassConductSlice = createSlice({
    name: "teacher",
    initialState,
    reducers: {
        storeSignupResponse: (state, { payload }) => {
            // state.signUpResponseData = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(classConducted.pending, (state) => {
                state.loading = true;
            })
            .addCase(classConducted.fulfilled, (state, action) => {
                state.loading = false;
                state.classConduct = action.payload.data;
                state.error = null;
            })
            .addCase(classConducted.rejected, (state, action) => {
                state.loading = false;
                state.classConduct = null;
                state.error = action.payload;
            })
            .addCase(teacherReviews.pending, (state) => {
                state.loading = true;
            })
            .addCase(teacherReviews.fulfilled, (state, action) => {
                state.loading = false;
                state.teacherReviews = action.payload.data;
                state.error = null;
            })
            .addCase(teacherReviews.rejected, (state, action) => {
                state.loading = false;
                state.teacherReviews = null;
                state.error = action.payload;
            })
            
        },
});


export const {
    storeSignupResponse
} = teacherClassConductSlice.actions;

export default teacherClassConductSlice.reducer;
